import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import Table from 'react-bootstrap/Table';
import deleteicon from '../../../assets/images/delete_forever.png';
import submiticon from '../../../assets/images/submit-svgrepo-com.svg';
import copyicon from '../../../assets/images/files-empty.svg';
import { 
    generatePartnerUrl, 
    getPartnerUrls,
    deletePartnerUrl,
} from "../../../api/api";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ErrorPopup from "../ErrorPopup";

const CDNPopUp = ({show, handleClose, formData, setFormData, setErrors, errors}) => {

    const defaultValue = {
        partnerPlaybacks: [
            {
                partnerCode: "",
                partnerName: "",
                contentPlaybackUrl: "",
            }
        ],
    }
    const [partnerPlaybacksData, setPartnerPlaybacksData] = useState(defaultValue);
    const [errorbox, setErrorbox] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [btntext, setbtntext] = useState(null);
    const [eventdelete, seteventdelete] = useState(null);
    const {
        appThemeReducer: { appTheme },
    } = useSelector((state) => state);

    const getPartnerUrl = async(id) => {
        const res = await getPartnerUrls(id);
        setPartnerPlaybacksData({...partnerPlaybacksData, partnerPlaybacks: res?.data});
    }

    useEffect(() => {
        getPartnerUrl(formData?.id);
    },[formData?.id])

    const addCDN = () => {
        const content = [...partnerPlaybacksData?.partnerPlaybacks, 
        {
            partnerCode: "",
            partnerName: "",
            contentPlaybackUrl: "",
        }];
        setPartnerPlaybacksData({ ...partnerPlaybacksData, partnerPlaybacks: content });
        setErrors({ ...errors, partnerPlaybacks: [...errors.partnerPlaybacks, {
            partnerCode: "",
            partnerName: "",
            contentPlaybackUrl: "",
        }] });
    }

    const updatedCDNLocation = ((event, index, key) => {
        const content = partnerPlaybacksData.partnerPlaybacks.map((item, i) => {
            if(i === index) {
              return {
                ...item,
                [key]: event.target.value
              }
            }
            return item;
        });
        setPartnerPlaybacksData({ ...partnerPlaybacksData, partnerPlaybacks: content });
    })

    const removeCDNLocation = async (data, indexToRemove) => {
        const res = await deletePartnerUrl(formData?.id, data?.id);
        if (res?.data?.success === true) {
            toast.success(res?.data?.message, {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 1000
            });
            getPartnerUrl(formData?.id);
        } else if (res?.success === false) {
            toast.error(res?.message, {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 1000
            });
        }
    }; 

    const deletepopup = (data, indexToRemove) => {
        if (data?.id) {
            setbtntext("Delete");
            setErrorMessage("Do you want to delete ?");
            setErrorbox(true);
            seteventdelete(() => () => removeCDNLocation(data, indexToRemove));
        } else if (indexToRemove) {
            const updatedCDNList = partnerPlaybacksData?.partnerPlaybacks?.filter((item, index) => index !== indexToRemove);
            setPartnerPlaybacksData({ ...partnerPlaybacksData, partnerPlaybacks: updatedCDNList });
        }
    }
    
    const generateUrl = async (data) => {
        const res = await generatePartnerUrl(data, formData?.id);
        if (res?.data?.success === true) {
            toast.success(res?.data?.message, {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 1000
            });
            getPartnerUrl(formData?.id);
        } else if (res?.response?.data?.success === false) {
            toast.error(res?.response?.data?.message, {
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 1000
            });
        }
    };

    const copyToClipboard = (data) => {
        if (data) {
            // Fallback for unsupported Clipboard API
            const textArea = document.createElement("textarea");
            textArea.value = data;
            document.body.appendChild(textArea);
            textArea.select();
            try {
                document.execCommand("copy");
                toast.success("Copied to clipboard.", {
                    position: toast.POSITION.BOTTOM_LEFT,
                    autoClose: 1000
                });
            } catch (err) {
                toast.error("Failed to copy to clipboard.", {
                    position: toast.POSITION.BOTTOM_LEFT,
                    autoClose: 1000
                });
            }
            document.body.removeChild(textArea);
        }
    } 

    const errorboxclose = () => {
        setErrorbox(false);
        setErrorMessage("");
    }

    return (
        <>
            <Modal
                show={show}
                className={`${appTheme}`}
                onHide={handleClose}
                size="xl"
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Generate Partner URLs
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table size="sm">
                        <thead>
                            <tr>
                                <th className="w-25">Partner Name</th>
                                <th className="w-25">Partner Code</th>
                                <th></th>
                                <th>Playback URL</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                partnerPlaybacksData?.partnerPlaybacks?.map((item, index) => {
                                    return (
                                        <tr>
                                            <td key={index}>
                                                <Form.Group>
                                                    <Form.Control
                                                        className="border-0"
                                                        placeholder="Enter Partner’s Code"
                                                        type="text"
                                                        defaultValue={partnerPlaybacksData?.partnerPlaybacks[index]?.partnerCode}
                                                        onChange={(event) => updatedCDNLocation(event, index, 'partnerCode')}
                                                        // disabled
                                                    />
                                                </Form.Group>
                                            </td>
                                            <td>
                                                <Form.Group>
                                                    <Form.Control
                                                        className="border-0"
                                                        placeholder="Enter Partner’s Name"
                                                        type="text"
                                                        defaultValue={partnerPlaybacksData?.partnerPlaybacks[index]?.partnerName}
                                                        onChange={(event) => updatedCDNLocation(event, index, 'partnerName')}
                                                        // disabled
                                                    />
                                                </Form.Group>
                                            </td>
                                            <td className="pt-2">
                                                
                                            </td>
                                            <td>
                                                <Form.Group
                                                    className="d-flex"
                                                >
                                                    <Form.Control
                                                        className="border-0"
                                                        placeholder="Playback URL"
                                                        type="text"
                                                        defaultValue={partnerPlaybacksData?.partnerPlaybacks[index]?.contentPlaybackUrl}
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </td>
                                            <td className="pt-3">
                                                <div className="table-action-group">
                                                    <div className='icon-group d-flex justify-content-around'>
                                                        {
                                                            partnerPlaybacksData?.partnerPlaybacks[index]?.contentPlaybackUrl?
                                                            <a className="table-icon" variant="outline-secondary" onClick={() => copyToClipboard(partnerPlaybacksData?.partnerPlaybacks[index]?.contentPlaybackUrl)}><img src={copyicon} alt="Edit bill"/></a>
                                                            :
                                                            <a className="table-icon" onClick={() => generateUrl(partnerPlaybacksData.partnerPlaybacks[index])}><img src={submiticon} alt="Edit bill"/></a>
                                                        }
                                                        <a className="table-icon" onClick={() => deletepopup(partnerPlaybacksData.partnerPlaybacks[index], index)}><img src={deleteicon} alt="Delete bill"/></a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            } 
                        </tbody>
                    </Table>
                    <a className='btn btn-secondary btn-add mt-3' onClick={addCDN}>
                        Add Partner URL
                    </a>
                </Modal.Body>
                <Modal.Footer>
                <button className="Cancel btn btn-primary" onClick={handleClose}>
                    Cancel
                </button>
                </Modal.Footer>
            </Modal>
            {errorbox && (
                <div  style={{ position: 'fixed', top: '50%', left: '50%', zIndex: 1060 }}>
                    <ErrorPopup
                      btntext={btntext}
                      message={errorMessage}
                      closebox={() => errorboxclose()}
                      overrides={() => eventdelete()}
                    />
                </div>
            )}
        </>
    )
}

export default CDNPopUp;