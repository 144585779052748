import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom"; // Import Link and useLocation
import { useDispatch, useSelector } from "react-redux";
import asseticon from "../../assets/images/asset-icon.svg";
import channelicon from "../../assets/images/channel-icon.svg";
import viewershipicon from "../../assets/images/viewership-icon.svg";
import cmsicon from "../../assets/images/cms-dashboard.png";
import Engineicon from "../../assets/images/Engine-icon.svg";
import scheduling from "../../assets/images/scheduling-icon.svg";
import master from "../../assets/images/master-settings.svg";
import backicon from "../../assets/images/back-icon.svg";
import storefrontIcon from "../../assets/images/store-front.svg";
import tenantIcon from "../../assets/images/tenant.svg";
import UserManagement from "../../assets/images/user-management.svg";
import "../../assets/css/style.scss";
import { adminModeAction, } from "../../Redux/slices";
import { permissionDetails } from "../../utility/localStoageData";

function SuperAdminSidebar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [menuStatus, setMenuStatus] = useState(false);
  const location = useLocation(); // Get the current location from react-router-dom
  const [storeFrontAccess, setStoreFrontAccess] = useState("");
  const [permission, setpermission] = useState(null);
  const [identifier, setIdentifier] = useState("");
  
  const {
    appThemeReducer: { appTheme },
    adminModeReducer: { adminMode },  
  } = useSelector((state) => state);
  const backMenu = () => {
    if (menuStatus) {
      setMenuStatus(false);
    } else {
      setMenuStatus(true);
    }
  };
  const accessStore = async () => {
    const tenantLocalData = localStorage.getItem('tenantInfo');
    const tenantLocalDataParse = await JSON.parse(tenantLocalData);
    setStoreFrontAccess(tenantLocalDataParse?.superTenant?.code);
    setIdentifier(tenantLocalDataParse?.identifier);
  };

  const getPermission = () => {
    let findEdit = permissionDetails();
    setpermission(findEdit);
   }

  useEffect(()=>{
    getPermission();
    accessStore();
  },[])

  return (
    <div
      className={
        menuStatus
          ? `sidebar-menu menu-icon ${(adminMode || appTheme == "dark") ? "dark" : "light"}`
          : `sidebar-menu ${(adminMode || appTheme == "dark") ? "dark" : "light"}`
      }
    >
      <div className="back-menu">
        <a onClick={backMenu}>
          <img src={backicon} alt="Back Icon" />
        </a>
      </div>
      <div className="menu">
        <ul>
          <>
            
            <li
              className={
                location.pathname === "/user-management" ||
                location.pathname === "/user-add" ||
                location.pathname === "/"
                  ? "active"
                  : ""
              }
            >
              <Link to="/user-management">
                <img src={UserManagement} alt="User Management" />
                <span>User Management</span>
              </Link>
            </li>
            <li //</>className={location.pathname === "/tenant-onboarding" ? "active" : ""}
              className={
                location.pathname === "/tenant-onboarding" ||
                location.pathname === "/add-super-tenant" ||
                location.pathname === "/add-tenant" 
                  ? "active"
                  : ""
              }
            >
              <Link to="/tenant-onboarding">
                <img src={tenantIcon} alt="Tenant Onboarding" />
                <span>Tenant Onboarding</span>
              </Link>
            </li>
            <li
              className={
                location.pathname === "/audit-logs"
                ? "active"
                : ""
              }
            >
              <Link to="/audit-logs">
                <img src={tenantIcon} alt="Audit Logs" />
                <span>Audit Logs</span>
              </Link>
            </li>
          </>
        </ul>
      </div>
    </div>
  );
}

export default SuperAdminSidebar;
