import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "../../../assets/css/style.scss";
import updownarrowIcon from "../../../assets/images/up-down-arrow.svg";
import deleteIcon from "../../../assets/images/delete_forever.png";
import dragableIcon from "../../../assets/images/dragable-icon.png";
import searchIcon from "../../../assets/images/search.svg";
import { StoreFrontSpotlightContentListAction } from "../../../Redux/slices";
import {
  spotlightContentList,
  allAvailableSpotlightContentList,
  updateSpotlightContentList,
} from "../../../api/api";
import { image_url } from "../../../service/Constant";
import "../index.scss";
import DuplicatePopup from "../DuplicatePopup";
import UILoader from "../../../component/Loader/UILoader";

function Spotlight({ deviceType, platforms, permission }) {
  const dispatch = useDispatch();
  const { spotlightContentData } = useSelector(
    (state) => state.StoreFrontReducer
  );
  const draggedItem = useRef();
  const [changed, setChanged] = useState(false);
  const [duplicatePopup, setDuplicatePopup] = useState(false);
  const [duplicatePlatform, setDuplicatePlatform] = useState([]);
  const [sportlights, setSportlights] = useState([]);
  const [draggableList, setDraggableList] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    setLoader(true);
    getChannelList();
  }, []);

  useEffect(() => {
    if (draggableList) {
      filterData();
    }
  }, [draggableList]);

  useEffect(() => {
    if (duplicatePlatform?.length) {
      applyChanges();
    }
  }, [duplicatePlatform]);

  const filterData = () => {
    let filteredData = {};
    for (let i = 0; i < draggableList.length; i++) {
      if (filteredData[draggableList[i].program?.partner?.id]) {
        filteredData[draggableList[i].program?.partner?.id].push(
          draggableList[i].program?.id || draggableList[i].programId
        );
      } else {
        filteredData[draggableList[i].program?.partner?.id] = [
          draggableList[i].program?.id || draggableList[i].programId,
        ];
      }
    }
    let obj = {};
    for (let k in spotlightContentData.partnerIdProgramMap) {
      if (filteredData[k]) {
        let arr = spotlightContentData.partnerIdProgramMap[k].filter((item) => {
          return !filteredData[k].includes(item.programId);
        });
        obj[k] = [...arr];
      } else {
        obj[k] = [...spotlightContentData.partnerIdProgramMap[k]];
      }
    }
    setSportlights({ ...spotlightContentData, partnerIdProgramMap: obj });
  };
  const getChannelList = async () => {
    const result = await allAvailableSpotlightContentList();
    if (result?.data) {
      dispatch(StoreFrontSpotlightContentListAction(result.data));
    }
    getSpotlightContentList();
    setLoader(false);
  };

  const getSpotlightContentList = async () => {
    const spotlightData = await spotlightContentList(deviceType?.toUpperCase());
    if (spotlightData?.data?.length) {
      setDraggableList([...spotlightData.data]);
    }
  };

  const handleDragStart = (event) => {
    draggedItem.current = event.target;
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setData("text/html", draggedItem.current.innerHTML);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const handleDrop = (event) => {
    event.preventDefault();
    let { type, value, key } = draggedItem.current.dataset;
    let { id } = event.target.dataset;
    value = value ? JSON.parse(value) : value;
    let obj = {};
    if (type == "channel-list" && value) {
      obj = {
        programId: value["programId"],
        title: value["title"],
        new: true,
        program: {
          partner: {
            id: key,
          },
        },
      };
      let arr = sportlights?.partnerIdProgramMap[key].filter((item) => {
        return item.programId != value["programId"];
      });
      setSportlights({
        partnerIdMap: { ...sportlights.partnerIdMap },
        partnerIdProgramMap: { ...sportlights.partnerIdProgramMap, [key]: arr },
      });
      addToDragabbleList(obj);
      setChanged(true);
      return;
    }

    if (type == "draggable-list" && id) {
      let targetItem = document.querySelector(`[data-id='${id}']`);
      if (targetItem !== draggedItem.current) {
        if (
          event.clientY >
          targetItem.getBoundingClientRect().top + targetItem.offsetHeight / 2
        ) {
          targetItem.parentNode.insertBefore(
            draggedItem.current,
            targetItem.nextSibling
          );
        } else {
          targetItem.parentNode.insertBefore(draggedItem.current, targetItem);
        }
      }
      let targetPosition = { ...draggedItem.current.dataset };
      let destinationPosition = { ...targetItem.dataset };
      draggedItem.current = null;
      ArrangeData(targetPosition, destinationPosition);
    } else if (event.target.dataset.type == "table-body") {
      addToDragabbleList({
        programId: value["programId"],
        title: value["title"],
        new: true,
        program: {
          partner: {
            id: key,
          },
        },
      });
    }
  };

  const ArrangeData = (tar, des) => {
    let targetIndex = 0,
      destinationIndex = 0;

    draggableList.forEach((item, index) => {
      if (tar.id == item?.program?.id) {
        targetIndex = index;
      }
      if (des.id == item?.program?.id) {
        destinationIndex = index;
      }
    });
    let arr = [...draggableList];
    // const obj1 = { ...arr[targetIndex] };
    // const obj2 = { ...arr[destinationIndex] };
    // const position = obj2.position;
    // obj2.position = obj1.position;
    // obj1.position = position;
    // arr[targetIndex] = obj2;
    // arr[destinationIndex] = obj1;
    const [movedItem] = arr.splice(targetIndex, 1);
    arr.splice(destinationIndex, 0, movedItem);
    // Adjust positions
    arr = arr.map((item, index) => {
        return {
            ...item,
            position: index + 1
        };
    });
    setDraggableList([...arr]);
    setChanged(true);
  };

  const channelDragStart = (event) => {
    draggedItem.current = event.target;
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setData("text/html", draggedItem.current.innerHTML);
  };

  const addToDragabbleList = (data) => {
    let listSize = draggableList.length;
    const obj = { ...data, position: listSize + 1 };
    setDraggableList([...draggableList, obj]);
  };

  const cancelChanges = (event) => {};

  const applyChanges = async (event) => {
    const device = deviceType?.toUpperCase();
    const payload = {
      sequenceList: [],
      deviceType: "",
      duplicateOnDevices: [],
    };
    payload.deviceType = device;
    payload.duplicateOnDevices = [...duplicatePlatform];
    for (let i = 0; i < draggableList.length; i++) {
      payload.sequenceList.push({
        position: draggableList[i].position,
        programId: draggableList[i]?.programId || draggableList[i]?.program?.id,
      });
    }
    setLoader(true);
    const result = await updateSpotlightContentList(payload);
    if (result.status == 200) {
      toast.success(result.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      setChanged(false);
      getSpotlightContentList();
    } else {
      toast.error(result.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    }
    setLoader(false);
  };

  const deleteChannel = (e, channel) => {
    const arr = draggableList.filter((item) => {
      return (
        (item.programId || item.program.id) !=
        (channel.programId || channel.program.id)
      );
    });
    setDraggableList([...arr]);
    setChanged(true);
  };

  const getChannelName = (item) => {
    if (item?.title) {
      return item.title;
    }
    if (item?.program?.metadata) {
      return item.program.metadata.title;
    }
    if (item?.program?.filename) {
      return item.program.filename;
    }
    return "";
  };
  const applyForOthers = () => {
    setDuplicatePopup(true);
  };

  useEffect(() => {
    if (duplicatePlatform.length) {
      applyChanges();
    }
  }, [duplicatePlatform]);

  return (
    <>
      <div className="page-content">
        <div className="content-wrapper">
          <table className="store-front-table tbl-50-per">
            <thead>
              <tr>
                <th>
                  <img src={updownarrowIcon} />
                </th>
                <th>Sequence Number</th>
                <th>Program Name</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody
              className={draggableList.length == 0 ? "no-content" : ""}
              data-type="table-body"
              role="none"
              onDragStart={handleDragStart}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              {draggableList?.map((item, index) => {
                return (
                  <tr
                    className="dragabble"
                    draggable={permission === "edit"? true : false}
                    data-type="draggable-list"
                    key={`${index}-${item?.program?.id}`}
                    data-value={JSON.stringify(item)}
                    data-id={item.programId || item.program?.id}
                  >
                    <td data-id={item.programId || item.program?.id}>
                      <div
                        className="icon-group"
                        data-id={item.programId || item.program?.id}
                      >
                        <span
                          className="table-icon"
                          data-id={item.programId || item.program?.id}
                        >
                          <img
                            className="dragabble-icon"
                            src={dragableIcon}
                            alt="dragableIcon"
                            data-id={item.programId || item.program?.id}
                          />
                        </span>
                      </div>
                    </td>
                    <td data-id={item.programId || item.program?.id}>
                      {item.position}
                    </td>
                    <td data-id={item.programId || item.program?.id}>
                      {getChannelName(item)}
                    </td>
                    <td data-id={item.programId || item.program?.id}>
                      {
                        permission === "edit"?
                        <div
                          className="icon-group"
                          data-id={item.programId || item.program?.id}
                        >
                          <span
                            className="table-icon"
                            data-id={item.programId || item.program?.id}
                            onClick={(e) => deleteChannel(e, item)}
                          >
                            <img
                              src={deleteIcon}
                              data-id={item.programId || item.program?.id}
                            />
                          </span>
                        </div>
                        :
                        ''
                      }
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="channel-listing-cseq">
          <div className="search-wrapper">
            <div className="text-wrapper">
              <input type="text" placeholder="search text" className="input" />
            </div>
            <div className="search-icon">
              <img src={searchIcon} alt="searchIcon" />
            </div>
          </div>

          <div className="list-wrapper" onDragStart={channelDragStart}>
            {Object.keys(sportlights["partnerIdMap"] || {})?.map((item) => {
              return (
                <div
                  key={sportlights?.partnerIdMap[item].partnerCode}
                  className={`item-wrapper ${
                    item == selectedId ? "active" : ""
                  }`}
                >
                  <div
                    className="list-item"
                    data-id={item}
                    onClick={(e) => {
                      item == selectedId
                        ? setSelectedId("")
                        : setSelectedId(item);
                    }}
                  >
                    <div className="channel-name" data-id={item}>
                      {sportlights?.partnerIdMap[item].partnerName}
                    </div>
                    <span className="channel-arrow"></span>
                  </div>
                  <ul className="nexted-list">
                    {sportlights?.partnerIdProgramMap[item]?.map((data) => {
                      return (
                        <li
                          key={`${item}-${data.id}`}
                          className="nexted-list-item"
                          data-type="channel-list"
                          draggable={permission === "edit"? true : false}
                          data-value={JSON.stringify(data)}
                          data-key={item}
                        >
                          <img
                            src={`${image_url}${data.posters[2]?.heroImagePath}`}
                            className="program-icon"
                            data-id={data.programId}
                            data-key={item}
                            alt="logo"
                          />
                          <span className="program-name" data-key={item}>
                            {data.title}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
        </div>
        {
          permission === "edit"?
          <div className="button-wrapper">
            <div
              role="none"
              className={`button-container ${!changed ? "disabled" : ""}`}
              onClick={cancelChanges}
            >
              <div className="button-text">Cancel</div>
            </div>
            <div
              role="none"
              className={`button-container ${!changed ? "disabled" : ""}`}
            >
              <div className="button-text">
                <span onClick={applyChanges}>Apply</span>
                <span className="arrow" onClick={applyForOthers}>
                  &gt;
                </span>
              </div>
            </div>
            {duplicatePopup ? (
              <DuplicatePopup
                platforms={platforms}
                deviceType={deviceType}
                cancel={(e) => {
                  setDuplicatePopup(false);
                }}
                apply={(data) => {
                  setDuplicatePlatform([...data]);
                  setDuplicatePopup(false);
                }}
              />
            ) : null}
          </div>
          :
          ''
        }
      </div>
      {loader ? <UILoader /> : null}
    </>
  );
}

export default Spotlight;
