import React,{useState,useRef,useEffect} from 'react'
import * as ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import styles from './scheduling.scss';
import { Calendar } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import copyOutline from "../../assets/images/solar_copy-outline.png";
import { useLocation } from 'react-router-dom';
import deleteicon from "../../assets/images/deleteicon.png";
import { toast, ToastContainer } from "react-toastify";
import AdsPopup from "./AdsPopup";
import moment from 'moment';
import {
  Schedules_list,
  Schedules_Datalist,
  Schedule_update
} from "../../api/api";

function ScheduleDetail() {
  const calendarRef = useRef();
  const draggableRef = useRef(null);
  
  const [dataChage, setDataChange] = useState(false);
  const [position, setPosition]= useState(0);
  const timer = useRef(null);
  const [eventData, setEventData] = useState([]);
  const [adData, setAdData] = useState([]);
  const [parentData, setParentData] = useState();
  const [isZoomIn, setIsZoomIn] = useState(true);
  const [selectedDate, setSelectedDate] = useState(true);
  const [eventStartDate, setEventStartDate] = useState('');
  const [weekView, setweekView] = useState(new Date().toISOString().substring(0,10));
  const [eventStartTime, setEventStartTime] = useState('');
  const [eventEndDate, setEventEndDate] = useState('');
  const [eventEndTime, setEventEndTime] = useState('');
  const [udpateAds, setUpdateAds] = useState('')
  const [programList, setProgramList] = useState();
  const [fillerList, setFillerist] = useState();
  const [gridList, setGridist] = useState();
  const [firstFetch, setFirstFetch] = useState(false)
  const [promoList, setPromoList] = useState();
  const [masterData, setMasterData] = useState();
  const [PartnerData, setPartnerData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [focusTime, setFocusTime] = useState(5550);
  const [copyData, setCopyData] = useState([]);

  const [firstData, setFirstData] = useState(true);
  const [singledata, setSingleData] = useState(null);
  const [validateData, setValidateData] = useState(null);
  const [data, setData] = useState([]);
  const locationState = useLocation();
  let calendar;
  useEffect(() => {
    
  const customButtonsSetup = () => {
    console.log(locationState?.state?.type)
    if(locationState?.state?.type === 'APIDriven' || locationState?.state?.type === 'XLSInput') {
      return [
        'prev,title,next today',
        'zoomButton'
      ]
    } else {
      return [
        'prev,title,next today autoSchedule',
        'undoButton zoomButton trimButton updateButton'
      ]
    }
  }
  const customBtns = customButtonsSetup(); 

  const calendarEl = document.getElementById('calendar');
  let eventUpdate = false;
  calendar = new Calendar(calendarEl, {
    timeZone: 'UTC',
    plugins: [timeGridPlugin, interactionPlugin],
    initialView: 'timeGridWeek',
    customButtons: {
      undoButton: {
        text: 'Undo',
        click: () => {
          fetchDate();
        }
      },
      zoomButton: {
        text: (isZoomIn) ? 'Zoom out' : 'Zoom in',
        click: () => {
        // setFirstData(true);
          const prevTop =  document.querySelector('[data-time="09:00:00"]').getBoundingClientRect().top;
          if(position !== 0) setPosition(prevTop)
          setIsZoomIn(!isZoomIn);
          setTimeout(() => {
            let top = document.querySelector('[data-time="09:00:00"]').getBoundingClientRect().top;
            const itemPosition = document.querySelector(".fc-scroller-liquid-absolute").scrollTop;
            if(isZoomIn) {
              top = (top * -1) + 140;
            } else{
              if(top < itemPosition) {
                top = itemPosition + top - 290;
              } else {
                top = itemPosition - top;
              }
            }
            document.querySelector(".fc-scroller-liquid-absolute").scrollTo(0,top);
          }, 100)
        }
      },
      autoSchedule: {
        text: 'Auto schedule',
        click: () => {
          console.log('Auto schedule')
        }
      },
      trimButton: {
        text: 'Trim',
        click: () => {
          setFirstData(true);
          let currentDate = false;
          const filteredData = eventData.map((data, index) => {
            if(data.scheduledDate === selectedDate) {
              if(eventData[index-1] && currentDate) {
                const currentStartTime = verifyMilliSeconds(data.startAt);

                const prevData = eventData[index-1];
                const prevEndTime = verifyMilliSeconds(prevData.endAt);
                if(currentStartTime !== prevEndTime && (currentStartTime < prevEndTime + 1800000)) {
                  const durationMS = verifyMilliSeconds(data.totalDuration)
                  data.totalDuration = durationMS;
                  data.endAt = millisecondsToHuman(prevEndTime + data.totalDuration);
                  data.startAt = millisecondsToHuman(prevEndTime);
                }
              }
              if(!currentDate) {
                currentDate = true;
              }
          } else {
            currentDate = false;
          }
            return {
              'adBreaks': data.adBreaks,
              'adBreaksCount': data.adBreaksCount,
              'start': data.scheduledDate + 'T'+data.startAt,
              'end': data.scheduledDate + 'T'+data.endAt,
              'endAt': data.endAt,
              'startAt': data.startAt,
              'id': data.id,
              'title': data.title,
              'scheduledDate': data.scheduledDate,
              'duration': data.duration,
              'totalDuration': data.totalDuration
             }
          });
          setEventData(filteredData)

        }
      },
      updateButton: {
        text: 'Update',
        click: async () => {
          const allData = calendarRef.current.currentData.dateProfile.activeRange;
          const dates = getDatesBetween(allData.start, allData.end);
          dates.pop();
          const filteredData = eventData.map(d => {
            const data = d;
            if(!data.totalDuration) return;
            const hms = data.totalDuration.toString().split(":");
            if(hms.length> 1) {
              data.duration = data.totalDuration = toMilliseconds(hms[0], hms[1], hms[2]);
            }
            return {
              'adBreaks': data.adBreaks,
              'adBreaksCount': data.adBreaksCount,
              'endAt': data.endAt || d.endStr.split('T')[1].substring(0,8),
              'startAt': data.startAt || d.startStr.split('T')[1].substring(0,8),
              'id': data.id,
              'assetId': data.id || data.assetId,
              'assetType': data?.assetType?.toUpperCase(),
              'scheduledDate': data.scheduledDate || d.startStr.split('T')[0],
              'duration': data.duration || data.totalDuration,
              'totalDuration': data.totalDuration,
              'title': data.title
            }
          })
          const groupdData = await Object.groupBy(filteredData, ({scheduledDate}) => scheduledDate);
          const transformedData = await Object.values(groupdData).map((data) => {
            return {
              date: data[0].scheduledDate,
              scheduleItems: [...data]
            }
          })
          const transformedDataNew = dates.filter(date => !transformedData.some(item => item.date === date));
          transformedDataNew.forEach((date) => {
            transformedData.push({
              "date": date,
              "programs": [
          
              ]
            });
          });
          const response = await Schedule_update(transformedData, locationState?.state?.channelId);
          if (response) {
            toast.success("Channel Updated successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
              autoClose: 3000
            });
            fetchDate();
          } else {
            toast.error("Channel Update failed", {
              position: toast.POSITION.BOTTOM_LEFT,
              autoClose: 3000
            });
          }
        }
      }
    },
    headerToolbar: {
      left: customBtns[0],
      right: customBtns[1],
    },
    editable: true,
    eventOverlap: false,
    events: [...eventData, ...adData],
    firstDay: new Date(weekView).getDay(),
    initialDate: new Date(weekView),
    eventDurationEditable: false, // Disable Resize
    eventStartEditable: false, // disable dreage drop
    dayHeaderContent: function(arg){
      let totalDuration = 0, videoDuration = 0, adsDuration = 0, promosDuration = 0, fillerDuration = 0;
      const argDate = new Date(arg.date.toGMTString()).toISOString().slice(0, 10);
      eventData.forEach((data) => {
        if(data.scheduledDate === argDate) {
          if(!data.totalDuration) {
            data.totalDuration = data.duration;
          }
          totalDuration += verifyMilliSeconds(data.totalDuration);
          videoDuration += verifyMilliSeconds(data.duration);
          data.adBreaks.forEach((adData) => {
            switch(adData.type) {
              case "filler":
                fillerDuration += verifyMilliSeconds(adData.duration);
                break;
              case "promo":
                promosDuration += verifyMilliSeconds(adData.duration);
                break;
              default:
                adsDuration += verifyMilliSeconds(adData.duration);
                break;
            }
          })
        }
      })

      const copyEvent = (evt) => {
        let emptyErr = [];
        //if data already copied
        if(copyData.length > 0) {
          
          let filteredData = eventData.filter((d) => { // remove data of paste date
            return d.scheduledDate !== evt.currentTarget.eventDate
          });
          const pasteData = JSON.parse(JSON.stringify(copyData))
          pasteData.forEach((d) => { // modifiy date of copied date
            d.scheduledDate=evt.currentTarget.eventDate;
            const start = d.start.split("T");
            d.start = evt.currentTarget.eventDate+"T"+start[1];
            const end = d.end.split("T");
            d.end = evt.currentTarget.eventDate+"T"+end[1];
          })
          filteredData = [...filteredData, ...pasteData]
          setEventData([...filteredData])
          const copyAr = document.querySelectorAll('.undo-btn')
          copyAr.forEach((item) => {
            item.classList.add('copy-btn');
            item.classList.remove('undo-btn')
          })
          setCopyData([])
          return;
        } else {
          // if data not already copied
          eventData.forEach((data) => {
            if(data.scheduledDate === evt.currentTarget.eventDate) {
              emptyErr.push(data);
            }
          });
          if(emptyErr.length > 0) {
            const copyAr = document.querySelectorAll('.copy-btn');
            copyAr.forEach((item) => {
              item.classList.remove('copy-btn');
              item.classList.add('undo-btn')
            })
            setCopyData(emptyErr)
          }
        }
      }

      let copyBtn = document.createElement('span');
      if(copyData.length> 0) {
        copyBtn.classList.add('undo-btn');
      } else {
        copyBtn.classList.add('copy-btn');
      }
      copyBtn.addEventListener('click', copyEvent);
      copyBtn.eventDate = argDate;  

      const content = `<div class="custom-fc-header">
        <div class="custom-fc-header-title">
          <span class="fc-date">${arg.text}</span>
          <span class="fc-event">
          </span>
        </div>
        <div class="fc-schedule-duration">
          <div class="flex">
            <div class="flex-left">Total Scheduled</div>
            <div class="flex-right">${millisecondsToHuman(totalDuration)}</div>
          </div>
          <div class="flex">
            <div class="flex-left">Video</div>
            <div class="flex-right">${millisecondsToHuman(videoDuration)}</div>
          </div>
          <div class="flex">
            <div class="flex-left">Ads</div>
            <div class="flex-right">${millisecondsToHuman(adsDuration)}</div>
          </div>
          <div class="flex">
            <div class="flex-left">Promos</div>
            <div class="flex-right">${millisecondsToHuman(promosDuration)}</div>
          </div>
          <div class="flex">
            <div class="flex-left">Fillers</div>
            <div class="flex-right">${millisecondsToHuman(fillerDuration)}</div>
          </div>
        </div>
        <div>

        </div>
      </div> `
      
      let dayTitle = document.createElement('div');
      dayTitle.innerHTML = content;
      const evt = dayTitle.querySelector('.fc-event');
      evt.appendChild(copyBtn)
      return { domNodes: [
      //  placeTitle,
        dayTitle
      ]};
    },
    dateClick: function(info) {
      if(info?.dateStr) {
        setSelectedDate(info.dateStr.split("T")[0]);
      }
    },  
    allDaySlot: false,
    eventOverlap: true,
    slotDuration: (isZoomIn) ? '00:05:00' : '00:15:00',
    viewDidMount :(view) => {
      view.el.querySelector('.fc-timegrid-axis-frame').innerHTML ='Time';
    },
    datesSet: (async data => {
      const startDate = data.startStr.substring(0, 10);
      const activeDate = new Date(calendar.view.activeStart.toGMTString()).toISOString().slice(0, 10);
      //week change logic heres
      if(weekView !== activeDate || !firstFetch) {
        setFirstData(true)
        setFirstFetch(true);
      //  calendar.setOption('defaultDate', data.startStr)
         setweekView(startDate);
         // week changed without save so call update api if event changed
         if(timer.current) {
          setDataChange(false);
          timer.current = undefined;
          const updateBtn = document.querySelector('.fc-updateButton-button');
          if(updateBtn) {
            document.querySelector('.fc-updateButton-button').click();
          }
         }
         clearTimeout(timer.current);
        
        // const endDate = data.endStr.substring(0, 10);
         const endDate =new Date(data.end.setDate(data.end.getDate() - 1)).toISOString().substring(0,10);
         const gridData = await Schedules_Datalist(locationState?.state?.channelId, startDate, endDate);
         setGridist(gridData.scheduleItems);
         parseData(gridData);
      }
    }),
    // dayHeaderContent: ({ date }, b, c) => {
    //   return (
    //     <div style={{ display: "flex", flexDirection: "column" }}>
    //       <span>{`${date.getDate()}.${date.getMonth()}`}</span>
    //       <span>{date.getFullYear()}</span>
    //       <div
    //         style={{
    //           display: "flex",
    //           flexDirection: "column",
    //           border: "black 1px solid"
    //         }}
    //       >
    //         <span>8: 00</span>
    //         <span style={{ backgroundColor: "gray" }}>Activity</span>
    //       </div>
    //     </div>
    //   );
    // },
    // dayHeaderContent:({ date }, b, c) => {
    //   console.log(date);
    //   return `<b>${date.getDate()}.${date.getMonth()}</b>`;
    // },
    // dayHeaderFormat:(param) => {
    //   console.log(param);
    //   return '<b>'+param.date.day.toString()+'</b>';
    // },
    eventContent: function (info) {
      const eventContent = document.createElement('div');
      setSelectedDate(info.event?.startStr?.split("T")[0]);
      eventContent.classList.add('custom-event');
      if(info.event.extendedProps.eventType === 'adData' || info.event.extendedProps.eventType === 'promo' || info.event.extendedProps.eventType === 'filler' ) {
       return;
      }
      eventContent.setAttribute('data-index',eventData.length);
      const setDate = () => {
        setSelectedDate(info.event?.startStr?.split("T")[0]);
      }
      // Check if the event is split and mark the original event as hidden
      if (info.event.extendedProps.isSplit) {
        eventContent.style.display = 'none';
      }
      // Add event title and time information with error handling
      if(info.event.title === '+ Ads') {
        const time = document.createElement('div');
        time.innerText = info.event.duration;
        eventContent.appendChild(time);
      } else {
        const title = document.createElement('div');
        title.setAttribute('title', info.event.title)
        title.innerText = info.event.title;
        eventContent.appendChild(title);
      }
      
      let yesterDay = new Date();
      yesterDay = new Date(yesterDay.setDate(yesterDay.getDate() - 1)).toISOString().substring(0,10);
  
      const adButton = document.createElement('div');
      const deleteButton = document.createElement('button');
      if(info.event.title !== '+ Ads') {

        adButton.classList.add('ad-schedule');
        adButton.textContent = info.event.extendedProps.adBreaksCount;
       // if(new Date(weekView).getTime() >= new Date(yesterDay).getTime())  { 
        if(info.event.startStr >= new Date().toISOString()) {
          deleteButton.classList.add('delete-btn-schedule');
          const eventClickMethod = () => {
            // Remove the event from the calendar
            info.event.remove();
            deleteButton.removeEventListener('click', eventClickMethod);
            eventContent.removeEventListener('click', setDate);
            // Mark the original event as hidden if it's split
            if (info.event.extendedProps.isSplit) {
              const originalEvent = calendarRef.current.getEventById(info.event.id);
              if (originalEvent) {
                originalEvent.setExtendedProp('isSplit', true);
              }
            }
            // Remove the event from the eventData state by comparing start and end times
            const updatedEventData = eventData.filter((event) => {
              if(event.start instanceof Date) {
                return (
                  event.start.getTime() !== info.event.start.getTime() ||
                  event?.end?.getTime() !== info?.event?.end?.getTime()
                );
              } else {
                return (
                  event.start != new Date(info.event.start).toISOString().substring(0,19) ||
                  event.end != new Date(info.event.end).toISOString().substring(0,19)
                );
              }
            });          
            setFocusTime(document.querySelector('.fc-scroller.fc-scroller-liquid-absolute').scrollTop);
            // Update the eventData state
            setEventData(updatedEventData);
            // Update the adData state
            const ads = getNoOverlap(info.event.start,info.event.end,adData);
            setAdData(ads);
            setSelectedDate(info.event.startAt)
          }
          deleteButton.addEventListener('click', eventClickMethod);
          
          eventContent.appendChild(deleteButton);
        }
        eventContent.addEventListener('click', setDate);
      
      // deleteButton.addEventListener('click', () => {
      //   // Remove the event from the calendar
      //   info.event.remove();

      //   // Mark the original event as hidden if it's split
      //   if (info.event.extendedProps.isSplit) {
      //     const originalEvent = calendarRef.current.getEventById(info.event.id);
      //     if (originalEvent) {
      //       originalEvent.setExtendedProp('isSplit', true);
      //     }
      //   }
      // // console.log("sdsd",eventData);
      //   // Remove the event from the eventData state by comparing start and end times
      //   const updatedEventData = eventData.filter((event) => {
      //     return (
      //       event.start.getTime() !== info.event.start.getTime() ||
      //       event.end.getTime() !== info.event.end.getTime()
      //     );
      //   });

      //   // Update the eventData state
      //   setEventData(updatedEventData);
      // });

      eventContent.appendChild(adButton);
      }
      return { domNodes: [eventContent] };
    },
    scrollTime : '09:00:00',
    eventClick: function(info) {
      info.jsEvent.preventDefault(); // don't let the browser navigate
      if(info.jsEvent.srcElement.className !== 'delete-btn-schedule' && info.event.extendedProps.adBreaksCount > 0) {
        setValidateData(info.event._def)
        setSingleData(info.event._def.extendedProps.adBreaks);
        setParentData(parseInt(info.el.querySelector('.custom-event').getAttribute('data-index'))-1)
        setShowPopup(true);
      }
    },
    eventReceive: function (info) {
      let yesterDay = new Date();
      yesterDay = new Date(yesterDay.setDate(yesterDay.getDate() - 1)).toISOString().substring(0,10);
      const currentDate =  new Date(calendarRef.current.currentData.currentDate).toISOString().substring(0,10);
      //if event drop is in past return from it and undo added event
     // if(new Date(currentDate).getTime() < new Date(yesterDay).getTime())  {
      if(info.event.startStr < new Date().toISOString()) {
        info.event.remove();
        toast.error("Can not modify past dates", {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 3000
        });
        return;
      } 
      setDataChange(true);
      let dur = info.draggedEl.getAttribute('data-duration');
      const eventType = info.draggedEl.getAttribute('data-type');
      const msDuration = dur.toString().split(":");
      dur = verifyMilliSeconds(dur)// toMilliseconds(msDuration[0], msDuration[1], msDuration[2]);
      let st = String(new Date(info.event.start).toISOString().split('T')[1]).substring(0,8);
      let en = String(new Date(info.event.end).toISOString().split('T')[1]).substring(0,8);
      let newEvent = [];
      //find event where to push data for ad type event only
      if(eventType === 'ad') {
        newEvent = isAnOverlapEvent(info.event.start,info.event.end, false);
        //  newEvent = [...updatedEvent];
        eventUpdate = true;
        if(newEvent.length>0) {
          const msTotalDuration = verifyMilliSeconds(newEvent[0].totalDuration);
          const msDur = (msTotalDuration.length>1) ? toMilliseconds(msTotalDuration[0], msTotalDuration[1], msTotalDuration[2]) : newEvent[0].totalDuration;
          newEvent[0].adBreaksCount += 1;
          newEvent[0].totalDuration = msDur + dur;
          const msStartDuration = newEvent[0].startAt.toString().split(":");
          const startDur = (msStartDuration.length>1) ? toMilliseconds(msStartDuration[0], msStartDuration[1], msStartDuration[2]) : msStartDuration;
          newEvent[0].endAt = millisecondsToHuman(startDur + newEvent[0].totalDuration);
          const newend = newEvent[0].end.split("T");
          newEvent[0].end = newend[0]+'T'+newEvent[0].endAt;

          const overlap = isAdOverlap(info.event.start, info.event.end, newEvent[0].adBreaks);
          if(overlap.length>0) {
            st = overlap[0].endAt;
            const stMil = verifyMilliSeconds(st);
            const durationMil = verifyMilliSeconds(info.draggedEl.getAttribute('data-duration'));
            en = millisecondsToHuman(stMil + durationMil);
          }
          
          newEvent[0].adBreaks.push(
            {
              "assetId": 1,
              "type": eventType,
              "startAt": st,
              "endAt": en,  
              "scheduledDate": new Date(info.event.start).toISOString().split('T')[0],
              "date": new Date(info.event.start).toISOString().split('T')[0],
              "duration": dur,
              "title": 'Ad'
            }
          )

          // sort ads by time
          newEvent[0].adBreaks.sort((a, b) => {
            const aTime = a.startAt.split(':').map(Number);
            const bTime = b.startAt.split(':').map(Number);
            return aTime[0]*3600 + aTime[1]*60 + aTime[2] - (bTime[0]*3600 + bTime[1]*60 + bTime[2]);
          });
          
          const updatedDate = [...eventData];
          updatedDate.splice(newEvent[1], 1);
          updatedDate.push(newEvent[0])
          setEventData([...updatedDate]);
          setAdData([...adData,  {
            "title": "Event 1",
            "start": info.event.start,
            "end": info.event.end,
            "eventType": 'adData',
            "className": "selected-event",
            "color": '#FFBE83'
          },])
          setFocusTime(document.querySelector('.fc-scroller.fc-scroller-liquid-absolute').scrollTop);
          return;
        } else {
          setFocusTime(document.querySelector('.fc-scroller.fc-scroller-liquid-absolute').scrollTop);
          setEventData([...eventData]);
          setAdData([...adData])
          return;
        }
      } else {
        eventUpdate = true;
        // When a draggable event is dropped, add it as a custom event to eventData
        const overLappedEvent = isAnOverlapEvent(info.event.start,info.event.end, (eventType === 'filler' || eventType === 'promo') ? false : true);
        if(overLappedEvent.length > 0) {
          // newEvent.start = overLappedEvent[0].end;
          // newEvent.startAt = overLappedEvent[0].endAt;
          // const startAr = newEvent.startAt.toString().split(":");
          // const durAr = (newEvent.duration.toString().split(":").length > 1) ? newEvent.duration.toString().split(":") : newEvent.duration;
          // const durSec = (newEvent.duration.toString().split(":").length > 1) ? toMilliseconds(durAr[0], durAr[1], durAr[2]) : durAr;
          // const endTime = millisecondsToHuman((toMilliseconds(startAr[0], startAr[1], startAr[2]) + durSec));
          // newEvent.endAt = endTime;
          // newEvent.end = newEvent.scheduledDate +'T'+ newEvent.endAt;
          if((eventType === 'filler' || eventType === 'promo')) {
            overLappedEvent[0].adBreaksCount += 1;
            let newEn = en;
            if(!en || en === '00:00:00') {
              const stMil = verifyMilliSeconds(st);
              const durationMil = verifyMilliSeconds(info.draggedEl.getAttribute('data-duration'));
              newEn = millisecondsToHuman(stMil + durationMil);
            }
            
            const overlap = isAdOverlap(info.event.start, info.event.end, overLappedEvent[0].adBreaks);
            if(overlap.length>0) {
              if (overlap[0].endAt instanceof Date) {
                overlap[0].endAt= new Date(overlap[0].endAt.toGMTString()).toISOString().split('T')[1].substring(0,8);
              }
              st = overlap[0].endAt;
              const stMil = verifyMilliSeconds(st);
              const durationMil = verifyMilliSeconds(info.draggedEl.getAttribute('data-duration'));
              newEn = millisecondsToHuman(stMil + durationMil);
            } else {
              st = info.event.end;
              if (st instanceof Date) {
                st = new Date(st.toGMTString()).toISOString().split('T')[1].substring(0,8);
              }
              const stMil = verifyMilliSeconds(st);
              const durationMil = verifyMilliSeconds(info.draggedEl.getAttribute('data-duration'));
              newEn = millisecondsToHuman(stMil + durationMil);
            }
            overLappedEvent[0].adBreaks.push(
              {
                "assetId": 1,
                "type": eventType,
                "title": info.event.title,
                "startAt": st,
                "endAt": newEn,
                "scheduledDate": new Date(info.event.start).toISOString().split('T')[0],
                "duration": verifyMilliSeconds(info.draggedEl.getAttribute('data-duration'))
              }
            )
           
            overLappedEvent[0].adBreaks.sort((a, b) => {
              const aTime = a.startAt.split(':').map(Number);
              const bTime = b.startAt.split(':').map(Number);
              return aTime[0]*3600 + aTime[1]*60 + aTime[2] - (bTime[0]*3600 + bTime[1]*60 + bTime[2]);
            });

            setAdData([...adData,   {
              "assetId": 1,
              "type": eventType,
              "title": info.event.title,
              "startAt": st,
              "endAt": newEn,
              "start": info.event.start,
              "end": info.event.end,
              "scheduledDate": new Date(info.event.start).toISOString().split('T')[0],
              "duration": verifyMilliSeconds(info.draggedEl.getAttribute('data-duration')),
              "className": "selected-event",
              "color": '#FFBE83'
            },])
         //   setAdData([...newAdData])
          //  console.log(newAdData)
            const updatedDate = [...eventData];
            updatedDate.splice(overLappedEvent[1], 1);

            updatedDate.push(overLappedEvent[0])
            
            setEventData([...updatedDate]);

          } else {
            newEvent = {
              title: info.event.title,
              start: info.event.start,
              startAt: st,
              end: info.event.end,
              endAt: en,
              assetId: parseInt(info.event.id),
              assetType: eventType,
              id: parseInt(info.event.id),
              duration: dur,
              totalDuration: dur,
              scheduledDate: new Date(info.event.start).toISOString().split('T')[0],
              adBreaksCount: info.event?.adBreaksCount || 0,
              adBreaks: [],
              color: (info.event.title === '+ Ads') ? '#FFBE83' : '#729BD2',
            };
            newEvent.start = overLappedEvent[0].end;
            newEvent.startAt = overLappedEvent[0].endAt;
            const endTime = millisecondsToHuman(verifyMilliSeconds(newEvent.startAt) + verifyMilliSeconds(newEvent.duration));
            newEvent.endAt = endTime;
            newEvent.end = newEvent.scheduledDate +'T'+ newEvent.endAt;
            
            setEventData([...eventData, newEvent]);
          }
        } else {
          let newEn = en;
          if(!en || en === '00:00:00') {
            const stMil = verifyMilliSeconds(st);
            const durationMil = verifyMilliSeconds(info.draggedEl.getAttribute('data-duration'));
            newEn = millisecondsToHuman(stMil + durationMil);
          }
          newEvent = {
            title: info.event.title,
            start: info.event.start,
            startAt: st,
            end: info.event.end,
            endAt: newEn,
            id: parseInt(info.event.id),
            assetId: parseInt(info.event.id),
            assetType: eventType,
            duration: dur,
            totalDuration: dur,
            scheduledDate: new Date(info.event.start).toISOString().split('T')[0],
            adBreaksCount: info.event?.adBreaksCount || 0,
            adBreaks: [],
            color: (info.event.title === '+ Ads') ? '#FFBE83' : '#729BD2',
            added: true
          };
          setEventData([...eventData, newEvent]);
        }
      }
      setFocusTime(document.querySelector('.fc-scroller.fc-scroller-liquid-absolute').scrollTop);
      calendarRef.current = calendar;
    }
    });
    calendar.render();
    draggableRef.current = new Draggable(
      document.getElementById('external-events'),
      {
        itemSelector: '.fc-event',
        eventData: function (eventEl) {
          
          const type = eventEl.getAttribute('data-type')
          const data = {
              id: Date.now(), // Assign a unique ID to the event
              title: eventEl.innerText,
              color: (type === 'ads') ? '#FFBE83' : '#1e5b83',
              type: type,
              assetType: type?.toUpperCase(),
              id: eventEl.getAttribute('data-id'),
              assetId: eventEl.getAttribute('data-id'),
              duration: eventEl.getAttribute('data-duration'),
              totalDuration: eventEl.getAttribute('data-mm-duration'),
              scheduledDate: '',
              adBreaksCount: 0,
              adBreaks: []
            }
          return data;
        },
      }
    );

    calendarRef.current = calendar;

  // calendarRef.current.currentData.options.scrollTime = '08:00:00'
    //  console.log(calendar)
    
    if((!firstData && eventData) || eventUpdate) {
      document.querySelector('.fc-scroller.fc-scroller-liquid-absolute').scrollTop = focusTime;
    } else {      
      setTimeout(() => {
        setFirstData(false)
      }, 1000)
    }
    const mainEvent = document.querySelectorAll('.fc-timegrid-event:not(.selected-event)');

     mainEvent.forEach((el, index) => {
      el.closest('div').classList.add('main-event');
      if(index % 2) {
        el.closest('div').classList.add('even')
      }
     });
     
     //console.log(document.querySelector('.selected-event').closest('div'));
  return () => {
    draggableRef.current.destroy();
  };
  
}, [isZoomIn, eventData, focusTime, adData, copyData, weekView]);

// time out update functinality
useEffect(() => {
  if(dataChage && !timer?.current) {
    const timerConst = setTimeout(() => {
      const url = window.location.href;
      const isSchedulingDetail =url.includes('schedulingDetail');
      if(!isSchedulingDetail) {
        clearTimeout(timer.current);
        return;
      }
      if(document.querySelector('.fc-updateButton-button')) {
        document.querySelector('.fc-updateButton-button').click();
      }
      setDataChange(false);
    }, 300000);
    if(document.querySelector('.fc-updateButton-button')) {
      timer.current = timerConst;
    }
  }
}, [dataChage])

//  useEffect(() => {
//   if(!calendarRef.current.currentData) return;
//   calendarRef.current.currentData.calendarOptions.scrollTime.milliseconds = focusTime
//   // 
//    console.log(focusTime)
// //calendar.scrollToTime(focusTime)
//  }, [focusTime]);

//  const scrollCalendar = (calendar, time) => {
//  }

const fetchDate = async () => {
  const startDate = new Date(calendarRef.current.currentData.currentDate).toISOString().substring(0,10);
  const endDate = new Date(calendarRef.current.currentData.viewApi.currentEnd).toISOString().slice(0, 10);
  const gridData = await Schedules_Datalist(locationState?.state?.channelId, startDate, endDate);
  setGridist(gridData.scheduleItems);
  parseData(gridData);
}
const verifyMilliSeconds = (duration) => {
  const len = duration?.toString()?.split(":");
  if(len?.length > 1) {
    return parseInt(toMilliseconds(len[0], len[1], len[2]))
  } else {
    return parseInt(duration);
  }
}
const millisecondsToHuman = (duration) => {
     let milliseconds = parseInt((duration % 1000) / 100),
        seconds = parseInt((duration / 1000) % 60),
        minutes = parseInt((duration / (1000 * 60)) % 60),
        hours = parseInt((duration / (1000 * 60 * 60)) % 24);

      hours = (hours < 10) ? "0" + hours : hours;
      minutes = (minutes < 10) ? "0" + minutes : minutes;
      seconds = (seconds < 10) ? "0" + seconds : seconds;

      return hours + ":" + minutes + ":" + seconds ;
}

const closePopup = () => {
  setShowPopup(false);
  setSingleData(null); // Clear the single data
 // partnerListDetail();
};
const getDatesBetween = (startDate, endDate) => {
  let dates = [];
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
      dates.push(moment(new Date(currentDate)).format('YYYY-MM-DD'));
      currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
}
const toMilliseconds = (hrs,min,sec) => (parseInt(hrs)*60*60+parseInt(min)*60+parseInt(sec))*1000;

const isAnOverlapEvent = (eventStartDay, eventEndDay, iterate) => {
  // Events
  let events = eventData;
  const ds = new Date(eventStartDay);
  eventStartDay= new Date(ds.toGMTString()).toISOString().slice(0, 19);
  const de = new Date(eventEndDay);
  eventEndDay= new Date(de.toGMTString()).toISOString().slice(0, 19);
  let iteratedData = [];
  for (let i = 0; i < events.length; i++) {
    const eventA = events[i];
    let newData = false;
    if(typeof eventA.start !== 'string') {
      eventA.start = new Date(eventA.start.toGMTString()).toISOString().slice(0, 19);
      eventA.end = new Date(eventA.end.toGMTString()).toISOString().slice(0, 19);
    }

    // start-time in between any of the events
    if (eventStartDay >= eventA.start && eventStartDay <= eventA.end) {
      iteratedData = [eventA, i];
      newData = true;
    }
    //end-time in between any of the events
    if (eventEndDay >= eventA.start && eventEndDay <= eventA.end) {
      iteratedData = [eventA,i];
      newData = true;
    }
    //any of the events in between/on the start-time and end-time
    if (eventStartDay <= eventA.start && eventEndDay >= eventA.end) {
      iteratedData = [eventA,i];
      newData = true;
    }
    if(newData && iterate) {
      eventStartDay = eventA.start;
      eventEndDay = eventA.end;
    }
  }
  return iteratedData;
}

const isAdOverlap = (eventStartDay, eventEndDay, ads) => {
  // Events
  let events = eventData;
  const ds = new Date(eventStartDay);
  eventStartDay= new Date(ds.toGMTString()).toISOString().slice(0, 19);
  const de = new Date(eventEndDay);
  eventEndDay= new Date(de.toGMTString()).toISOString().slice(0, 19);
  let iteratedData = [];
  for (let i = 0; i < events; i++) {
    const eventA = events[i];
    let newData = false;
    if(typeof eventA.start !== 'string') {
      if(!eventA.start) {
        eventA.start = eventA.scheduledDate + 'T' + eventA.startAt;
        eventA.end = eventA.scheduledDate + 'T' + eventA.endAt;
      } else {
        eventA.start = new Date(eventA.start.toGMTString()).toISOString().slice(0, 19);
        eventA.end = new Date(eventA.end.toGMTString()).toISOString().slice(0, 19);
      }
    }

    // start-time in between any of the events
    if (eventStartDay >= eventA.start && eventStartDay <= eventA.end) {
      iteratedData = [eventA, i];
      newData = true;
    }
    //end-time in between any of the events
    if (eventEndDay >= eventA.start && eventEndDay <= eventA.end) {
      iteratedData = [eventA,i];
      newData = true;
    }
    //any of the events in between/on the start-time and end-time
    if (eventStartDay <= eventA.start && eventEndDay >= eventA.end) {
      iteratedData = [eventA,i];
      newData = true;
    }
    if(newData) {
      eventStartDay = eventA.start;
      eventEndDay = eventA.end;
    }
  }
  return iteratedData;
}

const getNoOverlap = (eventStartDay, eventEndDay, data) => {
  const ds = new Date(eventStartDay);
  eventStartDay= new Date(ds.toGMTString()).toISOString().slice(0, 19);
  const de = new Date(eventEndDay);
  eventEndDay= new Date(de.toGMTString()).toISOString().slice(0, 19);
  let foundData = [];
  let events = data;

  for (let i = 0; i < events.length; i++) {
    const eventA = events[i];
    if(typeof eventA.start !== 'string') {
      eventA.start = new Date(eventA.start.toGMTString()).toISOString().slice(0, 19);
      eventA.end = new Date(eventA.end.toGMTString()).toISOString().slice(0, 19);
    }

    // start-time in between any of the events
    if (eventStartDay >= eventA.start && eventStartDay <= eventA.end) {
      // return [eventA, i];
    }
    //end-time in between any of the events
    else if (eventEndDay >= eventA.start && eventEndDay <= eventA.end) {
      //  return [eventA,i];
    }
    //any of the events in between/on the start-time and end-time
    else if (eventStartDay <= eventA.start && eventEndDay >= eventA.end) {
      //  return [eventA,i];
    } else {
      foundData.push(eventA)
    }
  }
  return foundData;
}
const parseData = (gridData) => {
  let edataIn = []
  if(!gridData?.data?.length) {
    return;
  }
  let adData = [];
  const edata = gridData.data.map((data) => {
    const tempData = data.scheduleItems.map(d => {
     // let tempAdData = [];
      d.adBreaks.forEach(dt => {
        adData.push({
          "title": dt.title,
          "start": dt.startAt,
          "end": dt.endAt,
          'start': dt.scheduledDate + 'T'+dt.startAt,
          'end': dt.scheduledDate + 'T'+dt.endAt,
          'scheduledDate': d.scheduledDate,
          'date': d.date,
          'duration': dt.duration,
          'totalDuration': dt.totalDuration,
          "eventType": 'adData',
          "className": "selected-event",
          "color": '#FFBE83'
        })
      });
      return {
        title: d.title,
        id: d.id || d.assetId,
        assetId: d.assetId,
        assetType: d.assetType,
        start: d.scheduledDate +'T'+ d.startAt,
        end: d.scheduledDate +'T'+ d.endAt,
        scheduledDate: d.scheduledDate,
        startAt: d.startAt,
        duration: d.duration,
        endAt: d.endAt,
        totalDuration: d.totalDuration,
        adBreaksCount: d.adBreaksCount,
        adBreaks: d.adBreaks
      }
    });
    edataIn.push(...tempData)
    // return {
    //   title: data.awsProgramName,
    //   start: info.event.start,
    //   end: info.event.end,
    //   duration: info.event.duraiton,
    //   color: (info.event.title === '+ Ads') ? '#FFBE83' : '#729BD2',
    // }

  })
  setAdData([...adData])
  setEventData([...edataIn]);
}

const fetchSchedule = async () => {
  if(!locationState?.state?.channelId) {
    return;
  }
  const scheduleData = await Schedules_list(locationState?.state?.channelId, 'PROGRAM');
  setProgramList(scheduleData.data);

  const promoData = await Schedules_list(locationState?.state?.channelId, 'PROMO');
  setPromoList(promoData.data);

  const fillerData = await Schedules_list(locationState?.state?.channelId, 'FILLER');
  setFillerist(fillerData.data);

  const date = new Date();
  const setDate = 5;
  const startDate = new Date().toISOString().slice(0, 10);
  const endDate = new Date(date.setDate(date.getDate() + setDate)).toISOString().slice(0, 10);
  const gridData = await Schedules_Datalist(locationState?.state?.channelId, startDate, endDate);
  setGridist(gridData.scheduleItems);
   //parseData(gridData);

  setMasterData([scheduleData.data, promoData.data, fillerData.data])
}


  useEffect(() => {
    fetchSchedule();
  }, [locationState]);

  //expand collapse inside tree list
  const expandChild = (e) => {
    e.stopPropagation();
    e.currentTarget.classList.toggle('active')
    e.currentTarget?.childNodes?.[1]?.classList?.toggle("block");
  }

  //toogle treelist 
  const toogleList = (e) => {
    e.stopPropagation();
    document.querySelectorAll(".list-active").forEach( e => {
      e.classList.remove("list-active");
    });
    
    e.currentTarget.classList.toggle('list-active')
  }

  const updateData = (data) => {
    const updatedData = [...eventData]
    updatedData[parentData].adBreaks = data;
    let totalDuration = verifyMilliSeconds(updatedData[parentData].duration);
    updatedData[parentData].adBreaks.forEach((data) => {
      totalDuration += verifyMilliSeconds(data.duration);
    })
    updatedData[parentData].adBreaksCount = data.length;
    const startAt = updatedData[parentData].startAt.toString().split(":");
    const currentStartTime = toMilliseconds(startAt[0], startAt[1], startAt[2]);
    updatedData[parentData].totalDuration = millisecondsToHuman(totalDuration);
    updatedData[parentData].endAt = millisecondsToHuman(currentStartTime + totalDuration);
    updatedData[parentData].end = updatedData[parentData].scheduledDate + 'T' + updatedData[parentData].endAt;
    let tempdata = [];
    updatedData.forEach((d) => {
      d.adBreaks.forEach(dt => {
        tempdata.push({
          "title": dt.title,
          "start": dt.startAt,
          "end": dt.endAt,
          'start': dt.scheduledDate + 'T'+dt.startAt,
          'end': dt.scheduledDate + 'T'+dt.endAt,
          'scheduledDate': dt.scheduledDate,
          'duration': dt.duration,
          'totalDuration': dt.totalDuration,
          "eventType": 'adData',
          "className": "selected-event",
          "color": '#FFBE83'
        })
      })

    })
    setAdData([...tempdata]);
    setEventData([...updatedData]);
    
   // info.view.getCurrentData().calendarOptions.events
  }

  const filterList = (evt) => {
    const filterPrograms = masterData?.[0]?.filter((data) => {
      return data.metadata?.title.toLowerCase().includes(evt.target.value.toLowerCase())
    });

    const filterFillers = masterData?.[1]?.filter((data) => {
      return data.filename.toLowerCase().includes(evt.target.value.toLowerCase())
    });

    const filterPromos = masterData?.[2]?.filter((data) => {
      return data.filename.toLowerCase().includes(evt.target.value.toLowerCase())
    });

    setProgramList(filterPrograms);
    setPromoList(filterPromos);
    setFillerist(filterFillers);
  }

  useEffect(() => {
    fetchSchedule();
  }, [locationState]);
    return (
        <div className='content-body SchedulingDetail'>
            <div className="dashboard-content flex">
                <div className="left" id='external-events'>
                    <h3 className='title-header'>Scheduling</h3>
                    <div className='list-box'>
                        <h3>{locationState?.state?.channelName}</h3>
                        {locationState?.state?.type !== 'APIDriven' && locationState?.state?.type !== 'XLSInput' && (<>
                        <div>
                            <input type='text' className='search-program' placeholder='Search Program' onChange={(evt) => filterList(evt)}/>
                        </div>
                        <div className='program-list'>
                            <div className='program-header list-active' onClick={(evt) => toogleList(evt)}>Programs</div>

                            <ul className="treelist">
                              {programList && programList.map((schedule, index) => (
                                <li key={index} className='fc-event' data-type="program" title={schedule?.metadata?.title} data-duration={schedule?.metadata?.duration} data-mm-duration={schedule?.duration} data-id={schedule?.id} onClick={(evt) => expandChild(evt)}>
                                  <span>{schedule?.metadata?.title}</span>
                                </li>
                              ))}
                            </ul>
                            <div className='program-header' onClick={(evt) => toogleList(evt)}>Fillers</div>

                            <ul className="treelist">
                              {fillerList && fillerList.map((schedule, index) => (
                                <li key={index} className='fc-event' data-type="filler" title={schedule?.filename} data-duration={millisecondsToHuman(verifyMilliSeconds(schedule?.duration))} data-id={schedule?.id} onClick={(evt) => expandChild(evt)}>
                                  <span>{schedule?.filename}</span>
                                </li>
                              ))}
                            </ul>
                            <div className='program-header' onClick={(evt) => toogleList(evt)}>Promos</div>

                            <ul className="treelist">
                              {promoList && promoList.map((schedule, index) => (
                                <li key={index} className='fc-event' data-type="promo" title={schedule?.filename} data-duration={millisecondsToHuman(verifyMilliSeconds(schedule.duration))} data-id={schedule.id} onClick={(evt) => expandChild(evt)}>
                                  <span>{schedule.filename}</span>
                                </li>
                              ))}
                            </ul>
                            {/* <ul className="treelist" id='external-events'>
                              <li className='active fc-event' data-duration='02:00:00' onClick={(evt) => expandChild(evt)}>
                                <span>Video 1</span>
                                <ul className="nested">
                                  <li>Video 11</li>
                                  <li>Video 12</li>
                                  <li>Video 13</li>
                                  <li>Video 14</li>
                                </ul>
                              </li>
                              <li className='fc-event' data-duration='01:30:00' onClick={(evt) => expandChild(evt)}>
                                <span>Video 2</span>
                                <ul className="nested">
                                  <li>Video 21</li>
                                  <li>Video 22</li>
                                  <li>Video 23</li>
                                  <li>Video 24</li>
                                </ul>
                              </li>
                              <li className='fc-event' data-duration='03:15:00' onClick={(evt) => expandChild(evt)}>
                                <span>Video 3</span>
                                <ul className="nested">
                                  <li>Video 31</li>
                                  <li>Video 32</li>
                                  <li>Video 33</li>
                                  <li>Video 34</li>
                                </ul>
                              </li>
                            </ul> */}
                        </div>
                        <div className='schedule-butttons'>
                          <button className='fc-event schedule-ads' data-type="ad" data-duration='00:00:30'>
                            + Ads
                          </button>
                        </div></>)}
                    </div>
                </div>
                <div className="right">
                  <div id='calendar'></div>
                </div>
                <ToastContainer />
                <AdsPopup
                  show={showPopup}
                  handleClose={closePopup}
                  data={singledata}
                  parentData={validateData}
                  updateData={updateData}
                />
            </div>
        </div>
    )
}
export default ScheduleDetail