import React, {useEffect, useState} from 'react';
import sortingDefault from '../../assets/images/sorting-default.svg';
import sortingUp from '../../assets/images/sortarrow.png';
import sortingDown from '../../assets/images/desc.png';
import { Super_Tenant_URL, Tenant_URL } from '../../service/API_URL';
import { getInstantData, deleteInstantData } from "../../api/apiMethod";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoIosArrowDown } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import editicon from "../../assets/images/editicon.png";
import archiveicon from "../../assets/images/archiveicon.png";
import deleteicon from "../../assets/images/delete_forever.png";
import viewIcon from '../../assets/images/carbon_view.svg';
import checkicon from '../../assets/images/check-true.svg'
const TenantOnboarding = () => {
  const [activeTab, setActiveTab] = useState('Super Tenants');
  const [dataGrid, setDataGrid] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [sortColumn, setSortColumn] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortColumn2, setSortColumn2] = useState('name');
  const [sortDirection2, setSortDirection2] = useState('asc');
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();
    
  /**
   * Retrieves the super tenant data from the specified URL and sets the data grid.
   * @async
   * @function getSuperTenant
   * @returns {Promise<void>} A Promise that resolves when the super tenant data is retrieved and the data grid is set.
   */
  const getSuperTenant = async (params) => {
    const URL = (activeTab === 'Super Tenants') ? `${Super_Tenant_URL}` : `${Tenant_URL}`;
    try {
      const response = await getInstantData(URL, {params} || {});
      setDataGrid(response.data);
      setIsDropdownOpenArray(new Array(response.data.length).fill(false));
    } catch (error) {
      toast.error(   
        "Something went wrong, please try after sometime",
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
    }
  }  // Create an array to store open states for each card
  const [isDropdownOpenArray, setIsDropdownOpenArray] = useState();
  const handleDropdownSelect = (selectedOption, index) => {
    // Implement the action for the selected option here
    // Close the dropdown for the selected card
    setIsDropdownOpenArray((prevState) =>
      prevState.map((value, i) => (i === index ? false : value))
    );
  };

  const openEditTenant = (tenant) => {
    const URL = (activeTab === 'Super Tenants') ? '/add-super-tenant' : '/add-tenant';
   navigate(URL, { state: { tenant }});
  };
  
  const deleteTenant = async (tenant) => {
    const URL = (activeTab === 'Super Tenants') ? `${Super_Tenant_URL}` : `${Tenant_URL}`;
    try {
    const response = await deleteInstantData(`${URL}/${tenant.id}`);
    toast.success("Tenant removed successfully", {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 3000
    });
    await getSuperTenant();
    } catch (error) {
    
    toast.error(   
      "Something went wrong, please try after sometime",
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
    }
  };

  const epochToDate = (epoch) => {
    let date = new Date(epoch); // Convert to Date object
    let day = ("0" + date.getDate()).slice(-2); // Get day and prepend zero if necessary
    let month = ("0" + (date.getMonth() + 1)).slice(-2); // Get month (0-11), increment by 1 and prepend zero if necessary
    let year = date.getFullYear(); // Get full year
    return day + "-" + month + "-" + year;
  }

  const expiryString = (epoch) => {
    const val = expiryValue(epoch);
    if(val < 0) {
      return 'Expired';
    }
    return `Expires in ${val} months`;
  }

  const expiryValue = (epoch) => {
    const currentDate = new Date();
    const targetDate = new Date(epoch);
  
    const yearsDiff = targetDate.getFullYear() - currentDate.getFullYear();
    const monthsDiff = targetDate.getMonth() - currentDate.getMonth();
    const totalMonthsDiff = yearsDiff * 12 + monthsDiff;
    return totalMonthsDiff;
  }

  const expiryClass = (epoch) => {
    const val = expiryValue(epoch);
    if(val < 0) {
      return 'red';
    } else if(val < 3) {
      return 'orange';
    }
    return '';
  }

  useEffect(() => {
    getSuperTenant();
  }, [activeTab]);

  const handleSorting = (column) => {
    let sorting = {
      sortColumn: column,
      sortDirection: null
    }
    setSortColumn(column);
    if (sortDirection == 'asc') {
      setSortDirection('desc');
      sorting.sortDirection = 'desc';
    } else {
      setSortDirection('asc');
      sorting.sortDirection = 'asc';
    }

    let queryParams = {
      sortBy: sorting.sortColumn,
      sortOrder: sorting.sortDirection,
      pageNumber: currentPage,
      pageSize: pageSize
    };
    getSuperTenant(queryParams);
  }

  const handleSortingTenant = (column) => {
    let sorting = {
      sortColumn: column,
      sortDirection: null
    }
    setSortColumn2(column);
    if (sortDirection == 'asc') {
      setSortDirection2('desc');
      sorting.sortDirection = 'desc';
    } else {
      setSortDirection2('asc');
      sorting.sortDirection = 'asc';
    }

    let queryParams = {
      sortBy: sorting.sortColumn,
      sortOrder: sorting.sortDirection,
      pageNumber: currentPage,
      pageSize: pageSize
    };
    getSuperTenant(queryParams);
  }

  return (
    <div className="content-body">
    <div className="dashboard-content tenant-content">
      <div className="top-content">
        <div className="left-side">
          <h3>Tenant onboarding</h3>
        </div>
        <div className="right-side">
          <div className='channel-setup-button'>            
            <div className="dropdown action-dropdown">
                                <a
                                    className="border-0"
                                    onClick={() => {
                                        setShowDropdown(!showDropdown);
                                      }
                                    }
                                >
                                    + Add New  
                                    <span>
                                        &#9660;
                                    </span>
                                    {/* <IoIosArrowDown/> */}
                                </a>
                                    <div className={` dropdown-content ${showDropdown ? "active" : ""}`}>
                                        <button
                                            className="transoption"
                                            onClick={() =>
                                              navigate('/add-super-tenant')
                                            }
                                        >
                                            Super Tenant
                                        </button>
                                        <button
                                            className="transoption"
                                            onClick={() =>
                                              navigate('/add-tenant')
                                            }
                                        >
                                            Tenant
                                        </button>
                                    </div>
                                


                            </div>
          </div>
        </div>
      </div>
        
      <div className='setting-genre'>
        {['Super Tenants', 'Tenants'].map(tab => (
          <span
            key={tab}
            className={activeTab == tab ? 'active' : ''}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </span>
        ))}
      </div>
      <div className='channel-setup-table'>
        <div className="cms-table-list tenant-table">
          {activeTab === 'Super Tenants' ? (
          <table>
            <thead>
              <tr>
                <th onClick={() => { handleSorting('name') }}>Super Tenant Name
                  <span>
                    {sortColumn == 'name' && sortDirection == 'asc' ? <img src={sortingUp} /> : null}
                    {sortColumn == 'name' && sortDirection == 'desc' ? <img src={sortingDown} /> : null}
                    {sortColumn != 'name' ? <img src={sortingDefault} /> : null}
                  </span>
                </th>
                <th onClick={() => { handleSorting('onboardingDate') }}>Onboarded
                  <span>
                    {sortColumn == 'onboardingDate' && sortDirection == 'asc' ? <img src={sortingUp} /> : null}
                    {sortColumn == 'onboardingDate' && sortDirection == 'desc' ? <img src={sortingDown} /> : null}
                    {sortColumn != 'onboardingDate' ? <img src={sortingDefault} /> : null}
                  </span>
                </th>
                <th onClick={() => { handleSorting('numberOfChannels') }}>Number of Channels
                  <span>
                    {sortColumn == 'numberOfChannels' && sortDirection == 'asc' ? <img src={sortingUp} /> : null}
                    {sortColumn == 'numberOfChannels' && sortDirection == 'desc' ? <img src={sortingDown} /> : null}
                    {sortColumn != 'numberOfChannels' ? <img src={sortingDefault} /> : null}
                  </span>
                </th>
                <th onClick={() => { handleSorting('numberOfTenants') }}>Number of Tenants
                  <span>
                    {sortColumn == 'numberOfTenants' && sortDirection == 'asc' ? <img src={sortingUp} /> : null}
                    {sortColumn == 'numberOfTenants' && sortDirection == 'desc' ? <img src={sortingDown} /> : null}
                    {sortColumn != 'numberOfTenants' ? <img src={sortingDefault} /> : null}
                  </span>
                </th>
                <th onClick={() => { handleSorting('expiryDate') }}>Expiring On
                  <span>
                    {sortColumn == 'expiryDate' && sortDirection == 'asc' ? <img src={sortingUp} /> : null}
                    {sortColumn == 'expiryDate' && sortDirection == 'desc' ? <img src={sortingDown} /> : null}
                    {sortColumn != 'expiryDate' ? <img src={sortingDefault} /> : null}
                  </span>
                </th>
                <th>Tenant</th>
              </tr>
            </thead>
            <tbody>
              {dataGrid.map((tenant, index) => (
                <tr key={tenant.id}>
                  <td>{tenant.name}</td>
                  <td><span className='onboarded'><img src={checkicon} alt="check" /> {epochToDate(tenant.createdAt)}</span></td>
                  <td>{tenant.numberOfChannels}</td>
                  <td>{tenant.numberOfTenants}</td>
                  <td>{epochToDate(tenant.expiryDate)}&nbsp;<span className={`expired ${expiryClass(tenant.expiryDate)}`}>{expiryString(tenant.expiryDate)}</span></td>
                  <td>
                    
                  <Dropdown
                        show={isDropdownOpenArray[index]}
                        onToggle={(event) => {
                          setIsDropdownOpenArray((prevState) =>
                            prevState.map((value, i) =>
                              i === index ? !value : false
                            )
                          );
                        }}
                      >
                        <Dropdown.Toggle
                          id={`dropdown-basic-${tenant.id}`}
                          variant="light"
                          style={{ cursor: "pointer" }}
                          className="three-dots"
                        >
                          <span className="px-4">⋮</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                        <Dropdown.Item
                            onClick={(e) => {
                              e.stopPropagation();
                              openEditTenant(tenant);
                            }}
                            eventKey="option1"
                            onSelect={(e) =>
                              handleDropdownSelect("option1", index)
                            }
                          >
                            <img src={viewIcon} alt="" /> View
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              e.stopPropagation();
                              openEditTenant(tenant);
                            }}
                            eventKey="option1"
                            onSelect={(e) =>
                              handleDropdownSelect("option1", index)
                            }
                          >
                            <img src={editicon} alt="" /> Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="option2"
                            onSelect={() =>
                              handleDropdownSelect("option2", index)
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                         //     deleteTenant(tenant);
                           //   partnerArchive(tenant.id);
                            }}
                          >
                            <img src={archiveicon} alt="" /> Archive
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="option3"
                            onSelect={() =>
                              handleDropdownSelect("option3", index)
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteTenant(tenant);
                            }}
                          >
                            <img src={deleteicon} alt="" /> Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          ) : (
          <table>
            <thead>
              <tr>
                <th onClick={() => { handleSortingTenant('name') }}>Tenant Name
                  <span>
                    {sortColumn2 == 'name' && sortDirection2 == 'asc' ? <img src={sortingUp} /> : null}
                    {sortColumn2 == 'name' && sortDirection2 == 'desc' ? <img src={sortingDown} /> : null}
                    {sortColumn2 != 'name' ? <img src={sortingDefault} /> : null}
                  </span>
                </th>
                <th onClick={() => { handleSortingTenant('onboardingDate') }}>Onboarded
                  <span>
                    {sortColumn2 == 'onboardingDate' && sortDirection2 == 'asc' ? <img src={sortingUp} /> : null}
                    {sortColumn2 == 'onboardingDate' && sortDirection2 == 'desc' ? <img src={sortingDown} /> : null}
                    {sortColumn2 != 'onboardingDate' ? <img src={sortingDefault} /> : null}
                  </span>
                </th>
                <th onClick={() => { handleSortingTenant('numberOfChannels') }}>NO of Channels
                  <span>
                    {sortColumn2 == 'numberOfChannels' && sortDirection2 == 'asc' ? <img src={sortingUp} /> : null}
                    {sortColumn2 == 'numberOfChannels' && sortDirection2 == 'desc' ? <img src={sortingDown} /> : null}
                    {sortColumn2 != 'numberOfChannels' ? <img src={sortingDefault} /> : null}
                  </span>
                </th>
                <th onClick={() => { handleSortingTenant('numberOfUsers') }}>NO of Users
                  <span>
                    {sortColumn2 == 'numberOfUsers' && sortDirection2 == 'asc' ? <img src={sortingUp} /> : null}
                    {sortColumn2 == 'numberOfUsers' && sortDirection2 == 'desc' ? <img src={sortingDown} /> : null}
                    {sortColumn2 != 'numberOfUsers' ? <img src={sortingDefault} /> : null}
                  </span>
                </th>
                <th onClick={() => { handleSortingTenant('expiryDate') }}>Expiring On
                  <span>
                    {sortColumn2 == 'expiryDate' && sortDirection2 == 'asc' ? <img src={sortingUp} /> : null}
                    {sortColumn2 == 'expiryDate' && sortDirection2 == 'desc' ? <img src={sortingDown} /> : null}
                    {sortColumn2 != 'expiryDate' ? <img src={sortingDefault} /> : null}
                  </span>
                </th>
                <th>Tenant</th>
              </tr>
            </thead>
            <tbody>
              {dataGrid.map((tenant, index) => (
                <tr key={tenant.id}>
                  <td>{tenant.name}</td>
                  <td><span className='onboarded'><img src={checkicon} alt="check" /> {epochToDate(tenant.createdAt)}</span></td>
                  <td>{tenant.numberOfChannels}</td>
                  <td>{tenant.numberOfUsers}</td>
                  <td>{epochToDate(tenant.expiryDate)}&nbsp;<span className={`expired ${expiryClass(tenant.expiryDate)}`}>{expiryString(tenant.expiryDate)}</span></td>
                  <td>
                    
                  <Dropdown
                        show={isDropdownOpenArray[index]}
                        onToggle={(event) => {
                          setIsDropdownOpenArray((prevState) =>
                            prevState.map((value, i) =>
                              i === index ? !value : false
                            )
                          );
                        }}
                      >
                        <Dropdown.Toggle
                          id={`dropdown-basic-${tenant.id}`}
                          variant="light"
                          style={{ cursor: "pointer" }}
                          className="three-dots"
                        >
                          <span className="px-4">⋮</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                        <Dropdown.Item
                            onClick={(e) => {
                              e.stopPropagation();
                              openEditTenant(tenant);
                            }}
                            eventKey="option1"
                            onSelect={(e) =>
                              handleDropdownSelect("option1", index)
                            }
                          >
                            <img src={viewIcon} alt="" /> View
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => {
                              e.stopPropagation();
                              openEditTenant(tenant);
                            }}
                            eventKey="option1"
                            onSelect={(e) =>
                              handleDropdownSelect("option1", index)
                            }
                          >
                            <img src={editicon} alt="" /> Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="option2"
                            onSelect={() =>
                              handleDropdownSelect("option2", index)
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                         //     deleteTenant(tenant);
                           //   partnerArchive(tenant.id);
                            }}
                          >
                            <img src={archiveicon} alt="" /> Archive
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="option3"
                            onSelect={() =>
                              handleDropdownSelect("option3", index)
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteTenant(tenant);
                            }}
                          >
                            <img src={deleteicon} alt="" /> Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          )
        }
        </div>
      </div>
    </div>
    <ToastContainer />
    </div>
  );
}

export default TenantOnboarding;