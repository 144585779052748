import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css"; // Use this CSS module
import styles from "./AddPartnerpopup.module.scss";
import { Partner_create, Partner_update } from "../../api/api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const AddPartnerpopup = ({ show, handleClose, isEditing, partnerData, permission }) => {
  const [partnerName, setPartnerName] = useState(null);
  const [partnerId, setPartnerId] = useState(null);
  const [startDate, setStartDate] = useState(null);

  const [validForm, setValidForm] = useState("invalid");
  const [startTime, setStartTime] = useState("00:01");
  const [endDate, setEndDate] = useState(null);
  const [endTime, setEndTime] = useState("23:59");

  const {
    appThemeReducer: { appTheme },
  } = useSelector((state) => state);

  const convertTimestampToTime = (timestamp) => {
    const date = new Date(timestamp);
    const hours = ("0" + date.getHours()).slice(-2); // Get hours in 2-digit format
    const minutes = ("0" + date.getMinutes()).slice(-2); // Get minutes in 2-digit format
    return `${hours}:${minutes}`;
  };

  useEffect(() => {
    if (isEditing) {
      setPartnerName(partnerData.name);
      setPartnerId(partnerData.code);
      setStartDate(new Date(partnerData.startDate));
      setEndDate(new Date(partnerData.endDate));
      const startDateTime = new Date(partnerData.startDate);
      const endDateTime = new Date(partnerData.endDate);

      // // Get the time strings in 24-hour format
      // const startTime = startDateTime.toLocaleTimeString('en-IN', {
      //     hour: '2-digit',
      //     minute: '2-digit',
      //     hour12: false // Use 24-hour format
      // });

      const starttime = convertTimestampToTime(partnerData.startDate);
      const endTime = convertTimestampToTime(partnerData.endDate);

      setStartTime(starttime);
      setEndTime(endTime);
    } else {
      setPartnerName("");
      setPartnerId("");
      setStartDate(null);
      setEndDate(null);
      setStartTime("00:01"); // Set it to an empty string
      setEndTime("23:59");
    }
  }, [isEditing, partnerData]);

  useEffect(() => {
    formValidate();
  }, [partnerName, partnerId, startDate, endDate]);

  const formValidate = () => {
    if (
      partnerName &&
      partnerId &&
      startDate &&
      endDate &&
      startTime &&
      endTime
    ) {
      const stime = new Date(
        `${startDate?.toDateString()} ${startTime}`
      ).getTime();
      const etime = new Date(`${endDate?.toDateString()} ${endTime}`).getTime();
      if (stime > etime) {
        setValidForm("invalid");
        return;
      }
      setValidForm("valid");
    } else {
      setValidForm("invalid");
    }
  };

  const datareset = () => {
    setPartnerName("");
    setPartnerId("");
    setStartDate(null);
    setEndDate(null);
    setStartTime(null); // Set it to an empty string, not null
    setEndTime(null);
  };

  const addPartner = async () => {
    if (!validForm) {
      return;
    }
    if (isEditing) {
      const updatedPartnerData = {
        name: partnerName,
        startDate: new Date(
          `${startDate?.toDateString()} ${startTime}`
        ).getTime(),
        endDate: new Date(`${endDate?.toDateString()} ${endTime}`).getTime(),
      };

      if (partnerName && partnerId && startDate && endDate) {
        if (partnerId.length == 8) {
          if (startDate < endDate) {
            await Partner_update(updatedPartnerData, partnerData.id);
            datareset();
            handleClose();

            toast.success("Content Partner updated successfully", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 5000,
            });
            datareset();
            handleClose();
          } else {
            toast.error("End date cannot be greater than Start date", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 5000,
            });
          }
        } else {
          toast.error("Partner Code should be limited to 8 letters", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000,
          });
        }
      } else {
        toast.error("Please fill-in all required fields", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
      }
    } else {
      const newPartnerData = {
        code: partnerId,
        name: partnerName,
        startDate: new Date(
          `${startDate.toDateString()} ${startTime}`
        ).getTime(),
        endDate: new Date(`${endDate.toDateString()} ${endTime}`).getTime(),
      };

      if (partnerName && partnerId && startDate && endDate) {
        if (partnerId.length == 8) {
          if (startDate < endDate) {
            await Partner_create(newPartnerData);

            toast.success("Content Partner added successfully", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 5000,
            });
            datareset();
            handleClose();
          } else {
            toast.error("End date cannot be greater than Start date", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 5000,
            });
          }
        } else {
          toast.error("Partner Code should be limited to 8 letters", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000,
          });
        }
      } else {
        toast.error("Please fill-in all required fields", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
      }
    }
  };

  return (
    
      permission === "edit" ?
      <Modal
        show={show}
        className={`${styles.partnermodal} ${appTheme}`}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditing ? "Edit Partner" : "Add New Content Partner"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>
                Partner Name <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                placeholder="Enter partner’s name"
                type="text"
                value={partnerName}
                onChange={(e) => setPartnerName(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Partner Code <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                placeholder="Enter Partner’s code"
                type="text"
                value={partnerId}
                onChange={(e) => setPartnerId(e.target.value)}
                disabled={isEditing}
                maxLength={8} // Set maximum length of input
                minLength={8} // Set minimum length of input
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Start Date <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <DatePicker
                  className={styles.partnerDatepicker}
                  selected={startDate || null}
                  onChange={(date) => setStartDate(date)}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="dd/mm/yyyy"
                  isClearable // This allows the user to clear the selected date
                />
  
                <Form.Control
                  placeholder="HH:MM"
                  className={styles.startTimePicker}
                  type="time"
                  value={startTime || "00:01"} // Use '12:00' as the default time
                  onChange={(e) => {
                    const inputTime = e.target.value;
                    setStartTime(inputTime);
                  }}
                />
              </div>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                End Date <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <DatePicker
                  className={styles.partnerDatepicker}
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="dd/mm/yyyy"
                  isClearable
                  minDate={startDate} // Setting the minimum selectable date for the end date picker
                  disabled={!startDate} // Disable the end date picker if start date is not selected
                />
  
                <Form.Control
                  isClearable // This allows the user to clear the selected date
                  placeholder="HH:MM"
                  className={styles.startTimePicker}
                  type="time"
                  defaultValue={endTime || "23:59"}
                  onChange={(e) => {
                    const inputTime = e.target.value;
                    setEndTime(inputTime);
                  }}
                />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="Cancel btn btn-primary" onClick={handleClose}>
            Cancel
          </button>
          <button
            className={`Add btn btn-primary ${validForm}`}
            onClick={addPartner}
          >
            {isEditing ? "Update" : "Add"}
          </button>
        </Modal.Footer>
      </Modal>
      : permission === "read"?
      <Modal
        show={show}
        className={`${styles.partnermodal} ${appTheme}`}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            View Partner
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>
                Partner Name <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                placeholder="Enter partner’s name"
                type="text"
                value={partnerName}
                disabled
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Partner Code <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                placeholder="Enter Partner’s code"
                type="text"
                value={partnerId}
                disabled
                maxLength={8} // Set maximum length of input
                minLength={8} // Set minimum length of input
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Start Date <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <DatePicker
                  className={styles.partnerDatepicker}
                  selected={startDate || null}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="dd/mm/yyyy"
                  disabled
                />
  
                <Form.Control
                  placeholder="HH:MM"
                  className={styles.startTimePicker}
                  type="time"
                  value={startTime || "00:01"} // Use '12:00' as the default time
                  disabled
                />
              </div>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                End Date <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <DatePicker
                  className={styles.partnerDatepicker}
                  selected={endDate}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="dd/mm/yyyy"
                  minDate={startDate} // Setting the minimum selectable date for the end date picker
                  disabled
                />
  
                <Form.Control
                  isClearable // This allows the user to clear the selected date
                  placeholder="HH:MM"
                  className={styles.startTimePicker}
                  type="time"
                  defaultValue={endTime || "23:59"}
                  disabled
                />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button className="Cancel btn btn-primary" onClick={handleClose}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
      :
      ''
  );
};

export default AddPartnerpopup;
