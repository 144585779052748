import React, { useEffect, useRef } from 'react';
import folder from "../../assets/images/floder-open.png"
import red_upload from "../../assets/images/red_upload.png"
import deleteicon from "../../assets/images/deleteicon.png";
import tvicon from "../../assets/images/tv.png";
import tableticon from "../../assets/images/tablet.png";
import laptopicon from "../../assets/images/laptop.png";
import mobileicon from "../../assets/images/mobile.png";
import './style.scss';
import { AssetPoster_URL } from '../../service/API_URL';
import instance from '../../service/axiosConfig';
import { image_url } from '../../service/Constant';
import { useState } from 'react';
import { DeletePoster } from '../../api/api';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ImageViewer from '../Image-Viewer/ImageViewer';
import { preventDefault } from '@fullcalendar/core/internal';
function UploadPosters({ closepartition, videodata, assetdetail, permission }) {
  const fileInputRefs = useRef(Array(8).fill(null).map(() => React.createRef()));

  const [postdata, setPostdata] = useState(null);
  // const [posters, setPosters] = useState(videodata?.posters);
  let posters = videodata?.posters ? videodata?.posters : []
  
  const openUploadDialog = (data, index) => {
    fileInputRefs.current[index].current.click();
    // console.log("data", data);
    setPostdata(data)
  };
  // useEffect(()=>{
  //   setPosters(videodata?.posters)
  // },[videodata])




  const uploadFile = (event, index) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      // console.log("datadata", postdata);
      // You can add code here to handle the selected file, e.g., upload it to a server or display it.
      // alert(`Selected file for input ${index + 1}: ${selectedFile.name}`);
      // You can trigger a page reload after handling the file upload.
      // window.location.reload();
      uploadposter(postdata, selectedFile)
    }
  };

  const uploadposter = async (datas, selectedFile) => {
    const data = {
      assetId: videodata.id,
      assetType: "VIDEO",
      deviceType: datas.deviceType,
      posterType: datas.posterType,
    };
    //console.log(datas,"data",data);
    const URL = AssetPoster_URL;
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("assetId", videodata.id);
    formData.append("assetType", "VIDEO");
    formData.append("deviceType", data.deviceType);
    formData.append("posterType", data.posterType);
    try {
      const response = await instance.post(URL, formData);


  
      assetdetail()
      // console.log("response",response.data);
      setPostdata(null)
      toast.success("Poster uploaded successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000
      });

    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000
      }); 
    }
  }

  const categories = [
    {
      label: 'Mobile',
      indexes: [
        {
          deviceType: "MOBILE",
          posterType: "HERO",
          size: "10 X 16",
          img: posters?.some(poster => poster?.deviceType == "MOBILE" && poster?.heroImagePath != null) ? image_url + posters?.find(poster => poster?.deviceType === "MOBILE" && poster?.heroImagePath != null).heroImagePath : red_upload,
          isExist: posters?.some(poster => poster?.deviceType === "MOBILE" && poster?.heroImagePath != null),
          id: posters?.some(poster => poster?.deviceType === "MOBILE" && poster?.heroImagePath != null) ? posters?.find(poster => poster?.deviceType === "MOBILE" && poster?.heroImagePath != null).id : ""
        },
        {
          deviceType: "MOBILE",
          posterType: "COVER",
          size: "20 X 16",
          img: posters.some(poster => poster?.deviceType == "MOBILE" && poster?.coverImagePath != null) ? image_url + posters?.find(poster => poster?.deviceType === "MOBILE" && poster?.coverImagePath != null).coverImagePath : red_upload,
          isExist: posters?.some(poster => poster?.deviceType === "MOBILE" && poster?.coverImagePath != null),
          id: posters?.some(poster => poster?.deviceType === "MOBILE" && poster?.coverImagePath != null) ? posters?.find(poster => poster?.deviceType === "MOBILE" && poster?.coverImagePath != null).id : ""
        }
      ],
      img: mobileicon
    },
    {
      label: 'TV',
      indexes: [
        {
          deviceType: "TV",
          posterType: "HERO",
          size: "10 X 16",
          img: posters?.some(poster => poster?.deviceType === "TV" && poster?.heroImagePath != null) ? image_url + posters?.find(poster => poster?.deviceType === "TV" && poster?.heroImagePath != null).heroImagePath : red_upload,
          isExist: posters?.some(poster => poster?.deviceType === "TV" && poster?.heroImagePath != null),
          id: posters?.some(poster => poster?.deviceType === "TV" && poster?.heroImagePath != null) ? posters?.find(poster => poster?.deviceType === "TV" && poster?.heroImagePath != null).id : ""
        },
        {
          deviceType: "TV",
          posterType: "COVER",
          size: "20 X 16",
          img: posters?.some(poster => poster?.deviceType === "TV" && poster?.coverImagePath != null) ? image_url + posters?.find(poster => poster?.deviceType === "TV" && poster?.coverImagePath != null).coverImagePath : red_upload,
          isExist: posters?.some(poster => poster?.deviceType === "TV" && poster?.coverImagePath != null),
          id: posters?.some(poster => poster?.deviceType === "TV" && poster?.coverImagePath != null) ? posters?.find(poster => poster?.deviceType === "TV" && poster?.coverImagePath != null).id : ""
        }
      ],
      img: tvicon
    },
    {
      label: 'WEB',
      indexes: [
        {
          deviceType: "WEB",
          posterType: "HERO",
          size: "10 X 16",
          img: posters?.some(poster => poster?.deviceType === "WEB" && poster?.heroImagePath != null) ? image_url + posters?.find(poster => poster?.deviceType === "WEB" && poster?.heroImagePath != null).heroImagePath : red_upload,
          isExist: posters?.some(poster => poster?.deviceType === "WEB" && poster?.heroImagePath != null),
          id: posters?.some(poster => poster?.deviceType === "WEB" && poster?.heroImagePath != null) ? posters?.find(poster => poster?.deviceType === "WEB" && poster?.heroImagePath != null).id : ""
        },
        {
          deviceType: "WEB",
          posterType: "COVER",
          size: "20 X 16",
          img: posters?.some(poster => poster?.deviceType === "WEB" && poster?.coverImagePath != null) ? image_url + posters?.find(poster => poster?.deviceType === "WEB" && poster?.coverImagePath != null).coverImagePath : red_upload,
          isExist: posters?.some(poster => poster?.deviceType === "WEB" && poster?.coverImagePath != null),
          id: posters?.some(poster => poster?.deviceType === "WEB" && poster?.coverImagePath != null) ? posters?.find(poster => poster?.deviceType === "WEB" && poster?.coverImagePath != null)?.id : ""
        }
      ],
      img: laptopicon
    },
    {
      label: 'TABLET',
      indexes: [
        {
          deviceType: "TABLET",
          posterType: "HERO",
          size: "10 X 16",
          img: posters?.some(poster => poster?.deviceType === "TABLET" && poster?.heroImagePath != null) ? image_url + posters?.find(poster => poster?.deviceType === "TABLET" && poster?.heroImagePath != null).heroImagePath : red_upload,
          isExist: posters?.some(poster => poster?.deviceType === "TABLET" && poster?.heroImagePath != null),
          id: posters?.some(poster => poster?.deviceType === "TABLET" && poster?.heroImagePath != null) ? posters?.find(poster => poster?.deviceType === "TABLET" && poster?.heroImagePath != null).id : ""
        },
        {
          deviceType: "TABLET",
          posterType: "COVER",
          size: "20 X 16",
          img: posters?.some(poster => poster?.deviceType === "TABLET" && poster?.coverImagePath != null) ? image_url + posters?.find(poster => poster?.deviceType === "TABLET" && poster?.coverImagePath != null).coverImagePath : red_upload,
          isExist: posters?.some(poster => poster?.deviceType === "TABLET" && poster?.coverImagePath != null),
          id: posters?.some(poster => poster?.deviceType === "TABLET" && poster?.coverImagePath != null) ? posters?.find(poster => poster?.deviceType === "TABLET" && poster?.coverImagePath != null).id : ""
        }
      ],
      img: tableticon
    },
  ];

  const imageArray = categories.flatMap(category =>
    category.indexes.map(index => ({
      deviceType: index.deviceType,
      posterType: index.posterType,
      img: index.img,
      Name :videodata 
    }))
  ).filter(obj => obj.img != red_upload);
  
  const deleteposters = async (event, id,type) => {
    event.stopPropagation();

    const response = await DeletePoster(id,type);

    if (response?.data?.status == true) {
      assetdetail()
      toast.success("Poster deleted successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000
      });
    }else{
      toast.error("Something went wrong, please try after sometime", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000
      }); 
    }

  
  }


  // console.log("videodata", videodata);
  return (
    <div className={videodata == null ? "channel-content d-flex col-4" : "channel-content col-4"}>
      {videodata == null ? <>

        <div className='blank-text m-auto'>Click on the file to view the device poster</div>


      </> : <>

        <div className="video-container">
          <div className="video-title">
            
          {videodata?.metadata == null
            ? videodata?.filename
            : videodata?.metadata?.title}
          <p className="small-grey-text">{videodata?.metadata == null
            ? null
            : "(" + videodata?.filename + ")"}</p>
            
            
         
            </div>

          <button className="image-container image-container-poster" onClick={() => closepartition()}>
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/4ce38f7a-447d-4033-ab0f-087a81ad20f5?"
              className="image"
            />
          </button>
        </div>
        <div className="upload-grid my-3">
          {categories.map((category, categoryIndex) => (
            <div className="row my-3" key={category.label}>
              <div className="col-4 label-box">
                <div className="icon-container">
                  <div className="folder-icon-container">
                    <img className="" src={category.img} alt="Folder" />
                  </div>
                  <div className="text">{category.label}</div>
                </div>
              </div>
              <div className=" row col-8 m-auto">
                {category.indexes.map((data, index) => (
                  permission === "edit"?
                  <>
                  <div className="col-6" key={index}>
                     
                    <div
                      className="folder-icon my-2"
                      onClick={() => openUploadDialog(data, index)}
                    >

                      <div className="background ">
                        {data.isExist ? (
                          <>
                     
                            <a className="delete-icon" onClick={(event) => deleteposters(event, data.id, data.posterType)}>
                              <img src={deleteicon} alt="Delete" />
                            </a>
                            <img className="back-image" src={data.img} alt="Uploaded" />
                            <a onClick={(event) =>{event.stopPropagation()}}>
                            <ImageViewer  imageUrl={imageArray} urlimg={data.img} />
                            </a>
                          
                          
                          </>
                        ) : <div className="content">
                          <div className='mb-1'> <img className="content-image" src={data.img} alt="Icon" /></div>

                          <div className="title">{data.posterType}</div>
                          <div className="title">Size: {data.size}</div>



                        </div>}
                      </div>


                    </div>
                    <input
                      key={index}
                      type="file"
                      ref={fileInputRefs.current[categoryIndex * category.indexes.length + index]}
                      style={{ display: 'none' }}
                      accept="image/*"
                      onChange={(event) => {
                        uploadFile(event, categoryIndex * category.indexes.length + index);
                        event.target.value = null;  // Reset the input value
                      }}
                    />
                  </div>
                  </>
                  : permission === "read"?
                  <>
                  <div className="col-6" key={index}>
                     
                    <div
                      className="folder-icon my-2"
                    >

                      <div className="background ">
                        {data.isExist ? (
                          <>
                            <img className="back-image" src={data.img} alt="Uploaded" />
                            <a onClick={(event) =>{event.stopPropagation()}}>
                            <ImageViewer  imageUrl={imageArray} urlimg={data.img} />
                            </a>
                          
                          
                          </>
                        ) : <div className="content">
                          <div className='mb-1'> <img className="content-image" src={data.img} alt="Icon" /></div>

                          <div className="title">{data.posterType}</div>
                          <div className="title">Size: {data.size}</div>



                        </div>}
                      </div>


                    </div>
                  </div>
                  </>
                  :
                  ''
                ))}
              </div>
            </div>
          ))}
        </div>
      </>
      }
    </div>
  );
}

export default UploadPosters;
