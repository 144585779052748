import React, { useState } from "react";
import down_arrow from "../../../assets/images/down-arrow.svg";
import filter_alt from "../../../assets/images/sortarrow.png";
import { TimeConverter, formatNumber } from "../../../service/commonFunc";
import sort from "../../../assets/images/sort.png";
import desc from "../../../assets/images/desc.png";
function AccordionTableHead({ data, onTableHeadClick, onToggle, isOpen }) {
  const TableHeadData = data;
  const [sortOrders, setSortOrders] = useState({}); // Store sorting orders for each column

  const defaultSortColumn = "showName"; // Default sorting column

  const toggleSortOrder = (columnValue) => {
    const currentSortOrder = sortOrders[columnValue] || "showName"; // Default to "desc" if not set
    const newSortOrder = currentSortOrder === "desc" ? "asc" : "desc";

    // Update the sorting order for the clicked column
    setSortOrders({  [columnValue]: newSortOrder });
    const combinedData = {
      sort: newSortOrder,
      key: columnValue,
    };
    onTableHeadClick(combinedData);
  };

  return (
    <div className="table-row">
      <div className="table-col name" onClick={() => onToggle()}>
        <div className="icon">
          <img src={down_arrow} alt="Down Arrow" />
        </div>
        <span className="mx-2">{TableHeadData?.viewKey}</span>
        <div className="filter-block">
          {isOpen && (
            <a
              className="filter-icon "
              onClick={(e) => {
                e.stopPropagation();
                toggleSortOrder("showName");
              }}
            >
             {sortOrders["showName"] === "asc" && <img src={filter_alt} alt="Ascending" />}
                    {sortOrders["showName"] === "desc" && <img src={desc} alt="Descending" />}
                    {!sortOrders["showName"] && <img src={sort} alt="No Sorting" />}
            </a>
          )}
        </div>
      </div>
      <div className="table-col" onClick={() => onToggle()}>
        <span className="mx-2">
          {formatNumber(TableHeadData?.numberOfActiveUsers)}
        </span>

        {isOpen && (
          <a
            className="filter-icon "
            onClick={(e) => {
              e.stopPropagation();
              toggleSortOrder("numberOfActiveUsers");
            }}
          >
           {sortOrders["numberOfActiveUsers"] === "asc" && <img src={filter_alt} alt="Ascending" />}
                    {sortOrders["numberOfActiveUsers"] === "desc" && <img src={desc} alt="Descending" />}
                    {!sortOrders["numberOfActiveUsers"] && <img src={sort} alt="No Sorting" />}
          </a>
        )}
      </div>
      <div className="table-col" onClick={() => onToggle()}>
        <span className="mx-2">
          {formatNumber(TableHeadData?.totalWatchHours?.toFixed(2))} Hrs
        </span>
        {isOpen && (
          <a
            className="filter-icon "
            onClick={(e) => {
              e.stopPropagation();
              toggleSortOrder("totalWatchHours");
            }}
          >
             {sortOrders["totalWatchHours"] === "asc" && <img src={filter_alt} alt="Ascending" />}
                    {sortOrders["totalWatchHours"] === "desc" && <img src={desc} alt="Descending" />}
                    {!sortOrders["totalWatchHours"] && <img src={sort} alt="No Sorting" />}
          </a>
        )}
      </div>

      <div className="table-col" onClick={() => onToggle()}>
        <span className="mx-2">
          {TimeConverter(TableHeadData?.averageWatchTimePerUser)}
        </span>
        {isOpen && (
          <a
            onClick={(e) => {
              e.stopPropagation();
              toggleSortOrder("averageWatchTimePerUser");
            }}
          >
             {sortOrders["averageWatchTimePerUser"] === "asc" && <img src={filter_alt} alt="Ascending" />}
                    {sortOrders["averageWatchTimePerUser"] === "desc" && <img src={desc} alt="Descending" />}
                    {!sortOrders["averageWatchTimePerUser"] && <img src={sort} alt="No Sorting" />}
          </a>
        )}
      </div>

      <div className="table-col" onClick={() => onToggle()}>
        <span className="mx-2">
          {TimeConverter(TableHeadData?.averageWatchTimePerSession)}
        </span>
        {isOpen && (
          <a
            onClick={(e) => {
              e.stopPropagation();
              toggleSortOrder("averageWatchTimePerSession");
            }}
          >
             {sortOrders["averageWatchTimePerSession"] === "asc" && <img src={filter_alt} alt="Ascending" />}
                    {sortOrders["averageWatchTimePerSession"] === "desc" && <img src={desc} alt="Descending" />}
                    {!sortOrders["averageWatchTimePerSession"] && <img src={sort} alt="No Sorting" />}
          </a>
        )}
      </div>

    
    </div>
  );
}

export default AccordionTableHead;
