import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import "./FileUploader.scss";

import { cloudFront_URL } from "../../service/API_URL";
import deleteicon from "../../assets/images/deleteicon.png";

export default function FilesDragAndDrop({
  label,
  size,
  icontype,
  validsize,
  onChangeHandler,
  ToastContainer,
  imagetype,
  imgcategory,
  uploadedFiles,
  deleteimages,
  edit,
}) {
  const [imageShow, setimageShow] = useState(null);
  useEffect(() => {
    if (imgcategory == "poster") {
      setimageShow(uploadedFiles?.[imagetype]?.[imgcategory]?.file);
    } else if (imgcategory == "tenantadd") {
      setimageShow(uploadedFiles?.[imagetype]?.file);
    }else if(imgcategory == "avatar") {
      setimageShow(uploadedFiles?.file);
    }else {
      setimageShow(uploadedFiles?.[imagetype]?.[imgcategory]?.[icontype]?.file);
    }
    // setimageShow(uploadedFiles[imagetype][icontype].file)
  }, [uploadedFiles, imagetype, imgcategory]);
  const validateFileSize = (e, imagetype, imgcategory, icontype) => {
    //if validsize not passed no need to validate

    if (!validsize) {
      onChangeHandler(e, imagetype, imgcategory, icontype);
      return;
    }

    const img = new Image();

    img.src = window.URL.createObjectURL(e.target.files[0]);
    img.onload = () => {
      const width = parseInt(validsize.substring(0, 2));
      const height = parseInt(validsize.substring(validsize.length - 2));
      if (img.naturalWidth === width && img.naturalHeight === height) {
        onChangeHandler(e, imagetype, imgcategory, icontype);
      } else {
        //if invalid image show toaster
        toast.error(
          `Image size invalid. Expected ${width} x ${height} but received ${img.naturalWidth} x ${img.naturalHeight}.`,
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
          }
        );
      }
    };
  };

  // const showImage = (imageFile) => {
  //   if (imageFile) {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       setImagepath(reader.result); // Set the uploaded image to state
  //     };
  //     reader.readAsDataURL(imageFile); // Read the file as a data URL
  //   }
  // };

  const delete_img = (imageShow, imagetype, imgcategory) => {
    deleteimages(imageShow, imagetype, imgcategory, icontype);
    setimageShow(null);
  };

  return (
    <div className={imageShow ? "imageexist" : "box__input"}>
      {imageShow ? (
        <>
          {!edit && (
            <a>
              <img
                onClick={() => delete_img(imageShow, imagetype, imgcategory)}
                src={deleteicon}
                alt="Delete"
              />
            </a>
          )}

          {
            typeof imageShow === "string" && imageShow.startsWith("data:image")
              ? <img className="image-show" src={imageShow} alt="Uploaded" />
              : <img className="image-show" src={cloudFront_URL + imageShow} alt="Uploaded" />
          }
        </>
      ) : (
        <>
          <input
            type="file"
            className="inputFile"
            name="file"
            // accept="image/png, image/jpeg"
            onChange={(e) => validateFileSize(e, imagetype, imgcategory, icontype)}
            disabled={edit}
          />
          <div className="box-label">{label}</div>
          <div className="box-size">{size}</div>
          {/* { imagepath && <img src={imagepath} alt="Uploaded" />} */}
        </>
      )}

      {/* Display the image if uploaded */}
    </div>
  );
}

FilesDragAndDrop.propTypes = {
  label: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  icontype: PropTypes.string.isRequired,
  validsize: PropTypes.string,
  onChangeHandler: PropTypes.func.isRequired,
  ToastContainer: PropTypes.element.isRequired,
  imagetype: PropTypes.string.isRequired,
  imgcategory: PropTypes.string.isRequired,
  uploadedFiles: PropTypes.object.isRequired,
  deleteimages: PropTypes.func.isRequired,
  edit: PropTypes.bool,
};
