export const xmlData = [
    {
        sectionTitle: 'inputs',
        fields: [
            {
                name: 'title',
                label: 'Title',
                type: 'text',
                required: true,
                placeholder: 'Enter a title',
            },
            {
                name: 'programType',
                label: 'Program Type',
                type: 'text',
                required: false,
                placeholder: 'Enter Program Type',
            },
            {
                name: 'duration',
                label: 'Duration',
                type: 'text',
                required: false,
                placeholder: 'Enter Duration',
            },
            {
                name: 'releaseDate',
                label: 'Release Date',
                type: 'date',
                required: false,
            },
            {
                name: 'genres',
                label: 'Genres',
                type: 'multiSelect',
                required: false,
            },
            {
                name: 'themes',
                label: 'Themes',
                type: 'multiSelect',
                required: false,
            },
            {
                name: 'categories',
                label: 'Categories',
                type: 'multiSelect',
                required: false,
            },
            {
                name: 'audioLanguages',
                label: 'Audio Languages',
                type: 'multiSelect',
                required: false,
            },
            {
                name: 'contentGroup',
                label: 'Content Group',
                type: 'select',
                required: false,
            },
            {
                name: 'ratings',
                label: 'Rating',
                type: 'select',
                required: false,
            },
            {
                name: 'ratingsDescriptor',
                label: 'Ratings Descriptor',
                type: 'text',
                required: false,
            },
            {
                name: 'territories',
                label: 'Territory',
                type: 'multiSelect',
                required: false,
            },
            {
                name: 'startDate',
                label: 'Start Date',
                type: 'date',
                required: false,
            },
            {
                name: 'endDate',
                label: 'End Date',
                type: 'date',
                required: false,
            },
            {
                name: 'originalLanguage',
                label: 'Original Language',
                type: 'select',
                required: false,
            },
            {
                name: 'origin',
                label: 'Origin',
                type: 'select',
                required: false,
            },
            {
                name: 'actors',
                label: 'Actors',
                type: 'text',
                required: false,
            },
            {
                name: 'directors',
                label: 'Directors',
                type: 'text',
                required: false,
            },
            
            
        ],
    },
    {
        sectionTitle: 'textarea',
        fields: [
            {
                name: 'shortSynopsis',
                label: 'Short Synopsis',
                type: 'textarea',
                required: false,
                placeholder: 'Share your thoughts',
            },
        ],
    },
];

export const channelSetupMetaData = [
    {
        channelSource: [
            {
                sectionTitle: 'inputs',
                fields: [
                    {
                        name: 'awsChannelName',
                        label: 'Channel Name',
                        type: 'text',
                        required: true,
                    },
                ],
            },
            {
                sectionTitle: 'selectors',
                fields: [
                    {
                        name: 'partners',
                        label: 'Partners',
                        type: 'multiSelect',
                        required: true,
                    },
                ],
            },
        ],
    },
    {
        channelDetails : [
            {
                sectionTitle: 'inputs',
                fields: [
                    {
                        name: 'channelId',
                        label: 'Channel Id',
                        type: 'text',
                        required: true,
                    },
                    {
                        name: 'epgTypeApiUrl',
                        label: 'API URL',
                        type: 'text',
                        required: false,
                    },
                    {
                        name: 'duration',
                        label: 'Duration',
                        type: 'text',
                        required: false,
                    },
                ],
            },
            {
                sectionTitle: 'selectors',
                fields: [
                    {
                        name: 'type',
                        label: 'Channel Type',
                        type: 'select',
                        required: true,
                    },
                    {
                        name: 'channelAssembly',
                        label: 'Channel Assembly',
                        type: 'select',
                        required: true,
                    },
                    {
                        name: 'epgHandler',
                        label: 'EPG Handler',
                        type: 'select',
                        required: true,
                    },
                    {
                        name: 'epgType',
                        label: 'EPG Type',
                        type: 'select',
                        required: true,
                    },
                    {
                        name: 'isStartOverEnabled',
                        label: 'Start Over',
                        type: 'select',
                        required: true,
                    },
                    {
                        name: 'isWatchListEnabled',
                        label: 'Watch List',
                        type: 'select',
                        required: true,
                    },
                    {
                        name: 'isForceLogin',
                        label: 'Force Login',
                        type: 'select',
                        required: true,
                    },
                    {
                        name: 'isKidsSafe',
                        label: 'Kids Safe',
                        type: 'select',
                        required: true,
                    },
                    {
                        name: 'goLiveDate',
                        label: 'Go-Live date',
                        type: 'date',
                        required: true,
                    },
                    {
                        name: 'channelSetupDate',
                        label: 'Channel Setup date',
                        type: 'date',
                        required: true,
                    },
                    {
                        name: 'description',
                        label: 'Channel Description',
                        type: 'textarea',
                        required: false,
                        placeholder: 'Share your thoughts',
                    },
                    {
                        name: 'territoryDetails',
                        label: 'Territory',
                        type: 'multiSelect',
                        required: true,
                    },
                    {
                        name: 'regionalLanguage',
                        label: 'Language',
                        type: 'select',
                        required: true,
                    },
                    {
                        name: 'categories',
                        label: 'Channel Category',
                        type: 'multiSelect',
                        required: true,
                    },
                    {
                        name: 'theme',
                        label: 'Channel Theme',
                        type: 'multiSelect',
                        required: true,
                    },
                    {
                        name: 'fillerSourcePath',
                        label: 'Default Filler',
                        type: 'select',
                        required: true,
                    },
                    {
                        name: 'channelTags',
                        label: 'Channel Tags',
                        type: 'text',
                        required: false,
                    },
                    {
                        name: 'playoutBaseUrl',
                        label: 'Playout Base URL',
                        type: 'text',
                        required: true,
                    },
                    {
                        name: 'playbackUrl',
                        label: 'Playback URL',
                        type: 'text',
                        required: true,
                    },
                    {
                        name: 'channelLogo',
                        label: 'Channel Image',
                        type: 'multiSelect',
                        required: true,
                    },
                ],
            },
            {
                sectionTitle: 'textarea',
                fields: [
                    
                    {
                        name: 'customAttributes',
                        label: 'Custom Attributes',
                        type: 'textarea',
                        required: true,
                        placeholder: 'Share your thoughts',
                    },
                ],
            },
        ]
    },   
]

// export default { xmlData, channelSetupMetaData };