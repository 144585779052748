export const permissionDetails = () => {
    const userLocalData = localStorage.getItem('userdetail');
    const userLocalDataParse = JSON.parse(userLocalData);
    return userLocalDataParse?.permissionSet
    // return [
    //     "ASSET_MANAGEMENT_EDIT",
    //     "CHANNEL_SETUP_EDIT",
    //     "STOREFRONT_EDIT",
    //     "MASTER_SETTINGS_EDIT",
    //     "RULE_ENGINE_READ",
    //     "USER_MANAGEMENT_READ",
    //     "RULE_ENGINE_EDIT",
    //     "PLAYOUT_MONITORING_READ",
    //     "CHANNEL_SETUP_READ",
    //     "TENANT_ONBOARDING_EDIT",
    //     "USER_MANAGEMENT_EDIT",
    //     "ASSET_MANAGEMENT_READ",
    //     "SCHEDULING_READ",
    //     "STOREFRONT_READ",
    //     "TENANT_ONBOARDING_READ",
    //     "MASTER_SETTINGS_READ",
    //     "DASHBOARD_READ",
    //     "SCHEDULING_READ"
    // ];
}