import axios from "axios";
import { findTenantUrl } from "./API_URL";

export const buildTenantId = () => {
  const hostnamePrefix = extractHostnamePrefix(window.location.hostname);
  if (hostnamePrefix === "localhost") {
    return "runn";
  } else if (window.location.hostname === '127.0.0.1') {
    return "ultra";
  } else if (window.location.hostname === 'cms-dev-ui.runn.tv') {
    return 'runn';
  } else if (window.location.hostname === 'runntv-cms-uat.s3-website.ap-south-1.amazonaws.com') {
    return 'runn';
  } else if (window.location.hostname === 'runnmedia-cms.runn.tv') {
    return 'runn';
  }
  return hostnamePrefix;
};

export const buildTenantIdV2 = () => {
  if (window.location.hostname === "localhost") {
    return "runn";
  } else if (window.location.hostname === "127.0.0.1") {
    return "default";
  } else {
    const tenantInfo = JSON.parse(localStorage.getItem("tenantInfo"));
    if (tenantInfo && tenantInfo?.identifier) {
      return tenantInfo?.identifier;
    } else {
      return "";
    }
  }
};

const extractHostnamePrefix = (hostname) => {
  // Extract the subdomain from the hostname
  const parts = hostname.split(".");
  console.log(">>> hostname parts:", parts);
  return parts[0];
};

// Fetchs tenant information from the backend
const fetchTenantInfo = async (subDomainName) => {
  try {
      const response = await axios.get(
          `${findTenantUrl}?subDomainName=${ subDomainName === 'localhost'? "uat-playstudio.runn.media" :subDomainName  }`,
          {
              headers: {
                  "X-Tenant-Id": "default",
              }
          }
      );
      
      if (response.data && response.data.status) {
          return response.data.data;
      } else {
          console.error("Failed to fetch tenant info");
          return null;
      }
  } catch (error) {
      console.error("Error fetching tenant info:", error);
      return null;
  }
};

// Saves tenant information to local storage
export const saveTenantInfo = async () => {
  const subDomainName = window.location.hostname;
  const tenantInfo = await fetchTenantInfo(subDomainName);
  if (tenantInfo) {
      localStorage.setItem("tenantInfo", JSON.stringify(tenantInfo));
  }
};
