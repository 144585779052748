import React, {useEffect, useState, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import editicon from "../../assets/images/editicon.png";
import deleteicon from "../../assets/images/delete_forever.png";
import billingAdd from '../../assets/images/billing-add.png';
import defaultStar from "../../assets/images/default-star.png";
import selectedStar from "../../assets/images/selected-star.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { postInstantData, getInstantData } from "../../api/apiMethod";


import DataGrid, { textEditor } from 'react-data-grid';
import ErrorPopup from '../../component/popup/ErrorPopup';
import { SaveTenant_URL, Super_Tenant_URL } from "../../service/API_URL";
import Multiselect from 'multiselect-react-dropdown';
import closeChip from '../../assets/images/close-chip.svg';
import dropdownArrow from '../../assets/images/dropdown-arrow.svg';

const TenantAdd = () => {
  const [rows, setRows] = useState([]);
  const [editTenant, setEditTenant] = useState(null);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
  const [superTenant, setSuperTenant] = useState([]);
  const [transcodeProfile, setTranscodeProfile] = useState([]);
  // default added

  const state = useSelector((state) => state);
  const Territorys = state?.CountryList?.data?.data;

  const locationState = useLocation();
  const tenant = locationState.state?.tenant;
  const { TimeZoneList, CountryList, MetaDataList, ValidationDataList, ConfigDataList, ChannelSchemes } = useSelector(state =>
    ({ TimeZoneList : state.TimeZoneList.data,
      CountryList: state.CountryList.data.data,
      MetaDataList: state.MetaDataList.data,
      ValidationDataList: state.ValidationDataList.data,
      ConfigDataList: state.ConfigDataList.data,
      ChannelSchemes:  state.ChannelSchemes.data
     }));
    
  // Default Form Value on Initial Load
  const defaultFormValue = {    
    code: '',
    name: '',
    email: '',
    phone: '',
    address: '',
    address2: '',
    primaryTimezoneId : '',
    numberOfUsers: '',
    numberOfChannels: '',
    tenantTerritoryId: '',
    superTenantId: '',
    posterDeviceIds: [],
    deviceIds: [],
    graphicIds: [],
    metadataSchemeId: 1,
    validationSchemeId: 1,
    defaultTranscodingProfileId: '',
    transcodingProfileIds: [],
    contentLocations: [
      {
        storageProvider: "RUNN_STORAGE",
        accessKey: "accessKey3",
        secretKey: "secretKey1"
      }
    ],
    cdnList: [
        {
            cdnProvider: "RUNN_CDN",
            name: "Primary CDN",
            cdnUrl: "https://prod-runntv.b-cdn.net",
            runnCdnTerritoryIds: []
        }
    ],
    billingDetail: {
      name: '',
      phone: '',
      email: '',
      currency: '',
      startDate: '',
      endDate: '',
      billingInfo: ''
    }
  }

  const getSuperTenant = async () => {
    const URL = Super_Tenant_URL;
    const response = await getInstantData(URL);
    setSuperTenant(response.data);
  }

  // Default Error Value on Initial Load
  const defaultFormError = {
    code: '',
    name: '',
    email: '',
    phone: '',
    address: '',
    address2: '',
    primaryTimezoneId : '',
    numberOfUsers: '',
    numberOfChannels: '',
    tenantTerritoryId: '',
    superTenantId: '',
    posterDeviceIds: [],
    deviceIds: [],
    graphicIds: [],
    metadataSchemeId: '',
    validationSchemeId: '',
    defaultTranscodingProfileId: '',
    transcodingProfileIds: [],
    contentLocations: [{
      storageProvider: "",
      accessKey: "",
      secretKey: ""
    }],
    cdnList: [
      {
          cdnProvider: "",
          name: "",
          cdnUrl: "",
          runnCdnTerritoryIds: ""
      }
    ],
    billingDetail: {
      name: '',
      phone: '',
      email: '',
      currency: '',
      startDate: '',
      endDate: '',
      billingInfo: ''
    }
  }

  const dropDownArray = [
    "One Time Payment",
    "Weekly",
    "Monthly",
    "Quarterly",
    "Semi Annual",
    "Annual"
  ];

  const setDropDownValue = (row, column,
    onRowChange) => {
    return <select
  //  className="gridDropdown"
    value={row[2]}
    onChange={(event) => {
      onRowChange({ ...row, billingType: event.target.value }, true);
    }}
    autoFocus
  >
    {dropDownArray?.map((val, index) => (
      <option key={index} value={val}>
        {val}
      </option>
    ))}
  </select>
  }
  const editRow = (row, index) => {
    // find index of rows if row.seqNo matches rows.seqNo
    const rowIndex = rows.findIndex((data) => data.seqNo === row.seqNo);
  }
  const deleteRow = (row) => {
    if(row?.seqNo) {
      // resume work from here
      setRows(rows.filter((data) => data.seqNo!==row.seqNo));
    }
  }
  const getColumns = () => {
    return [
      {
        key: 'seqNo',
        name: 'SEQUENCE NO',
        width: '120px',
      //  frozen: true,
        renderEditCell: textEditor
      },
      {
        key: 'billingDetail',
        name: 'BILLING DETAIL',
        width: 'calc(100% - 480px)',
        renderEditCell: textEditor,
      },
      {
        key: 'billingType',
        name: 'BILLING TYPE',
        width: '120px',
        renderEditCell: ({ row, column,
          onRowChange }) => setDropDownValue(row, column,
          onRowChange),
      },
      {
        key: 'amount',
        name: 'AMOUNT',
        width: '120px',
        renderEditCell: textEditor,
      },
      {
        key: 'action',
        name: 'ACTIONS',
        width: '120px',
        renderCell: ({row,index}) => {
          const btns = <div className='actions'>
            <img src={editicon} alt="Edit bill" onClick={() => {editRow(row)}}/>
            <img src={deleteicon} alt="Delete bill" onClick={() => {deleteRow(row)}} />
          </div>
          return btns
        },
      //  renderEditCell: textEditor,
      }
    ]
  }
  
  const columns = useMemo(() => getColumns(), []);
  // Form State
  const [formData, setFormData] = useState({ ...defaultFormValue });
  // Error State

  const [errors, setErrors] = useState({ ...defaultFormError });
  // Special Fiels Stat Handling
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [selectedRow, setSelectedRow] = useState([]);

  // Create or Update Identification Flag
  const [isEdit, setIsEdit] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  // Field Options State
  const [territoryOption, setTerritoryOption] = useState([]);
  const [dataCheckvalid, setdataCheckvalid] = useState({ ...defaultFormValue });
  const [storeFrontAccess, setStoreFrontAccess] = useState("");
  const [origin, setOrigin] = useState(91);
  const [contactCountry, setContactCountry] = useState(91);
  const navigate = useNavigate();

  const backToChannel = () => {
    navigate("/channel");
  };

  const [errorbox, setErrorbox] = useState(false);
 
  const errorboxclose = () => {
    setErrorbox(false)
  }

  const accessStore = async () => {
    const tenantLocalData = localStorage.getItem('tenantInfo');
    const tenantLocalDataParse = await JSON.parse(tenantLocalData);
    setStoreFrontAccess(tenantLocalDataParse?.identifier);
  };

  useEffect(() => {
    accessStore();
    getCountriesList();
    if (locationState?.state?.id) {
      setIsEdit(true);
    }
   if(tenant) {
    setEditTenant(tenant);
    setEditObject(tenant);
   }
   getSuperTenant();
  }, []);

  const rowHeight = (row) => {    
    return 46;
  }
  
  const handleFill = ({ columnKey, sourceRow, targetRow }) => {
    return { ...targetRow, [columnKey]: sourceRow[columnKey] };
  }

  const addBill = () => {
    const emptyRow =  { 
      seqNo: rows.length + 1,
      billingDetail: '',
      billingType: '',
      amount: 0,
      actions: ''
    }
    const newRow = [emptyRow, ...rows];
    setRows(newRow)
  }
  const setEditObject = (tenant) => {
    let updatObject = {...tenant};
    setName(tenant.name);
    updatObject.primaryTimezoneId  = tenant.primaryTimezone;
    if(tenant.phoneCode) {
      setOrigin(tenant.phoneCode);
    }
    // get id from object
    const posterDeviceIds = tenant.posterDevices.map((item) => {
      return item.id
    });
    updatObject.posterDeviceIds = posterDeviceIds;
    const deviceIds = tenant.devices.map((item) => {
      return item.id
    });
    updatObject.deviceIds = deviceIds;

    const graphicIds = tenant.graphics.map((item) => {
      return item.id
    });
    updatObject.graphicIds = graphicIds;
    updatObject.metadataSchemeId = tenant.metadataScheme?.id;
    updatObject.validationSchemeId = tenant.validationScheme?.id;
    updatObject.channelSchemeId = tenant.channelScheme?.id;
    // updatObject.runnCdnTerritoryIds = tenant.contentTerritories.map((item) => {
    //   return item.id
    // });
    updatObject.phone = parseInt(tenant.phone).toString().slice(-10);
    updatObject.tenantTerritoryId = tenant.tenantTerritory?.id;
    updatObject.primaryTimezoneId = tenant.primaryTimezone?.id;
    updatObject.superTenantId = tenant.superTenant?.id;
    updatObject.transcodingProfileIds = tenant.transcodingProfiles.map((item) => {
      return item.id
    });
    updatObject.defaultTranscodingProfileId = tenant.defaultTranscodingProfile?.id;
    setTranscodeProfile(updatObject.transcodingProfileIds);
    
    setSelectedCategoryIds(updatObject.transcodingProfileIds);
    setFormData(updatObject);
    
    const billTable = tenant.billingDetail?.billingTable?.body;
    if(billTable?.length > 0) {
      let rowDatas = billTable.map((item, index) => {
        return {
          seqNo: item[0],
          billingDetail: item[1],
          billingType: item[2],
          amount: item[3],
          actions: ''
        }
      });
      setRows(rowDatas);
    }
  }

  const parseFileUpload = async (existingFiles) => {
    let fileUploadedLocal = { ...formData.channelImages };
    existingFiles.forEach((item) => {
      fileUploadedLocal[item.size]['poster'].file = item.posterSourcePath ? item.posterSourcePath : null
      fileUploadedLocal[item.size]['thumbnail'].file = item.thumbnailSourcePath ? item.thumbnailSourcePath : null
    });
    handleFileUploader(fileUploadedLocal);
  }
  // Get Terretory Option List
  const getCountriesList = () => {
    // const countriesData = await Country_list();
    let countries = Territorys?.map((item) => {
      return { phoneCode: item.phoneCode, territoryId: item.iso, territoryName: item.niceName }
    });

    countries?.sort((a, b) => {
      return a.territoryName.localeCompare(b.territoryName);
    });

    if (countries && countries?.length) {
      setTerritoryOption(countries);
    }
    // setTerritoryOption(Territorys);
  }


  // Required validation
  const validateValue = (value) => {
    if (value == '' || value == null || value.length === 0) {
      return 'Field is Required';
    } else {
      return ''
    }
  }

  // Handle Name Change
  const handleNameChange = (event) => {
    setName(event.target.value);
    const newFormData = { ...formData, 'name': event.target.value };
    setFormData(newFormData);
    if (event.target.value.length > 1) {      
      setErrors({ ...errors, name: '' });
    } else {
      setErrors({ ...errors, name: 'Please enter a valid name' });
    }
  }

  
  const handleSelect = (selectedList, selectedItem) => {
    const updatedIds = selectedList.map(item => {
      return item.id;
    });
    setFormData({ ...formData, transcodingProfileIds: updatedIds });
    setTranscodeProfile(updatedIds);
    if(!updatedIds.length) {
      setErrors({ ...errors, transcodingProfileIds: 'Please select transcoding profile' });
    } else {
      setErrors({ ...errors, transcodingProfileIds: '' });
    }
  };
  
  const onSelectHandler = (selectedList, selectedItem, itemId) => {
    console.log("Selected Items: ", selectedList);
    console.log("Newly Selected Item: ", selectedItem);
    console.log("Additional Parameter: ", itemId);
    const updatedIds = selectedList.map(item => {
      return item.id;
    });
    const cdnItem = [...formData.cdnList];
    cdnItem[itemId].runnCdnTerritoryIds = [...updatedIds];
    setFormData({ ...formData, cdnList: cdnItem });
  }

  const onRemoveHandler = (selectedList, selectedItem, itemId) => {
    console.log("Selected Items: ", selectedList);
    console.log("Newly Selected Item: ", selectedItem);
    console.log("Additional Parameter: ", itemId);
    const updatedIds = selectedList.map(item => {
      return item.id;
    });
    const cdnItem = formData.cdnList[itemId];
    cdnItem.runnCdnTerritoryIds = updatedIds;
    const cdn = [...formData.cdnList, cdnItem];
    setFormData({ ...formData, cdnList: cdn });
  }

  const handleRemove = (selectedList, removedItem) => {
    const updatedIds = selectedList.map(item => {
      return item.id;
    });
    setTranscodeProfile(updatedIds);
    if(!updatedIds.includes(formData.defaultTranscodingProfileId)) {
      setFormData({ ...formData, defaultTranscodingProfileId: '' });
    }
    setTranscodeProfile(updatedIds);
    if(!updatedIds.length) {
      setErrors({ ...errors, transcodingProfileIds: 'Please select transcoding profile' });
    } else {
      setErrors({ ...errors, transcodingProfileIds: '' });
    }
  };

  const setTranscode = (id) => {
    setFormData({ ...formData, defaultTranscodingProfileId: (formData.defaultTranscodingProfileId !== id) ? id : '' });
  }

  const handleFormChange = (event, field, min, max, minMsg, maxMsg) => {
    const value = event.target.value;
    const newFormData = { ...formData, [field]: value };
    setFormData(newFormData); 
    if(field === 'email') {
      const  emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(value)) {
        setErrors({ ...errors, [field]: '' });
        return;
      } else {
        setErrors({ 
          ...errors, 
          [field]:'Please enter a valid email'
        });
        return;
      }
    }
    if(min) {
      if(value.length < min) {
        setErrors({ ...errors, [field]: minMsg ?? `Please enter minimum ${min} characters` });
        return;
      } else {
        setErrors({ ...errors, [field]: '' });
      }
    }
    if(max) {
      if(value.length > max) {
        setErrors({ ...errors, [field]: maxMsg ?? `Please enter maximum ${max} characters` });
        return;
      } else {
        setErrors({ ...errors, [field]: '' });
      }
    } 
    if(!value.length) {
      setErrors({ ...errors, [field]: 'Field is required' });
      return;
    }   
    setErrors({ ...errors, [field]: '' });
  }
  
  const handleFormBillChange = (event, field, min, max, minMsg, maxMsg) => {
    let value;
    if(field === 'startDate' || field === 'endDate') {
      value = event
      //new Date(event.toGMTString()).toISOString().split('T')[1].substring(0,8);
    } else {
      value = event.target.value;
    }
    const newFormData = { ...formData };
    newFormData.billingDetail[field] = value;
    setFormData(newFormData);
    if(field === 'email') {
      const  emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(value)) {
        setErrors({ 
          ...errors, 
          billingDetail: {
            ...errors.billingDetail,
            [field]:''
          }
        });
        return;
      } else {
        setErrors({ 
          ...errors, 
          billingDetail: {
            ...errors.billingDetail,
            [field]:'Please enter a valid email'
          }
        });
        return;
      }
    }
    if(min) {
      if(value.length < min) {
        setErrors({ 
          ...errors, 
          billingDetail: {
            ...errors.billingDetail,
            [field]: minMsg ?? `Please enter minimum ${min} characters`
          }
        });
        return;
      } else {
        setErrors({ 
          ...errors, 
          billingDetail: {
            ...errors.billingDetail,
            [field]: ''
          }
        });
      }
    }
    if(max) {
      if(value.length > max) {
        setErrors({ 
          ...errors, 
          billingDetail: {
            ...errors.billingDetail,
            [field]: minMsg ?? `Please enter minimum ${max} characters`
          }
        });
        return;
      } else {
        setErrors({ 
          ...errors, 
          billingDetail: {
            ...errors.billingDetail,
            [field]: ''
          }
        });
      }
    } 
    if(!value.length && !(value instanceof Date)) {
      setErrors({ 
        ...errors, 
        billingDetail: {
          ...errors.billingDetail,
          [field]:'Field is required'
        }
      });
      return;
    }   
    setErrors({ 
      ...errors, 
      billingDetail: {
        ...errors.billingDetail,
        [field]: ''
      }
    });
  }


  // Handle Field Validations
  const handleFieldValidations = (event) => {
    const { name, value } = event;
    setErrors({ ...errors, [name]: validateValue(value) });
  }

  // Reset Form
  const resetForm = () => {
    if (!editTenant) {
      setFormData(defaultFormValue);
      setErrors(defaultFormError);
      setName("");
      setDescription("");    

    } else {
     setEditObject(editTenant)
    }

  }

  const handleFileUploader = (event) => {
  //  handleFieldChangeKeyValue('channelImages', event, true);
    const value = event.file;
    const newFormData = { ...formData, [event.type]: { file: value }};
    setFormData(newFormData);
  }

  const validateForm = () => {
    let invalid = false;
    if(activePage === 1) {
      if(errors.name !== '' || errors.email !== '' || errors.phone !== '' || errors.address !== '' || errors.numberOfUsers !== '' || errors.numberOfChannels !== '' || errors.superTenantId !== ''
        || formData.name === '' || formData.email === '' || formData.phone === '' || formData.address === '' || formData.numberOfUsers === '' || formData.numberOfChannels === '' || formData.superTenantId === ''
      ) {
        invalid = true;
      }
    } else if(activePage === 2) {
      if(errors.transcodingProfileIds !== '' || errors.metadataSchemeId !== '' || errors.validationSchemeId !== ''
        || formData.transcodingProfileIds.length === 0 || formData.metadataSchemeId === '' || formData.validationSchemeId === ''  
      ) {
        invalid = true;
      }
      formData.contentLocations.forEach((item, index) => {
        if(errors.contentLocations[index].storageProvider !== '' || errors.contentLocations[index].accessKey !== '' || errors.contentLocations[index].secretKey !== ''
          || item.storageProvider === '' || item.accessKey === '' || item.secretKey === '') {
            invalid = true;
        }
      });
    } else if(activePage === 3) {
      if(errors.channelSchemeId !== '' || formData.channelSchemeId === '' ) {
        invalid = true;
      }
      formData.cdnList.forEach((item, index) => {
        if(errors.cdnList[index].cdnProvider !== '' || errors.cdnList[index].cdnUrl !== ''
          || item.cdnProvider === '' || item.cdnUrl === '') {
            invalid = true;
        } else if(item.cdnProvider === 'other' && (item.name === '' || errors.cdnList[index].name !== '')) {
          invalid = true;
        }
      });
    } else if(activePage === 4) {
      for (const key in errors.billingDetail) {
        if ((errors[key] !== '' && errors[key] !== undefined) && !invalid && key !== 'currency' && key !== 'code') {
          invalid = true;
        }
      }
    }
    // for (const key in errors) {
    //   if (errors[key] !=='' && !invalid && key !== 'logoImage' && key !== 'billingDetail' && key !== 'code') {
    //     invalid = true;
    //   }
    // }
    return invalid;
  }

  const validateFormWithBilling = () => {
    let invalid = false;
    for (const key in errors.billingDetail) {
      if (((errors[key] !== '' && errors[key] !== undefined) || formData.billingDetail?.[key] === '') && !invalid && key !== 'billingInfo') {
        invalid = true;
      }
    }
    return invalid;
  }

  const handleCheckboxChange = (event, val, key) => {
    if (event.target.checked) {
      const checkedItems = [...formData[key], val];
      setFormData({ ...formData, [key]: checkedItems });
    } else {
      const checkedItems = formData[key].filter(item => item !== val)
      setFormData({ ...formData, [key]: checkedItems });
    }
  };
    
  const handleCheckboxVal = (event, val, keyname) => {
    if (event.target.checked) {
      setFormData({ ...formData, [keyname]: val });
    } else {
      setFormData({ ...formData, [keyname]: '' });
    }
  };

  const saveFormMethod = async (data) => {
    const URL = (editTenant?.id) ? `${SaveTenant_URL}/${editTenant.id}` : SaveTenant_URL;
    try {
      const payload = {
        ...data
      }
      const response = await postInstantData(URL, payload);
      return response;
    } catch (error) {
      return false;
    }
  };

  const saveForm = async () => {
    setdataCheckvalid({ ...formData })
    setDisableBtn(true);
    const payload = {
      ...formData,
      "code": 'cloudtv12'
    }
      // Convert Payload to FormData
      const payloadForm = {};
      for (const key in payload) {
        if(key === 'phone') {
          payloadForm[key] = origin + payload[key]
        }
        if(key !== 'billingDetail' && key !== 'logoImage' && key !== 'faviconImage' && key !== 'phone') {
        //   if(key === "graphicIds") {
        //  //   debugger;
        //   }
        //   if(payload[key]?.length) {
        //     payloadForm[key] = [...payload[key]];
        //   } else {
        //     payloadForm[key] = payload[key];
        //   }
        payloadForm[key] = payload[key];
        }
      }
     // payloadForm.append('billingDetail', { });
      payloadForm.billingDetail = {};
      for (const key in payload.billingDetail) {
        const keyName = `billingDetail.${key}`;
        if(key !== 'billingTable') {
          if(key === 'startDate' || key === 'endDate') {
            payloadForm.billingDetail[key] = new Date(payload.billingDetail[key]).getTime()
          //  payloadForm.append(keyName, new Date(payload.billingDetail[key]).getTime());
          } else {
            payloadForm.billingDetail[key] = payload.billingDetail[key]
          }
          // push in object payloadForm.billingDetail        
        //  payloadForm.append(keyName, payload.billingDetail[key]);
        }
      }
      // if(origin) {
      //   payloadForm.phone = origin + payload[phone]
      // } else if(payload[phone]) {
      //   payloadForm.phone = payload[phone];
      // } else {
      //   payloadForm.phone = '';
      // }
      //  change code below here
      if(payload.billingDetail.phone) {
        payloadForm.billingDetail.phone = (contactCountry || '') + payload.billingDetail.phone; 
     //   payloadForm.append('billingDetail.phone', (contactCountry || '') + payload.billingDetail.phone);
      } else {
        payloadForm.billingDetail.phone = ''
      //  payloadForm.append('billingDetail.phone', '');
      }
      if(payload.billingDetail.billingTable) {
        // add bill table
        const billTable = {}
        billTable.header = tenant.billingDetail.billingTable?.header;
        billTable.body = rows.map((item) => {
          const values = Object.keys(item)
              .filter(key => key !== 'keyname') // Replace 'keyname' with the key you want to remove
              .map(key => item[key]);

            return values;
        });
       // payload.billingDetail.billingTable = JSON.stringify(billTable);
      //  payloadForm.append('billingDetail.billingTable', JSON.stringify({'header': billTable.header, 'body': billTable.body}));
       // payloadForm.billingDetail.billingTable = {'header': billTable.header, 'body': billTable.body};
      }
    //  payloadForm.contentLocations = []
   //   payloadForm.append('contentLocations', []);
   //   let object = {};
  //    payloadForm.forEach((value, key) => object[key] = value);
     // let rawData = JSON.stringify(object);
      const response = await saveFormMethod(payloadForm);
      if (response) {
        toast.success("Tenant created successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 3000
        });
        setTimeout(() => {
         // backToChannel();
          setDisableBtn(false);
        }, 3000)
      } else {
        toast.error("Tenant creation failed", {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 3000
        });
        setDisableBtn(false);
      }
  }

  const addStorage = () => {
    const content = [...formData.contentLocations,     {
      storageProvider: "RUNN_STORAGE",
      accessKey: "accessKey3",
      secretKey: "secretKey1"
    }];
    setFormData({ ...formData, contentLocations: content });
    setErrors({ ...errors, contentLocations: [...errors.contentLocations, {
      storageProvider: '',
      accessKey: '',
      secretKey: ''
    }] });
  }

  const removeStorage = (index) => {
    const content = formData.contentLocations.filter((item, i) => i !== index);
    setFormData({ ...formData, contentLocations: content });
  }

  const updatedContentLocation = (event, index, key) => {
    const content = formData.contentLocations.map((item, i) => {
      if(i === index) {
        return {
          ...item,
          [key]: event.target.value
        }
      }
      return item;
    });
    setFormData({ ...formData, contentLocations: content });
    const errorIndex = errors.contentLocations[index];
    if(errorIndex) {
      setErrors({ ...errors, contentLocations: errors.contentLocations.map((item, i) => {
        if(i === index) {
          return {
            ...item,
            [key]: (event.target.value) ? '' : `${key} is required`
          }
        }
        return item;
      }) });

    }
  }

  const addCDN = () => {
    const content = [...formData.cdnList, 
    {
        cdnProvider: "RUNN_CDN",
        name: "Primary CDN",
        cdnUrl: "https://prod-runntv.b-cdn.net"
    }];
    setFormData({ ...formData, cdnList: content });
    setErrors({ ...errors, cdnList: [...errors.cdnList, {
      cdnProvider: '',
      name: '',
      cdnUrl: ''
    }] });
  }

  const updatedCDNLocation = (event, index, key) => {
    const content = formData.cdnList.map((item, i) => {
      if(i === index) {
        return {
          ...item,
          [key]: event.target.value
        }
      }
      return item;
    });
    setFormData({ ...formData, cdnList: content });
    const errorIndex = errors.cdnList[index];
    if(errorIndex) {
      setErrors({ ...errors, cdnList: errors.cdnList.map((item, i) => {
        if(i === index) {
          return {
            ...item,
            [key]: (event.target.value) ? '' : `${key} is required`
          }
        }
        return item;
      }) });

    }
  }

  // default added removed
  return (
    <div className="content-body">
    <div className="dashboard-content tenant-add tenant-page">
      <div className="top-content">
        <div className="left-side">
          <h3>New Tenant Onboarding</h3>
        </div>
      </div>
        
      <div className='setting-genre'>
        <span
          className={activePage === 1 ? 'active' : ''}
          onClick={() => setActivePage(1)}
        >
          Tenant Details
        </span>
        <span
          className={activePage === 2 ? 'active' : ''}
          onClick={() => setActivePage(2)}
        >
          Asset Configuration
        </span>
        <span
          className={activePage === 3 ? 'active' : ''}
          onClick={() => setActivePage(3)}
        >
          Workflow Configuration
        </span>
        <span
          className={activePage === 4 ? 'active' : ''}
          onClick={() => {
            setActivePage(4);
          }}
        >
          Billing Details
        </span>
      </div>
      <div className='super-tenant-form'>
        
        <div className="channel-creation-form">
          <form>
            <div className={`form-container overflow-hidden ${activePage === 1 ? 'show' : 'hidden'}`}>
              <h3>Super Tenant Details</h3>
              <div className='form-row'>
                <div className='form-col'>
                  <div className={errors.name ? 'form-field hasError' : 'form-field'}>
                    <label>Name <i>*</i></label>
                    <input name='name' type='text' placeholder='Enter name' required value={name} onChange={handleNameChange} onBlur={handleNameChange} />
                    <div className='field-info-row'>
                      {errors.name ? <span className='field-error'>{errors.name}</span> : null}
                    </div>
                  </div>
                </div>
                <div className='form-col'>
                  <div className={errors.email ? 'form-field hasError' : 'form-field'}>
                    <label>Email <i>*</i></label>
                    <input name='email' type='email' placeholder='Enter email' required value={formData.email} onChange={(event) => handleFormChange(event, 'email', 10,10)} onBlur={(event) => handleFormChange(event, 'email', 10,10)} />
                    <div className='field-info-row'>
                      {errors.email ? <span className='field-error'>{errors.email}</span> : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className='form-row'>
                <div className='form-col'>
                  <div className={errors.channelId ? 'form-field hasError' : 'form-field'}>
                    <label>Contact Details <i>*</i></label>
                    {/* <input disabled={isEdit ? "disabled" : ""} name='channelId' type='text' placeholder='Enter channel name' required value={channelId} onChange={handleChennelIdChange} onBlur={handleChennelIdChange} />
                    {errors.channelId ? <span className='field-error'>{errors.channelId}</span> : null} */}
                    <div className='form-row split-box'>
                      <div className='form-col width-20'>
                        <select
                          className="detail-inputs"
                          name="phoneCountryCode"
                          value={origin}
                          onChange={(e) => setOrigin(e.target.value)}
                          required
                        >
                          {CountryList &&
                              CountryList?.map((item) => {
                                  return (
                                      <option key={item.territoryId} value={item.phoneCode}>
                                          {item.phoneCode} - {item.niceName}
                                      </option>
                                  );
                              })}
                        </select>
                      </div>
                      <div className='form-col width-80'>
                        <input name='phone' type='number' placeholder='Enter phone number' required value={formData.phone} onChange={(event) => handleFormChange(event, 'phone', 10,10)} onBlur={(event) => handleFormChange(event, 'phone', 10,10)} />
                      </div>
                    </div>
                    <div className='field-info-row'>
                      {errors.phone ? <span className='field-error'>{errors.phone}</span> : null}
                    </div>
                  </div>
                </div>
                <div className='form-col '>
                  <div className={errors.type ? 'form-field hasError' : 'form-field'}>                    
                    <div className='form-row split-box'>
                      <div className='form-col'>
                        <label>Geographical location</label>
                        <select
                          className="detail-inputs"
                          name="tenantTerritoryId"
                          value={formData.tenantTerritoryId}
                          onChange={(event) => handleFormChange(event, 'tenantTerritoryId', 1, 6)} 
                        >
                          <option value="">Select Geographical location</option>
                          {TimeZoneList &&
                              TimeZoneList?.map((item) => {
                                  return (
                                    <option key={item.id} value={item.id}>
                                      {item.timezone}
                                    </option>
                                  );
                              })}
                        </select>
                      </div>
                      <div className='form-col'>
                        <label>Primary Timezone</label>
                        <select
                          className="detail-inputs"
                          name="primaryTimezoneId "
                          value={formData.primaryTimezoneId }
                          onChange={(event) => handleFormChange(event, 'primaryTimezoneId', 1, 6)} 
                        >
                          <option value="">Select Primary Timezone</option>
                          {TimeZoneList &&
                              TimeZoneList?.map((item) => {
                                  return (
                                    <option key={item.id} value={item.id}>
                                        {item.timezone}
                                    </option>
                                  );
                              })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                <div className='form-row'>
                  <div className='form-col'>
                    <div className={errors.address ? 'form-field hasError' : 'form-field'}>
                      <label>Address line<i>*</i></label>
                      <input name='address' type='text' placeholder='Enter address line 1' value={formData.address} required onChange={(event) => handleFormChange(event, 'address', 10,100)}/>
                      <div className='field-info-row'>
                        {errors.address ? <span className='field-error'>{errors.address}</span> : null}
                      </div>
                    </div>
                  </div>
                  <div className='form-col'>
                  <div className='form-field'>
                      <label>Address line</label>
                      <input name='address1' type='text' placeholder='Enter address line 2' value={formData.address1} required onChange={(event) => handleFormChange(event, 'address1')}/>
                      <div className='field-info-row'>
                        {errors.address1 ? <span className='field-error'>{errors.address1}</span> : null}
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className='form-row'>
                <div className='form-col '>
                  <div className={errors.type ? 'form-field hasError' : 'form-field'}>                    
                    <div className='form-row split-box'>
                      <div className='form-col'>
                        <label>Number of Channels <i>*</i></label>
                        <input name='channelsno' type='number' placeholder='Enter channel number' required value={formData.numberOfChannels} onChange={(event) => handleFormChange(event, 'numberOfChannels', 1,'')} onBlur={(event) => handleFormChange(event, 'numberOfChannels', 1,'')} />
                        <div className='field-info-row'>
                          {errors.numberOfChannels ? <span className='field-error'>{errors.numberOfChannels}</span> : null}
                        </div>
                      </div>
                      <div className='form-col'>
                        <label>Number of User <i>*</i></label>
                        <input name='tenantno' type='number' placeholder='Enter tenant number' value={formData.numberOfUsers} onChange={(event) => handleFormChange(event, 'numberOfUsers', 1,'')} onBlur={(event) => handleFormChange(event, 'numberOfUsers', 1,'')} />
                        <div className='field-info-row'>
                          {errors.numberOfUsers ? <span className='field-error'>{errors.numberOfUsers}</span> : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='form-col '>
                  <div className='form-field'>
                    <label>Super Tenants <i>*</i></label>
                    <select
                      className="detail-inputs"
                      name="primaryTimezoneId "
                      value={formData.superTenantId}
                      onChange={(event) => handleFormChange(event, 'superTenantId', 1, 4, 'Super Tenant is required')} 
                    >
                      <option value="">Select Super Tenant</option>
                      {superTenant &&
                          superTenant?.map((item) => {
                              return (
                                <option key={item.id} value={item.id}>
                                    {item.name}
                                </option>
                              );
                          })}
                    </select>
                    <div className='field-info-row'>
                      {errors.superTenantId ? <span className='field-error'>{errors.superTenantId}</span> : null}
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div className={`form-container ${activePage === 2 ? 'show' : 'hidden'}`}>
                <h3>Asset Configuration</h3>
                <div className='text-white mb-3'>Select the features you want for your asset management</div>
                {
                  formData.contentLocations.map((item, index) => {
                    return (
                    <div className='form-row'>
                      <div className='form-col width-30 p-r-0'>                      
                        <div className={errors.contentLocations[index]?.storageProvider ? 'form-field hasError' : 'form-field'}>
                          {index === 0 && <label>Content Location <i>*</i></label>}
                          <select
                              className="detail-inputs"
                              name="storageProvider"
                              value={formData.contentLocations[index]?.storageProvider}
                              onChange={(event) => updatedContentLocation(event, index, 'storageProvider')} 
                            >
                              {ConfigDataList.cdnProviders &&
                                  ConfigDataList.cdnProviders.map((item) => {
                                      return (
                                        <option key={item.cdnProvider} value={item.cdnProvider}>
                                          {item.displayName}
                                        </option>
                                      );
                                  })}
                            </select>
                          <div className='field-info-row'>
                            {errors.contentLocations[index].storageProvider ? <span className='field-error'>{errors.contentLocations[index].storageProvider}</span> : null}
                          </div>
                        </div>
                      </div>
                      <div className='form-col width-30 p-r-0'>                      
                        <div className={errors.contentLocations[index].accessKey ? 'form-field hasError' : 'form-field'}>
                          {index === 0 && <label>&nbsp;</label>}
                          <input name='accessKey' type='text' placeholder='Enter Access Key for location' required value={formData.contentLocations[index].accessKey} onChange={(event) => updatedContentLocation(event, index, 'accessKey')} onBlur={(event) => updatedContentLocation(event, index, 'accessKey')} />
                          <div className='field-info-row'>
                            {errors.contentLocations[index].accessKey ? <span className='field-error'>{errors.contentLocations[index].accessKey}</span> : null}
                          </div>
                        </div>
                      </div>
                      <div className='form-col width-30 p-r-0'>                      
                        <div className={errors.contentLocations[index].secretKey ? 'form-field hasError' : 'form-field'}>
                          {index === 0 && <label>&nbsp;</label>}
                          <input name='secretKey' type='text' placeholder='Enter Secret Key for Location' required value={formData.contentLocations[index].secretKey} onChange={(event) => updatedContentLocation(event, index, 'secretKey')} onBlur={(event) => updatedContentLocation(event, index, 'secretKey')} />
                          <div className='field-info-row'>
                            {errors.contentLocations[index].secretKey ? <span className='field-error'>{errors.contentLocations[index].secretKey}</span> : null}
                          </div>
                        </div>
                      </div>
                      <div className='form-col width-10 p-r-0 p-l-0'>
                        <div className='form-field'>
                          {index === 0 && <label>&nbsp;</label>}
                          {index !== 0 && <button className='btn btn-delete'>
                            <img src={deleteicon} onClick={() => {removeStorage(index)}} alt="Delete CDN" />
                          </button>}
                        </div>
                      </div>
                    </div>)
                  })
                }
                <div className='form-row'>
                    <div className='form-col width-33 p-r-0'>                        
                      <button className='btn btn-secondary btn-add' onClick={addStorage}>
                        + Add Storage Location
                      </button>
                    </div>
                </div>
                <div className='form-row'>
                  <div className='form-col width-100'>                      
                    <div className={errors.name ? 'form-field hasError' : 'form-field'}>
                      <label>Posters (optional)</label>
                      <div className="flex-row">
                        <label class="checkbox-container">
                          <input
                              type="checkbox"
                              name="posters"
                              id="Mobile"
                              checked={formData.posterDeviceIds.includes(1)}
                              onChange={(e) => {handleCheckboxChange(e, 1, 'posterDeviceIds')}}
                          />
                          <span style={{ marginLeft: "10px" }}>Mobile</span>
                        </label>
                        <label class="checkbox-container">
                          <input
                              type="checkbox"
                              name="posters"
                              id="TV"
                              checked={formData.posterDeviceIds.includes(2)}
                              onChange={(e) => {handleCheckboxChange(e, 2, 'posterDeviceIds')}}
                          />
                          <span style={{ marginLeft: "10px" }}>TV</span>
                        </label>
                        <label class="checkbox-container">
                          <input
                              type="checkbox"
                              name="posters"
                              id="Tablet"
                              checked={formData.posterDeviceIds.includes(3)}
                              onChange={(e) => {handleCheckboxChange(e, 3, 'posterDeviceIds')}}
                          />
                          <span style={{ marginLeft: "10px" }}>Tablet</span>
                        </label>
                        <label class="checkbox-container">
                          <input
                              type="checkbox"
                              name="posters"
                              id="Web"
                              checked={formData.posterDeviceIds.includes(4)}
                              onChange={(e) => {handleCheckboxChange(e, 4, 'posterDeviceIds')}}
                          />
                          <span style={{ marginLeft: "10px" }}>Web</span>
                        </label>
                      </div>
                      
                    </div>
                  </div>
                </div>
                <div className='form-row'>
                  <div className='form-col w-100'>                    
                    <div className={errors.name ? 'form-field hasError' : 'form-field'}>
                      <label>Transcoding Profile <i>*</i></label>
                      <label className='text-normal mb-2 text-12'>
                        Mark a transcoding profile as “Default” by clicking on the star icon next to it
                      </label>
                      <div className="transcoding-profile">
                        {transcodeProfile &&
                          CountryList?.map((item) => {
                            // return nice name if item id exists in transcodeProfile array
                            return (
                              transcodeProfile.includes(item.id) ? <span onClick={() => {setTranscode(item.id)}}><img src={(formData.defaultTranscodingProfileId === item.id) ? selectedStar : defaultStar} alt="Default transcode" /> {item.niceName}</span> : null
                            )
                          })
                        }
                      </div>
                         {CountryList && 
                        <Multiselect
                                disable={false}
                                className="multiselect-field"
                                options={CountryList}
                                displayValue="niceName"
                                selectedValues={CountryList?.filter(option => selectedCategoryIds?.includes(option?.id))}
                                onSelect={handleSelect}
                                onRemove={handleRemove}
                                placeholder="Select Geo"
                                customCloseIcon={<img src={closeChip} />}
                                showArrow={false}
                                customArrow={<img src={dropdownArrow} />}
                              />
                      }
                      <div className='field-info-row'>
                        {errors.transcodingProfileIds ? <span className='field-error'>{errors.transcodingProfileIds}</span> : null}
                      </div>
                    </div>
                  </div>
                </div> 
                <div className='form-row'>
                  <div className='form-col w-50'>                    
                    <div className={errors.name ? 'form-field hasError' : 'form-field'}>
                      <label>Metadata <i>*</i></label>
                      <select
                          className="detail-inputs"
                          name="metadataSchemeId"
                          value={formData.metadataSchemeId}
                          onChange={(event) => handleFormChange(event, 'metadataSchemeId', 1, 6)} 
                        >
                          <option value="">Select a Metadata</option>
                          {MetaDataList &&
                              MetaDataList?.map((item) => {
                                  return (
                                    <option key={item.id} value={item.id}>
                                      {item.name}
                                    </option>
                                  );
                              })}
                        </select>
                      <div className='field-info-row'>
                        {errors.metadataSchemeId ? <span className='field-error'>{errors.metadataSchemeId}</span> : null}
                      </div>
                    </div>
                  </div>
                  <div className='form-col w-50'>                    
                    <div className={errors.name ? 'form-field hasError' : 'form-field'}>
                      <label>Criteria for Validation <i>*</i></label>
                      <select
                          className="detail-inputs"
                          name="validationSchemeId"
                          value={formData.validationSchemeId}
                          onChange={(event) => handleFormChange(event, 'validationSchemeId', 1, 6)} 
                        >
                          <option value="">Select a Validation</option>
                          {ValidationDataList &&
                            ValidationDataList?.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                );
                          })}
                      </select>
                      <div className='field-info-row'>
                        {errors.validationSchemeId ? <span className='field-error'>{errors.validationSchemeId}</span> : null}
                      </div>
                    </div>
                  </div>
                </div> 
            </div>
            
            <div className={`form-container ${activePage === 3 ? 'show' : 'hidden'}`}>
                <h3>Workflow Configuration</h3>
                {
                  formData.cdnList.map((item, index) => {
                    return (
                      <>
                        <div className='form-row'>
                          <div className='form-col w-50'>                    
                            <div className={errors.name ? 'form-field hasError' : 'form-field'}>
                              <label>CDN <i>*</i></label>
                              <select
                                className="detail-inputs"
                                name="cdnProvider"
                                value={formData.cdnList[index].cdnProvider}
                                onChange={(event) => updatedCDNLocation(event, index, 'cdnProvider')} 
                                >
                                  {ConfigDataList.cdnProviders &&
                                    ConfigDataList.cdnProviders.map((item) => {
                                      return (
                                        <option key={item.cdnProvider} value={item.cdnProvider}>
                                          {item.displayName}
                                        </option>
                                      );
                                    })}
                                </select>
                              <div className='field-info-row'>
                                {errors.cdnList[index].cdnProvider ? <span className='field-error'>{errors.cdnList[index].cdnProvider}</span> : null}
                              </div>
                            </div>
                          </div>
                          <div className='form-col w-50'>                    
                            <div className={errors.name ? 'form-field hasError' : 'form-field'}>
                              <label>URL <i>*</i></label>
                              <input name='url' type='text' placeholder='Enter URL' required value={formData.cdnList[index].cdnUrl}  onChange={(event) => updatedCDNLocation(event, index, 'cdnUrl')} onBlur={(event) => updatedCDNLocation(event, index, 'cdnUrl')}/>
                              <div className='field-info-row'>
                                {errors.cdnList[index].cdnUrl ? <span className='field-error'>{errors.cdnList[index].cdnUrl}</span> : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='form-row'>
                          <div className='form-col w-100'>              
                            {formData.cdnList[index].cdnProvider !== 'other' ? <div className='form-field'>
                              <label>Geo Availability</label>
                              {
                                <Multiselect
                                  disable={false}
                                  className="multiselect-field"
                                  options={CountryList}
                                  displayValue="niceName"
                                  selectedValues={CountryList?.filter(option => formData.cdnList[index].runnCdnTerritoryIds?.includes(option?.id))}
                                  onSelect={(selectedList, selectedItem) => onSelectHandler(selectedList, selectedItem, index)}
                                  onRemove={(selectedList, selectedItem) => onRemoveHandler(selectedList, selectedItem, index)}
                              //    onSelect={handleSelect}
                                 // onRemove={handleRemove}
                                  placeholder="Select Geo"
                                  customCloseIcon={<img src={closeChip} />}
                                  showArrow={false}
                                  customArrow={<img src={dropdownArrow} />}
                                />
                              }
                            </div> :
                            <div className={errors.name ? 'form-field hasError' : 'form-field'}>
                              <label>Name <i>*</i></label>
                              <input name='url' type='text' placeholder='Enter URL' required value={formData.cdnList[index].name}  onChange={(event) => updatedCDNLocation(event, index, 'name')} onBlur={(event) => updatedCDNLocation(event, index, 'name')}/>
                              <div className='field-info-row'>
                                {errors.cdnList[index].name ? <span className='field-error'>{errors.cdnList[index].name}</span> : null}
                              </div>
                            </div>
                            }

                          </div>
                        </div>
                      </>
                    )})
                }
                <div className='form-row'>
                    <div className='form-col width-33 p-r-0'>                        
                      <button className='btn btn-secondary btn-add' onClick={addCDN}>
                        + Add CDN
                      </button>
                    </div>
                </div>
                <div className='form-row'>
                  <div className='form-col w-50'>
                    <div className={errors.name ? 'form-field hasError' : 'form-field'}>
                      <label>Channel Schema <i>*</i></label>                      
                      <select
                          className="detail-inputs"
                          name="channelSchemeId"
                          value={formData.channelSchemeId}
                          onChange={(event) => handleFormChange(event, 'channelSchemeId', '', '', '','')} 
                        >
                          <option value="">Select a Scheme</option>
                          {ChannelSchemes &&
                              ChannelSchemes?.map((item) => {
                                  return (
                                    <option key={item.id} value={item.id}>
                                      {item.name}
                                    </option>
                                  );
                              })}
                      </select>
                      <div className='field-info-row'>
                        {errors.channelSchemeId ? <span className='field-error'>{errors.channelSchemeId}</span> : null}
                      </div>
                    </div>
                  </div>
                  <div className='form-col w-50'>
                    <div className={errors.name ? 'form-field hasError' : 'form-field'}>
                      <label className='mb-3'>Scheduling ( Default : Manual )</label>
                      <div className="flex-row">
                        <label class="checkbox-container">
                          <input
                              type="checkbox"
                              name="posters"
                              id="schedulingType"
                              checked={formData.schedulingType === 'AUTO'}
                              onChange={(e) => {handleCheckboxVal(e, "AUTO", 'schedulingType')}}
                          />
                          <span style={{ marginLeft: "10px" }}>Auto Scheduling</span>
                        </label>
                        <label class="checkbox-container">
                          <input
                              type="checkbox"
                              name="posters"
                              id="SchedulingNone"
                              checked={formData.schedulingType === 'NONE'}
                              onChange={(e) => {handleCheckboxVal(e, "NONE", 'schedulingType')}}
                          />
                          <span style={{ marginLeft: "10px" }}>None</span>
                        </label>
                      </div>
                      <div className='field-info-row'>
                        {errors.billingDetail.name ? <span className='field-error'>{errors.billingDetail.name}</span> : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='form-row'>
                  <div className='form-col w-50'>                      
                    <div className={errors.name ? 'form-field hasError' : 'form-field'}>
                      <label className='mb-2'>Devices</label>
                      <div className="flex-row mb-2">
                        <label class="checkbox-container">
                          <input
                              type="checkbox"
                              name="posters"
                              id="selectAllCheckbox"
                              checked={formData.deviceIds.includes(1)}
                              onChange={(e) => {handleCheckboxChange(e, 1, 'deviceIds')}}
                          />
                          <span style={{ marginLeft: "10px" }}>Mobile</span>
                        </label>
                        <label class="checkbox-container">
                          <input
                              type="checkbox"
                              name="posters"
                              id="selectAllCheckbox"
                              checked={formData.deviceIds.includes(2)}
                              onChange={(e) => {handleCheckboxChange(e, 2, 'deviceIds')}}
                          />
                          <span style={{ marginLeft: "10px" }}>TV</span>
                        </label>
                      </div>
                      
                      <div className="flex-row mb-2">
                        <label class="checkbox-container">
                          <input
                              type="checkbox"
                              name="posters"
                              id="selectAllCheckbox"
                              checked={formData.deviceIds.includes(3)}
                              onChange={(e) => {handleCheckboxChange(e, 3, 'deviceIds')}}
                          />
                          <span style={{ marginLeft: "10px" }}>Tablet</span>
                        </label>
                        <label class="checkbox-container">
                          <input
                              type="checkbox"
                              name="posters"
                              id="selectAllCheckbox"
                              checked={formData.deviceIds.includes(4)}
                              onChange={(e) => {handleCheckboxChange(e, 4, 'deviceIds')}}
                          />
                          <span style={{ marginLeft: "10px" }}>Web</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='form-col w-50'>                      
                    <div className={errors.name ? 'form-field hasError' : 'form-field'}>
                      <label className='mb-2'>Graphics</label>
                      <div className="flex-row mb-2">
                        <label class="checkbox-container">
                          <input
                              type="checkbox"
                              name="posters"
                              id="selectAllCheckbox"
                              checked={formData.graphicIds.includes(1)}
                              onChange={(e) => {handleCheckboxChange(e, 1, 'graphicIds')}}
                          />
                          <span style={{ marginLeft: "10px" }}>Channel Logo</span>
                        </label>
                        <label class="checkbox-container">
                          <input
                              type="checkbox"
                              name="posters"
                              id="selectAllCheckbox"
                              checked={formData.graphicIds.includes(2)}
                              onChange={(e) => {handleCheckboxChange(e, 2, 'graphicIds')}}
                          />
                          <span style={{ marginLeft: "10px" }}>Additional Graphics (upto 2)</span>
                        </label>
                      </div>
                      
                      <div className="flex-row mb-2">
                        <label class="checkbox-container">
                          <input
                              type="checkbox"
                              name="posters"
                              id="selectAllCheckbox"
                              checked={formData.graphicIds.includes(3)}
                              onChange={(e) => {handleCheckboxChange(e, 3, 'graphicIds')}}
                          />
                          <span style={{ marginLeft: "10px" }}>Disclaimer & Warnings</span>
                        </label>
                        <label class="checkbox-container">
                          <input
                              type="checkbox"
                              name="posters"
                              id="selectAllCheckbox"
                              checked={formData.graphicIds.includes(4)}
                              onChange={(e) => {handleCheckboxChange(e, 4, 'graphicIds')}}
                          />
                          <span style={{ marginLeft: "10px" }}>Monetization Graphics</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            
            <div className={`form-container ${activePage === 4 ? 'show' : 'hidden'}`}>
                <div className='form-row'>
                  <div className='form-col width-33 p-r-0'>                      
                    <div className={errors.name ? 'form-field hasError' : 'form-field'}>
                      <label>Contact Name <i>*</i></label>
                      <input name='name' type='text' placeholder='Enter name' required value={formData.billingDetail?.name} onChange={(event) => handleFormBillChange(event, 'name', 1,'')} onBlur={(event) => handleFormBillChange(event, 'name', 1,'')} />
                      <div className='field-info-row'>
                        {errors.billingDetail?.name ? <span className='field-error'>{errors.billingDetail.name}</span> : null}
                      </div>
                    </div>
                  </div>
                  <div className='form-col width-33 p-r-0'>                      
                    <div className={errors.name ? 'form-field hasError' : 'form-field'}>
                      <label>Phone Number <i>*</i></label>
                      <div className='form-row split-box'>
                        <div className='form-col width-33'>
                          <select
                            className="detail-inputs"
                            name="phoneCountryCode"
                            value={contactCountry}
                            onChange={(e) => setContactCountry(e.target.value)}
                            required
                          >
                            {territoryOption &&
                                territoryOption?.map((item) => {
                                    return (
                                        <option key={item.territoryId} value={item.phoneCode}>
                                            {item.phoneCode} - {item.territoryName}
                                        </option>
                                    );
                                })}
                          </select>
                        </div>
                        <div className='form-col width-67'>
                          <input name='phone' type='number' placeholder='Enter phone number' required value={formData.billingDetail?.phone} onChange={(event) => handleFormBillChange(event, 'phone', 10,10)} onBlur={(event) => handleFormBillChange(event, 'phone', 10,10)} />
                        </div>
                      </div>
                      <div className='field-info-row'>
                        {errors.billingDetail?.phone ? <span className='field-error'>{errors.billingDetail.phone}</span> : null}
                      </div>
                    </div>
                  </div>
                  <div className='form-col width-33'>                      
                    <div className={errors.name ? 'form-field hasError' : 'form-field'}>
                      <label>Email-ID <i>*</i></label>
                      <input name='billEmail' type='text' placeholder='Enter email' required value={formData.billingDetail?.email} onChange={(event) => handleFormBillChange(event, 'email', 10,10)} onBlur={(event) => handleFormBillChange(event, 'email', 10,10)} />
                      <div className='field-info-row'>
                        {errors.billingDetail?.email ? <span className='field-error'>{errors.billingDetail.email}</span> : null}
                      </div>
                    </div>
                  </div>
                </div>      
                <div className='form-row'>
                  <div className='form-col width-33 p-r-0'>                      
                    <div className={errors.name ? 'form-field hasError' : 'form-field'}>
                      <label>Billing Currency <i>*</i></label>
                      <input name='name' type='text' placeholder='Enter name' required value={formData.billingDetail?.currency} onChange={(event) => handleFormBillChange(event, 'currency', 2, 5)} onBlur={(event) => handleFormBillChange(event, 'currency', 2, 5)} />
                      <div className='field-info-row'>
                        {errors.billingDetail?.currency ? <span className='field-error'>{errors.billingDetail.currency}</span> : null}
                      </div>
                    </div>
                  </div>
                  <div className='form-col width-33 p-r-0'>                      
                    <div className={errors.name ? 'form-field hasError' : 'form-field'}>
                      <label>Billing Start Date <i>*</i></label>
                        <DatePicker
                            className="detail-inputs"
                            selected={formData.billingDetail?.startDate || null}
                            onChange={(date) => handleFormBillChange(date, 'startDate', 6, 12)} 
                            dateFormat="dd/MM/yyyy"
                            placeholderText="dd/mm/yyyy"
                            isClearable
                          //  readOnly={editable}
                          />
                      <div className='field-info-row'>
                        {errors.billingDetail?.startDate ? <span className='field-error'>{errors.billingDetail.startDate}</span> : null}
                      </div>
                    </div>
                  </div>
                  <div className='form-col width-33'>                      
                    <div className={errors.name ? 'form-field hasError' : 'form-field'}>
                      <label>Billing End Date <i>*</i></label>
                      <DatePicker
                            className="detail-inputs"
                            selected={formData.billingDetail?.endDate || null}
                            onChange={(date) => handleFormBillChange(date, 'endDate', 6, 12)} 
                            dateFormat="dd/MM/yyyy"
                            placeholderText="dd/mm/yyyy"
                            isClearable
                          //  readOnly={editable}
                          />
                      <div className='field-info-row'>
                        {errors.billingDetail?.endDate ? <span className='field-error'>{errors.billingDetail.endDate}</span> : null}
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className='form-container'>
                  <div className="dashboard-content">
                    <div className="top-content m-b-10">
                      <div className="left-side">
                        <h3>Billing table</h3>
                      </div>
                      <div className="right-side">
                        <div className='channel-setup-button'>
                          <button className='btn btn-grey'>
                            <img src={billingAdd} onClick={addBill} alt="billing table" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-container">                    
                  <DataGrid style={{height: 200 + 'px'}} id="tenant-grid" columns={columns} rows={rows} onRowsChange={setRows}
                    rowHeight={rowHeight}
                    getRowHeight={() => 'auto'}
                    onFill={handleFill}
                    editType = 'fullRow'
                    enableCellAutoFocus="true"
                    onCellClick={(args, event) => {
                      if(args.column.key === 'actions') {

                      //  event.target.parentElement.parentElement.parentElement.firstChild.click();
                       // event.target.parentElement.parentElement.parentElement.firstChild.click();
                        // gridApi!.setFocusedCell(1, "make");
                        // gridApi!.startEditingCell({
                        //   rowIndex: 1,
                        //   colKey: "make",
                        // });
                        args.selectCell(true);
                      }
                      if (args.column.key === 'sequence' || args.column.key === 'rating') {
                      //  event.preventGridDefault();
                        args.selectCell(true);
                      }
                      setSelectedRow(args.row);
                    }}
                  />
                  </div>
                </div>            
            </div>

            <div className={`form-container ${activePage !== 4 ? 'show' : 'hidden'}`}>
              <div className='button-row'>
                <button type='button' 
                // onClick={() => { saveChannel() }}
                 onClick={() => setActivePage(activePage - 1)}
                 className={`btn btn-primary ${activePage === 1 ? 'hidden' : ''}`}>
                  Prev
                </button>
                <button type='button' className='btn btn-danger' onClick={resetForm}>Reset</button>
                <button type='button' 
                 disabled={validateForm()} 
                // onClick={() => { saveChannel() }}
                 onClick={() => setActivePage(activePage + 1)}
                 className='btn btn-primary'>
                  Next
                </button>
              </div>
            </div>
            
            <div className={`form-container ${activePage === 4 ? 'show' : 'hidden'}`}>
              <div className='button-row'>
                <button type='button' onClick={() => setActivePage(activePage - 1)} className='btn btn-secondary'>Prev</button>
                <button type='button' onClick={backToChannel} className='btn btn-secondary'>Cancel</button>
                <button type='button' className='btn btn-danger' onClick={resetForm}>Reset</button>
                <button type='button'
                disabled={validateFormWithBilling()}
                onClick={() => { saveForm() }} 
                className='btn btn-primary'>
                  {editTenant ? 'Update' : 'Create'}
                </button>
              </div>
            </div>



            {errorbox && <ErrorPopup btntext={"Sure"} message={"Your changes have not been saved. Are you sure you want to exit this page?"} closebox={() => errorboxclose()} overrides={() =>backToChannel()} />}

          </form>
          <ToastContainer />
        </div>
      </div>
    </div>
    <ToastContainer />
    </div>
  );
}

export default TenantAdd;