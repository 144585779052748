import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import DashboardHeader from "../component/dashboard-header";
import GridMonitor from "../pages/Scheduling/GridMonitor";



function GridMonitorView() {
  const location = useLocation();
  const dispatch = useDispatch();

  // Determine if the current view is Monitor
  const isMonitor = location.pathname.includes("monitorgrid");

  // Redux state selectors
 

 

  return (
    <>
    <div className="page-layout">
      <DashboardHeader />
      <div className="main-content-gridVideo " >

        {isMonitor && <GridMonitor  />}

     
        <Outlet />
      </div>
      </div>
    </>
  );
}

export default GridMonitorView;
