import React, { useEffect, useState } from 'react';
// import { toast } from "react-toastify";
import './file-uploader.scss';

import { cloudFront_URL } from "../../service/API_URL";
import deleteicon from "../../assets/images/deleteicon.png"

export default function FilesDragAndDrop(props) {

  const validateSize = async(e) => {
    let validSize = false;
    const img = new Image();
    img.src = window.URL.createObjectURL(e.target.files[0]);
    img.onload = () => {
      // const width = props.size.split('x')[0];
      // const height = props.size.split('x')[1];
      return validSize = true;
      // if (img.naturalWidth == width || img.naturalHeight == height) {
      //   validSize = true;
      // } else {
      //   validSize = false;
      //   toast.error(`Image size invalid. Expected ${img.naturalWidth} x ${img.naturalWidth} but received ${width} x ${height}.`, {
      //     position: toast.POSITION.BOTTOM_RIGHT,
      //     autoClose: 3000
      //   });
      // }
    }
  }

  const handleChange = (e) => {
    if (validateSize(e)) {
      const imageFile = e.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        const file = {
          multiPart: imageFile,
          file: reader.result,
          subType: props.subType,
          type: props.type
        };
        props.onChange(file);
      };
      reader.readAsDataURL(imageFile);
    }
  }

  const handleDelete = (e) => {
    const emptyFile = {
      file: null,
      multiPart: null,
      subType: props.subType,
      type: props.type
    }
    props.onChange(emptyFile);
  }

  return (
    <div className={props?.file?.file ? "imageexist" : "box__input"}>
      { props?.file?.file ? 
        
        <>
          {
            !props.readOnly && <a><img onClick={(e) => handleDelete(e)} src={deleteicon} /></a>
          }
          {
            // typeof props.file.file === 'string' && props.file.file.startsWith('data:image/jpeg') ?
            typeof props.file.file === 'string' && props.file.file.startsWith('data:image/') ?
            props.file.file && <img className="image-show" src={props.file.file} alt="Uploaded" /> 
            :
            props.file.file && <img className="image-show" src={cloudFront_URL + props.file.file} alt="Uploaded" />
          }
        </>
        : 
        <>
          <input
            type="file"
            className="inputFile"
            name="file"
            onChange={(e) => {handleChange(e)}}
            disabled={props.readOnly}
          />
          <div className='box-label'>{props.label}</div>
          <div className='box-size'>{props.size}</div>
        </>
      }
    </div>
  )
}

