import React, { useState, useEffect } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import closeChip from '../../assets/images/close-chip.svg';
import dropdownArrow from '../../assets/images/dropdown-arrow.svg';
import "./multi.scss"



function DynamicMultiSelect({ options, displayValue, selectedValues, setSelectedCategoryIds, selectedCategoryIds, placeholder, editable }) {



  const handleSelect = (selectedList, selectedItem) => {
    const updatedIds = selectedList.map(item => {
      if (displayValue == "niceName") {
        return item.id; // Use item.terrirotryid if displayValue is "terrirotry"
      } else {
        return item.id; // Use item.id for other cases
      }
    });
    setSelectedCategoryIds(updatedIds);
  };

  const handleRemove = (selectedList, removedItem) => {
    const updatedIds = selectedList.map(item => {
      if (displayValue == "niceName") {
        return item.id; // Use item.terrirotryid if displayValue is "terrirotry"
      } else {
        return item.id; // Use item.id for other cases
      }
    });
    setSelectedCategoryIds(updatedIds);
  };


  return (
    <div>
      <Multiselect
        disable={editable}
        className="multiselect-field"
        options={options}
        displayValue={displayValue}
      selectedValues={options?.filter(option => selectedCategoryIds?.includes(displayValue == "niceName" ? option?.id : option?.id))}
       
        onSelect={handleSelect}
        onRemove={handleRemove}
        placeholder={placeholder}
        customCloseIcon={<img src={closeChip} />}
        showArrow={true}
        customArrow={<img src={dropdownArrow} />}
      />
    </div>
  );
}

export default DynamicMultiSelect;
