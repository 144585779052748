import React, { useState, useEffect } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { Country_list, Language_Lists } from '../../api/api';
import arrow from "../../assets/images/dropdown-arrow.svg";
import "./Langselect.scss"

const LanguageDropdown = ({ setName, names, onlyview, setId }) => {
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(names);

  useEffect(() => {
    if (names) {
      const selectedLanguageName = languages.find(lang => lang.name === names);
      setSelectedLanguage(selectedLanguageName);
    }
  }, [names, languages]);

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        // const countries = await Country_list();
        // if (Array.isArray(countries) && countries.length > 0) {
        //   const langs = countries.flatMap(country => {
        //     if (country && country.languages) {
        //       return Object.entries(country.languages).map(([code, name]) => ({ code, name }));
        //     } else {
        //       return [];
        //     }
        //   });

        //   const uniqueNames = Array.from(new Set(langs.map(lang => lang.name)));

        //   const uniqueLanguages = uniqueNames.map(name => {
        //     const correspondingCode = langs.find(lang => lang.name === name).code;
        //     return { code: correspondingCode, name };
        //   });

        //   setLanguages(uniqueLanguages);
        // }
     

        // else {
        //   console.error("Invalid 'countries' data:", countries);
        // }
        const Language = await Language_Lists();
        if(Language?.status == true && Language.data != []){
          setLanguages(Language.data)
        }
       
      } catch (error) {
        console.error("Error fetching countries:", error);

      }
    };

    fetchLanguages();
  }, []);

  const handleLanguageChange = (e) => {
    const selectedLanguageCode = e.target.value;
    const selectedLanguageName = languages.find(lang => lang.code === selectedLanguageCode);

    if (selectedLanguageName) {
      setSelectedLanguage(selectedLanguageName);
      setId(selectedLanguageCode); // Assuming setId takes the code
      setName(selectedLanguageName.name);
    }
  };

  return (
  <div style={{position:"relative"}}>
      <Form.Control
      className='lang-dropdown'
        style={{ padding: "12px 16px"}}
        readOnly={onlyview}
        as="select"
        value={selectedLanguage ? selectedLanguage.code : ''}
        onChange={handleLanguageChange}
      >
        <option className='options' key="default" value="">Select</option>
        {languages.map((lang, index) => (
            <option
     
            className='options' key={`${lang.code}-${index}`} value={lang.code}>
              {lang.name}
            </option>
          ))}
      </Form.Control>
      <img
        src={arrow} // Your arrow icon image source
        alt="arrow"
        style={{
          position: 'absolute',
          top: '50%',
          right: '10px', // Adjust the right positioning as needed
          transform: 'translateY(-50%)',
          width: '20px', // Adjust the width of the arrow icon
          height: '20px', // Adjust the height of the arrow icon
          pointerEvents: 'none', // Ensures the icon doesn't interfere with dropdown clicks
        }}
      />
      
    
    
   </div>
  );
};

export default LanguageDropdown;
