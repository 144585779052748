import React, {useEffect, useState, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import editicon from "../../assets/images/editicon.png";
import deleteicon from "../../assets/images/delete_forever.png";
import billingAdd from '../../assets/images/billing-add.png';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { postInstantData } from "../../api/apiMethod";


import DataGrid, { textEditor } from 'react-data-grid';
import ErrorPopup from '../../component/popup/ErrorPopup';
import FilesDragAndDrop from '../../component/FileUploader/file-uploader';
import { SaveSuperTenant_URL } from "../../service/API_URL";

const SuperTenantAdd = () => {
  const [rows, setRows] = useState([]);
  const [editTenant, setEditTenant] = useState(null);

  // default added

  const state = useSelector((state) => state);
  const Territorys = state?.CountryList?.data?.data;

  const locationState = useLocation();
  const tenant = locationState.state?.tenant;
  const { TimeZoneList, Config } = useSelector(state =>
    ({ 
      TimeZoneList : state.TimeZoneList.data, 
      Config: state.ConfigDataList.data,
    }));
  
  // Default Form Value on Initial Load
  const defaultFormValue = {    
    code: '',
    name: '',
    email: '',
    phone: '',
    address: '',
    timezoneId: '',
    territoryId: '',
    numberOfTenants: '',
    numberOfChannels: '',
    isWhiteLabel: '',
    allowTenantOnboarding: '',
    darkThemeIcon: '',
    faviconImageFile: '',
    appTitle:'',
    logoImage: {
      file: null
    },
    faviconImage: {
      file: null,
    },
    darkThemeLogoImage: {
      file: null,
    },
    billingDetail: {
      name: '',
      phone: '',
      email: '',
      currency: '',
      startDate: '',
      endDate: '',
      address: '',
      billingInfo: ''
    }
  }

  // Default Error Value on Initial Load
  const defaultFormError = {
    code: '',
    name: '',
    email: '',
    phone: '',
    address: '',
    timezoneId: '',
    territoryId: '',
    numberOfTenants: '',
    numberOfChannels: '',
    isWhiteLabel: '',
    allowTenantOnboarding: '',
    darkThemeIcon: '',
    logoImage: {
      file: null,
    },
    faviconImageFile: '',
    appTitle:'',
    billingDetail: {
      name: '',
      phone: '',
      email: '',
      currency: '',
      startDate: '',
      endDate: '',
      address: '',
      billingInfo: ''
    }
  }

  const dropDownArray = [
    "One Time Payment",
    "Weekly",
    "Monthly",
    "Quarterly",
    "Semi Annual",
    "Annual"
  ];

  const setDropDownValue = (row, column,
    onRowChange) => {
    return <select
  //  className="gridDropdown"
    value={row[2]}
    onChange={(event) => {
      onRowChange({ ...row, billingType: event.target.value }, true);
    }}
    autoFocus
  >
    {dropDownArray?.map((val, index) => (
      <option key={index} value={val}>
        {val}
      </option>
    ))}
  </select>
  }
  const editRow = (row, index) => {
    // find index of rows if row.seqNo matches rows.seqNo
    const rowIndex = rows.findIndex((data) => data.seqNo === row.seqNo);
  }
  const deleteRow = (row) => {
    if(row?.seqNo) {
      // resume work from here
      setRows(rows.filter((data) => data.seqNo!==row.seqNo));
    }
  }
  const getColumns = () => {
    return [
      {
        key: 'seqNo',
        name: 'SEQUENCE NO',
        width: '120px',
      //  frozen: true,
        renderEditCell: textEditor
      },
      {
        key: 'billingDetail',
        name: 'BILLING DETAIL',
        width: 'calc(100% - 480px)',
        renderEditCell: textEditor,
      },
      {
        key: 'billingType',
        name: 'BILLING TYPE',
        width: '120px',
        renderEditCell: ({ row, column,
          onRowChange }) => setDropDownValue(row, column,
          onRowChange),
      },
      {
        key: 'amount',
        name: 'AMOUNT',
        width: '120px',
        renderEditCell: textEditor,
      },
      {
        key: 'action',
        name: 'ACTIONS',
        width: '120px',
        renderCell: ({row,index}) => {
          const btns = <div className='actions'>
            <img src={editicon} alt="Edit bill" onClick={() => {editRow(row)}}/>
            <img src={deleteicon} alt="Delete bill" onClick={() => {deleteRow(row)}} />
          </div>
          return btns
        },
      //  renderEditCell: textEditor,
      }
    ]
  }
  
  const columns = useMemo(() => getColumns(), []);
  // Form State
  const [formData, setFormData] = useState({ ...defaultFormValue });
  // Error State
  const [errors, setErrors] = useState({ ...defaultFormError });
  // Special Fiels Stat Handling
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [description, setDescription] = useState("");
  const [firstPage, setFirstPage] = useState(true);
  const [selectedRow, setSelectedRow] = useState([]);

  // Create or Update Identification Flag
  const [isEdit, setIsEdit] = useState(false);
  const [editChannelData, setEditChannelData] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);

  // Field Options State
  const [territoryOption, setTerritoryOption] = useState([]);
  const [dataCheckvalid, setdataCheckvalid] = useState({ ...defaultFormValue });
  const [storeFrontAccess, setStoreFrontAccess] = useState("");
  const [origin, setOrigin] = useState(91);
  const [contactCountry, setContactCountry] = useState(91);
  const data = [
    { seqNo: 1, billingDetail: 'Detail 1', billingType: 'Type 1', amount: 100, actions: 'Action 1' },
    { seqNo: 2, billingDetail: 'Detail 2', billingType: 'Type 2', amount: 200, actions: 'Action 2' },
    // More objects...
  ];
  const navigate = useNavigate();

  const backToChannel = () => {
    navigate("/channel");
  };

  const [errorbox, setErrorbox] = useState(false);
 
  const errorboxclose = () => {
    setErrorbox(false)
  }

  const accessStore = async () => {
    const tenantLocalData = localStorage.getItem('tenantInfo');
    const tenantLocalDataParse = await JSON.parse(tenantLocalData);
    setStoreFrontAccess(tenantLocalDataParse?.identifier);
  };

  useEffect(() => {
    accessStore();
    getCountriesList();
    if (locationState?.state?.id) {
      setIsEdit(true);
    }
   if(tenant) {
    setEditTenant(tenant);
    setEditObject(tenant);
   }
  }, []);

  const rowHeight = (row) => {    
    return 46;
  }
  
  const handleFill = ({ columnKey, sourceRow, targetRow }) => {
    return { ...targetRow, [columnKey]: sourceRow[columnKey] };
  }

  const addBill = () => {
    const emptyRow =  { 
      seqNo: rows.length + 1,
      billingDetail: '',
      billingType: '',
      amount: 0,
      actions: ''
    }
    const newRow = [emptyRow, ...rows];
    setRows(newRow)
  }
  const setEditObject = (tenant) => {
    let updatObject = {...tenant};
    updatObject.territoryId = tenant.territory.id;
    updatObject.timezoneId = tenant.timezone.id;
    if(tenant?.billingDetail?.startDate) {
      updatObject.billingDetail.startDate = new Date(tenant.billingDetail.startDate);
    }
    if(updatObject?.billingDetail?.endDate) {
      updatObject.billingDetail.endDate = new Date(tenant.billingDetail.endDate);
    }
    if(!tenant.logoImage) {
      updatObject.logoImage = {
        file: null
      }
    }
    if(!tenant.faviconImage) {
      updatObject.faviconImage = {
        file: null
      }
    }
    if(!tenant.darkThemeLogoImage) {
      updatObject.darkThemeLogoImage = {
        file: null
      }
    }
    if(tenant.phoneCode) {
      setOrigin(tenant.phoneCode);
    }
    setName(tenant.name);
    updatObject.phone = parseInt(tenant.phone).toString().slice(-10);
    setFormData(updatObject);
    
    const billTable = tenant.billingDetail?.billingTable?.body;
    if(billTable?.length > 0) {
      let rowDatas = billTable.map((item, index) => {
        return {
          seqNo: item[0],
          billingDetail: item[1],
          billingType: item[2],
          amount: item[3],
          actions: ''
        }
      });
      setRows(rowDatas);
    } else {
      setRows([]);
    }
  }

  const parseFileUpload = async (existingFiles) => {
    let fileUploadedLocal = { ...formData.channelImages };
    existingFiles.forEach((item) => {
      fileUploadedLocal[item.size]['poster'].file = item.posterSourcePath ? item.posterSourcePath : null
      fileUploadedLocal[item.size]['thumbnail'].file = item.thumbnailSourcePath ? item.thumbnailSourcePath : null
    });
    handleFileUploader(fileUploadedLocal);
  }
  // Get Terretory Option List
  const getCountriesList = () => {
    // const countriesData = await Country_list();
    let countries = Territorys?.map((item) => {
      return { phoneCode: item.phoneCode, territoryId: item.iso, territoryName: item.niceName }
    });

    countries?.sort((a, b) => {
      return a.territoryName.localeCompare(b.territoryName);
    });

    if (countries && countries?.length) {
      setTerritoryOption(countries);
    }
    // setTerritoryOption(Territorys);
  }


  // Required validation
  const validateValue = (value) => {
    if (value == '' || value == null || value.length === 0) {
      return 'Field is Required';
    } else {
      return ''
    }
  }

  // Handle Name Change
  const handleNameChange = (event) => {
    setName(event.target.value);
    const newFormData = { ...formData, 'name': event.target.value };
    setFormData(newFormData);
    if (event.target.value.length > 1) {      
      setErrors({ ...errors, name: '' });
    } else {
      setErrors({ ...errors, name: 'Please enter a valid name' });
    }
  }

  const handleFormChange = (event, field, min, max, minMsg, maxMsg) => {
    const value = event.target.value;
    const newFormData = { ...formData, [field]: value };
    setFormData(newFormData);
    if(field === 'email') {
      const  emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(value)) {
        setErrors({ ...errors, [field]: '' });
        return;
      } else {
        setErrors({ 
          ...errors, 
          [field]:'Please enter a valid email'
        });
        return;
      }
    }
    if(min) {
      if(value.length < min) {
        setErrors({ ...errors, [field]: minMsg ?? `Please enter minimum ${min} characters` });
        return;
      } else {
        setErrors({ ...errors, [field]: '' });
      }
    }
    if(max) {
      if(value.length > max) {
        setErrors({ ...errors, [field]: maxMsg ?? `Please enter maximum ${max} characters` });
        return;
      } else {
        setErrors({ ...errors, [field]: '' });
      }
    } 
    if(!value.length) {
      setErrors({ ...errors, [field]: 'Field is required' });
      return;
    }   
    setErrors({ ...errors, [field]: '' });
  }
  
  const handleFormBillChange = (event, field, min, max, minMsg, maxMsg) => {
    let value;
    if(field === 'startDate' || field === 'endDate') {
      value = event
      //new Date(event.toGMTString()).toISOString().split('T')[1].substring(0,8);
    } else {
      value = event.target.value;
    }
    const newFormData = { ...formData };
    newFormData.billingDetail[field] = value;
    setFormData(newFormData);
    if(field === 'email') {
      const  emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(value)) {
        setErrors({ 
          ...errors, 
          billingDetail: {
            ...errors.billingDetail,
            [field]:''
          }
        });
        return;
      } else {
        setErrors({ 
          ...errors, 
          billingDetail: {
            ...errors.billingDetail,
            [field]:'Please enter a valid email'
          }
        });
        return;
      }
    }
    if(min) {
      if(value.length < min) {
        setErrors({ 
          ...errors, 
          billingDetail: {
            ...errors.billingDetail,
            [field]: minMsg ?? `Please enter minimum ${min} characters`
          }
        });
        return;
      } else {
        setErrors({ 
          ...errors, 
          billingDetail: {
            ...errors.billingDetail,
            [field]: ''
          }
        });
      }
    }
    if(max) {
      if(value.length > max) {
        setErrors({ 
          ...errors, 
          billingDetail: {
            ...errors.billingDetail,
            [field]: minMsg ?? `Please enter minimum ${max} characters`
          }
        });
        return;
      } else {
        setErrors({ 
          ...errors, 
          billingDetail: {
            ...errors.billingDetail,
            [field]: ''
          }
        });
      }
    } 
    if(!value.length && !(value instanceof Date)) {
      setErrors({ 
        ...errors, 
        billingDetail: {
          ...errors.billingDetail,
          [field]:'Field is required'
        }
      });
      return;
    }   
    setErrors({ 
      ...errors, 
      billingDetail: {
        ...errors.billingDetail,
        [field]: ''
      }
    });
  }


  // Handle Field Validations
  const handleFieldValidations = (event) => {
    const { name, value } = event;
    setErrors({ ...errors, [name]: validateValue(value) });
  }

  // Reset Form
  const resetForm = () => {
    if (!editTenant) {
      setFormData(defaultFormValue);
      setErrors(defaultFormError);
      setName("");
      setDescription("");    

    } else {
     setEditObject(editTenant)
    }

  }

  const generateId = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    const words = value.split(' ');
  
    // Extracting the first and last word
    const firstWord = words[0].toUpperCase();
    let lastWord = '';
    if (words.length > 1 && words[1].length < 3) {
        // If the second word has less than 3 characters, consider the first word for the last word
        lastWord = firstWord;
    } else {
        lastWord = words[words.length - 1].toUpperCase();
    }
  
    // Creating the code with the first word and last word's first 3 letters
    let code = firstWord.slice(0, 3) + lastWord.slice(0, 3);
  
    // If the code is less than 6 letters, add 'X' to make it 6 letters long
    while (code.length < 6) {
      code += 'X';
    }
  
   // handleMultiFieldChange([{ name: name, value: value, required: true }, { name: 'channelId', value: code, required: true }]);
}

  const handleFileUploader = (event) => {
  //  handleFieldChangeKeyValue('channelImages', event, true);
    const value = event.file;
    const newFormData = { ...formData, [event.type]: { file: value }};
    setFormData(newFormData);
  }

  const validateForm = () => {
    let invalid = false;
    for (const key in errors) {
      if (errors[key] !=='' && !invalid && key !== 'logoImage' && key !== 'billingDetail' && key !== 'code') {
        invalid = true;
      }
    }
    return invalid;
  }

  const validateFormWithBilling = () => {
    let invalid = false;
    for (const key in errors.billingDetail) {
      if ((errors[key] !== '' && errors[key] !== undefined) && !invalid && key !== 'currency' && key !== 'code') {
        invalid = true;
      }
    }
    return invalid;
  }

  const saveSuperTenantMethod = async (data) => {
    const URL = (editTenant?.id) ? `${SaveSuperTenant_URL}/${editTenant.id}` : SaveSuperTenant_URL;
    try {
      const response = await postInstantData(URL, data);
      return response;
    } catch (error) {
      return false;
    }
  };

  const saveSuperTenant = async () => {
    setdataCheckvalid({ ...formData })
    setDisableBtn(true);
    const payload = {
      ...formData,
      "code": 'cloudtv12'
    }
      // Convert Payload to FormData
      const payloadForm = new FormData();
      for (const key in payload) {
        if(key === 'phone') {
          debugger;
          payloadForm.append(key, origin + payload[key]);
        }
        if(key !== 'billingDetail' && key !== 'darkThemeLogoImage' && key !== 'logoImage' && key !== 'faviconImage' && key !== 'phone') {
          payloadForm.append(key, payload[key]);
        }
      }
     // payloadForm.append('billingDetail', { });
      for (const key in payload.billingDetail) {
        const keyName = `billingDetail.${key}`;
        if(key !== 'billingTable') {
          if(key === 'startDate' || key === 'endDate') {
            payloadForm.append(keyName, new Date(payload.billingDetail[key]).getTime());
          }
          // push in object payloadForm.billingDetail        
          payloadForm.append(keyName, payload.billingDetail[key]);
        }
      }
      if(origin) {
        payloadForm.append('phone', origin + payload[phone]);
      } else if(payload[phone]) {
        payloadForm.append('phone', payload[phone]);
      } else {
        payloadForm.append('phone', '');
      }
      //  payloadForm.phone = origin + payloadForm[phone];
      if(payload.billingDetail.phone) {
        payloadForm.append('billingDetail.phone', (contactCountry || '') + payload.billingDetail.phone);
      } else {
        payloadForm.append('billingDetail.phone', '');
      }
      if(payload.billingDetail.billingTable) {
        // add bill table
        const billTable = {}
        billTable.header = tenant.billingDetail.billingTable?.header;
        billTable.body = rows.map((item) => {
          const values = Object.keys(item)
              .filter(key => key !== 'keyname') // Replace 'keyname' with the key you want to remove
              .map(key => item[key]);

            return values;
        });
       // payload.billingDetail.billingTable = JSON.stringify(billTable);
        payloadForm.append('billingDetail.billingTable', JSON.stringify({'header': billTable.header, 'body': billTable.body}));
       // payloadForm.billingDetail.billingTable = {'header': billTable.header, 'body': billTable.body};
      }
      const response = await saveSuperTenantMethod(payloadForm);
      if (response) {
        toast.success("Tenant created successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 3000
        });
        setTimeout(() => {
         // backToChannel();
          setDisableBtn(false);
        }, 3000)
      } else {
        toast.error("Tenant creation failed", {
          position: toast.POSITION.BOTTOM_LEFT,
          autoClose: 3000
        });
        setDisableBtn(false);
      }
    

  }
  // Function to format the GMT offset
  const formatGmtOffset = (offset) => {
    const sign = offset >= 0 ? '+' : '-';
    const absoluteOffset = Math.abs(offset);
    const hours = Math.floor(absoluteOffset);
    const minutes = (absoluteOffset - hours) * 60;

    return `GMT${sign}${String(hours).padStart(2, '0')}:${String(minutes).padEnd(2, '0')}`;
  };

  // default added removed
  return (
    <div className="content-body">
    <div className="dashboard-content tenant-add">
      <div className="top-content">
        <div className="left-side">
          <h3>New Super Tenant Onboarding</h3>
        </div>
      </div>
        
      <div className='setting-genre'>
        <span
            className={firstPage ? 'active' : ''}
            onClick={() => setFirstPage(true)}
          >
            Tenant Details
          </span>
          <span
            className={!firstPage ? 'active' : ''}
            onClick={() => {
              if(!validateForm()) { setFirstPage(false); }
            }}
          >
            Billing Details
          </span>
      </div>
      <div className='super-tenant-form'>
        <h3>Super Tenant Details</h3>
        
        <div className="channel-creation-form">
          <form>
            <div className={`form-container overflow-hidden ${firstPage ? 'show' : 'hidden'}`}>
              <div className='form-row'>
                <div className='form-col'>
                  <div className={errors.name ? 'form-field hasError' : 'form-field'}>
                    <label>Name <i>*</i></label>
                    <input name='name' type='text' placeholder='Enter name' required value={name} onChange={handleNameChange} onBlur={handleNameChange} />
                    <div className='field-info-row'>
                      {errors.name ? <span className='field-error'>{errors.name}</span> : null}
                    </div>
                  </div>
                </div>
                <div className='form-col'>
                  <div className={errors.email ? 'form-field hasError' : 'form-field'}>
                    <label>Email <i>*</i></label>
                    <input name='email' type='email' placeholder='Enter email' required value={formData.email} onChange={(event) => handleFormChange(event, 'email', 10,10)} onBlur={(event) => handleFormChange(event, 'email', 10,10)} />
                    <div className='field-info-row'>
                      {errors.email ? <span className='field-error'>{errors.email}</span> : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className='form-row'>
                <div className='form-col'>
                  <div className={errors.channelId ? 'form-field hasError' : 'form-field'}>
                    <label>Contact Details <i>*</i></label>
                    {/* <input disabled={isEdit ? "disabled" : ""} name='channelId' type='text' placeholder='Enter channel name' required value={channelId} onChange={handleChennelIdChange} onBlur={handleChennelIdChange} />
                    {errors.channelId ? <span className='field-error'>{errors.channelId}</span> : null} */}
                    <div className='form-row split-box'>
                      <div className='form-col width-20'>
                        <select
                          className="detail-inputs"
                          name="phoneCountryCode"
                          value={origin}
                          onChange={(e) => setOrigin(e.target.value)}
                          required
                        >
                          {territoryOption &&
                              territoryOption?.map((item) => {
                                  return (
                                      <option key={item.territoryId} value={item.phoneCode}>
                                          {item.phoneCode} - {item.territoryName}
                                      </option>
                                  );
                              })}
                        </select>
                      </div>
                      <div className='form-col width-80'>
                        <input name='phone' type='number' placeholder='Enter phone number' required value={formData.phone} onChange={(event) => handleFormChange(event, 'phone', 10,10)} onBlur={(event) => handleFormChange(event, 'phone', 10,10)} />
                      </div>
                    </div>
                    <div className='field-info-row'>
                      {errors.phone ? <span className='field-error'>{errors.phone}</span> : null}
                    </div>
                  </div>
                </div>
                <div className='form-col '>
                  <div className={errors.type ? 'form-field hasError' : 'form-field'}>                    
                    <div className='form-row split-box'>
                      <div className='form-col'>
                        <label>Geographical location</label>
                        <select
                          className="detail-inputs"
                          name="territoryId"
                          value={formData.territoryId}
                          onChange={(event) => handleFormChange(event, 'territoryId', 1, 6)} 
                        >
                          <option value="">Select Geographical location</option>
                          {Territorys &&
                              Territorys?.map((item) => {
                                  return (
                                    <option key={item.id} value={item.id}>
                                      {item.niceName}
                                    </option>
                                  );
                              })}
                        </select>
                      </div>
                      <div className='form-col'>
                        <label>Primary Timezone</label>
                        <select
                          className="detail-inputs"
                          name="timezoneId"
                          value={formData.timezoneId}
                          onChange={(event) => handleFormChange(event, 'timezoneId', 1, 6)} 
                        >
                          <option value="">Select Primary Timezone</option>
                          {TimeZoneList &&
                              TimeZoneList?.map((item) => {
                                  return (
                                    <option key={item.id} value={item.id}>
                                        {`(${formatGmtOffset(item?.gmtOffset)}) ${item?.timezone}`}
                                    </option>
                                  );
                              })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                <div className='form-row'>
                  <div className='form-col'>
                    <div className={errors.address ? 'form-field hasError' : 'form-field'}>
                      <label>Address line<i>*</i></label>
                      <input name='address' type='text' placeholder='Enter address line 1' value={formData.address} required onChange={(event) => handleFormChange(event, 'address', 10,100)}/>
                      <div className='field-info-row'>
                        {errors.address ? <span className='field-error'>{errors.address}</span> : null}
                      </div>
                    </div>
                  </div>
                  <div className='form-col'>
                  <div className='form-field'>
                      <label>Address line</label>
                      <input name='address1' type='text' placeholder='Enter address line 1' value={formData.address1} required onChange={(event) => handleFormChange(event, 'address1')}/>
                      <div className='field-info-row'>
                        {errors.address1 ? <span className='field-error'>{errors.address1}</span> : null}
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className='form-row'>
                <div className='form-col '>
                  <div className={errors.type ? 'form-field hasError' : 'form-field'}>                    
                    <div className='form-row split-box'>
                      <div className='form-col'>
                        <label>Number of tenants <i>*</i></label>
                        <input name='tenantno' type='number' placeholder='Enter tenant number' value={formData.numberOfTenants} onChange={(event) => handleFormChange(event, 'numberOfTenants', 1,'')} onBlur={(event) => handleFormChange(event, 'numberOfTenants', 1,'')} />
                        <div className='field-info-row'>
                          {errors.numberOfTenants ? <span className='field-error'>{errors.numberOfTenants}</span> : null}
                        </div>
                      </div>
                      <div className='form-col'>
                        <label>Number of channels <i>*</i></label>
                        <input name='channelsno' type='number' placeholder='Enter channel number' required value={formData.numberOfChannels} onChange={(event) => handleFormChange(event, 'numberOfChannels', 1,'')} onBlur={(event) => handleFormChange(event, 'numberOfChannels', 1,'')} />
                        <div className='field-info-row'>
                          {errors.numberOfChannels ? <span className='field-error'>{errors.numberOfChannels}</span> : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='form-col '>
                  <div className='form-field'>

                    <div className='form-row split-box'>
                      <div className='form-col'>

                        <div className='form-row'>
                          <div className='form-col'>
                            <label>White label</label>
                          </div>
                        </div>

                                            
                        <div className='form-row split-box'>
                          <div className='form-col radio-form'>
                            <div className='form-row'>
                              <div className='form-col'>
                                <input
                                  type="radio"
                                  name="whiteLabel"
                                  value="true"
                                  className='custom-checkbox'
                                  id="label-yes"
                                  checked={formData.isWhiteLabel?.toString() == "true"}
                                  onChange={(event) => handleFormChange(event, 'isWhiteLabel','','')}
                                />
                                <label for="label-yes">Yes</label>
                              </div>
                              <div className='form-col'>
                                <input
                                  type="radio"
                                  name="bannertype"
                                  value="false"
                                  className='custom-checkbox'
                                  id="label-no"
                                  checked={formData.isWhiteLabel?.toString() == "false"}
                                  onChange={(event) => handleFormChange(event, 'isWhiteLabel','','')}
                                />
                                <label for="label-no">No</label>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className='form-col'>

                        <div className='form-row'>
                          <div className='form-col'>
                            <label>Tenant Onboarding </label>
                          </div>
                        </div>

                                            
                        <div className='form-row split-box'>
                          <div className='form-col radio-form'>
                            <div className='form-row'>
                              <div className='form-col'>
                                <input
                                  type="radio"
                                  name="bannertype"
                                  value="true"
                                  className='custom-checkbox'
                                  id="tenant-yes"
                                  checked={formData.allowTenantOnboarding?.toString() === "true"}
                                  onChange={(event) => handleFormChange(event, 'isWhiteLabel','','')}
                                />
                                <label for="tenant-yes">Yes</label>
                              </div>
                              <div className='form-col'>
                                <input
                                  type="radio"
                                  name="bannertype"
                                  value="false"
                                  className='custom-checkbox'
                                  id="tenant-no"
                                  checked={formData.allowTenantOnboarding?.toString() === "false"}
                                  onChange={(event) => handleFormChange(event, 'isWhiteLabel','','')}
                                />
                                <label for="tenant-no">No</label>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                                    
                      
                      
                    </div>
                  </div>
                </div>
          
                <div className='form-col'>

                  <div className='form-field'>
                    <div className='form-row split-box'>
                      <div className='form-col'>
                        <label>Upload Logo for Dark theme</label>
                        <FilesDragAndDrop 
                                id="dark-logo"
                                label={'TV Thumbnail'}
                                size={'16x14'}
                                type={'darkThemeLogoImage'}
                                imgcategory="tenantadd"
                                onChange={handleFileUploader}
                                readOnly={false}
                                sizeLable={'Size 16 x 14px'}
                                file={formData?.darkThemeLogoImage}
                            />
                      </div>
                      <div className='form-col'>
                        <label>Upload Logo for Light theme</label>
                        <FilesDragAndDrop 
                                id="dark-logo"
                                label={'TV Thumbnail'}
                                size={'16x14'}
                                type={'logoImage'}
                                imgcategory="tenantadd"
                                onChange={handleFileUploader}
                                readOnly={false}
                                sizeLable={'Size 16 x 14px'}
                                file={formData?.logoImage}
                            />
                      </div>
                    </div>
                  </div>
                  
                </div>
                <div className='form-col'>
                  
                <div className='form-field upload-box-split'>
                    <div className='form-row split-box'>
                      <div className='form-col'>
                        <label>Upload Favicon</label>
                        <FilesDragAndDrop 
                                id="dark-logo"
                                label={'TV Thumbnail'}
                                size={'16x14'}
                                type={'faviconImage'}
                                imgcategory="tenantadd"
                                onChange={handleFileUploader}
                                readOnly={false}
                                sizeLable={'Size 16 x 14px'}
                                file={formData?.faviconImage}
                            />
                      </div>
                      <div className='form-col'>
                        <label>Add page tab title</label>
                        <input name='tab-title' type='text' placeholder='Enter page tab title' value={formData.appTitle} onChange={(event) => handleFormChange(event, 'appTitle', 1,30)} onBlur={(event) => handleFormChange(event, 'appTitle', 1,30)}  />
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className={`form-container ${firstPage ? 'hidden' : 'show'}`}>
                <div className='form-row'>
                  <div className='form-col width-33 p-r-0'>                      
                    <div className={errors.name ? 'form-field hasError' : 'form-field'}>
                      <label>Contact Name <i>*</i></label>
                      <input name='name' type='text' placeholder='Enter name' required value={formData.billingDetail?.name} onChange={(event) => handleFormBillChange(event, 'name', 1,'')} onBlur={(event) => handleFormBillChange(event, 'name', 1,'')} />
                      <div className='field-info-row'>
                        {errors.billingDetail?.name ? <span className='field-error'>{errors.billingDetail.name}</span> : null}
                      </div>
                    </div>
                  </div>
                  <div className='form-col width-33 p-r-0'>                      
                    <div className={errors.name ? 'form-field hasError' : 'form-field'}>
                      <label>Phone Number <i>*</i></label>
                      <div className='form-row split-box'>
                        <div className='form-col width-33'>
                          <select
                            className="detail-inputs"
                            name="phoneCountryCode"
                            value={contactCountry}
                            onChange={(e) => setContactCountry(e.target.value)}
                            required
                          >
                            {territoryOption &&
                                territoryOption?.map((item) => {
                                    return (
                                        <option key={item.territoryId} value={item.phoneCode}>
                                            {item.phoneCode} - {item.territoryName}
                                        </option>
                                    );
                                })}
                          </select>
                        </div>
                        <div className='form-col width-67'>
                          <input name='phone' type='number' placeholder='Enter phone number' required value={formData.billingDetail?.phone} onChange={(event) => handleFormBillChange(event, 'phone', 10,10)} onBlur={(event) => handleFormBillChange(event, 'phone', 10,10)} />
                        </div>
                      </div>
                      <div className='field-info-row'>
                        {errors.billingDetail?.phone ? <span className='field-error'>{errors.billingDetail.phone}</span> : null}
                      </div>
                    </div>
                  </div>
                  <div className='form-col width-33'>                      
                    <div className={errors.name ? 'form-field hasError' : 'form-field'}>
                      <label>Email-ID <i>*</i></label>
                      <input name='email' type='text' placeholder='Enter email' required value={formData.billingDetail?.email} onChange={(event) => handleFormBillChange(event, 'email', 10,10)} onBlur={(event) => handleFormBillChange(event, 'email', 10,10)} />
                      <div className='field-info-row'>
                        {errors.billingDetail?.email ? <span className='field-error'>{errors.billingDetail.email}</span> : null}
                      </div>
                    </div>
                  </div>
                </div>      
                <div className='form-row'>
                  <div className='form-col width-33 p-r-0'>                      
                    <div className={errors.name ? 'form-field hasError' : 'form-field'}>
                      <label>Billing Currency <i>*</i></label>
                      <input name='name' type='text' placeholder='Enter name' required value={formData.billingDetail?.currency} onChange={(event) => handleFormBillChange(event, 'currency', 2, 5)} onBlur={(event) => handleFormBillChange(event, 'currency', 2, 5)} />
                      <div className='field-info-row'>
                        {errors.billingDetail?.currency ? <span className='field-error'>{errors.billingDetail.currency}</span> : null}
                      </div>
                    </div>
                  </div>
                  <div className='form-col width-33 p-r-0'>                      
                    <div className={errors.name ? 'form-field hasError' : 'form-field'}>
                      <label>Billing Start Date <i>*</i></label>
                        <DatePicker
                            className="detail-inputs"
                            selected={formData.billingDetail?.startDate || null}
                            onChange={(date) => handleFormBillChange(date, 'startDate', 6, 12)} 
                            dateFormat="dd/MM/yyyy"
                            placeholderText="dd/mm/yyyy"
                            isClearable
                          //  readOnly={editable}
                          />
                      <div className='field-info-row'>
                        {errors.billingDetail?.startDate ? <span className='field-error'>{errors.billingDetail.startDate}</span> : null}
                      </div>
                    </div>
                  </div>
                  <div className='form-col width-33'>                      
                    <div className={errors.name ? 'form-field hasError' : 'form-field'}>
                      <label>Billing End Date <i>*</i></label>
                      <DatePicker
                            className="detail-inputs"
                            selected={formData.billingDetail?.endDate || null}
                            onChange={(date) => handleFormBillChange(date, 'endDate', 6, 12)} 
                            dateFormat="dd/MM/yyyy"
                            placeholderText="dd/mm/yyyy"
                            isClearable
                          //  readOnly={editable}
                          />
                      <div className='field-info-row'>
                        {errors.billingDetail?.endDate ? <span className='field-error'>{errors.billingDetail.endDate}</span> : null}
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className='form-container'>
                  <div className="dashboard-content">
                    <div className="top-content m-b-10">
                      <div className="left-side">
                        <h3>Billing table</h3>
                      </div>
                      <div className="right-side">
                        <div className='channel-setup-button'>
                          <button className='btn btn-grey'>
                            <img src={billingAdd} onClick={addBill} alt="billing table" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-container">                    
                  <DataGrid style={{height: 200 + 'px'}} id="tenant-grid" columns={columns} rows={rows} onRowsChange={setRows}
                    rowHeight={rowHeight}
                    getRowHeight={() => 'auto'}
                    onFill={handleFill}
                    editType = 'fullRow'
                    enableCellAutoFocus="true"
                    onCellClick={(args, event) => {
                      if(args.column.key === 'actions') {

                      //  event.target.parentElement.parentElement.parentElement.firstChild.click();
                       // event.target.parentElement.parentElement.parentElement.firstChild.click();
                        // gridApi!.setFocusedCell(1, "make");
                        // gridApi!.startEditingCell({
                        //   rowIndex: 1,
                        //   colKey: "make",
                        // });
                        args.selectCell(true);
                      }
                      if (args.column.key === 'sequence' || args.column.key === 'rating') {
                      //  event.preventGridDefault();
                        args.selectCell(true);
                      }
                      setSelectedRow(args.row);
                    }}
                  />
                  </div>
                </div>            
            </div>
            <div className={`form-container ${firstPage ? 'show' : 'hidden'}`}>
              <div className='button-row'>
                <button type='button' className='btn btn-danger' onClick={resetForm}>Reset</button>
                <button type='button' 
                 disabled={validateForm()} 
                // onClick={() => { saveChannel() }}
                 onClick={() => setFirstPage(false)}
                 className='btn btn-primary'>
                  Next
                </button>
              </div>
            </div>
            
            <div className={`form-container ${firstPage ? 'hidden' : 'show'}`}>
              <div className='button-row'>
                <button type='button' onClick={() => setFirstPage(true)} className='btn btn-secondary'>Back</button>
                <button type='button' onClick={backToChannel} className='btn btn-secondary'>Cancel</button>
                <button type='button' className='btn btn-danger' onClick={resetForm}>Reset</button>
                <button type='button'
                disabled={validateFormWithBilling()}
                onClick={() => { saveSuperTenant() }} 
                className='btn btn-primary'>
                  {editTenant ? 'Update' : 'Create'}
                </button>
              </div>
            </div>



            {errorbox && <ErrorPopup btntext={"Sure"} message={"Your changes have not been saved. Are you sure you want to exit this page?"} closebox={() => errorboxclose()} overrides={() =>backToChannel()} />}

          </form>
          <ToastContainer />
        </div>
      </div>
    </div>
    <ToastContainer />
    </div>
  );
}

export default SuperTenantAdd;