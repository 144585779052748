import { configureStore } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";

import {
  ChannelDataReducer,
  MapDataReducer,
  AssetDetailReducer,
  ChannelListReducer,
  GenreReducer,
  ThemesReducer,
  CategoriesReducer,
  MetaGridReducer,
  AudioLanguagesReducer,
  CountryReducer,
  MaturityListReducer,
  StoreFrontReducer,
  appThemeReducer,
  adminModeReducer,
  MonitorDateReducer,
  CategoryDurationReducer,
  SearchChannelReducer,
  TimeZoneListReducer,
  MetaDataReducer,
  ValidationDataReducer,
  ConfigDataReducer,
  ChannelSchemesReducer,
  RoleDataReducer
} from "./slices";

export const store = configureStore({
  reducer: {
    ChannelData: ChannelDataReducer,
    MapData: MapDataReducer,
    AssetDetail: AssetDetailReducer,
    ChannelList: ChannelListReducer,
    GenreList: GenreReducer,
    ThemesList: ThemesReducer,
    CategoriesList: CategoriesReducer,
    AudioLanguageList: AudioLanguagesReducer,
    CountryList: CountryReducer,
    MetaGridList: MetaGridReducer,
    MaturityList: MaturityListReducer,
    StoreFrontReducer: StoreFrontReducer,
    appThemeReducer: appThemeReducer,
    adminModeReducer: adminModeReducer,
    MonitorDate: MonitorDateReducer,
    CategoryDuration: CategoryDurationReducer,
    SearchChannel: SearchChannelReducer,
    TimeZoneList: TimeZoneListReducer,
    MetaDataList: MetaDataReducer,
    ValidationDataList: ValidationDataReducer,
    ConfigDataList: ConfigDataReducer,
    ChannelSchemes: ChannelSchemesReducer,
    RolesData: RoleDataReducer
  },
  middleware: [thunkMiddleware],
});
