// react js base component
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { BrowseFiles } from '../../../api/api';

import icroundsearch from "../../../assets/images/ic_round-search.svg";
import { FolderOutlined, DescriptionOutlined } from '@material-ui/icons';
import viewlist from "../../../assets/images/view_list.svg";
import view_module from "../../../assets/images/view_module.svg";

const BrowsePopup = ({ show, handleClose, data, parentData, updateData }) => {
    const [files, setFiles] = useState([]);
    const [searchedFiles, setSearchedFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [searchedString, setSearchedString] = useState('');
    const [activeView, setActiveView] = useState("grid");
    const [folderPath, setFolderPath] = useState([{
        name: 'Home',
        path: '/'
    }]);

    useEffect(() => {
        if (show)
            setSelectedFile(null);
            setSearchedFiles(null);
            setFiles(null);
            getFiles();
            setFolderPath([{
                name: 'Home',
                path: '/'
            }]);
    }, [show]);
    useEffect(() => {
        if(!searchedString) return setSearchedFiles(files);
        const searchedData = files.filter(file => file.name.toLowerCase().includes(searchedString.toLowerCase()));
        setSearchedFiles(searchedData);
    }, [searchedString])
    const getFiles = async () => {
        const response = await BrowseFiles();
        parsePath(response);
    };
    const getFolders = async (folder, pushPath, index) => {
        if(!pushPath && folderPath.length === index + 1) return;
        if(folder.type === 'file') {
            setSelectedFile(index);
            return;
        };
        setSelectedFile(null);
      //  const setFolder = folderPath[index - 1]
        if(pushPath) {
            setFolderPath([...folderPath, {
                name: folder.name,
                path: folder.path
            
            }]);
        } else {
            const newFolderPath = folderPath.slice(0, index + 1);
            setFolderPath([...newFolderPath]);
        }
        const response = await BrowseFiles(folder.path);
        parsePath(response);
    }
    const parsePath = (response) => {
        let files = [];
        response?.data?.files?.forEach(file => {
            const parts = file.key.split("/");
            const fileName = parts[parts.length - 1];
            files.push({
                name: fileName,
                type: 'file',
                path: file.key
            });
        });
        response?.data?.folders?.forEach(folder => {
            const parts = folder.split("/");
            const foldeName = parts[parts.length - 2];
            files.push({
                name: foldeName,
                type: 'folder',
                path: folder
            });
        });
        setFiles(files);
        setSearchedFiles(files);
        setSearchedString('');
    }
    const handleViewChange = (view) => {
        setActiveView(view);
    };
    return (
        <Modal show={show} className="browse-modal" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className='browse-header div-flex-space-between'>
                        <div>
                            <div>Browse Files</div>
                        </div>
                        <div>
                            <div className="search-box bg-white p-1 mr-16">

                                <img src={icroundsearch} alt="Search" />

                                <input
                                className="border-0"
                                type="text"
                                onChange={(e) => setSearchedString(e.target.value)}
                                value={searchedString}
                                placeholder="Search Files/Folders"
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='folder-path'>    
                    <div className='flex'>                
                        {folderPath.map((folder, index) => (
                            <>
                                {(index !== 0) && <span className='s3-heirarchy'> / </span>}
                                <a className='s3-heirarchy' key={index} onClick={() => getFolders(folder, false, index)}> {folder.name} </a>
                            </>
                        ))}
                    </div>
                     <div className="view-list">
                        <a
                            className={`grid-icon icon ${activeView === "grid" ? "active" : ""
                            }`}
                            onClick={() => handleViewChange("grid")}
                        >
                            <img src={view_module} alt="" />
                        </a>
                        <a
                            className={`grid-icon icon ${activeView === "list" ? "active" : ""
                            }`}
                            onClick={() => handleViewChange("list")}
                        >
                            <img src={viewlist} alt="" />
                        </a>
                        </div>
                </div>
                <div className={`folder-file-collection ${activeView === "grid" ? "grid" : "list"}`}>
                    {searchedFiles?.map((file, index) => (
                        <div key={index} 
                        className={`browse-file ${selectedFile === index ? 'selected' : ''}`}
                        title='Open File/Folder'
                        onClick={() => getFolders(file, true, index)}>
                            {file.type === 'file' ? <DescriptionOutlined style={{ fontSize: (activeView === "grid") ? 60 : 20  }} />: <FolderOutlined style={{ fontSize: (activeView === "grid") ? 60 : 20 }} /> }
                            <span>{file.name}</span>
                        </div>
                    ))}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className='Cancel' onClick={handleClose}>
                    Cancel
                </Button>
                <Button
                    className={`Add`}
                    onClick={handleClose}
                    disabled={selectedFile === null}>
                    Select
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default BrowsePopup;