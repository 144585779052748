import React, { useState } from "react";
import "../../../assets/css/style.scss";
import "../index.scss";
import Theme from "./Theme";
import Genre from "./Genre";
import Language from "./Language";
import Category from "./Category";

function ContentSequencing({ deviceType, platforms, permission }) {
  const [selectedTab, setSelectedTab] = useState("Themes");
  const getActiveTabData = () => {
    switch (selectedTab) {
      case "Themes":
        return <Theme deviceType={deviceType} platforms={platforms} permission={permission} />;
      case "Categories":
        return <Category deviceType={deviceType} platforms={platforms} permission={permission} />;
      case "Languages":
        return <Language deviceType={deviceType} platforms={platforms} permission={permission} />;
      case "Genres":
        return <Genre deviceType={deviceType} platforms={platforms} permission={permission} />;
    }
  };

  const onTabClick = (e, tab) => {
    setSelectedTab(tab);
  };

  return (
    <div className="page-content">
      <div className="options-list-wrapper">
        {["Themes", "Categories", "Genres", "Languages"].map((tab) => (
          <div
            key={tab}
            role="none"
            className={`options-list ${tab === selectedTab ? "active" : ""}`}
            onClick={(e) => onTabClick(e, tab)}
          >
            {tab}
          </div>
        ))}
      </div>
      {getActiveTabData()}
    </div>
  );
}

export default ContentSequencing;
