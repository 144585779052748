import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as moment from "moment";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import "../../../assets/css/style.scss";
import updownarrowIcon from "../../../assets/images/up-down-arrow.svg";
import deleteIcon from "../../../assets/images/delete_forever.png";
import dragableIcon from "../../../assets/images/dragable-icon.png";
import editIcon from "../../../assets/images/edit-icon.png";
import viewIcon from "../../../assets/images/view.png";
import featuredIcon from "../../../assets/images/featured-star.png";
import defaultIcon from "../../../assets/images/default-star.png";
import { StoreFrontBannerListAction } from "../../../Redux/slices";
import {
  // categoryRepositionAll,
  deleteBannerData,
  bannerlList,
  updateBannerList,
} from "../../../api/api";
import "../index.scss";
import BannerPopup from "../../../component/popup/BannerPopup";
import DuplicatePopup from "../DuplicatePopup";
import { image_url } from "../../../service/Constant";
import UILoader from "../../../component/Loader/UILoader";

function Banners({ deviceType, platforms, permission }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    StoreFrontReducer: { bannerList },
  } = useSelector((state) => state);
  const [banners, setBanners] = useState([]);
  const [changed, setChanged] = useState(false);
  const [showpopup, setShowpopup] = useState(false);
  const [popupType, setPopupType] = useState("create");
  const [duplicatePopup, setDuplicatePopup] = useState(false);
  const [duplicatePlatform, setDuplicatePlatform] = useState([]);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [loader, setLoader] = useState(false);
  const draggedItem = useRef();
  const calendar = useRef();

  useEffect(() => {
    setLoader(true);
    getBannerList();
  }, [deviceType]);

  useEffect(() => {
    if (bannerList?.length) {
      setBanners(bannerList);
    }
  }, [bannerList]);

  const getBannerList = async () => {
    const result = await bannerlList();
    if (result?.data?.length) {
      // let data = result.data.filter((item) => {
      //   return item.deviceType == deviceType?.toUpperCase();
      // });
      dispatch(StoreFrontBannerListAction(result.data));
    }
    setLoader(false);
  };

  const toggleStatus = async (event, item) => {
    const data = banners.map((row) =>
      row.id === item.id ? { ...row, active: !row.active } : row
    );
    setBanners(data);
    setChanged(true);
  };

  const cancelChanges = (event) => {
    setBanners([...bannerList]);
    setChanged(false);
  };

  const applyChanges = async (event) => {
    const payload = {
      sequenceList: [],
    };

    banners?.forEach((item) => {
      let obj = {
        id: item.id,
        shuffle: item.shuffle,
        isActive: item.active,
        startDate: item.startDate,
        endDate: item.endDate,
      };
      payload.sequenceList.push(obj);
    });
    setLoader(true);
    const result = await updateBannerList(payload);
    if (result.status == 200) {
      toast.success(result.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      setChanged(false);
      getBannerList();
    } else {
      toast.error(result.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    }
    setLoader(false);
  };

  const createBanner = () => {
    setSelectedBanner(null);
    setPopupType("Create");
    setShowpopup(true);
  };

  const closePopup = (fetchList) => {
    setShowpopup(false);
    if (fetchList) {
      getBannerList();
    }
  };

  const updateDate = (type, date, item) => {
    // const d = moment(date).toISOString();
    const d = Date.parse(date);
    let arr = [...banners];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].id === item.id) {
        let data = { ...arr[i] };
        if (type == "start") data.startDate = d;
        if (type == "end") data.endDate = d;
        arr[i] = data;
        break;
      }
    }
    setBanners([...arr]);
    setChanged(true);
  };

  const suffleChange = (e, data) => {
    let arr = [...banners];
    for (let i = 0; i < banners.length; i++) {
      if (arr[i].id == data.id) {
        let obj = { ...arr[i] };
        obj.shuffle = e.target.checked;
        arr[i] = obj;
        break;
      }
    }
    setBanners([...arr]);
    if (!changed) {
      setChanged(true);
    }
  };

  const editBanner = (e, item) => {
    setSelectedBanner(item);
    setPopupType("Edit");
    setShowpopup(true);
  };

  const viewBanner = (e, item) => {
    setSelectedBanner(item);
    setPopupType("View");
    setShowpopup(true);
  };

  const deleteBanner = async (e, item) => {
    const result = await deleteBannerData(item.id);
    if (result.status == 200) {
      toast.success(result.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      getBannerList();
    } else {
      toast.error(result.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    }
  };

  return (
    <>
      <div className="page-content">
        <div className="content-wrapper">
          <table role="none" className="store-front-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Device</th>
                <th className="w-10">Shuffle</th>
                <th>Date Range</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody role="none">
              {banners?.map((item) => {
                return (
                  <tr className="dragabble" key={item.id}>
                    <td>
                      <div className="icon-group feature">
                        <img
                          style={{ height: "30px", width: "30px" }}
                          src={`${image_url}${item.images[0]?.imagePath}`}
                        />
                        {item?.name}
                      </div>
                    </td>
                    <td>
                      {item?.images.map((image) => (
                        <span
                          className="img-icon"
                          key={`unique-${image.deviceType}`}
                        >
                          {image?.deviceType?.toLowerCase()}
                        </span>
                      ))}
                    </td>
                    <td className="col5">
                      {
                        permission === "edit"?
                        <div className="table-action-group">
                          <div className="switch-check">
                            <input
                              type="checkbox"
                              checked={item?.shuffle}
                              value={item?.shuffle}
                              onChange={(event) => suffleChange(event, item)}
                              id={`${item.id}-shuffle`}
                            />
                            <label htmlFor={`${item.id}-shuffle`}></label>
                          </div>
                          <label className="action-label">
                            {item?.shuffle ? "Yes" : "No"}
                          </label>
                        </div>
                        : permission === "read"?
                        <div className="table-action-group">
                          <div className="switch-check">
                            <input
                              type="checkbox"
                              checked={item?.shuffle}
                              value={item?.shuffle}
                              id={`${item.id}-shuffle`}
                              disabled
                            />
                            <label htmlFor={`${item.id}-shuffle`}></label>
                          </div>
                          <label className="action-label">
                            {item?.shuffle ? "Yes" : "No"}
                          </label>
                        </div>
                        :
                        ''
                      }
                      {/**  <label class="checkbox-container">
                        {item.shuffle ? "Yes" : "No"}
                        <input
                          type="checkbox"
                          checked={item.shuffle}
                          onChange={(e) => suffleChange(e, item)}
                          name="shuffle"
                        />
                        <span class="checkmark"></span>
                      </label>
                      */}
                    </td>
                    <td>
                      <div className="date-range w_100">
                        <DatePicker
                          ref={(ref) => (calendar.current = ref)}
                          onChange={(date) => {
                            updateDate("start", date, item);
                          }}
                          dateFormat="DD/MM/YYYY"
                          customInput={
                            <span
                              className={`from-date ${
                                !item.startDate ? "empty" : ""
                              }`}
                              calendar={calendar.current}
                            >
                              {(item.startDate &&
                                moment(item.startDate).format("DD/MM/YYYY")) ||
                                "DD/MM/YYYY"}
                            </span>
                          }
                        />
                        <span className="to">TO</span>
                        <DatePicker
                          ref={(ref) => (calendar.current = ref)}
                          onChange={(date) => {
                            updateDate("end", date, item);
                          }}
                          dateFormat="DD/MM/YYYY"
                          customInput={
                            <span
                              className={`to-date ${
                                !item.endDate ? "empty" : ""
                              }`}
                              calendar={calendar.current}
                            >
                              {(item.endDate &&
                                moment(item.endDate).format("DD/MM/YYYY")) ||
                                "DD/MM/YYYY"}
                            </span>
                          }
                        />
                        <span className="calander-icon"></span>
                      </div>
                    </td>

                    <td className="col5">
                      {
                        permission === "edit"?
                        <div className="table-action-group">
                          <div className="switch-check">
                            <input
                              type="checkbox"
                              checked={item?.active}
                              value={item?.active}
                              onChange={(event) => toggleStatus(event, item)}
                              id={`${item.id}-active`}
                            />
                            <label htmlFor={`${item.id}-active`}></label>
                          </div>
                          <label className="action-label">
                            {item?.active ? "Enabled" : "Disabled"}
                          </label>
                        </div>
                        : permission === "read"?
                        <div className="table-action-group">
                          <div className="switch-check">
                            <input
                              type="checkbox"
                              checked={item?.active}
                              value={item?.active}
                              id={`${item.id}-active`}
                              disabled
                            />
                            <label htmlFor={`${item.id}-active`}></label>
                          </div>
                          <label className="action-label">
                            {item?.active ? "Enabled" : "Disabled"}
                          </label>
                        </div>
                        :
                        ''
                      }
                    </td>
                    <td className="w-10">
                      <div className="icon-group small">
                        <span
                          className="table-icon"
                          onClick={(e) => viewBanner(e, item)}
                        >
                          <img src={viewIcon} alt="viewIcon" />
                        </span>
                        {
                          permission === "edit"?
                          <>
                            <span
                              className="table-icon"
                              onClick={(e) => editBanner(e, item)}
                            >
                              <img src={editIcon} alt="editIcon" />
                            </span>
                            <span
                              className="table-icon"
                              onClick={(e) => deleteBanner(e, item)}
                            >
                              <img src={deleteIcon} alt="deleteIcon" />
                            </span>
                          </>
                          :
                          ''
                        }
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {
          permission === "edit"?
          <div className="button-wrapper">
            <div
              role="none"
              className={`button-container ${!changed ? "disabled" : ""}`}
              onClick={cancelChanges}
            >
              <div className="button-text">Cancel</div>
            </div>
            <div
              role="none"
              className={`button-container ${!changed ? "disabled" : ""}`}
            >
              <div className="button-text" onClick={applyChanges}>
                Apply
              </div>
            </div>
            <div
              role="none"
              className={`button-container`}
              onClick={createBanner}
            >
              <div className="button-text">Create</div>
            </div>

            {duplicatePopup ? (
              <DuplicatePopup
                customStyle={{ marginLeft: "-105px" }}
                platforms={platforms}
                deviceType={deviceType}
                cancel={(e) => {
                  setDuplicatePopup(false);
                }}
                apply={(data) => {
                  setDuplicatePlatform([...data]);
                  setDuplicatePopup(false);
                }}
              />
            ) : null}
          </div>
          :
          ''
        }
      </div>
      {showpopup ? (
        <BannerPopup
          show={true}
          platforms={platforms}
          handleClose={closePopup}
          popupType={popupType}
          selectedData={selectedBanner}
        />
      ) : null}
    </>
  );
}

export default Banners;
