import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "../../../assets/css/style.scss";
import updownarrowIcon from "../../../assets/images/up-down-arrow.svg";
import deleteIcon from "../../../assets/images/delete_forever.png";
import dragableIcon from "../../../assets/images/dragable-icon.png";
import viewIcon from "../../../assets/images/view.png";
import { StoreFrontContentSequenceGenreListAction } from "../../../Redux/slices";
import { updateGenre, genreSequenceList } from "../../../api/api";
import "../index.scss";
import DuplicatePopup from "../DuplicatePopup";
import UILoader from "../../../component/Loader/UILoader";

function Genre({ deviceType, platforms, permission }) {
  const dispatch = useDispatch();
  const { genreList } = useSelector((state) => state.StoreFrontReducer);
  const [genres, setGenres] = useState([]);
  const [changed, setChanged] = useState(false);
  const [duplicatePopup, setDuplicatePopup] = useState(false);
  const [duplicatePlatform, setDuplicatePlatform] = useState([]);
  const draggedItem = useRef();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    getGenreList();
  }, [deviceType]);

  useEffect(() => {
    if (genreList?.length) {
      setGenres(genreList);
    }
  }, [genreList]);

  useEffect(() => {
    if (duplicatePlatform?.length) {
      applyChanges();
    }
  }, [duplicatePlatform]);

  const getGenreList = async () => {
    const result = await genreSequenceList(deviceType?.toUpperCase());
    setLoader(false);
    if (result?.data?.length) {
      let data = result.data.filter((item) => {
        return item.deviceType == deviceType?.toUpperCase();
      });
      dispatch(StoreFrontContentSequenceGenreListAction(data));
    }
  };

  const toggleStatus = async (event, item) => {
    const data = genres.map((row) =>
      row.id === item.id ? { ...row, active: !row.active } : row
    );
    setGenres(data);
    if (!changed) {
      setChanged(true);
    }
  };

  const handleDragStart = (event) => {
    draggedItem.current = event.target;
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setData("text/html", draggedItem.current.innerHTML);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const { id } = event.target.dataset;
    if (!id) {
      return;
    }
    let targetItem = document.querySelector(`[data-custom='${id}']`);
    if (targetItem !== draggedItem.current) {
      if (
        event.clientY >
        targetItem.getBoundingClientRect().top + targetItem.offsetHeight / 2
      ) {
        targetItem.parentNode.insertBefore(
          draggedItem.current,
          targetItem.nextSibling
        );
      } else {
        targetItem.parentNode.insertBefore(draggedItem.current, targetItem);
      }
    }
    let targetPosition = { ...draggedItem.current.dataset };
    let destinationPosition = { ...targetItem.dataset };
    draggedItem.current = null;
    ArrangeData(targetPosition, destinationPosition);
  };

  const ArrangeData = (tar, des) => {
    let targetIndex = 0,
      destinationIndex = 0;
    genres.forEach((item, index) => {
      if (tar.custom == item.id) {
        targetIndex = index;
      }
      if (des.custom == item.id) {
        destinationIndex = index;
      }
    });
    let arr = [...genres];
    // const obj1 = { ...arr[targetIndex] };
    // const obj2 = { ...arr[destinationIndex] };
    // const position = obj2.position;
    // obj2.position = obj1.position;
    // obj1.position = position;
    // arr[targetIndex] = obj2;
    // arr[destinationIndex] = obj1;
    const [movedItem] = arr.splice(targetIndex, 1);
    arr.splice(destinationIndex, 0, movedItem);
    // Adjust positions
    arr = arr.map((item, index) => {
        return {
            ...item,
            position: index + 1
        };
    });
    setGenres([...arr]);
    setChanged(true);
  };

  const cancelChanges = (event) => {
    setGenres([...genreList]);
  };

  const applyChanges = async (event) => {
    const device = deviceType?.toUpperCase();
    const payload = {
      sequenceList: [],
      deviceType: "",
      duplicateOnDevices: [],
    };
    payload.deviceType = device;
    payload.duplicateOnDevices = [...duplicatePlatform];
    genres?.forEach((item, index) => {
      payload.sequenceList.push({
        id: item.id,
        position: index + 1,
        isActive: item.active,
      });
    });
    setLoader(true);
    const result = await updateGenre(payload);
    if (result.status == 200) {
      toast.success(result.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      setChanged(false);
      getGenreList();
    } else {
      toast.error(result.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    }
    setLoader(false);
  };

  const applyForOthers = () => {
    setDuplicatePopup(true);
  };

  return (
    <>
      <div className="content-wrapper w_70" style={{ height: "340px" }}>
        <table role="none" className="store-front-table">
          <thead>
            <tr>
              <th>
                <img src={updownarrowIcon} alt="updownarrowIcon" />
              </th>
              <th className="w-20">Sequence No</th>
              <th className="w-30">Genre Name</th>
              <th className="w-30">Status</th>
              {/* <th className="w-10"></th> */}
            </tr>
          </thead>
          <tbody
            role="none"
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            {genres?.map((item) => {
              return (
                <tr
                  className="dragabble"
                  draggable={permission === "edit"? true : false}
                  key={item.id}
                  data-custom={item.id}
                  data-position={item.position}
                >
                  <td data-id={item.id}>
                    <div className="icon-group" data-id={item.id}>
                      <span className="table-icon" data-id={item.id}>
                        <img
                          className="dragabble-icon"
                          src={dragableIcon}
                          alt="dragableIcon"
                          data-id={item.id}
                        />
                      </span>
                    </div>
                  </td>
                  <td className="w-20 sequence" data-id={item.id}>
                    {item.position}
                  </td>
                  <td className="w-30" data-id={item.id}>
                    {item?.genre?.name}
                  </td>
                  <td className="col5" data-id={item.id}>
                    <div className="table-action-group" data-id={item.id}>
                      {
                        permission === "edit"?
                        <>
                          <div className="switch-check" data-id={item.id}>
                            {/* {item.activeProgress ? (
                          <span className="switch-loader"></span>
                        ) : null} */}
                            <input
                              type="checkbox"
                              checked={item.active}
                              value={item.active}
                              onChange={(event) => toggleStatus(event, item)}
                              id={item.id}
                              data-id={item.id}
                            />
                            <label htmlFor={item.id} data-id={item.id}></label>
                          </div>
                          <label className="action-label" data-id={item.id}>
                            {item.active ? "Enabled" : "Disabled"}
                          </label>
                        </>
                        : permission === "read"?
                        <>
                          <div className="switch-check" data-id={item.id}>
                            <input
                              type="checkbox"
                              checked={item.active}
                              value={item.active}
                              id={item.id}
                              data-id={item.id}
                              disabled
                            />
                            <label htmlFor={item.id} data-id={item.id}></label>
                          </div>
                          <label className="action-label" data-id={item.id}>
                            {item.active ? "Enabled" : "Disabled"}
                          </label>
                        </>
                        :
                        ''
                      }
                    </div>
                  </td>
                  {/*<td data-id={item.id}>
                    <div className="icon-group" data-id={item.id}>
                      <span className="table-icon" data-id={item.id}>
                        <img src={viewIcon} alt="viewIcon" data-id={item.id} />
                      </span>
                      <span className="table-icon" data-id={item.id}>
                        <img
                          src={deleteIcon}
                          alt="deleteIcon"
                          data-id={item.id}
                        />
                      </span>
                    </div>
                  </td>*/}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {
        permission === "edit"?
        <div className="button-wrapper">
          <div
            role="none"
            className={`button-container ${!changed ? "disabled" : ""}`}
            onClick={cancelChanges}
          >
            <div className="button-text">Cancel</div>
          </div>
          <div
            role="none"
            className={`button-container ${!changed ? "disabled" : ""}`}
          >
            <div className="button-text" onClick={applyChanges}>
              Apply
              <span className="arrow" onClick={applyForOthers}>
                &gt;
              </span>
            </div>
          </div>
          {duplicatePopup ? (
            <DuplicatePopup
              platforms={platforms}
              deviceType={deviceType}
              cancel={(e) => {
                setDuplicatePopup(false);
              }}
              apply={(data) => {
                console.log(data);
                setDuplicatePlatform([...data]);
                setDuplicatePopup(false);
              }}
            />
          ) : null}
        </div>
        :
        ''
      }
      {loader ? <UILoader /> : null}
    </>
  );
}

export default Genre;
