import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "../../../assets/css/style.scss";

import updownarrowIcon from "../../../assets/images/up-down-arrow.svg";
import deleteIcon from "../../../assets/images/delete_forever.png";
import dragableIcon from "../../../assets/images/dragable-icon.png";
import searchIcon from "../../../assets/images/search.svg";
import { StoreFrontChannelListAction } from "../../../Redux/slices";
import {
  channelSequenceList,
  allAvailableChannelList,
  updateChannelSequenceList,
} from "../../../api/api";
import { image_url } from "../../../service/Constant";
import "../index.scss";
import DuplicatePopup from "../DuplicatePopup";
import UILoader from "../../../component/Loader/UILoader";

function ChannelSequencing({ deviceType, platforms, permission }) {
  const dispatch = useDispatch();
  const { channelList } = useSelector((state) => state.StoreFrontReducer);
  const draggedItem = useRef();
  const [changed, setChanged] = useState(false);
  const [duplicatePopup, setDuplicatePopup] = useState(false);
  const [duplicatePlatform, setDuplicatePlatform] = useState([]);
  const [channels, setChannels] = useState([]);
  const [channelsTemp, setChannelsTemp] = useState([]);
  const [draggableList, setDraggableList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setLoader(true);
    getAllAvailableChannelList();
  }, []);

  useEffect(() => {
    getChannelSequenceList();
  }, [deviceType]);

  useEffect(() => {
    filterChannel();
  }, [channelList]);

  useEffect(() => {
    if (duplicatePlatform?.length) {
      applyChanges();
    }
  }, [duplicatePlatform]);

  useEffect(() => {
    if (channelsTemp?.length) {
      if (searchText) {
        const filteredList = channelsTemp.filter((item) => {
          return item?.name.toLowerCase().includes(searchText.toLowerCase());
        });
        setChannels(filteredList);
      } else {
        setChannels([...channelsTemp]);
      }
    }
  }, [searchText]);

  const filterChannel = (data) => {
    data = data || draggableList;
    if (channelList?.length) {
      const ids = new Set(data.map((item) => item.channel?.id || item.id));
      const filteredList = channelList.filter((item) => {
        return !ids.has(item.id);
      });
      setChannelsTemp(filteredList);
      setChannels(filteredList);
    }
  };

  const getChannelSequenceList = async () => {
    const channelSequence = await channelSequenceList(
      deviceType?.toUpperCase()
    );
    setLoader(false);
    if (channelSequence?.data?.length) {
      setDraggableList([...channelSequence.data]);
      filterChannel(channelSequence.data);
    } else {
      setDraggableList([]);
      addBlankSlot();
    }
  };

  const getAllAvailableChannelList = async () => {
    const result = await allAvailableChannelList();
    if (result?.data?.length) {
      dispatch(StoreFrontChannelListAction(result.data));
    }
  };

  const handleDragStart = (event) => {
    draggedItem.current = event.target;
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setData("text/html", draggedItem.current.innerHTML);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const handleDrop = (event) => {
    event.preventDefault();
    let { type, value } = draggedItem.current.dataset;
    let { id } = event.target.dataset;
    value = value ? JSON.parse(value) : value;
    let obj = {};
    if (type == "channel-list" && value) {
      obj = {
        id: value["id"],
        name: value["name"],
        slotType: "channel",
        new: true,
      };
      let arr = channels.filter((item) => {
        return item.id != value.id;
      });
      setChannelsTemp(arr);
      setChannels(arr);
      addToDragabbleList(obj);
      setChanged(true);
      return;
    }

    if (type == "draggable-list" && id) {
      let targetItem = document.querySelector(`[data-id='${id}']`);
      if (targetItem !== draggedItem.current) {
        if (
          event.clientY >
          targetItem.getBoundingClientRect().top + targetItem.offsetHeight / 2
        ) {
          targetItem.parentNode.insertBefore(
            draggedItem.current,
            targetItem.nextSibling
          );
        } else {
          targetItem.parentNode.insertBefore(draggedItem.current, targetItem);
        }
      }
      let targetPosition = { ...draggedItem.current.dataset };
      let destinationPosition = { ...targetItem.dataset };
      draggedItem.current = null;
      ArrangeData(targetPosition, destinationPosition);
    }
  };

  const ArrangeData = (tar, des) => {
    let targetIndex = 0,
      destinationIndex = 0;
    draggableList.forEach((item, index) => {
      if (tar.id == item.id) {
        targetIndex = index;
      }
      if (des.id == item.id) {
        destinationIndex = index;
      }
    });
    let arr = [...draggableList];
    // const obj1 = { ...arr[targetIndex] };
    // const obj2 = { ...arr[destinationIndex] };
    // const position = obj2.position;
    // obj2.position = obj1.position;
    // obj1.position = position;
    // arr[targetIndex] = obj2;
    // arr[destinationIndex] = obj1;
    const [movedItem] = arr.splice(targetIndex, 1);
    arr.splice(destinationIndex, 0, movedItem);
    // Adjust positions
    arr = arr.map((item, index) => {
        return {
            ...item,
            position: index + 1
        };
    });
    setDraggableList([...arr]);
    setChanged(true);
  };

  const channelDragStart = (event) => {
    draggedItem.current = event.target;
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setData("text/html", draggedItem.current.innerHTML);
  };

  const addAutomatedSlot = (e) => {
    const obj = {
      id: `AUT_${Date.now()}`,
      name: "Automated Slot",
      slotType: "automated",
      new: true,
    };
    addToDragabbleList(obj);
    setChanged(true);
  };
  const addBlankSlot = (e) => {
    const obj = {
      id: `BLK_${Date.now()}`,
      name: "Blank Slot",
      slotType: "blank",
      new: true,
    };
    addToDragabbleList(obj);
    setChanged(true);
  };
  const addToDragabbleList = (data) => {
    let listSize = draggableList.length;
    const obj = { ...data, position: listSize + 1 };
    setDraggableList([...draggableList, obj]);
  };

  const cancelChanges = (event) => {};

  const applyChanges = async (event) => {
    const device = deviceType?.toUpperCase();
    const payload = {
      sequenceList: [],
      deviceType: "",
      duplicateOnDevices: [],
    };
    payload.deviceType = device;
    payload.duplicateOnDevices = [...duplicatePlatform];
    for (let i = 0; i < draggableList.length; i++) {
      const id = draggableList[i]?.id?.toString();
      const slotType = draggableList[i].slotType.toUpperCase();
      let channelId = "";
      if (id.includes("BLK") || id.includes("AUT")) {
        channelId = "";
      } else if (slotType == "BLANK" || slotType == "AUTOMATED") {
        channelId = draggableList[i].id;
      } else {
        channelId = draggableList[i]?.channel?.id || draggableList[i].id;
      }

      payload.sequenceList.push({
        position: i + 1,
        slotType,
        channelId,
      });
    }
    setLoader(true);
    const result = await updateChannelSequenceList(payload);
    if (result.status == 200) {
      toast.success(result.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    } else {
      toast.error(result.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    }
    setLoader(false);
    setChanged(false);
    getChannelSequenceList();
  };

  const deleteChannel = (e, data) => {
    e.stopPropagation();
    const id = data?.channel?.id || data.id;
    const arr = channelList.filter((item) => {
      return item.id == id;
    });
    const arr1 = draggableList.filter((item) => {
      return (item?.channel?.id || item.id) != id;
    });
    setDraggableList([...arr1]);
    setChannels([...arr, ...channels]);
    setChannelsTemp([...arr, ...channels]);
    setChanged(true);
  };

  const getChannelName = (item) => {
    if (item.slotType.toUpperCase() == "BLANK") {
      return "Blank Slot";
    }
    if (item.slotType.toUpperCase() == "AUTOMATED") {
      return "Automated Slot";
    }
    if (item?.name) {
      return item.name;
    }
    if (item?.channel?.name) {
      return item.channel.name;
    }
    return "";
  };
  const applyForOthers = () => {
    setDuplicatePopup(true);
  };

  return (
    <>
      <div className="page-content">
        {
          permission === "edit"?
          <div className="time-slot-wrapper">
            <span className="automated" onClick={addAutomatedSlot}></span>
            <span className="blank" onClick={addBlankSlot}></span>
          </div>
          :
          ''
        }

        <div className="content-wrapper w_60">
          <table className="store-front-table">
            <thead>
              <tr>
                <th>
                  <img src={updownarrowIcon} />
                </th>
                <th className="w-30">Sequence Number</th>
                <th>Channel Name</th>
                <th></th>
              </tr>
            </thead>
            <tbody
              className={draggableList.length == 0 ? "no-content" : ""}
              role="none"
              onDragStart={handleDragStart}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              {draggableList?.map((item) => {
                return (
                  <tr
                    className="dragabble"
                    draggable={permission === "edit"? true : false}
                    data-type="draggable-list"
                    key={item.id}
                    data-value={JSON.stringify(item)}
                    data-id={item.id}
                  >
                    <td data-id={item.id}>
                      <div className="icon-group" data-id={item.id}>
                        <span className="table-icon" data-id={item.id}>
                          <img
                            className="dragabble-icon"
                            src={dragableIcon}
                            alt="dragableIcon"
                            data-id={item.id}
                          />
                        </span>
                      </div>
                    </td>
                    <td data-id={item.id}>{item.position}</td>
                    <td data-id={item.id}>{getChannelName(item)}</td>
                    <td data-id={item.id}>
                      {
                        permission === "edit"?
                        <div className="icon-group" data-id={item.id}>
                          <span
                            className="table-icon"
                            data-id={item.id}
                            onClick={(e) => deleteChannel(e, item)}
                          >
                            <img src={deleteIcon} data-id={item.id} />
                          </span>
                        </div>
                        :
                        ''
                      }
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="channel-listing-cseq top">
          <div className="search-wrapper">
            <div className="text-wrapper">
              <input
                type="text"
                placeholder="search text"
                className="input"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                value={searchText}
              />
            </div>
            <div className="search-icon">
              <img src={searchIcon} alt="searchIcon" />
            </div>
          </div>

          <div className="list-wrapper" onDragStart={channelDragStart}>
            {channels?.map((item) => {
              return (
                <div
                  className="list-item"
                  draggable={permission === "edit"? true : false}
                  data-type="channel-list"
                  key={item.id}
                  data-value={JSON.stringify(item)}
                  data-id={item.id}
                >
                  <img
                    src={`${image_url}${item.channelLogo[0]?.thumbnailSourcePath}`}
                    className="channel-icon"
                    data-id={item.id}
                    alt="logo"
                  />
                  <div className="channel-name" data-id={item.id}>
                    {item.name}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {
          permission === "edit"?
          <div className="button-wrapper">
            <div
              role="none"
              className={`button-container ${!changed ? "disabled" : ""}`}
              onClick={cancelChanges}
            >
              <div className="button-text">Cancel</div>
            </div>
            {/* <div
              role="none"
              className={`button-container ${!changed ? "disabled" : ""}`}
              onClick={applyChanges}
            >
              <div className="button-text">Apply</div>
            </div> */}
            <div
              role="none"
              className={`button-container ${!changed ? "disabled" : ""}`}
            >
              <div className="button-text">
                <span onClick={applyChanges}>Apply</span>
                <span className="arrow" onClick={applyForOthers}>
                  &gt;
                </span>
              </div>
            </div>
            {duplicatePopup ? (
              <DuplicatePopup
                platforms={platforms}
                deviceType={deviceType}
                cancel={(e) => {
                  setDuplicatePopup(false);
                }}
                apply={(data) => {
                  setDuplicatePlatform([...data]);
                  setDuplicatePopup(false);
                }}
              />
            ) : null}
          </div>
          :
          ''
        }
      </div>
      {loader ? <UILoader /> : null}
    </>
  );
}

export default ChannelSequencing;
