import { cloudFront_URL } from "./API_URL"

export const encryptionKey = "1234567890123456"

export const commonHeaders =   {
    "Access-Control-Allow-Origin": "*",
    "ngrok-skip-browser-warning": true,
}
export const Profile_data = JSON.parse(localStorage.getItem("userdetail"))
// export const image_url="https://d1lq7wiqj91e0r.cloudfront.net/"
export const image_url= cloudFront_URL

export const ratings = ['U', 'UA-7+', 'UA-13+', 'UA-16+', 'A']
export const ContentGroup = ["Super Platinum", "Platinum", "Gold", "Silver", "Bronze"]
