import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import * as moment from "moment";
import { toast } from "react-toastify";
import "../../../assets/css/style.scss";
import updownarrowIcon from "../../../assets/images/up-down-arrow.svg";
import dragableIcon from "../../../assets/images/dragable-icon.png";
import { StoreFrontChannelFilterListAction } from "../../../Redux/slices";
import { channelFilterlList, updateChannelFilter } from "../../../api/api";
import "../index.scss";
import DuplicatePopup from "../DuplicatePopup";
import UILoader from "../../../component/Loader/UILoader";

function ChannelsFilterSequencing({ deviceType, platforms, permission }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { channelFilterList } = useSelector((state) => state.StoreFrontReducer);
  const [channelFilter, setChannelFilter] = useState([]);
  const [changed, setChanged] = useState(false);
  const [duplicatePopup, setDuplicatePopup] = useState(false);
  const [duplicatePlatform, setDuplicatePlatform] = useState([]);
  const [loader, setLoader] = useState(false);
  const draggedItem = useRef();
  const calendar = useRef();

  useEffect(() => {
    setLoader(true);
    getChannelFilteredList();
  }, [deviceType]);

  useEffect(() => {
    if (channelFilterList?.length) {
      setChannelFilter(channelFilterList);
    }
  }, [channelFilterList]);

  const getChannelFilteredList = async () => {
    const result = await channelFilterlList(deviceType.toUpperCase());
    setLoader(false);
    if (result?.data?.length) {
      let data = result.data.filter((item) => {
        return item.deviceType == deviceType?.toUpperCase();
      });

      dispatch(StoreFrontChannelFilterListAction(data));
    }
  };

  const toggleStatus = async (event, item) => {
    const data = channelFilter.map((row) =>
      row.id === item.id ? { ...row, active: !row.active } : row
    );
    setChannelFilter(data);
    if (!changed) {
      setChanged(true);
    }
  };

  const handleDragStart = (event) => {
    draggedItem.current = event.target;
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setData("text/html", draggedItem.current.innerHTML);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const { id } = event.target.dataset;
    if (!id) {
      return;
    }
    let targetItem = document.querySelector(`[data-custom='${id}']`);
    if (targetItem !== draggedItem.current) {
      if (
        event.clientY >
        targetItem.getBoundingClientRect().top + targetItem.offsetHeight / 2
      ) {
        targetItem.parentNode.insertBefore(
          draggedItem.current,
          targetItem.nextSibling
        );
      } else {
        targetItem.parentNode.insertBefore(draggedItem.current, targetItem);
      }
    }
    let targetPosition = { ...draggedItem.current.dataset };
    let destinationPosition = { ...targetItem.dataset };
    draggedItem.current = null;
    ArrangeData(targetPosition, destinationPosition);
  };

  const ArrangeData = (tar, des) => {
    let targetIndex = 0,
      destinationIndex = 0;
    channelFilter.forEach((item, index) => {
      if (tar.id == item.id) {
        targetIndex = index;
      }
      if (des.id == item.id) {
        destinationIndex = index;
      }
    });
    let arr = [...channelFilter];
    // const obj1 = { ...arr[targetIndex] };
    // const obj2 = { ...arr[destinationIndex] };
    // const position = obj2.position;
    // obj2.position = obj1.position;
    // obj1.position = position;
    // arr[targetIndex] = obj2;
    // arr[destinationIndex] = obj1;
    const [movedItem] = arr.splice(targetIndex, 1);
    arr.splice(destinationIndex, 0, movedItem);
    // Adjust positions
    arr = arr.map((item, index) => {
        return {
            ...item,
            position: index + 1
        };
    });
    setChannelFilter([...arr]);
    setChanged(true);
  };

  const cancelChanges = (event) => {
    setChannelFilter([...channelFilterList]);
  };

  const applyChanges = async (event) => {
    const device = deviceType?.toUpperCase();
    const payload = {
      sequenceList: [],
      deviceType: "",
      duplicateOnDevices: [],
    };
    payload.deviceType = device;
    payload.duplicateOnDevices = [...duplicatePlatform];
    channelFilter?.forEach((item) => {
      let obj = {
        id: item?.id,
        position: item.position,
        isActive: item.active,
        featuredType: item.featuredType,
      };
      if (item.startDate) obj.startDate = item.startDate;
      if (item.endDate) obj.endDate = item.endDate;
      payload.sequenceList.push(obj);
    });
    setLoader(true);
    const result = await updateChannelFilter(payload);
    if (result.status == 200) {
      toast.success(result.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      setChanged(false);
      getChannelFilteredList();
    } else {
      toast.error(result.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    }
    setLoader(false);
  };

  const updateDate = (type, date, item) => {
    const d = moment(date).toISOString();
    let arr = [...channelFilter];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].id === item.id) {
        let data = { ...arr[i] };
        if (type == "start") data.startDate = d;
        if (type == "end") data.endDate = d;
        arr[i] = data;
        break;
      }
    }
    setChannelFilter([...arr]);
    setChanged(true);
  };

  const applyForOthers = () => {
    setDuplicatePopup(true);
  };

  return (
    <>
      <div className="page-content">
        <div className="content-wrapper w_90" style={{ height: "340px" }}>
          <table role="none" className="store-front-table">
            <thead>
              <tr>
                <th className="w-10">
                  <img src={updownarrowIcon} alt="updownarrowIcon" />
                </th>
                <th className="w-15">Sequence No</th>
                <th className="w-40">Channel Filter</th>
                <th className="w-40">Date Range</th>
                <th className="w-15">Status</th>
              </tr>
            </thead>
            <tbody
              role="none"
              onDragStart={handleDragStart}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              {channelFilter?.map((item) => {
                return (
                  <tr
                    className="dragabble"
                    key={item.id}
                    draggable={permission === "edit"? true : false}
                    data-custom={item.id}
                    data-position={item.position}
                    data-id={item.id}
                  >
                    <td data-id={item.id}>
                      <div className="icon-group" data-id={item.id}>
                        <span className="table-icon" data-id={item.id}>
                          <img
                            className="dragabble-icon"
                            src={dragableIcon}
                            alt="dragableIcon"
                            data-id={item.id}
                          />
                        </span>
                      </div>
                    </td>
                    <td className="w-15" data-id={item.id}>
                      {item?.position}
                    </td>
                    <td className="w-40" data-id={item.id}>
                      {item?.category?.name}
                    </td>
                    <td className="w-40" data-id={item.id}>
                      <div className="date-range" style={{ width: "80%" }}>
                        <DatePicker
                          ref={(ref) => (calendar.current = ref)}
                          onChange={(date) => {
                            updateDate("start", date, item);
                          }}
                          dateFormat="DD/MM/YYYY"
                          customInput={
                            <span
                              className={`from-date ${
                                !item.endDate ? "empty" : ""
                              }`}
                              calendar={calendar.current}
                            >
                              {(item.startDate &&
                                moment(item.startDate).format("DD/MM/YYYY")) ||
                                "DD/MM/YYYY"}
                            </span>
                          }
                        />
                        <span className="to">TO</span>
                        <DatePicker
                          ref={(ref) => (calendar.current = ref)}
                          onChange={(date) => {
                            updateDate("end", date, item);
                          }}
                          dateFormat="DD/MM/YYYY"
                          customInput={
                            <span
                              className={`to-date ${
                                !item.startDate ? "empty" : ""
                              }`}
                              calendar={calendar.current}
                            >
                              {(item.endDate &&
                                moment(item.endDate).format("DD/MM/YYYY")) ||
                                "DD/MM/YYYY"}
                            </span>
                          }
                        />
                        <span className="calander-icon"></span>
                      </div>
                    </td>
                    <td className="w-15" data-id={item.id}>
                      {
                        permission === "edit"?
                        <div className="table-action-group" data-id={item.id}>
                          <div className="switch-check" data-id={item.id}>
                            <input
                              type="checkbox"
                              checked={item?.active}
                              value={item?.active}
                              onChange={(event) => toggleStatus(event, item)}
                              id={item.id}
                            />
                            <label htmlFor={item.id}></label>
                          </div>
                          <label className="action-label">
                            {item?.active ? "Enabled" : "Disabled"}
                          </label>
                        </div>
                        : permission === "read"?
                        <div className="table-action-group" data-id={item.id}>
                          <div className="switch-check" data-id={item.id}>
                            <input
                              type="checkbox"
                              checked={item?.active}
                              value={item?.active}
                              id={item.id}
                              disabled
                            />
                            <label htmlFor={item.id}></label>
                          </div>
                          <label className="action-label">
                            {item?.active ? "Enabled" : "Disabled"}
                          </label>
                        </div>
                        :
                        ''
                      }
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {
          permission === "edit"?
          <div className="button-wrapper">
            <div
              role="none"
              className={`button-container ${!changed ? "disabled" : ""}`}
              onClick={cancelChanges}
            >
              <div className="button-text">Cancel</div>
            </div>
            <div
              role="none"
              className={`button-container ${!changed ? "disabled" : ""}`}
            >
              <div className="button-text" onClick={applyChanges}>
                Apply
                <span className="arrow" onClick={applyForOthers}>
                  &gt;
                </span>
              </div>
            </div>
            {duplicatePopup ? (
              <DuplicatePopup
                platforms={platforms}
                deviceType={deviceType}
                cancel={(e) => {
                  setDuplicatePopup(false);
                }}
                apply={(data) => {
                  setDuplicatePlatform([...data]);
                  setDuplicatePopup(false);
                }}
              />
            ) : null}
          </div>
          :
          ''
        }
      </div>
      {loader ? <UILoader /> : null}
    </>
  );
}

export default ChannelsFilterSequencing;
