import React, { useState } from "react";
import "./validatepop.scss"; // Import the CSS file
import folder from "../../../assets/images/floder-open.png";
import donecheck from "../../../assets/images/donecheck.png";
import pending from "../../../assets/images/pending.png";
import error from "../../../assets/images/error.png";
import warning from "../../../assets/images/warning.png";
import processing from "../../../assets/images/processing.png";
import override from "../../../assets/images/override.png";
import dangerwhite from "../../../assets/images/danger-white.png";
import { Assetoverride } from "../../../api/api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Button from 'react-bootstrap/Button';
import { Permission } from "@aws-sdk/client-s3";
function Validatepopup({ validateinfo, handleClosePopup, assetsingledata, permission }) {




  const [assetinfo, setAssetinfo] = useState(assetsingledata);
  const [filetranscoded, setFileTranscoded] = useState(validateinfo?.transcoded);
  const [Postersvalidation, setPostersValidation] = useState(validateinfo?.missingPosters);
  const [metadatavalidation, setMetadataValidation] = useState(validateinfo?.missingMetadataFields);
  let validationStatus = assetsingledata?.validationStatus;
  let missingMetadata = !validateinfo?.missingMetadata;



  const overrideposter = async () => {
    const response = await Assetoverride(assetsingledata.id)

    if (response?.data?.status == true) {

      toast.success("User override successful", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000
      });
    } else {
      toast.error("Something went wrong, please try after sometime", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000
      });
    }

    handleClosePopup()



  }



  return (

    <div className="validate">
      <div className="validation-container">
        <div className="validation-text">Validating</div>
        <div className="episode-text">Video : <b>{assetinfo?.metadata != null ? assetinfo?.metadata.title : assetinfo?.filename}</b></div>
        <div className="file-section border-bottom ">
          <div className="videos-header d-flex justify-content-between">
            <div>

              <img src={folder} style={{ width: "20px" }} />
              <span className="videos-label p-2">Videos</span>
            </div>

            <div className="status-icon">
              <div className={filetranscoded ? "status-icon-shape success" : "status-icon-shape error"}>
                {filetranscoded ? <><img src={donecheck} style={{ height: "20px" }} /> <span>Completed</span></> : <><img src={error} style={{ height: "20px" }} /><span>Error</span></>}
              </div>
            </div>
          </div>
          <div className="videos-status">
            <div className="status-text success">

              {filetranscoded ?
                "Video is transcoded" : "Video is not transcoded"}
            </div>
          </div>
        </div>
        <div className="file-section border-bottom ">
          <div className="videos-header d-flex justify-content-between">
            <div>

              <img src={folder} style={{ width: "20px" }} />
              <span className="videos-label p-2">Posters</span>
            </div>

            <div className="status-icon">
              <div className={Postersvalidation && Postersvalidation?.length == 0 ? "status-icon-shape success" : validationStatus == "OVERRIDDEN" ? "status-icon-shape error" : "status-icon-shape danger"}>

                {Postersvalidation && Postersvalidation?.length == 0 ? <><img src={donecheck} style={{ height: "20px" }} />
                 <span>Completed</span></> : validationStatus == "OVERRIDDEN" ? 
                 <><a  style={{ cursor:"unset" }}>
                  <img style={{ height: "20px" }} src={override} /> 
                  <span>Over Riden</span> </a></> 
                 : validationStatus == "ERROR" && <>
                  {
                    permission? 
                 <a onClick={() => overrideposter(assetinfo)} style={{ cursor: "pointer" }}>
                  <img className="mb-1" src={dangerwhite} /> <span>Override</span> </a>
                   :
                   <a style={{ cursor: "pointer" }}>
                  <img className="mb-1" src={dangerwhite} /> <span>Override</span> </a>
                  }
                  
                  </>

                }

              </div>
            </div>
          </div>
          <div className="videos-status">
            <div className="status-text">
            <span className={Postersvalidation?.length != 0 ? 'missing' : 'success'}>
              {Postersvalidation && Postersvalidation?.length != 0 ? "Missing :" : "All Posters Are Present"}</span>{
              Postersvalidation && Postersvalidation.map((data, index) => {
                return (
                <React.Fragment>
                  <span className="ml-2">{data}</span>
                  {(index+1 != Postersvalidation?.length) ? ',': ''}
                </React.Fragment>
                )
              })
            }
            </div>
            <div className="mt-2" style={{ cursor: "pointer" }}></div>
          </div>
        </div>
        <div className="file-section  ">
          <div className="videos-header d-flex justify-content-between">
            <div>

              <img src={folder} style={{ width: "20px" }} />
              <span className="videos-label p-2">Metadata</span>
            </div>

            <div className="status-icon">
              <div className={metadatavalidation && metadatavalidation.length == 0 ? "status-icon-shape success" : "status-icon-shape error"}>
                {metadatavalidation && metadatavalidation.length == 0 ? <><img src={donecheck} style={{ height: "20px" }} /> <span>Completed</span></> : <><img src={error} style={{ height: "20px" }} /><span>Error</span></>
                }
              </div>
            </div>
          </div>
          <div className="videos-status">
            <div className={`status-text ${(missingMetadata && metadatavalidation.length != 0 ) ? '' : 'success'}`}>
              {missingMetadata && metadatavalidation?.length != 0 ? "Missing :" : "All MetaData Are Present"} {metadatavalidation && metadatavalidation.map((data) =>
                <span className="mx-2"> {data}</span>)

              }
            </div>
          </div>
        </div>
        <div className="float-right">
          <Button className='Cancel btn btn-primary' onClick={handleClosePopup}>
              cancel
          </Button>
        </div>
      </div>
    </div>
  )

}

export default Validatepopup;
