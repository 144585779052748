import React, { useState } from "react";
import AccordionChild from "./AccordionChild";
import AccordionTableHead from "./AccordionTableHead";
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import chart from "../../../assets/images/chart.png";
import table from "../../../assets/images/table.png";

function AccordionBlock({ title, isOpen, onToggle, filter, graphView, handleGraphicalView}) {
  // const [isOpen, setIsOpen] = useState(false);
  // Toggle the isOpen state when the link is clicked
  const [clickedData, setClickedData] = useState(null);
  const [viewGraph, setViewGraph] = useState(false);

  // Function to handle the click event from AccordionTableHead
  const handleTableHeadClick = (data) => {
    // Store the clicked data in state
    setClickedData(data);
  };

  return (
    <div className="accordion-block">
      <OverlayTrigger
        trigger="click"
        key={'left'}
        placement={'left'}
        overlay={
          <Popover id={`popover-positioned-${'left'}`}>
            <Popover.Body>
              <div className="view-mode">
                <span className={!viewGraph && 'active'} onClick={() => {setViewGraph(false)}}>
                  <img src={table} alt="table view" />
                </span>
                <span className={`${viewGraph && 'active'} mt-2`} onClick={() => {setViewGraph(true)}} >
                  <img src={chart} alt="chart view"  />
                </span>
              </div>
            </Popover.Body>
          </Popover>
        }
        show={isOpen}
      >
      <a variant="secondary" className={isOpen ? "toggle active" : "toggle"}>
        <AccordionTableHead
          isOpen={isOpen}
          data={title}
          onToggle={onToggle}
          onTableHeadClick={handleTableHeadClick}
        />
      </a>
      </OverlayTrigger>
      {isOpen && (
        <AccordionChild
          data={title}
          filter={filter}
          clickedData={clickedData}
          graphView={viewGraph}
       
        />
      )}
    </div>
  );
}

export default AccordionBlock;
