import React, {useEffect, useState } from 'react';
import { deleteInstantData, getInstantData } from "../../api/apiMethod";
import { Get_User } from "../../service/API_URL";
import { useNavigate, useLocation } from 'react-router-dom';
import { Dropdown, DropdownButton } from "react-bootstrap";
import editicon from "../../assets/images/editicon.png";
import archiveicon from "../../assets/images/archiveicon.png";
import deleteicon from "../../assets/images/delete_forever.png";
import viewIcon from '../../assets/images/carbon_view.svg';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import sortingDefault from '../../assets/images/sorting-default.svg';
import sortingUp from '../../assets/images/sortarrow.png';
import sortingDown from '../../assets/images/desc.png';
import rightarrow from "../../assets/images/rightvector.png";
import leftarrow from "../../assets/images/Vector.png";

function UserManagement() {

  const [userList, setUserList] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isDropdownOpenArray, setIsDropdownOpenArray] = useState();
  const [sortColumn, setSortColumn] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  
  // List view
  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = userList?.slice(startIndex, endIndex);
  const totalPages = Math.ceil(userList.length / itemsPerPage);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleDropdownSelect = (selectedOption, index) => {
    setIsDropdownOpenArray((prevState) =>
      prevState.map((value, i) => (i === index ? false : value))
    );
  };
  const navigate = useNavigate();
  const getUser = async (params) => {
    const response = await getInstantData(Get_User, {params} || {});
    setUserList(response.data);    
    setIsDropdownOpenArray(new Array(response.data.length).fill(false));
  }
  useEffect(() => {
    getUser();
  }, []);

  
  const handleSorting = (column) => {
    let sorting = {
      sortColumn: column,
      sortDirection: null
    }
    setSortColumn(column);
    if (sortDirection == 'asc') {
      setSortDirection('desc');
      sorting.sortDirection = 'desc';
    } else {
      setSortDirection('asc');
      sorting.sortDirection = 'asc';
    }

    let queryParams = {
      sortBy: sorting.sortColumn,
      sortOrder: sorting.sortDirection,
      pageNumber: currentPage,
      pageSize: pageSize
    };
    getUser(queryParams);
   // getSuperTenant(queryParams);
  }

  const openEditTenant = (user) => {
    const URL = '/user-add';
    navigate(URL, { state: { user }});
  };
  const addNew = () => {
    navigate('/user-add');
  };
  const deleteTenant = async (user) => {
    const URL = `${Get_User}/${user.id}`;
    try {
      const response = await deleteInstantData(URL);
      toast.success('User deleted successfully', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
      getUser();
    } catch (error) {
      const msg = 'User deletion failed. Please try again later.';
      toast.error( msg, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
    }
  }
  return (
    
    <div className="content-body">
      <div className="dashboard-content">
        <div className="top-content">
          <div className="left-side">
            <h3>User Management</h3>
          </div>
          <div className="right-side">
            <div className='channel-setup-button'>
              <button className='btn btn-primary' onClick={addNew}>Add New</button>
            </div>
          </div>
        </div>
        <div className='channel-setup-table'>
          <div className="cms-table-list tenant-table">
            <table className='w-100'>
              <thead>
                <tr>
                  <th onClick={() => { handleSorting('firstName') }}>First Name
                    <span>
                      {sortColumn == 'firstName' && sortDirection == 'asc' ? <img src={sortingUp} /> : null}
                      {sortColumn == 'firstName' && sortDirection == 'desc' ? <img src={sortingDown} /> : null}
                      {sortColumn != 'firstName' ? <img src={sortingDefault} /> : null}
                    </span>
                  </th>
                  <th onClick={() => { handleSorting('lastName') }}>Last Name
                    <span>
                      {sortColumn == 'lastName' && sortDirection == 'asc' ? <img src={sortingUp} /> : null}
                      {sortColumn == 'lastName' && sortDirection == 'desc' ? <img src={sortingDown} /> : null}
                      {sortColumn != 'lastName' ? <img src={sortingDefault} /> : null}
                    </span>
                  </th>
                  <th onClick={() => { handleSorting('email') }}>Email
                    <span>
                      {sortColumn == 'email' && sortDirection == 'asc' ? <img src={sortingUp} /> : null}
                      {sortColumn == 'email' && sortDirection == 'desc' ? <img src={sortingDown} /> : null}
                      {sortColumn != 'email' ? <img src={sortingDefault} /> : null}
                    </span>
                  </th>
                  <th onClick={() => { handleSorting('mobileNumber') }}>Mobile
                    <span>
                      {sortColumn == 'mobileNumber' && sortDirection == 'asc' ? <img src={sortingUp} /> : null}
                      {sortColumn == 'mobileNumber' && sortDirection == 'desc' ? <img src={sortingDown} /> : null}
                      {sortColumn != 'mobileNumber' ? <img src={sortingDefault} /> : null}
                    </span>
                  </th>
                  <th>Role</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentData.map((user, index) => {
                  return (
                    <tr key={index}>
                      <td>{user.firstName}</td>
                      <td>{user.lastName}</td>
                      <td>{user.email}</td>
                      <td>{user.mobileNumber}</td>
                      <td>{user.roles[0].name}</td>
                      <td>
                    
                    <Dropdown
                          show={isDropdownOpenArray[index]}
                          onToggle={(event) => {
                            setIsDropdownOpenArray((prevState) =>
                              prevState.map((value, i) =>
                                i === index ? !value : false
                              )
                            );
                          }}
                        >
                          <Dropdown.Toggle
                            id={`dropdown-basic-${index}`}
                            variant="light"
                            style={{ cursor: "pointer" }}
                            className="three-dots"
                          >
                            <span className="px-4">⋮</span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                          <Dropdown.Item
                              onClick={(e) => {
                                e.stopPropagation();
                                openEditTenant(user);
                              }}
                              eventKey="option1"
                              onSelect={(e) =>
                                handleDropdownSelect("option1", index)
                              }
                            >
                              <img src={viewIcon} alt="" /> View
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(e) => {
                                e.stopPropagation();
                                openEditTenant(user);
                              }}
                              eventKey="option1"
                              onSelect={(e) =>
                                handleDropdownSelect("option1", index)
                              }
                            >
                              <img src={editicon} alt="" /> Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="option3"
                              onSelect={() =>
                                handleDropdownSelect("option3", index)
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                                deleteTenant(user);
                              }}
                            >
                              <img src={deleteicon} alt="" /> Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                    </td>
                    </tr>
                    );
                  })
                }
              </tbody>
            </table>

            <div className="table-pagination">
              <div className="pagination-count">
                <div className="count">
                  {`${(currentPage - 1) * itemsPerPage + 1}-${Math.min(
                    currentPage * itemsPerPage,
                    userList.length
                  )} of ${userList.length}`}
                </div>
                <div className="pagination-arrow">
                  <a
                    className="prev"
                    onClick={() =>
                      currentPage > 1 &&
                      handlePageChange(currentPage - 1)
                    }
                  >
                    <img src={leftarrow} alt="Previous" />
                  </a>
                  <a
                    className="next"
                    onClick={() =>
                      currentPage < totalPages &&
                      handlePageChange(currentPage + 1)
                    }
                  >
                    <img src={rightarrow} alt="Next" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}

export default UserManagement;