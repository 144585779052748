import style from './editable-grid.module.scss';
import { useSelector } from 'react-redux';
import {ratings, ContentGroup} from './../../service/Constant';

export function RenderDropdown({ row, column,
  onRowChange }) {
    
    const { AudioLanguageList, CountryList, MaturityList } = useSelector(state => ({ AudioLanguageList: state.AudioLanguageList?.data, CountryList: state.CountryList?.data?.data, MaturityList: state.MaturityList?.data }));
    const updateValue = (event) => {
      if(column.key === 'originalLanguage') {
        const val = event.target.value.split(",")
        onRowChange({ ...row, originalLanguageId: [val[0]] }, true);
        onRowChange({ ...row, originalLanguage: {id:val[0], name: val[1]} }, true);
      } else if (column.key === 'originName') {
        const val = event.target.value.split(",")
        onRowChange({ ...row, origin: [val[0]] }, true);
        onRowChange({ ...row, originName: {id:val[0], name: val[1]} }, true);
      }
      // else if(column.key === 'origin') {
      //   onRowChange({ ...row, origin: event.target.value }, true);
      // } 
      else if(column.key === 'ratings') {
        const val = event.target.value.split(",")
        onRowChange({ ...row, ratings: event.target.value }, true);
      } 
    }
    const updateStaticValue = (event) => {
      if(column.key === 'contentGroup') {
        onRowChange({ ...row, contentGroup: event.target.value }, true);
      }
    }
    let dataList;
    if(column.key === 'originalLanguage') {
      dataList = AudioLanguageList;
    } else if(column.key === 'originName') {
      dataList = CountryList;
    } else if(column.key === 'ratings') {
      dataList = MaturityList;
    } else if(column.key === 'contentGroup') {
      dataList = ContentGroup;
    }
  return (
    (column.key === 'contentGroup') ? 
    <select
      className={style.gridDropdown}
      value={row[column.key]}
      onChange={(event) => updateStaticValue(event)}
      autoFocus
    >
      {dataList?.map((rating) => (
        <option key={rating} value={rating}>
          {rating}
        </option>
      ))}
    </select> : (column.key === 'ratings') ? 
    <select
      className={style.gridDropdown}
      value={row[column.key]?.[0]?.name}
      onChange={(event) => updateValue(event)}
      autoFocus
    >
      <option>Select rating</option>
      {dataList?.map((list) => (
        <option key={list?.id} value={list.name}>
          {list.name}
        </option>
      ))}
    </select>
    :
    <select
      className={style.gridDropdown}
      value={row[column.key]}
      onChange={(event) => updateValue(event)}
      autoFocus
    >
      {dataList?.map((audio) => (
        <option key={audio.id} value={[audio.id, audio.name]}>
          {audio.name}
        </option>
      ))}
    </select>
  );
}