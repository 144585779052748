import React, { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import "../../../assets/css/style.scss";
import "./index.scss";
function DuplicatePopup(props) {
  const { apply, cancel, platforms, deviceType, customStyle } = props;
  let arr = [];
  const clickHandler = (e, type) => {
    switch (type) {
      case "cancel":
        cancel();
        break;
      case "apply":
        apply(arr);
        break;
    }
  };

  const changeHandler = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      arr.push(value);
    } else {
      arr = arr.filter((item) => item != value);
    }
  };

  return (
    <div className="duplicate-wrapper" style={customStyle}>
      <h5 className="title">Also Apply to</h5>
      <ul className="list">
        {platforms?.map((item) => {
          return item != deviceType ? (
            <li className="list-item" key={item}>
              <input
                type="checkbox"
                className="checkbox"
                id={item}
                value={item?.toUpperCase()}
                onChange={changeHandler}
              />
              <span>{item}</span>
            </li>
          ) : null;
        })}
      </ul>
      <div className="button-wrapper-small">
        <div
          role="none"
          className="button-container-small"
          onClick={(e) => clickHandler(e, "cancel")}
        >
          <div className="button-text">Cancel</div>
        </div>
        <div
          role="none"
          className="button-container-small"
          onClick={(e) => clickHandler(e, "apply")}
        >
          <div className="button-text">Apply</div>
        </div>
      </div>
    </div>
  );
}

export default DuplicatePopup;
