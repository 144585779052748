import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import test from "../../../assets/images/floder-open.png";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css"; // Use this CSS module
import "./AddEditSettingspopup.scss";
import {
  Categories_create,
  Categories_update,
  Genres_create,
  Languages_create,
  Genres_update,
  Languages_update,
  Theme_create,
  Theme_update,
  MatuarityRatings_create,
  MatuarityRatings_update,
  Devices_create,
  Devices_update,
  getPeerGroups,
  getUserGroup,
  Avatar_create,
  Avatar_update,
  getAvatarGender,
} from "../../../api/api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FilesDragAndDrop from "../../File-Uploader/FileUploader";
import { logDOM } from "@testing-library/react";
import { capitalizeFirstLetter } from "../../../service/commonFunc";
import LanguageDropdown from "../../Lang-dropdown/Langselect";

const AddEditSettingspopup = ({
  handleClose,
  isEditing,
  gridData,
  tab,
  TabChange,
  onlyview,
}) => {
  const {
    appThemeReducer: { appTheme },
  } = useSelector((state) => state);
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [description, setDescription] = useState("");
  const [validForm, setValidForm] = useState("invalid");
  const [activeTab, setActiveTab] = useState("Mobile");
  const [setting_name, setSettingName] = useState("DefaultName");
  const [Code, setCode] = useState(null);
  const [codeprefix, setCodeprefix] = useState("TH-");
  const [size, setISize] = useState(null);
  const [peerGroups, setPeerGroups] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [peerGroup, setPeerGroup] = useState('');
  const [userGroup, setUserGroup] = useState('');
  const [gender, setGender] = useState('');
  const [avatarGender, setAvatarGender] = useState([]);

  // for example, initializing it with a default value
  const [tabContent, setTabContent] = useState({
    TV: [],
    Web: [],
    Mobile: [],
    Tablet: [],
  });
  const [uploadedFiles, setUploadedFiles] = useState({
    TV: {
      icon: {
        enabled: { file: null, size: null, type: null, icon_type: null },
        disabled: { file: null, size: null, type: null, icon_type: null },
      },
      poster: { file: null, size: null, type: null, icon_type: null },
    },
    Web: {
      icon: {
        enabled: { file: null, size: null, type: null, icon_type: null },
        disabled: { file: null, size: null, type: null, icon_type: null },
      },
      poster: { file: null, size: null, type: null, icon_type: null },
    },
    Mobile: {
      icon: {
        enabled: { file: null, size: null, type: null, icon_type: null },
        disabled: { file: null, size: null, type: null, icon_type: null },
      },
      poster: { file: null, size: null, type: null, icon_type: null },
    },
    Tablet: {
      icon: {
        enabled: { file: null, size: null, type: null, icon_type: null },
        disabled: { file: null, size: null, type: null, icon_type: null },
      },
      poster: { file: null, size: null, type: null, icon_type: null },
    },
  });
  const [uploadedFile, setUploadedFile] = useState({
    TV: {
      icon: {
        enabled: { file: null, size: null, type: null, icon_type: null },
        disabled: { file: null, size: null, type: null, icon_type: null },
      },

      poster: { file: null, size: null, type: null, icon_type: null },
    },
    Web: {
      icon: {
        enabled: { file: null, size: null, type: null, icon_type: null },
        disabled: { file: null, size: null, type: null, icon_type: null },
      },
      poster: { file: null, size: null, type: null, icon_type: null },
    },
    Mobile: {
      icon: {
        enabled: { file: null, size: null, type: null, icon_type: null },
        disabled: { file: null, size: null, type: null, icon_type: null },
      },
      poster: { file: null, size: null, type: null, icon_type: null },
    },
    Tablet: {
      icon: {
        enabled: { file: null, size: null, type: null, icon_type: null },
        disabled: { file: null, size: null, type: null, icon_type: null },
      },
      poster: { file: null, size: null, type: null, icon_type: null },
    },
  });
  const [deleteFile, setdeleteFile] = useState({
    TV: {
      icon: {
        enabled: { file: null, size: null, type: null, icon_type: null },
        disabled: { file: null, size: null, type: null, icon_type: null },
      },
      poster: { file: null, size: null, type: null, icon_type: null },
    },
    Web: {
      icon: {
        enabled: { file: null, size: null, type: null, icon_type: null },
        disabled: { file: null, size: null, type: null, icon_type: null },
      },
      poster: { file: null, size: null, type: null, icon_type: null },
    },
    Mobile: {
      icon: {
        enabled: { file: null, size: null, type: null, icon_type: null },
        disabled: { file: null, size: null, type: null, icon_type: null },
      },
      poster: { file: null, size: null, type: null, icon_type: null },
    },
    Tablet: {
      icon: {
        enabled: { file: null, size: null, type: null, icon_type: null },
        disabled: { file: null, size: null, type: null, icon_type: null },
      },
      poster: { file: null, size: null, type: null, icon_type: null },
    },
  });
  const [existingImage, setExistingImage] = useState({
    TV: {
      icon: {
        enabled: { file: null, size: null, type: null, icon_type: null },
        disabled: { file: null, size: null, type: null, icon_type: null },
      },
      poster: { file: null, size: null, type: null, icon_type: null },
    },
    Web: {
      icon: {
        enabled: { file: null, size: null, type: null, icon_type: null },
        disabled: { file: null, size: null, type: null, icon_type: null },
      },
      poster: { file: null, size: null, type: null, icon_type: null },
    },
    Mobile: {
      icon: {
        enabled: { file: null, size: null, type: null, icon_type: null },
        disabled: { file: null, size: null, type: null, icon_type: null },
      },
      poster: { file: null, size: null, type: null, icon_type: null },
    },
    Tablet: {
      icon: {
        enabled: { file: null, size: null, type: null, icon_type: null },
        disabled: { file: null, size: null, type: null, icon_type: null },
      },
      poster: { file: null, size: null, type: null, icon_type: null },
    },
  });
  const [avatarImage, setAvatarImage] = useState('');
  const [avatarImages, setAvatarImages] = useState('');
  
  const updateUploadedFiles = () => {
    const updated = { ...uploadedFiles };
    if (tab == "Themes") {
      [gridData?.images].forEach((images) => {
        images.forEach((image) => {
          const { sourcePath } = image;
          const size = image?.size;
          const fileType = "poster";
          if (size == "TV") {
            updated.TV[fileType] = { file: sourcePath, size };
          } else if (size == "Web") {
            updated.Web[fileType] = { file: sourcePath, size };
          } else if (size == "Mobile") {
            updated.Mobile[fileType] = { file: sourcePath, size };
          } else if (size == "Tablet") {
            updated.Tablet[fileType] = { file: sourcePath, size };
          }
        });
      });
    } else {
      gridData?.posterImages.forEach((image) => {
        const { size, sourcePath } = image;
        const type = sourcePath.includes("icon") ? "icon" : "poster";

        if (["TV", "Web", "Mobile", "Tablet"].includes(size)) {
          updated[
            size.charAt(0).toUpperCase() + size.slice(1) == "Tv"
              ? "TV"
              : size.charAt(0).toUpperCase() + size.slice(1)
          ][type] = { file: sourcePath, size, type };
        }
      });

      // For iconImages
      gridData?.iconImages.forEach((image) => {
        const { size, sourcePath, iconImageDesc } = image;
        const type = sourcePath.includes("icon") ? "icon" : "poster";
        const icon_type =
          iconImageDesc == "disabled"
            ? "disabled"
            : iconImageDesc == "null" || iconImageDesc == "enabled"
            ? "enabled"
            : "enabled";
        if (["TV", "Web", "Mobile", "Tablet"].includes(size)) {
          updated[
            size.charAt(0).toUpperCase() + size.slice(1) == "Tv"
              ? "TV"
              : size.charAt(0).toUpperCase() + size.slice(1)
          ][type][icon_type] = { file: sourcePath, size, type, icon_type };
        }
      });
    }

    // Iterate through posterImages and iconImages

    // Set the updated state
    setUploadedFiles(updated);
  };

  const existingImages = () => {
    const existImage = { ...existingImage };
    if (tab == "Themes") {
      [gridData?.images].forEach((images) => {
        images.forEach((image) => {
          const { size, sourcePath } = image;
          const fileType = sourcePath.includes("poster") ? "poster" : "poster";
          if (size == "TV") {
            existingImage.TV[fileType] = { file: sourcePath, size };
          } else if (size == "Web") {
            existingImage.Web[fileType] = { file: sourcePath, size };
          } else if (size == "Mobile") {
            existingImage.Mobile[fileType] = { file: sourcePath, size };
          } else if (size == "Tablet") {
            existingImage.Tablet[fileType] = { file: sourcePath, size };
          }
        });
      });
    } else {
      // For posterImages, assuming a default value for iconImageDesc
      gridData?.posterImages.forEach((image) => {
        const { size, sourcePath } = image;
        const fileType = sourcePath.includes("icon") ? "icon" : "poster";
        const target = "enabled"; // Assuming a default value for posterImages

        if (["TV", "Web", "Mobile", "Tablet"].includes(size)) {
          existImage[
            size.charAt(0).toUpperCase() + size.slice(1) == "Tv"
              ? "TV"
              : size.charAt(0).toUpperCase() + size.slice(1)
          ][fileType] = { file: sourcePath, size };
        }
      });

      // For iconImages
      gridData?.iconImages.forEach((image) => {
        const { size, sourcePath, iconImageDesc } = image;
        const fileType = sourcePath.includes("icon") ? "icon" : "poster";
        const target = iconImageDesc === "enabled" ? "enabled" : "disabled";

        if (["TV", "Web", "Mobile", "Tablet"].includes(size)) {
          existImage[
            size.charAt(0).toUpperCase() + size.slice(1) == "Tv"
              ? "TV"
              : size.charAt(0).toUpperCase() + size.slice(1)
          ][fileType][target] = { file: sourcePath, size };
        }
      });
    }

    // Iterate through posterImages and iconImages

    // Set the updated state
    setExistingImage(existImage);
  };

  const Sizes = {
    Theme: {
      icon: {
        mobile: "",
        tv: "",
        tablet: "",
        web: "",
      },
      poster: {
        mobile: "1144 x 1324px",
        tv: "7200 x 2752px",
        tablet: "64 x 64px",
        web: "5056 x 1920px",
      },
    },
    Category: {
      icon: {
        mobile: "64 x 64px",
        tv: "64 x 64px",
        tablet: "64 x 64px",
        web: "64 x 64px",
      },
      poster: {
        mobile: "320 x 224px",
        tv: "672 x 480px",
        tablet: "320 x 224px",
        web: "672 x 480px",
      },
    },
    Genre: {
      icon: {
        mobile: "96 x 96px",
        tv: "96 x 96px",
        tablet: "96 x 96px",
        web: "96 x 96px",
      },
      poster: {
        mobile: "832 x 544px",
        tv: "832 x 544px",
        tablet: "832 x 544px",
        web: "832 x 544px",
      },
    },
    Languages: {
      icon: {
        mobile: "64 x 64px",
        tv: "64 x 64px",
        tablet: "64 x 64px",
        web: "64 x 64px",
      },
      poster: {
        mobile: "312 x 112px",
        tv: "672 x 240px",
        tablet: "312 x 112px",
        web: "672 x 240",
      },
    },
  };

  const getAvatarSelectFieldsData = async () => {
    const peerGroupRes = await getPeerGroups();
    const userGroupRes = await getUserGroup();
    const avatarGenderList = await getAvatarGender();
    setPeerGroups(peerGroupRes?.data);
    setUserGroups(userGroupRes?.data);
    setAvatarGender(avatarGenderList?.data);
  }

  const [setting_Code, setsetting_Code] = useState(null);
  const Settingname = (tab) => {
    if (tab == "Themes") {
      setSettingName("Theme");
      setsetting_Code(gridData?.themeCode);
      setCode("themeCode");
      setCodeprefix("TH-");
      setISize(Sizes?.Theme);
    } else if (tab == "Categories") {
      setSettingName("Category");
      setsetting_Code(gridData?.categoryCode);
      setCode("categoryCode");
      setCodeprefix("CT-");
      setISize(Sizes?.Category);
    } else if (tab == "Genres") {
      setSettingName("Genre");
      setsetting_Code(gridData?.genreCode);
      setCode("genreCode");
      setCodeprefix("GN-");
      setISize(Sizes?.Genre);
    } else if (tab == "Languages") {
      setSettingName("Language");
      setsetting_Code(gridData?.languageCode);
      setCode("languageCode");
      setCodeprefix("LN-");
      setISize(Sizes?.Language);
    } else if (tab == "Maturity Ratings") {
      setsetting_Code(gridData?.maturityRatingCode);
      setCode("maturityRatingCode");
      setSettingName("Maturity Rating");
      setCodeprefix("MR-");
    } else if (tab == "Devices") {
      setSettingName("Device");
      setsetting_Code(gridData?.deviceCode);
      setCode("deviceCode");
      setCodeprefix("DV-");
    }
    else if (tab == "Avatar") {
      setSettingName("Avatar");
      setsetting_Code(gridData?.avatarCode);
      setCode("avatarCode");
      setCodeprefix("AV-");
      getAvatarSelectFieldsData();
    }
  };

  useEffect(() => {
    Settingname(tab);

    if (isEditing && gridData) {
      setName(gridData?.name);
      setDescription(gridData?.description);
      setPeerGroup(gridData?.peerGroup?.id);
      setUserGroup(gridData?.userGroup?.id);
      setGender(gridData?.gender?.id);
      // Update state function
      if (setting_Code) {
        setId(setting_Code.replace(new RegExp(`^${codeprefix}`), "").slice(-4));
      }

      // Call the function to update the state
      if (tab !== "Maturity Ratings" && tab !== "Devices" && tab!== "Avatar") {
        updateUploadedFiles();
        existingImages();
      }else if(tab === "Avatar") {
        setAvatarImages({"file":gridData?.sourcePath});
      }
    } else {
      setName("");
      setId("");
      setDescription("");
      //   setStartDate(null);
      //   setEndDate(null);
      //   setStartTime(null); // Set it to an empty string, not null
      //   setEndTime(null);
      //   setRendered(true);
    }
    setValidForm("invalid");
  }, [tab, setting_Code]);

  useEffect(() => {
    formValidate();
  }, [name, id, description]);

  const addSettings = async () => {
    let updated = false;
    let Add = false;
    let settingname = "";
    if (isEditing) {
      const newData = new FormData();

      if (tab !== "Maturity Ratings" && tab !== "Devices" && tab !== "Avatar") {
        newData.append("id", gridData?.id);
        newData.append(Code, codeprefix + id);
        newData.append("name", name);
        newData.append("description", description);
        let index = 0;
        let deleteindex = 0;
        let listingindex = 0;

        for (const [type, images] of Object.entries(uploadedFile)) {
          for (const [category, fileData] of Object.entries(images)) {
            const file = fileData.file;
            const size = fileData.size;
            const iconfile = fileData?.enabled?.file;
            const icondfile = fileData?.disabled?.file;

            if (file || icondfile || iconfile) {
              if (tab == "Themes") {
                newData.append(`uploadThemeImageList[${index}].size`, size);
                newData.append(
                  `uploadThemeImageList[${index}].multipartFile`,
                  file
                );
                index++;
              } else {
                if (category === "poster") {
                  newData.append(`uploadImageList[${index}].size`, size);
                  newData.append(
                    `uploadImageList[${index}].multipartFile`,
                    file
                  );
                  newData.append(`uploadImageList[${index}].type`, "poster");
                  newData.append(
                    `uploadImageList[${index}].iconImageDesc`,
                    "enabled"
                  );
                  index++;
                } else if (category === "icon") {
                  // Accessing icon data properly within the icon category
                  const enabledIcon = fileData.enabled;
                  const disabledIcon = fileData.disabled;

                  // Assuming 'enabled' and 'disabled' are keys containing icon data
                  if (enabledIcon && enabledIcon.file) {
                    const fileType = enabledIcon.file;
                    const sizeType = enabledIcon.size;

                    newData.append(`uploadImageList[${index}].size`, sizeType);
                    newData.append(
                      `uploadImageList[${index}].multipartFile`,
                      fileType
                    );
                    newData.append(`uploadImageList[${index}].type`, "icon");
                    newData.append(
                      `uploadImageList[${index}].iconImageDesc`,
                      "enabled"
                    );

                    index++;
                  }

                  if (disabledIcon && disabledIcon.file) {
                    const fileType = disabledIcon.file;
                    const sizeType = disabledIcon.size;

                    newData.append(`uploadImageList[${index}].size`, sizeType);
                    newData.append(
                      `uploadImageList[${index}].multipartFile`,
                      fileType
                    );
                    newData.append(`uploadImageList[${index}].type`, "icon");
                    newData.append(
                      `uploadImageList[${index}].iconImageDesc`,
                      "disabled"
                    );
                    index++;
                  }
                }
              }
            }
            // Increment the index counter for the next file
          }
        }

        for (const [type, images] of Object.entries(deleteFile)) {
          for (const [category, fileData] of Object.entries(images)) {
            const file = fileData.file;
            const size = fileData.size;
            const iconfile = fileData?.enabled?.file;
            const icondfile = fileData?.disabled?.file;
            if (file || icondfile || iconfile) {
              if (tab == "Themes") {
                newData.append(`deleteImageList[${deleteindex}].size`, size);
                newData.append(
                  `deleteImageList[${deleteindex}].sourcePath`,
                  file
                );
                deleteindex++;
              } else {
                // newData.append(`deleteImageList[${deleteindex}].size`, size);
                // newData.append(`deleteImageList[${deleteindex}].sourcePath`, file);
                // newData.append(`deleteImageList[${deleteindex}].type`, category == "icon" ? "icon" : "poster");

                if (category === "poster") {
                  newData.append(`deleteImageList[${deleteindex}].size`, size);
                  newData.append(
                    `deleteImageList[${deleteindex}].sourcePath`,
                    file
                  );
                  newData.append(
                    `deleteImageList[${deleteindex}].type`,
                    "poster"
                  );
                  newData.append(
                    `deleteImageList[${deleteindex}].iconImageDesc`,
                    "enabled"
                  );
                  deleteindex++;
                } else if (category === "icon") {
                  // Accessing icon data properly within the icon category
                  const enabledIcon = fileData.enabled;
                  const disabledIcon = fileData.disabled;

                  // Assuming 'enabled' and 'disabled' are keys containing icon data
                  if (enabledIcon && enabledIcon.file) {
                    const fileType = enabledIcon.file;
                    const sizeType = enabledIcon.size;

                    newData.append(
                      `deleteImageList[${deleteindex}].size`,
                      sizeType
                    );
                    newData.append(
                      `deleteImageList[${deleteindex}].sourcePath`,
                      fileType
                    );
                    newData.append(
                      `deleteImageList[${deleteindex}].type`,
                      "icon"
                    );
                    newData.append(
                      `deleteImageList[${deleteindex}].iconImageDesc`,
                      "enabled"
                    );

                    deleteindex++;
                  }

                  if (disabledIcon && disabledIcon.file) {
                    const fileType = disabledIcon.file;
                    const sizeType = disabledIcon.size;

                    newData.append(
                      `deleteImageList[${deleteindex}].size`,
                      sizeType
                    );
                    newData.append(
                      `deleteImageList[${deleteindex}].sourcePath`,
                      fileType
                    );
                    newData.append(
                      `deleteImageList[${deleteindex}].type`,
                      "icon"
                    );
                    newData.append(
                      `deleteImageList[${deleteindex}].iconImageDesc`,
                      "disabled"
                    );
                    deleteindex++;
                  }
                }
              }
              // else if (tab == "Genres") {

              // } else if (tab == "Languages") {

              // } else if (tab == "Maturity Ratings") {

              // } else if (tab == "Devices") {

              // }
            }
          }
        }

        for (const [type, images] of Object.entries(existingImage)) {
          for (const [category, fileData] of Object.entries(images)) {
            const file = fileData.file;
            const size = fileData.size;
            const iconfile = fileData?.enabled?.file;
            const icondfile = fileData?.disabled?.file;

            if (file || icondfile || iconfile) {
              if (tab == "Themes") {
                newData.append(
                  `listExistingAttachedImages[${listingindex}].size`,
                  size
                );
                newData.append(
                  `listExistingAttachedImages[${listingindex}].sourcePath`,
                  file
                );
                listingindex++;
              } else {
                // newData.append(`listExistingImage[${listingindex}].size`, size?.toLowerCase());
                // newData.append(`listExistingImage[${listingindex}].sourcePath`, file);
                // newData.append(`listExistingImage[${listingindex}].type`, category == "icon" ? "icon" : "poster");

                if (category === "poster") {
                  newData.append(
                    `listExistingImage[${listingindex}].size`,
                    size
                  );
                  newData.append(
                    `listExistingImage[${listingindex}].sourcePath`,
                    file
                  );
                  newData.append(
                    `listExistingImage[${listingindex}].type`,
                    "poster"
                  );
                  newData.append(
                    `listExistingImage[${listingindex}].iconImageDesc`,
                    "enabled"
                  );
                  listingindex++;
                } else if (category === "icon") {
                  // Accessing icon data properly within the icon category
                  const enabledIcon = fileData.enabled;
                  const disabledIcon = fileData.disabled;

                  // Assuming 'enabled' and 'disabled' are keys containing icon data
                  if (enabledIcon && enabledIcon.file) {
                    const fileType = enabledIcon.file;
                    const sizeType = enabledIcon.size;

                    newData.append(
                      `listExistingImage[${listingindex}].size`,
                      sizeType
                    );
                    newData.append(
                      `listExistingImage[${listingindex}].sourcePath`,
                      fileType
                    );
                    newData.append(
                      `listExistingImage[${listingindex}].type`,
                      "icon"
                    );
                    newData.append(
                      `listExistingImage[${listingindex}].iconImageDesc`,
                      "enabled"
                    );
                    listingindex++;
                  }
                  if (disabledIcon && disabledIcon.file) {
                    const fileType = disabledIcon.file;
                    const sizeType = disabledIcon.size;

                    newData.append(
                      `listExistingImage[${listingindex}].size`,
                      sizeType
                    );
                    newData.append(
                      `listExistingImage[${listingindex}].sourcePath`,
                      fileType
                    );
                    newData.append(
                      `listExistingImage[${listingindex}].type`,
                      "icon"
                    );
                    newData.append(
                      `listExistingImage[${listingindex}].iconImageDesc`,
                      "disabled"
                    );
                    listingindex++;
                  }
                }
              }
              // else if (tab == "Genres") {

              // } else if (tab == "Languages") {

              // } else if (tab == "Maturity Ratings") {

              // } else if (tab == "Devices") {

              // }
            }
          }
        }
      } else if(tab === "Avatar") {
        newData.append(Code, codeprefix + id);
        newData.append("name", name);
        newData.append("description", description);
        newData.append("peerGroupId", peerGroup);
        newData.append("userGroupId", userGroup);
        newData.append("genderId", gender);
        if (avatarImage) {
          newData.append(`file`, avatarImage);
        } else if (avatarImages?.file) {
          newData.append('sourcePath', avatarImages?.file);
        } else {
          toast.error("Avatar image is required.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
          });
        }
      } else {
        const newData = {
          id: gridData?.id,
          [Code]: codeprefix + id,
          name: name,
          description: description,
        };
        if (tab == "Maturity Ratings") {
          updated = await MatuarityRatings_update(newData);
          settingname = "maturity ratings";
        } else if (tab == "Devices") {
          updated = await Devices_update(newData);
          settingname = "device";
        }
      }

      closePopup();

      if (tab == "Themes") {
        updated = await Theme_update(newData);
        settingname = "theme";
      } else if (tab == "Categories") {
        updated = await Categories_update(newData);
        settingname = "category";
      } else if (tab == "Genres") {
        updated = await Genres_update(newData);
        settingname = "genre";
      } else if (tab == "Languages") {
        updated = await Languages_update(newData);
        settingname = "language";
      } else if (tab == "Avatar") {
        updated = await Avatar_update(newData, gridData?.id);
        settingname = "avatar";
      }

      if (updated?.status == 200) {
        toast.success(updated?.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
        // theme_ListDetail();
      } else {
        toast.error("Something went wrong, please try after sometime", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      }
    } else {
      const newData = new FormData();
      if (tab !== "Maturity Ratings" && tab !== "Devices" & tab !== "Avatar") {
        newData.append(Code, codeprefix + id);
        newData.append("name", name);
        newData.append("description", description);
        let index = 0;
        let posterindex = 0;

        // Initialize the index counter

        for (const [type, images] of Object.entries(uploadedFile)) {
          for (const [category, fileData] of Object.entries(images)) {
            const file = fileData.file;
            const size = fileData.size;
            const icon_type = fileData.icon_type;
            const iconfile = fileData?.enabled?.file;
            const icondfile = fileData?.disabled?.file;
            // const iconsize = fileData[icon_type].size;
            // const iconicon_type = fileData[icon_type].icon_type

            if (file || icondfile || iconfile) {
              if (tab == "Themes") {
                newData.append(`themeImageList[${index}].size`, size);
                newData.append(`themeImageList[${index}].multipartFile`, file);
                index++;
              } else {
                if (category == "icon") {
                  if (fileData?.enabled?.icon_type == "enabled") {
                    const iconfile = fileData.enabled.file;
                    const iconsize = fileData.enabled.size;
                    const iconicon_type = fileData.enabled.icon_type;

                    newData.append(`iconImages[${index}].size`, iconsize);
                    newData.append(
                      `iconImages[${index}].multipartFile`,
                      iconfile
                    );
                    newData.append(`iconImages[${index}].type`, "icon");
                    newData.append(
                      `iconImages[${index}].iconImageDesc`,
                      iconicon_type
                    );
                    index++;
                  }
                }
                if (category == "icon") {
                  if (fileData?.disabled?.icon_type == "disabled") {
                    const iconfile = fileData?.disabled.file;
                    const iconsize = fileData?.disabled.size;
                    const iconicon_type = fileData?.disabled.icon_type;

                    newData.append(`iconImages[${index}].size`, iconsize);
                    newData.append(
                      `iconImages[${index}].multipartFile`,
                      iconfile
                    );
                    newData.append(`iconImages[${index}].type`, "icon");
                    newData.append(
                      `iconImages[${index}].iconImageDesc`,
                      iconicon_type
                    );

                    index++;
                  }
                }
                if (category == "poster") {
                  newData.append(`posterImages[${posterindex}].size`, size);
                  newData.append(
                    `posterImages[${posterindex}].multipartFile`,
                    file
                  );
                  newData.append(`posterImages[${posterindex}].type`, "poster");

                  posterindex++;
                }
              }
              // else if (tab == "Genres") {

              // } else if (tab == "Languages") {

              // } else if (tab == "Maturity Ratings") {

              // } else if (tab == "Devices") {

              // }

              // Increment the index counter for the next file
            }
          }
        }
      } else if(tab === "Avatar") {
        newData.append(Code, (codeprefix + id).toUpperCase());
        newData.append("name", name);
        newData.append("description", description);
        newData.append("peerGroupId", peerGroup);
        newData.append("userGroupId", userGroup);
        newData.append("genderId", gender);
        newData.append(`file`, avatarImage);
      } else {
        const newData = {
          [Code]: codeprefix + id,
          name: name,
          description: description,
        };
        if (tab == "Maturity Ratings") {
          Add = await MatuarityRatings_create(newData);
          settingname = "maturity rating";
        } else if (tab == "Devices") {
          Add = await Devices_create(newData);
          settingname = "device";
        }
      }

      if (tab == "Themes") {
        Add = await Theme_create(newData);
        settingname = "theme";
      } else if (tab == "Categories") {
        Add = await Categories_create(newData);
        settingname = "category";
      } else if (tab == "Genres") {
        Add = await Genres_create(newData);
        settingname = "genre";
      } else if (tab == "Languages") {
        Add = await Languages_create(newData);
        settingname = "language";
      } else if (tab == "Avatar") {
        Add = await Avatar_create(newData);
        settingname = "avatar";
      }
      closePopup();
      if (Add?.status == 201) {
        handleTabChange(activeTab);
        toast.success(Add.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
        // theme_ListDetail();
      } else {
        toast.error("Something went wrong, try after some time", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      }
    }

    setTimeout(() => {
      TabChange(tab, "", "");
    }, 1000);
  };
  const changeHandler = (e, imagetype, imageCategory, icontype) => {
    const imageFile = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const file = {
        file: reader.result,
        size: imagetype,
        type: imageCategory,
        icon_type: icontype ? icontype : null,
      };
      if (imageCategory === "avatar") {
        setAvatarImages(file);
      }else {
        setUploadedFiles((prevUploadedFiles) => ({
          ...prevUploadedFiles,
          [imagetype]: {
            ...prevUploadedFiles[imagetype],
            [imageCategory]: {
              ...prevUploadedFiles[imagetype][imageCategory],
              ...(imageCategory === "poster" ? file : { [icontype]: file }),
            },
          },
        }));
      }
    };
    reader.readAsDataURL(imageFile); // Read the file as a data URL
    createchangeHandler(e, imagetype, imageCategory, icontype);
  };
  const createchangeHandler = (e, imagetype, imageCategory, icontype) => {
    const file = e.target.files[0];
    const files = {
      file: file,
      size: imagetype,
      icon_type: icontype ? icontype : "enabled",
      type: imageCategory,
    };

    if (imageCategory === "avatar") {
      setAvatarImage(e.target.files[0]);
    } else {
      setUploadedFile((prevUploadedFiles) => ({
        ...prevUploadedFiles,
        [imagetype]: {
          ...prevUploadedFiles[imagetype],
          [imageCategory]: {
            ...prevUploadedFiles[imagetype][imageCategory],
            ...(imageCategory === "poster" ? files : { [icontype]: files }),
          },
        },
      }));
    }
  };

  const delete_poster = (url, imagetype, imageCategory, icontype) => {
    const file = url;
    const files = {
      file: file,
      size: imagetype,
      icon_type: icontype,
      type: imageCategory,
    };
    // if (typeof file === "string" && file.startsWith("platform")) {
      if (imageCategory === "avatar") {
        setAvatarImage('');
      } else {
        if (typeof file === "string" && file.includes("platform")) {
        setdeleteFile((prevUploadedFile) => ({
          ...prevUploadedFile,
          [imagetype]: {
            ...prevUploadedFile[imagetype],
            [imageCategory]: {
              ...prevUploadedFile[imagetype][imageCategory],
              ...(imageCategory === "poster" ? files : { [icontype]: files }),
            },
          },
        }));
      }
    }

    remove_poster(url, imagetype, imageCategory, icontype);
  };

  const remove_poster = (url, imagetype, imageCategory, icontype) => {
    const file = url;
    if (imageCategory === 'avatar') {
      setAvatarImages('');
    } else {
      const files = {
        file: null,
        size: null,
        icon_type: null,
        type: null,
      };
  
      setExistingImage((prevUploadedFile) => ({
        ...prevUploadedFile,
        [imagetype]: {
          ...prevUploadedFile[imagetype],
          [imageCategory]: {
            ...prevUploadedFile[imagetype][imageCategory],
            ...(imageCategory === "poster" ? files : { [icontype]: files }),
          },
        },
      }));
      setUploadedFile((prevUploadedFile) => ({
        ...prevUploadedFile,
        [imagetype]: {
          ...prevUploadedFile[imagetype],
          [imageCategory]: {
            ...prevUploadedFile[imagetype][imageCategory],
            ...(imageCategory === "poster" ? files : { [icontype]: files }),
          },
        },
      }));
      setUploadedFiles((prevUploadedFile) => ({
        ...prevUploadedFile,
        [imagetype]: {
          ...prevUploadedFile[imagetype],
          [imageCategory]: {
            ...prevUploadedFile[imagetype][imageCategory],
            ...(imageCategory === "poster" ? files : { [icontype]: files }),
          },
        },
      }));
    }
  };
  //   const changeHandler = (e, imagetype, imageCategory) => {
  //     const imageFile = e.target.files[0];
  // const file = {
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     setImagepath(reader.result); // Set the uploaded image to state
  //   };
  //   reader.readAsDataURL(imageFile); // Read the file as a data URL
  // }
  //     setUploadedFiles((prevUploadedFiles) => ({
  //       ...prevUploadedFiles,
  //       [imagetype]: {
  //         ...prevUploadedFiles[imagetype],
  //         [imageCategory]: {
  //           file: file,
  //           size: imagetype,
  //         },
  //       },
  //     }));

  //   };
  useEffect(() => {
    setId(id);
  }, [id]);
  const closePopup = () => {
    // setRendered(false);
    handleClose();
  };
  const formValidate = () => {
    if (name && id?.length == 3) {
      setValidForm("valid");
    } else {
      setValidForm("invalid");
    }
  };

  //   const updateForm = (field, value) => {};
  const handleTabChange = async (tabName) => {
    setActiveTab(tabName);

    if (!tabContent[tabName] || tabContent[tabName].length === 0) {
    }
  };

  return (
    <Modal
      className={`AddEditSettingspopup ${appTheme}`}
      show={true}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {isEditing ? `Edit ${setting_name}` : `Add New ${setting_name}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="flex">
            <Form.Group className="flex-50 " style={{ marginRight: "20px" }}>
              <Form.Label>
                Name <span style={{ color: "red" }}>*</span>
              </Form.Label>
              {tab != "Languages" ? (
                <Form.Control
                  placeholder={"Enter " + setting_name + " name"}
                  type="text"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  readOnly={onlyview}
                />
              ) : (
                <LanguageDropdown
                  setName={setName}
                  names={name}
                  onlyview={onlyview}
                  setId={setId}
                />
              )}
            </Form.Group>

            <Form.Group className="flex-50 ">
              <Form.Label>
                {setting_name} ID (ex: {codeprefix}XYZ)
                <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <div style={{ display: "flex" }}>
                {/* <span className="prefixcode pr-0">{codeprefix}</span> */}

                <Form.Control
                  className="prefixcode"
                  placeholder={codeprefix}
                  type="text"
                  // value={codeprefix}d
                  // minLength="3"
                  // maxLength="3"
                  // onChange={(e) => {
                  //   setId(e.target.value);
                  // }}
                  disabled
                />
                <Form.Control
                  placeholder={"Enter " + setting_name + " Id"}
                  type="text"
                  value={id}
                  minLength="3"
                  maxLength="3"
                  onChange={(e) => {
                    setId(e.target.value);
                  }}
                  disabled={isEditing || tab == "Languages"}
                />
              </div>
            </Form.Group>
          </div>
          {
            tab === "Avatar"? (
              <>
                <div className="flex">
                  <Form.Group className="flex-50" style={{ marginRight: "20px" }}>
                    <Form.Label>
                      Peer Group<span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Select
                      value={peerGroup}
                      onChange={(e) => setPeerGroup(e.target.value)}
                      disabled={onlyview}
                    >
                      <option value="" disabled>
                        Select a Peer Group
                      </option>
                      {
                        peerGroups?.map((data) => {
                          return (
                            <option value={data?.id}>{data?.ageGroup} ({data?.name})</option>
                          )
                        })
                      }
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="flex-50" style={{ marginRight: "20px" }}>
                    <Form.Label>
                      User Group<span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Select
                      value={userGroup}
                      onChange={(e) => setUserGroup(e.target.value)}
                      disabled={onlyview}
                    > 
                    <option value="" disabled>
                      Select a User Group
                    </option>
                      {
                        userGroups?.map((data) => {
                          return (
                            <option value={data?.id}>{data?.name}</option>
                          )
                        })
                      }
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="flex-50">
                    <Form.Label>
                      Gender<span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Select
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                      disabled={onlyview}
                    >
                      <option value="">Select Gender</option>
                      {
                        avatarGender?.map((data) => {
                          return (
                            <option value={data?.id}>{data?.name}</option>
                          )
                        })
                      }
                    </Form.Select>
                  </Form.Group>
                </div>
              </>
            ):
            null
          }
          <div className="flex mt-30">
            <Form.Group className="flex-100">
              <Form.Label>{setting_name} Description</Form.Label>
              <Form.Control
                placeholder={"Enter " + setting_name + " Description"}
                as="textarea"
                rows={3}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                readOnly={onlyview}
                maxLength="1024"
              />
              <div className="field-length">
                <span>{description.length}/1024</span>
              </div>
            </Form.Group>
          </div>
          {
            tab === "Avatar"? (
              <div className="mt-30">
                <div className="mt-30">
                  <label class="form-label">{setting_name} Images<span style={{ color: "red" }}>*</span></label>
                </div>
                <div className="mt-30 box-shadow d-flex">
                  <FilesDragAndDrop
                    id="Avatar-poster"
                    imgcategory="avatar"
                    label={"Avatar Image"}
                    index={1}
                    size={size?.poster.mobile}
                    uploadedFiles={avatarImages}
                    onChangeHandler={changeHandler}
                    deleteimages={delete_poster}
                    imagetype="Avatar"
                    edit={onlyview}
                  />
                </div>
              </div>
            )
            :
            null
          }
          {tab !== "Maturity Ratings" && tab !== "Devices" && tab !== "Avatar" ? (
            <div className="mt-30">
              <div className="mt-30">
                <label class="form-label">{setting_name} Images</label>
                <div className="image-button">
                  {["Mobile", "Tablet", "TV", "Web"].map((tab) => (
                    <span
                      key={tab}
                      className={activeTab === tab ? "active" : ""}
                      onClick={() => handleTabChange(tab)}
                    >
                      {tab}
                    </span>
                  ))}
                </div>
              </div>
              {activeTab === "Mobile" && (
                <div className="mt-30 box-shadow d-flex">
                  <FilesDragAndDrop
                    id="Mobile-poster"
                    imgcategory="poster"
                    label={"Mobile poster"}
                    index={1}
                    size={size?.poster.mobile}
                    uploadedFiles={uploadedFiles}
                    onChangeHandler={changeHandler}
                    deleteimages={delete_poster}
                    imagetype="Mobile"
                    edit={onlyview}
                  />
                  {tab != "Themes" && tab != "Languages" && (
                    <>
                      <FilesDragAndDrop
                        // id="Mobile-icon"
                        imgcategory="icon"
                        label={"Mobile enabled Icon"}
                        index={0}
                        size={size?.icon.mobile}
                        uploadedFiles={uploadedFiles}
                        onChangeHandler={changeHandler}
                        deleteimages={delete_poster}
                        imagetype="Mobile"
                        edit={onlyview}
                        icontype={"enabled"}
                      />
                      <FilesDragAndDrop
                        // id="Mobile-icon"
                        imgcategory="icon"
                        label={"Mobile disabled Icon"}
                        index={0}
                        size={size?.icon.mobile}
                        uploadedFiles={uploadedFiles}
                        onChangeHandler={changeHandler}
                        deleteimages={delete_poster}
                        imagetype="Mobile"
                        edit={onlyview}
                        icontype={"disabled"}
                      />
                    </>
                  )}

                  {/* <div ref={drop} className='FilesDragAndDrop'></div> */}
                </div>
              )}
              {activeTab === "TV" && (
                <div className="mt-30 box-shadow d-flex">
                  <FilesDragAndDrop
                    id="tv-poster"
                    imgcategory="poster"
                    label={"TV poster"}
                    index={1}
                    size={size?.poster.tv}
                    uploadedFiles={uploadedFiles}
                    onChangeHandler={changeHandler}
                    deleteimages={delete_poster}
                    imagetype="TV"
                    // validsize={"16 x 14"}
                    edit={onlyview}
                  />

                  {tab != "Themes" && tab != "Languages" && (
                    <>
                      <FilesDragAndDrop
                        id="tv-icon"
                        imgcategory="icon"
                        label={"TV enabled icon"}
                        index={0}
                        size={size?.icon.tv}
                        uploadedFiles={uploadedFiles}
                        onChangeHandler={changeHandler}
                        deleteimages={delete_poster}
                        imagetype="TV"
                        edit={onlyview}
                        icontype={"enabled"}
                      />
                      <FilesDragAndDrop
                        id="tv-icon"
                        imgcategory="icon"
                        label={"TV disabled icon"}
                        index={0}
                        size={size?.icon.tv}
                        uploadedFiles={uploadedFiles}
                        onChangeHandler={changeHandler}
                        deleteimages={delete_poster}
                        imagetype="TV"
                        edit={onlyview}
                        icontype={"disabled"}
                      />
                    </>
                  )}

                  {/* <div ref={drop} className='FilesDragAndDrop'></div> */}
                </div>
              )}
              {activeTab === "Web" && (
                <div className="mt-30 box-shadow d-flex">
                  <FilesDragAndDrop
                    id="Web-poster"
                    imgcategory="poster"
                    label={"Web poster"}
                    index={1}
                    size={size?.poster.web}
                    uploadedFiles={uploadedFiles}
                    onChangeHandler={changeHandler}
                    deleteimages={delete_poster}
                    imagetype="Web"
                    edit={onlyview}
                  />
                  {tab != "Themes" && tab != "Languages" && (
                    <>
                      <FilesDragAndDrop
                        id="Web-icon"
                        imgcategory="icon"
                        label={"Web enabled icon"}
                        index={0}
                        size={size?.icon.web}
                        uploadedFiles={uploadedFiles}
                        onChangeHandler={changeHandler}
                        deleteimages={delete_poster}
                        imagetype="Web"
                        edit={onlyview}
                        icontype={"enabled"}
                      />
                      <FilesDragAndDrop
                        id="Web-icon"
                        imgcategory="icon"
                        label={"Web disabled icon"}
                        index={0}
                        size={size?.icon.web}
                        uploadedFiles={uploadedFiles}
                        onChangeHandler={changeHandler}
                        deleteimages={delete_poster}
                        imagetype="Web"
                        edit={onlyview}
                        icontype={"disabled"}
                      />
                    </>
                  )}

                  {/* <div ref={drop} className='FilesDragAndDrop'></div> */}
                </div>
              )}

              {activeTab === "Tablet" && (
                <div className="mt-30 box-shadow d-flex">
                  <FilesDragAndDrop
                    id="Tablet-poster"
                    imgcategory="poster"
                    label={"Tablet poster"}
                    index={1}
                    size={size?.poster.tablet}
                    uploadedFiles={uploadedFiles}
                    onChangeHandler={changeHandler}
                    imagetype="Tablet"
                    edit={onlyview}
                    deleteimages={delete_poster}
                  />
                  {tab != "Themes" && tab != "Languages" && (
                    <>
                      <FilesDragAndDrop
                        id="Tablet-icon"
                        imgcategory="icon"
                        label={"Tablet enabled icon"}
                        index={0}
                        size={size?.icon.tablet}
                        uploadedFiles={uploadedFiles}
                        onChangeHandler={changeHandler}
                        imagetype="Tablet"
                        edit={onlyview}
                        icontype={"enabled"}
                        deleteimages={delete_poster}
                      />
                      <FilesDragAndDrop
                        id="Tablet-icon"
                        imgcategory="icon"
                        label={"Tablet disabled icon"}
                        index={0}
                        size={size?.icon.tablet}
                        uploadedFiles={uploadedFiles}
                        onChangeHandler={changeHandler}
                        imagetype="Tablet"
                        edit={onlyview}
                        icontype={"disabled"}
                        deleteimages={delete_poster}
                      />
                    </>
                  )}

                  {/* <div ref={drop} className='FilesDragAndDrop'></div> */}
                </div>
              )}
            </div>
          ) : null}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="Cancel" onClick={closePopup}>
          Cancel
        </Button>
        {!onlyview && (
          <Button
            disabled={validForm == "valid" ? false : true}
            className={`Add ${validForm}`}
            onClick={addSettings}
          >
            {isEditing ? "Update" : "Add"}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default AddEditSettingspopup;
