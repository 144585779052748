
import React, { useState, useEffect } from 'react';
import 'react-time-picker/dist/TimePicker.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css'; // Use this CSS module
import styles from './editable-grid.module.scss';


import TimePicker from 'react-time-picker';
import Form from 'react-bootstrap/Form';

export function TimePickerGrid({
    row,
    column,
    onRowChange,
    }) {
        let timeValue
        if( row[column.key] && row[column.key].toUpperCase() !== row[column.key].toLowerCase() ) {
            timeValue="00:00:00"
        } else {
            timeValue=row[column.key]
        }
        return (
            <TimePicker 
                value={timeValue}
                hourPlaceholder="HH"
                minutePlaceholder="mm"
                secondPlaceholder="ss"
                maxDetail="second"
                onChange={(e) => {
                    onRowChange({ ...row, [column.key]: e });
                }}
                disableClock={true}
                format="HH:mm:ss"
                />
        );
    };



