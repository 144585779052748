import React, { useEffect, useState } from "react";

import "./CmsChannelDetail.scss";

import icroundsearch from "../../../assets/images/ic_round-search.svg";

import video_icon from "../../../assets/images/Group.png";
import deleteicon from "../../../assets/images/delete_forever.png";
import option from "../../../assets/images/option.png";
import { Asset_Delete, Asset_Detail, Asset_search, DeleteallPoster, Promo_delete } from "../../../api/api";
import Posterupload from "../../../component/popup/Posterupload";
import Partitioncontent from "../../../component/content-update/Partitioncontent";
import UploadPoster from "../../../component/content-update/uploadposter";
import UploadPosters from "../../../component/content-update/uploadposter";
import { commonHeaders, image_url } from "../../../service/Constant";
import MetaDataView from "../../../component/metaData/MetaDataView";
import SearchView from "../../../component/asset-search/AssetSearch";
import VideoBlock from "../../../component/video-block/VideoBlock";

import PosterBlock from "../../../component/poster-block/PosterBlock";
import { posterimage } from "../../../service/commonFunc";
import { toast, ToastContainer } from "react-toastify";
import Videoplayer from "../../../component/player-block/Videoplayer";


function SearchData({ searchdata, AssetSearch, searchQuerys, assetdetail }) {

    // const someValue = useSelector(state => state);
    const assetData = JSON.parse(localStorage.getItem("AssetDetail"));
    const AssetPartnerDetail = JSON.parse(localStorage.getItem("AssetPartnerDetail"));

    let contentArray = searchdata
    let Query = searchQuerys
    // const [contentArray, setContentArray] = useState(searchdata);
    const [activeTab, setActiveTab] = useState("PROGRAM");
    const [categoryTab, setCategoryTab] = useState("VIDEO");
    const handleClose = () => setShowModal(false);
    const [partition, setpartition] = useState(false);
    const [posterpartion, setposterpartion] = useState(false);
    const [postermeta, setpostermeta] = useState(true);
    const [assetSignledata, setAssetSignledata] = useState(null);
    const [showplayer, setshowplayer] = useState(false);
    const programVideos = contentArray?.programs
    const [programVid, setprogramVid] = useState(null);




   
    // .filter(
    //     (item) => item.assetType === "VIDEO"
    // );
    const promosVideos = contentArray?.promos
    // .filter(
    //     (item) => item.assetType === "VIDEO" && item.videoType === "PROMOS"
    // );
    const fillersVideos = contentArray?.programs.filter(
        (item) => item.assetType === "VIDEO" && item.videoType === "FILLERS"
    );
    const PostersData = contentArray?.posters
    // .filter(
    //     (item) => item.assetType === "POSTERS"
    // );
    const SecondaryData = contentArray?.secondaries.filter(
        (item) => item.assetType === "SECONDARY"
    );
    const MetafileData = contentArray?.programs.filter(
        (item) => item.assetType === "METAFILE"
    );

    // useEffect(()=>{
    //     if(renderdataposter != null){
    //         if (contentArray != null) {
    //             const filteredItems = contentArray?.programs.filter(item => item.id == renderdataposter?.id);

    //             // setAssetSignledata(filteredItems[0])
    //           }
    //     }


    // },[videodata,searchdata])


    const handlecategoryTab = (tab) => {
        setpartition(false)
        setCategoryTab(tab);
        if (tab == "POSTERS") {
            setposterpartion(true)

        } else {
            setposterpartion(false)
        }
        setAssetSignledata(null)
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const opendata = (data, bool, e) => {


        //   setrenderdataposter(data);
  

        if (e && e.stopPropagation) {
            e.stopPropagation();
        }

        setpartition(true)
        setAssetSignledata(data)
        setpostermeta(bool)

    }

    const renderPoster = async (data) => {

        setAssetSignledata(data)
    }


    const assetdelete = async (assetid) => {
        setpartition(false)
        const deleteasset = await Asset_Delete(assetid);

        assetdetail(AssetPartnerDetail)
    }
    // const assetdetail = async (AssetPartnerDetail) => {

    //     const params = {
    //         partnerId: AssetPartnerDetail?.id,
    //         pageSize: "1000",

    //     };

    //     const AssetDetail = await Asset_Detail(params);

    //     // if (AssetDetail.status == true) {

    //     //     localStorage.setItem("AssetDetail", JSON.stringify(AssetDetail?.data));

    //     //     setContentArray(JSON.parse(localStorage.getItem("AssetDetail"))?.content)
    //     // }
    //     // dispatch(AssetDetailAction(AssetDetail?.data))
    //     if (assetSignledata != null) {
    //         const filteredItems = AssetDetail?.data?.content.filter(item => item.id == assetSignledata?.id);

    //         setAssetSignledata(filteredItems[0])
    //     }
    //     // setFilteredData(filteredItems);


    // };
    const [showModal, setShowModal] = useState(false);
    const [assetsingledata, setAssetsingledata] = useState(null);

    const handleShow = (data) => {

        setAssetsingledata(data)
        if (assetsingledata !== null) {
            setShowModal(true)
        }

    };




    const postertab = (data) => {
        setAssetSignledata(data)
    }
    const deleteallposter = async (event, id) => {
        event.stopPropagation();
        const response = await DeleteallPoster(id);
        if (response.data.status == true) {
            setTimeout(() => {
                assetdetail(AssetPartnerDetail)
            }, 1000);

        }

    }
    const databymeta = (data) => {
        setAssetSignledata(data)

    }
    const [expandMeta, setExpandMeta] = useState(true);
    const [ismetadata, setIsmetadata] = useState(true);
    const expandMetaView = () => {
        setExpandMeta(false)
        setIsmetadata(true)
    }

    const minmizemeta = () => {
        setExpandMeta(true)
    }
    const [searchQuery, setSearchQuery] = useState("");
    const [searchData, setSearchData] = useState(null);

    // const AssetSearch = async (searchQuery) => {

    //     const data = {
    //         id: AssetPartnerDetail?.id,
    //         query: searchQuery,
    //     }

    //     const partnerDataSearch = await Asset_search(data);
    //     setSearchData(partnerDataSearch)
    //     setExpandMeta(false)
    //     setIsmetadata(false)
    // };  

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            AssetSearch(searchQuery);
        }
    };
    const promodelete = async (assetid) => {

        const deleteasset = await Promo_delete(assetid);

        if (deleteasset.status == 200) {
            assetdetail(AssetPartnerDetail)
            toast.success("Promo deleted successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 5000
            });
            AssetSearch(Query)
        } else {

            toast.error("Something went wrong, please try after sometime", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 5000
            });
        }
    }
   
    const openplayerdata =(data)=>{
        setprogramVid(programVideos)
        setAssetSignledata(data)
      
        setshowplayer(true)
      }
     
    
      const closeplayer =()=>{
        setshowplayer(false)
      }
    return (
        <div className="">
{
showplayer ?
  (    <Videoplayer closepartition={() => closeplayer()} videodata={assetSignledata} assetdetail={() => assetdetail(AssetPartnerDetail)} programVideos={programVid} databymeta={databymeta} /> 
)
:

            expandMeta ? <div className="dashboard-content">
                <div className="top-content">
                    <div className="left-side">
                        <div className="breadcrumbs">
                            <ul>
                                <li>
                                    <a href='/asset' >Asset Management</a>

                                </li>
                                <li>
                                    <a href="/CmsChannelDetail">{AssetPartnerDetail?.name}</a>

                                </li>
                                <li className="active">Search Results</li>
                            </ul>
                        </div>

                    </div>
                    <div className="right-side">
                        <div className="icon-list">
                            <div className="search-box bg-white ">

                                <img src={icroundsearch} alt="Search" />


                                <input
                                    className="border-0"
                                    type="text"
                                    placeholder="Search"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    onKeyPress={handleKeyPress}
                                />

                            </div>
                        </div>
                    </div>

                </div>
                <div className="search-top">
                    <div>
                        <div className="grey-text16 text-black mb-24">Search results for “{Query}"</div>
                        <p className="grey-text16">Search Results : {(programVideos?.length * 2) + promosVideos?.length}</p>
                    </div>
                </div>

                <div className="row">
                    <div className={partition || posterpartion ? "col-8" : "col-12"}>
                        {categoryTab === "VIDEO" && (
                            <div className="channel-content part-heights ">

                                <div className="content-wrapper">
                                    <div>
                                        <div className="grey-text16 text-black mb-24"> 
                                        <span> Program ({programVideos && programVideos?.length}) </span><span className="px-4">
                                            Poster ({PostersData && PostersData?.length})</span> </div>

                                        <div className="grey-text16 text-black mb-24"></div>
                                        <div className="video-channel-list">

                                            {programVideos &&
                                                programVideos.map((data) => (
                                                    <VideoBlock
                                                        assetSignledata={assetSignledata}
                                                        key={data.id} // Add a unique key
                                                        data={data}
                                                        onVideoClick={opendata}
                                                        onUploadPosterClick={opendata}
                                                        onDeleteClick={assetdelete}
                                                        image_url={image_url}
                                                        openplayercomp={openplayerdata}
                                                    />
                                                ))}


                                        </div>





                                        <div className="grey-text16 text-black mb-24"> Promos ({promosVideos && promosVideos?.length})</div>

                                        <div className="video-channel-list">
                                            {promosVideos &&
                                                promosVideos.map((data, index) => {
                                                    return (

                                                        <div className="block"
                                                        onDoubleClick={()=>{openplayerdata(data);setprogramVid(promosVideos) }} 


                                                        key={index}>

                                                            <div className="img-block ">
                                                                <img src={video_icon} alt="" />
                                                            </div>

                                                            <div className="text-block">
                                                                <label>
                                                                    {data?.filename}
                                                                </label>
                                                            </div>
                                                            <div className="dropdown">
                                                                <a className="dropdown-button">
                                                                    <img src={option} />
                                                                </a>
                                                                <div className="dropdown-content">

                                                                    <a className="delete-button" onClick={() => promodelete(data.id)}>
                                                                        <span className="img-box">
                                                                            <img src={deleteicon} />
                                                                        </span>
                                                                        Delete
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    );
                                                })}
                                        </div>
                                    </div>


                                    {/* <div
                                      
                                    >
                                        {fillersVideos &&
                                            fillersVideos.map((data) => {
                                                return (
                                                    <div className="block">
                                                        <img src={video_icon} alt="" />
                                                        <label>Video 1</label>
                                                    </div>
                                                );
                                            })}
                                    </div> */}
                                </div>

                            </div>
                        )}
                        {/* {categoryTab === "POSTERS" && (
                            <div className="channel-content">
                                <div className="channel-top">
                                    <div className="left-side"></div>
                                    <div className="right-side">
                                        <input
                                            type="file"
                                            id="fileInput"
                                            style={{ display: "none" }}
                                            accept="image/*"
                                            onChange={(e) => handleFileChange(e)}
                                        />
                                        <a
                                            className="mx-2"
                                            onClick={() => document.getElementById("fileInput").click()}
                                        >
                                            <img src={uploadicon} />
                                        </a>

                                    </div>
                                </div>
                                <div className="content-wrapper">


                                    <div className="video-channel-list">
                                        {programVideos &&
                                            programVideos.map((data) => {
                                                return (
                                                    <div className="block" onClick={() => postertab(data)} >
                                                        <div className="img-block"><img src={Groupicon} alt="" /></div>
                                                        <div className="text-block"><label>{data?.metadata == null ? data.filename : data?.metadata?.title == null ? data.filename :data?.metadata?.title}</label></div>
                                                        <div className="dropdown">
                                                            <a onClick={(e) => deleteallposter(e, data.id)} className="delete-poster"><img src={deleteicon} /></a>

                                                        </div>
                                                    </div>

                                                );
                                            })}
                                    </div>
                                </div>
                            </div>
                        )}
                        {categoryTab === "SECONDARY" && (
                            <div className="channel-content">
                                <div className="channel-top">
                                    <div className="left-side"></div>
                                    <div className="right-side">
                                        <input
                                            type="file"
                                            id="fileInput"
                                            style={{ display: "none" }}
                                            onChange={(e) => handleFileChange(e)}
                                        />
                                        <a
                                            className="mx-2"
                                            onClick={() => document.getElementById("fileInput").click()}
                                        >
                                            <img src={uploadicon} />
                                        </a>

                                    </div>
                                </div>
                                <div className="content-wrapper">
                                    <div className="video-channel-list">
                                        {SecondaryData &&
                                            SecondaryData.map(() => {
                                                return (
                                                    <div className="block">
                                                        <img src={video_icon} alt="" />
                                                        <label>Video 1</label>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            </div>
                        )}
                        {categoryTab === "METAFILE" && (
                            <div className="channel-content">
                                <div className="channel-top">
                                    <div className="left-side"></div>
                                    <div className="right-side">
                                        <input
                                            type="file"
                                            id="fileInput"
                                            style={{ display: "none" }}
                                            accept=".xls, .xlsx, .csv"
                                            onChange={(e) => handleFileChange(e)}
                                        />
                                        <a
                                            className="mx-2"
                                            onClick={() => document.getElementById("fileInput").click()}
                                        >
                                            <img src={uploadicon} />
                                        </a>

                                    </div>
                                </div>
                                <div className="video-channel-list">
                                    {MetafileData &&
                                        MetafileData.map((data) => {
                                            return (
                                                <div className="meta-block border">
                                                    <div className="p-3 bg-light">
                                                        <img src={metafile} alt="" />
                                                    </div>
                                                    <div className="px-1 m-auto">
                                                        <label>{data?.metadata == null ? data.filename : data?.metadata?.title == null ? data.filename :data?.metadata?.title}</label>
                                                    </div>
                                                </div>

                                            );
                                        })}
                                </div>
                            </div>
                        )} */}




                    </div>
                    {partition && (
                        postermeta ?
                            <UploadPosters videodata={assetSignledata} assetdetail={() => assetdetail(AssetPartnerDetail)} closepartition={() => setpartition(false)} />
                            :
                            <Partitioncontent assetdetail={() => assetdetail(AssetPartnerDetail)} expandMetaView={() => expandMetaView()} partition={partition} videodata={assetSignledata} closepartition={() => setpartition(false)} />


                    )}

                    {/* {posterpartion && (

                        <UploadPosters videodata={assetSignledata} assetdetail={() => assetdetail(AssetPartnerDetail)} />
                    )


                    } */}
                </div>


            </div> : ismetadata ?
                <MetaDataView closepartition={() => minmizemeta()} videodata={assetSignledata} assetdetail={() => assetdetail(AssetPartnerDetail)} programVideos={programVideos} databymeta={databymeta} /> :
                <SearchView searchQuery={searchQuery} closepartition={() => minmizemeta()} videodata={assetSignledata} assetdetail={() => assetdetail(AssetPartnerDetail)} programVideos={programVideos} databymeta={databymeta} searchdata={searchData} />
            } 
        </div>
    );
}

export default SearchData;
