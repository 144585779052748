import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import "./ChannelActions.scss";
import Dashboardheader from "../../component/dashboard-header";
import SlideBar from "../../component/sidebar/sidebar";
import donecheck from "../../assets/images/donecheck.png";
import downarrow from "../../assets/images/down-arrow.svg";
import pending from "../../assets/images/pending.png";
import error from "../../assets/images/error.png";
import warning from "../../assets/images/warning.png";
import processing from "../../assets/images/processing.png";
import filter_alt from "../../assets/images/sortarrow.png";
import desc from "../../assets/images/desc.png";
import rightArrow from "../../assets/images/rightvector.png";
import leftArrow from "../../assets/images/Vector.png";
import filterimg from "../../assets/images/sort.png";
import override from "../../assets/images/override.png";
import refresh from "../../assets/images/refresh.png";
import {
  DateStamp,
  TimeConverter,
  capitalizeFirstLetter,
  convertTimestampToTime,
  isLogin,
} from "../../service/commonFunc";
import {
  AssetPublish,
  AssetTranscode,
  AssetValidate,
  Asset_Detail,
  FillerList,
  ProgramsList_By_Partner,
  PromosList_By_Partner,
} from "../../api/api";
import Validatepopup from "../../component/popup/asset-action-popup/Validatepopup";
import { useEffect } from "react";
import icroundsearch from "../../assets/images/ic_round-search.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { baseURL } from "../../service/API_URL";
import { getInstantData } from "../../api/apiMethod";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactTooltip from "react-tooltip";
import { permissionDetails } from "../../utility/localStoageData";
// import ReactTooltip from 'react-tooltip';

export default function ChannelActionsComponent() {
  const AssetActionDetail = JSON.parse(localStorage.getItem("AssetDetail"));
  const AssetPartnerDetail = JSON.parse(
    localStorage.getItem("AssetPartnerDetail")
  );
  const [actionDetail, setActionDetail] = useState([]);
  const [actionData, setActionData] = useState([]);

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuerys, setSearchQuery] = useState("");
  const [searchButtonClicked, setSearchButtonClicked] = useState(false);
  const [rotation, setRotation] = useState(0);
  const typeOptions = ["PROGRAM", "PROMO", "FILLER"];
  const [selectedType, setSelectedType] = useState("PROGRAM");
  const [permission, setpermission] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [pageJump, setPageJump] = useState(0);
  const [currentSortOrder, setCurrentSortOrder] = useState({
    sortBy: "filename", // Default sort order for 'filename' column
    sortOrder: "asc", // Default sort order: ascending
  });
  
  async function fetchData(currentPage) {
    // const  = {
    //   partnerId: AssetPartnerDetail?.id,
    //   pageSize: itemsPerPage,
    //   pageNumber: currentPage - 1,
    //   ...(searchQuerys ? { searchQuery: searchQuerys } : null)
    // };
    
    const params = {
      partnerId: AssetPartnerDetail?.id,
      pageSize: itemsPerPage,
      sortBy: currentSortOrder.sortBy,
      sortOrder: currentSortOrder.sortOrder,
      pageNumber: currentPage - 1,
      ...(searchQuerys ? { searchQuery: searchQuerys } : null),
    };

    try {
      const assetDetail = await ProgramsList_By_Partner(params);
      const fillerDetail = await FillerList(params);
      const PromoDetail = await PromosList_By_Partner(params);

      if (selectedType == "PROGRAM") {
        if (assetDetail.status === true) {
          localStorage.setItem("AssetDetail", JSON.stringify(assetDetail.data));
          setActionDetail(assetDetail.data?.content);
          setActionData(assetDetail.data);
          setTotalPages(assetDetail?.data?.totalPages);   
          setCurrentPage(assetDetail?.data?.number + 1);        
        }
      } else if (selectedType == "FILLER") {
        if (fillerDetail.status === true) {
          localStorage.setItem(
            "fillerDetail",
            JSON.stringify(fillerDetail.data)
          );
          setActionDetail(fillerDetail.data?.content);
          setActionData(fillerDetail.data);
          setTotalPages(assetDetail?.data?.totalPages);
          setCurrentPage(assetDetail?.data?.number + 1);
        }
      } else if (selectedType == "PROMO") {
        if (PromoDetail.status === true) {
          localStorage.setItem(
            "fillerDetail",
            JSON.stringify(PromoDetail.data)
          );
          setActionDetail(PromoDetail.data?.content);
          setActionData(PromoDetail.data);
          setTotalPages(assetDetail?.data?.totalPages);
          setCurrentPage(assetDetail?.data?.number + 1);
        }
      }
      setSearchButtonClicked(false);

    } catch (error) {
      console.error(error);
    }
  }

  // Function to check if any object has transcodingStatus as "IN_PROGRESS"
  const checkTranscodingInProgress = (data) => {

    for (let i = 0; i < data.length; i++) {

      if (data[i].transcodingStatus === "SUBMITTED" || data[i].transcodingStatus === "IN_PROGRESS") {
        return true; // Found IN_PROGRESS status

      }
    }
    return false; // Did not find IN_PROGRESS status
  }
  


  
  // useEffect(() => {

  //   setCheckedIds([]);

  //   fetchData();
  
  //   const intervalId = setInterval(() => {
  //     console.log("AWSEDRFTYUIOP",actionDetail)
  //     if (checkTranscodingInProgress(actionDetail)) {
  //       fetchData();
  //     }


  //   }, 3000);

  //   return () => {
  //     clearInterval(intervalId);
  //   };
   
  // }, [currentPage, searchButtonClicked, selectedType, currentSortOrder]);
  const getPermission = () => {
    let findEdit = permissionDetails().includes("ASSET_MANAGEMENT_EDIT");
    if(findEdit) setpermission(true)
       else setpermission(false);
   }

  useEffect(() => {
    getPermission();
    setCheckedIds([]);
    fetchData(currentPage);
  }, [currentPage, searchButtonClicked, selectedType, currentSortOrder]);
  
  useEffect(() => {
    const intervalId = setInterval(() => {
 
      if (actionDetail.length > 0 && checkTranscodingInProgress(actionDetail)) {
        fetchData();
      }
    }, 30000);
  
    return () => {
      clearInterval(intervalId);
    };
  }, [actionDetail]);
  
  const blankValue = (
    <>
      <img src={pending} alt="Error Image" /> pending
    </>
  );
  const processingValue = (
    <>
      <img src={processing} alt="Error Image" /> processing..
    </>
  );
  const errorValue = (
    <>
      <img src={error} alt="Error Image" /> Error Summary
    </>
  );
  const OVERRIDDEN = (
    <>
      <img src={override} /> Over Riden
    </>
  );

  const [validatepopup, setvalidatepopup] = useState(false);

  const [transcodeActive, setTranscodeActive] = useState(false);
  const [validateActive, setValidateActive] = useState(false);
  const [publishActive, setPublishActive] = useState(false);
  const [validatedata, setValidate] = useState(null);
  const [assetSignledata, setAssetSignledata] = useState(null);

  const actionStatus = (status) => {
    if (status === "NOT_DONE") {
      return "pending";
    }
    if (status === "ERROR") {
      return "error";
    }

    if (status == "IN_PROGRESS" || status == "SUBMITTED") {
      return "processing ";
    }
    if (status === "DONE") {
      return "complete";
    }
    if (status == null) {
      return "pending ";
    }
    if (status == "OVERRIDDEN") {
      return "OverRiden";
    }
  };

  const errorstatus = (status) => {
    if (status === "NOT_DONE") {
      return blankValue;
    }
    if (status === "OVERRIDDEN") {
      return OVERRIDDEN;
    }
    if (status === "ERROR") {
      return errorValue;
    }

    if (status == "IN_PROGRESS" || status == "SUBMITTED") {
      return processingValue;
    }
    if (status === "DONE") {
      return blankValue;
    }
    if (status == null) {
      return blankValue;
    }
  };

  const [checkedIds, setCheckedIds] = useState([]);

  // const handleCheckboxChange = (data) => {
  //   if (data?.transcoded === false) {
  //     setTranscodeActive(true);
  //   } else if (data?.validated === false) {
  //     setValidateActive(true);
  //   } else {
  //     setPublishActive(true);
  //   }

  //   if (checkedIds.includes(data.id)) {
  //     setCheckedIds(checkedIds.filter((id) => id !== data.id));
  //   } else {
  //     setCheckedIds([...checkedIds, data.id]);
  //   }
  // };

  const validationation = async (data) => {
    
    if(data?.validationStatus != null && data?.validationStatus != "Completed"){
      const actionData = {
        //   actionType: actionType,
        assetIds: [data.id],
      };

      const validate = await AssetValidate(actionData);
  
      setValidate(validate?.data?.data[0]);
      setAssetSignledata(data);
    setvalidatepopup(true);
  };
  }
  const AssetActions = async (actionType, state) => {
    const actionData = {
      actionType: actionType,
      assetIds: checkedIds,
      videoType: selectedType,
      transcodingProvider: state ? "AWS_MEDIA_CONVERT" : "R_MEDIA_ENCODER",
    };
    
    if (actionType == "TRANSCODE") {
      const transcode = await AssetTranscode(actionData);
      if (transcode?.data?.status == true) {
        toast.success("Transcode operation initiated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
        setCheckedIds([])
        setChecked(false);
        setDisabledFiled(false);
        setPublishActive(false);
        fetchData(currentPage);
      } else {
        toast.error("Something went wrong, please try after sometime", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
      }
    } else if (actionType == "VALIDATE") {
      const validate = await AssetValidate(actionData);
      if (validate.data.status == true) {
        setValidate(validate?.data?.data[0]);
        toast.success("Content validated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
        setCheckedIds([])
        setChecked(false);
        setDisabledFiled(false);
        setPublishActive(false);
        fetchData(currentPage);
      } else {
        toast.error("Something went wrong, please try after sometime", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
      }
    // } else if (actionType == "PUBLISH") {
    } else if (actionType == "RUNN_MEDIA" || actionType == "AWS") {
      const publish = await AssetPublish(actionData);

      if (publish?.data?.status == true) {
        toast.success("Content published successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
        setCheckedIds([])
        setChecked(false);
        setDisabledFiled(false);
        setPublishActive(false);
        fetchData(currentPage);
      } else {
        toast.error("Something went wrong, please try after sometime", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
      }
    }
    setCheckedIds([])
    setChecked(false)
  };

  const [sortOrders, setSortOrders] = useState({});
  useEffect(() => {
    isLogin();
    const initialSortOrders = {
      filename: "asc", // Default sort order for 'filename' column
      duration: "", // Default sort order for 'duration' column
      dateUploaded: "", // Default sort order for 'dateUploaded' column
      dateValidated: "", // Default sort order for 'dateUploaded' column
      datePublished: "", // Default sort order for 'dateUploaded' column
      dateTranscoded: "", // Default sort order for 'dateUploaded' column
    };
    setSortOrders(initialSortOrders);
  }, [selectedType]);

  const handleSort = async (field) => {
    let currentSortOrder = "desc"; // Default sort order
    let sortField = field
    // Check if the field was previously sorted and toggle the sort order
    if (sortOrders[field]) {
      currentSortOrder = sortOrders[field] === "asc" ? "desc" : "asc";
    }

    const params = {
      partnerId: AssetPartnerDetail?.id,
      sortBy: field,
      sortOrder: currentSortOrder,
      pageNumber: currentPage - 1,
      pageSize: itemsPerPage,
    };
    setCurrentSortOrder({
      sortBy: sortField,
      sortOrder: currentSortOrder
    })

    let updatedData = {};

    if (selectedType === "PROGRAM") {
      updatedData = await ProgramsList_By_Partner(params);
    } else if (selectedType === "FILLER") {
      updatedData = await FillerList(params);
    } else if (selectedType === "PROMO") {
      updatedData = await PromosList_By_Partner(params);
    }

    if (updatedData.status === true) {
      const newData = updatedData.data;
      const newDataContent = newData?.content || [];

      localStorage.setItem(
        selectedType === "PROGRAM"
          ? "AssetDetail"
          : selectedType === "FILLER"
            ? "fillerDetail"
            : "PromoDetail",
        JSON.stringify(newData)
      );
      setActionDetail(newDataContent);

      const newSortOrders = Object.keys(sortOrders).reduce((acc, key) => {
        if (key === field) {
          acc[key] = currentSortOrder;
        } else {
          acc[key] = ""; // Reset other sort orders
        }
        return acc;
      }, {});

      setSortOrders(newSortOrders);
    }
  };

  const getSortIcon = (field) => {
    if (sortOrders[field] == "") {
      return <img src={filterimg} alt="filterimg" />;
    }
    if (sortOrders[field] == "desc") {
      return <img src={desc} alt="desc" />; // Image for ascending order
    } else {
      return <img src={filter_alt} alt="Ascending" />; // Image for ascending order
    }
  };
  //   const handleSearch = () => {
  // if(searchQuerys !== ""){
  //     setSearchButtonClicked(true);

  // }
  //   };
  const handleKeyPress = (e) => {

    if (e.key === "Enter") {
      // if (searchQuerys !== "") {
        setSearchButtonClicked(true);
      // }
    }
  };

  const [disabledFiled, setDisabledFiled] = useState(false);

  const [publishvalidation, setPublishvalidation] = useState(true);

  const handleCheckboxChange = (e, data, index) => {
    const checked = e.target.checked;
    // Update checkedIds based on checkbox status
    let updatedCheckedIds = [...checkedIds];
    if (checked) {
      if (data?.transcodingStatus == null) {
          setTranscodeActive(true);
          setValidateActive(false);
          setPublishActive(false);
          setDisabledFiled(data?.transcodingStatus);
      } else if(data?.transcodingStatus == "SUBMITTED" ||
      data?.transcodingStatus == "IN_PROGRESS") {
          setTranscodeActive(false);
          setValidateActive(false);
          setPublishActive(false);
          setDisabledFiled(data?.transcodingStatus);
      } else if(data?.transcodingStatus == "ERROR") {
          setTranscodeActive(true);
          setValidateActive(false);
          setPublishActive(false);
          setDisabledFiled(data?.transcodingStatus);
      } else if(data?.transcodingStatus == "COMPLETED") {
          if (data?.validationStatus == null || 
            data?.validationStatus == "ERROR" 
          ) {
            setTranscodeActive(false);
            setValidateActive(true);
            setPublishActive(false);
            setDisabledFiled({
              transcodingStatus: data?.transcodingStatus,
              validationStatus: data?.validationStatus
            });
          } else if(data?.validationStatus == "OVERRIDDEN") {
            setTranscodeActive(false);
            setValidateActive(true);
            setPublishActive(true);
            setDisabledFiled({
              transcodingStatus: data?.transcodingStatus,
              validationStatus: data?.validationStatus
            });
          } else if(data?.validationStatus == "COMPLETED"){
            if(data?.published == false || data?.published == null){
              setTranscodeActive(false);
              setValidateActive(false);
              setPublishActive(true);
              setDisabledFiled({
                transcodingStatus: data?.transcodingStatus,
                validationStatus: data?.validationStatus,
                published: data?.published
              });
            } else if(data?.published == true || data?.published == "COMPLETED"){
              setTranscodeActive(false);
              setValidateActive(false);
              setPublishActive(false);
              setDisabledFiled({
                transcodingStatus: data?.transcodingStatus,
                validationStatus: data?.validationStatus,
                published: data?.published
              });
            }
          }
      } 
      updatedCheckedIds.push(data.id);
    }else{
      updatedCheckedIds = updatedCheckedIds.filter((id) => id !== data.id);
    }

    // if (checked) {
    //   if (data.transcoded == false) {
    //     if (
    //       data.transcodingStatus == "SUBMITTED" ||
    //       data.transcodingStatus == "IN_PROGRESS"
    //     ) {
    //       toast.error("Transcoding is in progress", {
    //         position: toast.POSITION.BOTTOM_RIGHT,
    //         autoClose: 5000,
    //       });
    //     } else {
    //       setTranscodeActive(true);
    //       setValidateActive(false);
    //       setPublishActive(false);
    //       setDisabledFiled("transcode");
    //     }
    //   } else {
    //     if (data.validated == false) {
    //       setTranscodeActive(false);
    //       if (data.validationStatus == "ERROR" || data.validationStatus == null) {
    //         setPublishvalidation(false);
    //         setDisabledFiled("novalidation");
    //         setValidateActive(true);
    //         setPublishActive(false);
    //       }
    //     } else if (data.validated == true) {
    //       if (data.published == false) {

    //         if (data.validationStatus == "OVERRIDDEN") {
    //           if (publishvalidation) {
    //             setPublishActive(true);
    //           }
    //           setValidateActive(true);
  
    //           setDisabledFiled("novalidation");
    //         } else
    //         {
    //           setDisabledFiled("publish");
    //           setTranscodeActive(false);
    //           setValidateActive(false);
    //           setPublishActive(true);
    //         }

           
    //       } else if (data.published == true) {
    //         if (publishvalidation) {
    //           // setPublishActive(true);
    //         }
    //         setValidateActive(true);

    //         setDisabledFiled("novalidation");
    //       } else
    //       {
    //         setTranscodeActive(false);
    //         setValidateActive(false);
    //         setPublishActive(false);
          
    //       }
    //     }
    //   }
    //   updatedCheckedIds.push(data.id);
    // } else {
    //   updatedCheckedIds = updatedCheckedIds.filter((id) => id !== data.id);
    // }

    setCheckedIds(updatedCheckedIds);
    if (updatedCheckedIds.length == 0) {
      setChecked(false);
      setDisabledFiled(false);
      setPublishActive(false);
    } else {
      setChecked(true);
      if (updatedCheckedIds.length === actionDetail.length) {
        setAllcheck(false)
    } else {
        setAllcheck(true)
    }
    }
  };

  const navigate = useNavigate();
  const navigateBack = () => {
    navigate(-1);
  };
  const [isChecked, setChecked] = useState(false);
  const [allcheck, setAllcheck] = useState(false);
  const handleSelectAllClick = () => {
    let updatedCheckedIds = [...checkedIds];
    if (checkedIds.length == 0){
      if (isChecked) {
        setCheckedIds([])
        setChecked(false);
        setDisabledFiled(false);
        setPublishActive(false);
        setAllcheck(false)
      }else{
        function findValue(array, condition) {
          for (let i = 0; i < array.length; i++) {
            if (condition(array[i])) {
              return array[i];
            }
          }
          return null; // or undefined, or any default value you'd like to return if the condition is not met
        }
        const checkedData = findValue(actionDetail, (element) => element?.transcoded === false);
        actionDetail?.map((data) => {
          if (data?.validationStatus == checkedData.validationStatus) {
            updatedCheckedIds.push(data.id);
            setDisabledFiled(data?.validationStatus)
            // setCheckedIds(updatedCheckedIds)
            setChecked(true);
          }else{
            setCheckedIds([])
            setChecked(false);
            setDisabledFiled(false);
            setPublishActive(false);
          }
        })
        setChecked(true);
        setCheckedIds(updatedCheckedIds);
      }
    }else{
      actionDetail?.map((data, index) => {
        if ((data.validationStatus == "IN_PROGRESS" && true) ||
          (disabledFiled == false && false) ||
          (disabledFiled == "transcode" && data.transcoded == true) ||
          data.transcodingStatus == "IN_PROGRESS" ||
          data.transcodingStatus == "SUBMITTED" ||
          (disabledFiled == "novalidation" && ((data.transcoded == false && data.validated == false) ||
          (data.transcoded == true && data.validated == true && data.published == true))) ||
          (disabledFiled == "publish_overriden" && data.validated == false && data.validationStatus != "OVERRIDDEN"))
          {
              
        } else {
          setChecked(true);
          if (checkedIds.length == 1) {
            updatedCheckedIds.push(data.id);
            setCheckedIds(updatedCheckedIds)
          } else {
            setCheckedIds([])
            setChecked(false);
            setDisabledFiled(false);
            setPublishActive(false);
          }
        }
  
  
        if(isChecked){
          setAllcheck(false)
      }
  
      })
    }
  };

  const [disabled, setDisabled] = useState(false);
const rotations =()=>{
  setRotation(rotation + 360)
  if (!disabled) {
    setDisabled(true);

    // Add your logic for fetching data here
    // You can also update the rotation state if needed
    setRotation(rotation + 360);

    // Enable the button after 5 seconds
    setTimeout(() => {
      setDisabled(false);
    }, 5000);
  }
}


// Functions is used to set checkbox enable disable

const isNullStatusAndNullDisabledField = (data, disabledFiled) => {
  return data?.transcodingStatus != null && disabledFiled == null;
};

const isNotSubmittedOrInProgress = (data, disabledFiled) => {
  return (
    (data?.transcodingStatus !== "SUBMITTED" && disabledFiled === "SUBMITTED") ||
    (data?.transcodingStatus !== "IN_PROGRESS" && disabledFiled === "IN_PROGRESS")
  );
};

const isNotError = (data, disabledFiled) => {
  return data?.transcodingStatus !== "ERROR" && disabledFiled === "ERROR";
};

const isNotCompletedWithNullOrError = (data, disabledFiled) => {
  return (
    data?.transcodingStatus !== "COMPLETED" &&
    disabledFiled?.transcodingStatus === "COMPLETED" &&
    (data?.validationStatus === null || data?.validationStatus === "ERROR")
  );
};

const isNotCompletedWithOverridden = (data, disabledFiled) => {
  return (
    data?.transcodingStatus !== "COMPLETED" &&
    disabledFiled?.transcodingStatus === "COMPLETED" &&
    data?.validationStatus === "OVERRIDDEN"
  );
};

const isNotCompletedWithCompleted = (data, disabledFiled) => {
  return (
    data?.transcodingStatus !== "COMPLETED" &&
    disabledFiled?.transcodingStatus === "COMPLETED" &&
    data?.validationStatus === "COMPLETED"
  );
};

const isPublishedConditionMet = (data, disabledFiled) => {
  const isFalseOrNull = 
    (data?.published !== false) &&
    (disabledFiled?.published === false);
  
  const isTrueOrCompleted = 
    (data?.published !== true) &&
    (disabledFiled?.published === true);

  return isFalseOrNull || isTrueOrCompleted;
};

const shouldDisableCheckbox = (data, disabledFiled) => {
  if (!data) return false;

  return (
    (disabledFiled && false) || 
    isNullStatusAndNullDisabledField(data, disabledFiled) ||
    isNotSubmittedOrInProgress(data, disabledFiled) ||
    isNotError(data, disabledFiled) ||
    isNotCompletedWithNullOrError(data, disabledFiled) ||
    isNotCompletedWithOverridden(data, disabledFiled) ||
    isNotCompletedWithCompleted(data, disabledFiled) ||
    isPublishedConditionMet(data, disabledFiled)
  );
};

// End of the functions is used to set checkbox enable disable

  const setPage = (e) => {
    if (e.target.value > 0 && e.target.value <= totalPages) {
      setPageJump(e.target.value);
    }
  }

  const handlePageKeyPress = (event) => {
    event.preventDefault();
    fetchData(pageJump)
  }
  
  return (
    <div className="content-body">
      <div className="dashboard-content">
        <div className="top-content">
          <div className="left-side">
            <div className="breadcrumbs bread-action">
              <ul>
                <li>
                  <a href="/asset">Asset Management</a>
                </li>
                <li>
                  <a onClick={navigateBack}>{AssetPartnerDetail?.name}</a>
                </li>
                <li className="active">Actions</li>
              </ul>
            </div>
          </div>
          <div>

          </div>
          <div className="right-side">

            <div className="search-box bg-white p-1 ">
              <img src={icroundsearch} alt="Search" />

              <input
                className="border-0"
                type="text"
                placeholder="Search"
                value={searchQuerys}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyPress={handleKeyPress}
              />
            </div>
          </div>
        </div>
        <div className="action-category">
        <button
      className={`refresh-button m-2 border-1 `}
      onClick={() =>{fetchData();rotations();}}
     disabled={disabled}
    >
      <img className={`${rotation !== 0 ? 'rotate' : ''}`} 
       style={{ transform: `rotate(${rotation}deg)` }}
      src={refresh} alt="refresh" />
    </button>
    {
      permission? 
          <ul>
            <li
              className={
                transcodeActive && checkedIds.length > 0 ? "active" : "disable"
              }
            >
              <div className="dropdown action-dropdown">
                <a
                  className="border-0"
                  onClick={() =>
                    transcodeActive && checkedIds.length > 0
                      ? AssetActions("TRANSCODE", true)
                      : null
                  }
                >
                  Transcode
                  <span>
                    &#9660;
                  </span>
                </a>

                {transcodeActive && checkedIds.length > 0 && (
                  <div className="dropdown-content w-auto">
                    <button
                      className="transoption"
                      onClick={() =>
                        transcodeActive && checkedIds.length > 0
                          ? AssetActions("TRANSCODE", false)
                          : null
                      }
                    >
                      Runn Media Encoder
                    </button>
                    <button
                      className="transoption"
                      onClick={() =>
                        transcodeActive && checkedIds.length > 0
                          ? AssetActions("TRANSCODE", true)
                          : null
                      }
                    >
                      AWS MediaConvert
                    </button>
                  </div>
                )}


              </div>
            </li>
            <li
              className={
                validateActive && checkedIds.length > 0 ? "active" : "disable"
              }
            >
              <a
                onClick={() =>
                  validateActive && checkedIds.length > 0
                    ? AssetActions("VALIDATE")
                    : null
                }
              >
                Validate
              </a>
            </li>
            <li
              className={
                publishActive && checkedIds.length > 0 ? "active" : "disable"
              }
            >
              <div className="dropdown action-dropdown">
                <a
                  className="border-0"
                >
                  Publish
                  <span>
                    &#9660;
                  </span>
                </a>
                {
                  publishActive && checkedIds.length > 0 && (
                    <div className="dropdown-content w-auto">
                      <button
                        className="transoption"
                        onClick={() =>
                          publishActive && checkedIds.length > 0
                            ? AssetActions("RUNN_MEDIA")
                            : null
                        }
                      >
                        Runn Playout
                      </button>
                      <button
                        className="transoption"
                        onClick={() =>
                          publishActive && checkedIds.length > 0
                            ? AssetActions("AWS")
                            : null
                        }
                      >
                        AWS Mediatailor
                      </button>
                    </div>
                  )
                }
              </div>
              {/* <a
                onClick={() =>
                  publishActive && checkedIds.length > 0
                    ? AssetActions("PUBLISH")
                    : null
                }
              >
                Publish
              </a> */}
            </li>
          </ul>
      :
      ''
    }
        </div>
        <div className="table-checkbox">
          <div className="table">
            <table className="striped-table">
              <thead>
                <tr>
                  {permission? 
                  <th>
                    <input
                      type="checkbox"
                      id="selectAllCheckbox"
                      checked={isChecked}
                      onChange={()=>handleSelectAllClick()}
                    />
                <label className={allcheck ?"check":null }htmlFor="selectAllCheckbox">&nbsp;</label>
                    {/* <label for="1">&nbsp;</label> */}
                  </th>
                  :
                  ''
                }
                  <th>
                    {selectedType} Name{" "}
                    <a
                      className="filter-icon mx-2"
                      onClick={() => handleSort("filename")}
                    >
                      {getSortIcon("filename")}
                    </a>
                  </th>
                  <th>
                    {/* Type */}

                    {/* <select className="mx-1 border-0" style={{width:"20px", cursor:"pointer"}}
                    value={selectedType}
                    onChange={(e) => setSelectedType(e.target.value)}
                  >
                    <option  value="">All type</option>
                    {typeOptions.map((option, index) => (
                      <option  key={index} value={option}>
                        {option}
                      </option>
                    ))}


  
                  </select> */}

                    <div
                      className="dropdown p-0"
                      style={{
                        position: "relative",
                        top: 0,
                        cursor: "pointer",
                      }}
                    >
                      <span>Type </span> <img src={downarrow} />
                      <a className="dropdown-button"></a>
                      <div className="dropdown-content w-auto h-auto p-0 m-0">
                        {typeOptions.map((option, index) => (
                          <a
                            key={index}
                            value={option}
                            className={
                              selectedType == option
                                ? " dropdownfilter active px-2"
                                : "dropdownfilter px-2"
                            }
                            onClick={() => setSelectedType(option)}
                          >
                            {option}
                          </a>
                        ))}
                      </div>
                    </div>
                  </th>
                  <th>
                    Duration{" "}
                    <a
                      className="filter-icon mx-2"
                      onClick={() => handleSort("duration")}
                    >
                      {getSortIcon("duration")}
                    </a>
                    <br /> hh:mm:ss
                  </th>
                  <th>
                    Date Uploaded{" "}
                    <a
                      className="filter-icon mx-2"
                      onClick={() => handleSort("dateUploaded")}
                    >
                      {getSortIcon("dateUploaded")}
                    </a>
                    <br />
                    dd/mm/yy{" "}
                  </th>
                  <th>
                    Transcode{" "}
                    <a
                      className="filter-icon mx-2"
                      onClick={() => handleSort("dateTranscoded")}
                    >
                      {getSortIcon("dateTranscoded")}
                    </a>
                  </th>
                  <th>
                    VALIDATE{" "}
                    <a
                      className="filter-icon mx-2"
                      onClick={() => handleSort("dateValidated")}
                    >
                      {getSortIcon("dateValidated")}
                    </a>
                  </th>
                  <th>
                    PUBLISH{" "}
                    <a
                      className="filter-icon mx-2"
                      onClick={() => handleSort("datePublished")}
                    >
                      {getSortIcon("datePublished")}
                    </a>
                  </th>
                </tr>
              </thead>
              <tbody>
                {actionDetail &&
                  actionDetail?.map((data, index) => {
                    return (
                      <tr
                        key={index}
                        className={index % 2 === 0 ? "even" : "odd"}
                      >
                        {
                          permission?
                        <td>
                          <input
                            id={data.id}
                            type="checkbox"
                            value=""
                            onChange={(e) =>
                              data?.active !== false ?
                              handleCheckboxChange(e, data, index):null
                            }
                            checked={checkedIds.includes(data?.id)}
                            disabled={data?.active !== false ?
                              data?.published == true && data?.transcodingStatus == "COMPLETED" && data?.validationStatus == "COMPLETED"?
                              true:
                              shouldDisableCheckbox(data, disabledFiled)
                              :
                              true
                            }
                            // disabled = {
                            //   (disabledFiled && false) || 
                            //   (data?.transcodingStatus != null && disabledFiled == null) ||
                            //   ((data?.transcodingStatus != "SUBMITTED" && disabledFiled == "SUBMITTED") || (data?.transcodingStatus != "IN_PROGRESS" && disabledFiled == "IN_PROGRESS")) ||
                            //   (data?.transcodingStatus != "ERROR" && disabledFiled == "ERROR")||
                            //   (((data?.transcodingStatus != "COMPLETED" && disabledFiled.transcodingStatus == "COMPLETED") && (data.validationStatus != null && disabledFiled.validationStatus == null)) || ((data.transcodingStatus != "COMPLETED" && disabledFiled.transcodingStatus == "COMPLETED") && (data.validationStatus != "ERROR" && disabledFiled.validationStatus == "ERROR"))) ||
                            //   ((data?.transcodingStatus != "COMPLETED" && disabledFiled.transcodingStatus == "COMPLETED") && (data.validationStatus != "OVERRIDDEN" && disabledFiled.validationStatus == "OVERRIDDEN")) ||
                            //   ((data?.transcodingStatus != "COMPLETED" && disabledFiled.transcodingStatus == "COMPLETED") && (data.validationStatus != "COMPLETED" && disabledFiled.validationStatus == "COMPLETED") && ((data.published != false  && disabledFiled.published == false) || (data.published != null && disabledFiled.published == null))) ||
                            //   ((data?.transcodingStatus != "COMPLETED" && disabledFiled.transcodingStatus == "COMPLETED") && (data.validationStatus != "COMPLETED" && disabledFiled.validationStatus == "COMPLETED") && ((data.published != true && disabledFiled.published == true) || (data.published != "COMPLETED" && disabledFiled.published == "COMPLETED")))
                              
                            // }
                            // disabled={
                            //   (data.validationStatus != disabledFiled && typeof disabledFiled == "string") ||
                            //   (data.validationStatus == "IN_PROGRESS" && true) ||
                            //   // (disabledFiled == false && false) ||
                            //   (disabledFiled == "transcode" && data.transcoded == true) ||
                            //   data.transcodingStatus == "IN_PROGRESS" ||
                            //   data.transcodingStatus == "SUBMITTED" ||
                            //   (disabledFiled == "novalidation" && ((data.transcoded == false && data.validated == false) ||
                            //   (data.transcoded == true && data.validated == true && data.published == true))) ||
                            //   (disabledFiled == "publish_overriden" && data.validated == false && data.validationStatus != "OVERRIDDEN")
                            // }
                          // disabled={
                          //   data.validated == false && data.validationStatus != "OVERRIDDEN" || data.published == false
                          // }
                          />
                          <label for={data?.id}>&nbsp;</label>
                        </td>
                          :
                          ''
                        }

                        <td>
                          {data?.metadata == null
                            ? data.filename
                            : data?.metadata?.title == null
                              ? data.filename
                              : data?.metadata?.title}
                        </td>

                        <td>
                          {data.videoType ? data.videoType : selectedType}
                        </td>
                        <td>
                          {data.duration != null
                            ? convertTimestampToTime(data.duration)
                            : data.metadata != null
                              ? data.metadata.duration != null
                                ? data.metadata.duration
                                : "N/A"
                              : "N/A"
                          }
                        </td>
                        <td>{DateStamp(data.dateUploaded)}</td>
                        <td>
                          <div
                            data-tip={data?.jobPercentComplete == null || data.transcoded == true ? "" : data?.jobPercentComplete + "%"}
                            className={
                              data?.transcoded == true
                                ? "complete"
                                : actionStatus(data?.transcodingStatus)
                            }
                          >
                            {data.transcoded !== true ? (
                              errorstatus(data?.transcodingStatus)
                            ) : (
                              <>
                                <img src={donecheck} />
                                {DateStamp(data.dateTranscoded)}
                              </>
                            )}
                          </div>
                        </td>


                        <td>
                          <div
                            onClick={data?.active !== false ?() => {
                              validationation(data);
                            }:null}
                            className={
                              data?.transcoded === true
                                ? data?.validated === true &&
                                data?.validationStatus == "COMPLETED"
                                  ? "complete"
                                  : actionStatus(data?.validationStatus)
                                : "pending"
                            }
                          >
                            {data?.validated == true && data?.validationStatus == "COMPLETED" ? (
                               < >
                               <img src={donecheck} />
                               {DateStamp(data?.dateValidated)}
                             </>
                           
                            ) : (
                              errorstatus(data?.validationStatus)
                            )}
                          </div>
                        </td>
                        <td>
                          <div
                            className={data?.published === true ? 'complete' : 'pending'}

                          >
                            {data.datePublished === null ? (
                              blankValue
                            ) : (
                              <>
                                <img src={donecheck} />
                                {DateStamp(data.datePublished)}
                              </>
                            )}

                          </div>


                        </td>
                        <ReactTooltip type="error" />
                      </tr>

                    );
                  })}
              </tbody>
            </table>
          </div>
          {actionDetail.length != 0 && (
            <div className="table-pagination">
              <div className="pagination-count">
                <div className="count">
                  Page: {currentPage} of {totalPages}
                </div>
                <div className="count">
                  {`${Math.min(
                    (currentPage - 1) * itemsPerPage + 1,
                    actionData.totalElements
                  )}-${Math.min(
                    currentPage * itemsPerPage,
                    actionData.totalElements
                  )} of ${actionData.totalElements}`}
                </div>
                <div className="pagination-arrow">
                  <a
                    className="prev"
                    onClick={() =>
                      currentPage > 1 && setCurrentPage(currentPage - 1)
                    }
                  >
                    <img src={leftArrow} alt="Previous" />
                  </a>
                  <div className="text-center">
                    <form 
                      onSubmit={handlePageKeyPress}
                    >
                      <input 
                        type='text' 
                        defaultValue={currentPage} 
                        onChange={setPage} 
                      />
                    </form>
                  </div>
                  <a
                    className="next"
                    onClick={() =>
                      currentPage < actionData.totalPages &&
                      setCurrentPage(currentPage + 1)
                    }
                  >
                    <img src={rightArrow} alt="Next" />
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>


      <ToastContainer />
      {validatepopup && (
        <Validatepopup
          handleClosePopup={() => setvalidatepopup(false)}
          validateinfo={validatedata}
          assetsingledata={assetSignledata}
          permission={permission}
        />
      )}
    </div>
  );
}
