import React, { useState, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import './imageViewer.scss';
import { capitalizeFirstLetter } from '../../service/commonFunc';
import { Modal } from 'react-bootstrap'; // Import Bootstrap modal component

const ImageViewer = ({ imageUrl, urlimg }) => {
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    // Find the index of the selected image
    const selectedIndex = imageUrl.findIndex(img => img.img === urlimg);
    if (selectedIndex !== -1) {
      setCurrentImageIndex(selectedIndex);
    }
  }, [imageUrl, urlimg]);

  const images = imageUrl.map(url => ({
    original: url.img,
    thumbnail: url.img,
  }));

  const toggleFullScreen = () => {
    setShowFullScreen(!showFullScreen);
  };

  const handleSlide = index => {
    setCurrentImageIndex(index);
  };

  return (
    <div className="">
      <Modal  className="imageViewer-modal"  show={showFullScreen} onHide={toggleFullScreen} dialogClassName="modal-lg">
        <Modal.Body>
          <div className="image-viewer">
            <ImageGallery
              items={images}
              showThumbnails={true}
              showNav={showFullScreen}
              showPlayButton={false}
              showFullscreenButton={false}
              showCloseButton={true}
              autoPlay={false}
              onScreenChange={toggleFullScreen}
              onSlide={handleSlide}
              startIndex={currentImageIndex} // Set the startIndex to the currentImageIndex
            />
          </div>
          {showFullScreen && (
            <div className="custom-text-fullscreen">
              <div className="title-font">

              <p>
  {imageUrl[currentImageIndex].Name?.metadata == null
    ? (imageUrl[currentImageIndex].Name?.filename.length > 60
        ? imageUrl[currentImageIndex].Name?.filename.substring(0, 60) + '...'
        : imageUrl[currentImageIndex].Name?.filename)
    : (imageUrl[currentImageIndex].Name?.metadata?.title.length > 60
        ? imageUrl[currentImageIndex].Name?.metadata?.title.substring(0, 60) + '...'
        : imageUrl[currentImageIndex].Name?.metadata?.title)}
  <span className="small-grey-text text-light">
    {imageUrl[currentImageIndex].Name?.metadata == null
      ? null
      : ' (' + (imageUrl[currentImageIndex].Name?.filename.length > 20
                ? imageUrl[currentImageIndex].Name?.filename.substring(0, 20) + '...'
                : imageUrl[currentImageIndex].Name?.filename) + ')'}
  </span>
</p>


              </div>
            
              <p className="small-font">Device Type : {capitalizeFirstLetter(imageUrl[currentImageIndex].deviceType) == "Tv" ? "TV" :capitalizeFirstLetter(imageUrl[currentImageIndex].deviceType) }</p>
              <p className="small-font">Poster Type : {capitalizeFirstLetter(imageUrl[currentImageIndex].posterType)}</p>
            </div>
          )}
             <button type="button"
            onClick={toggleFullScreen} class=" p-5 image-gallery-icon image-gallery-fullscreen-button" aria-label="Open Fullscreen"><svg class="image-gallery-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3"></path></svg></button>
        </Modal.Body>
      </Modal>
      {!showFullScreen && (
        <button
          type="button"
          onClick={toggleFullScreen}
          className="image-gallery-icon image-gallery-fullscreen-button"
          aria-label="Open Fullscreen">
          <svg
            className="image-gallery-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round">
            <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"></path>
          </svg>
        </button>
      )}
    </div>
  );
};

export default ImageViewer;
