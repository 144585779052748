import React, {useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { postInstantData, getInstantData } from "../../api/apiMethod";
import { Get_User, Create_User } from "../../service/API_URL";
import { useSelector } from "react-redux";
import Multiselect from 'multiselect-react-dropdown';
import closeChip from '../../assets/images/close-chip.svg';
import dropdownArrow from '../../assets/images/dropdown-arrow.svg';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function UserAdd() {
  // Default Form Value on Initial Load
  const defaultFormValue = {
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
    roleId: '',
    password: 'Runn@123'
  }

  // Default Error Value on Initial Load
  const defaultFormError = {
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
    roleId: '',
    password: ''
  }

  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const { Territorys, UserRoles} = useSelector(state =>
    ({ Territorys: state.CountryList?.data.data,
      UserRoles: state.RolesData?.data
    }));

  const [userList, setUserList] = useState([]);
  const [userRole, setUserRole] = useState([]);
  const [adminRole, setAdminRole] = useState([]);
  const [editObject, setEditObject] = useState(null);
  const locationState = useLocation();
  const user = locationState.state?.user;
  const getUser = async () => {
    const response = await getInstantData(Get_User);
    setUserList(response);
  }
  
  const [origin, setOrigin] = useState(91);
  const [territoryOption, setTerritoryOption] = useState([]);
  // Form State
  const [formData, setFormData] = useState({ ...defaultFormValue });
  // Error State

  const [errors, setErrors] = useState({ ...defaultFormError });

  // Get Terretory Option List
  const getCountriesList = () => {
    let countries = Territorys?.map((item) => {
      return { phoneCode: item.phoneCode, territoryId: item.iso, territoryName: item.niceName }
    });

    countries?.sort((a, b) => {
      return a.territoryName.localeCompare(b.territoryName);
    });

    if (countries && countries?.length) {
      setTerritoryOption(countries);
    }
  }
  const handleFormChange = (event, field, min, max, minMsg, maxMsg) => {
    const value = event.target.value;
    const newFormData = { ...formData, [field]: value };
    setFormData(newFormData);
    if(field === 'email') {
      const  emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(value)) {
        setErrors({ ...errors, [field]: '' });
        return;
      } else {
        setErrors({ 
          ...errors, 
          [field]:'Please enter a valid email'
        });
        return;
      }
    }
    if(min) {
      if(value.length < min) {
        setErrors({ ...errors, [field]: minMsg ?? `Please enter minimum ${min} characters` });
        return;
      } else {
        setErrors({ ...errors, [field]: '' });
      }
    }
    if(max) {
      if(value.length > max) {
        setErrors({ ...errors, [field]: maxMsg ?? `Please enter maximum ${max} characters` });
        return;
      } else {
        setErrors({ ...errors, [field]: '' });
      }
    } 
    if(!value.length) {
      setErrors({ ...errors, [field]: 'Field is required' });
      return;
    }   
    setErrors({ ...errors, [field]: '' });
  }

  useEffect(() => {
    getCountriesList();
    getUser();
    if(user) {
      setEditObjectData();
    }
  }, []);

  const setEditObjectData = () => {   
    setEditObject(user);
    if(user.mobileNumber.length > 10) {
      // get last 10 digit of mobile number
      const mobileNumber = user.mobileNumber.slice(-10);
      
      // remove last 10 digit of mobile number
      const origin = user.mobileNumber.slice(0, -10);

      setOrigin(origin);
      setFormData({...user, mobileNumber});
    } else {
      setFormData({...user});
    }
    const roles = user.roles.map(item => {
      return item.id;
    });
    setUserRole(roles); 
  }

  const handleSelect = (selectedList, selectedItem) => {
    const items = [...userRole, selectedItem.id]
    setUserRole(items)
  }

  const handleRemove = (selectedList, removedItem) => {
    const items = selectedList.map(item => {
      return item.id;
    });
    setUserRole(items);
  }
  
  const handleSelectAdmin = (selectedList, selectedItem) => {
    const items = [...adminRole, selectedItem.id]
    setAdminRole(items)
  }

  const handleRemoveAdmin = (selectedList, removedItem) => {
    const items = selectedList.map(item => {
      return item.id;
    });
    setAdminRole(items);
  }
  const validateForm = () => {
    let invalid = false;
    for (const key in errors) {
      if (errors[key] !=='' || !formData[key]) {
        if(invalid) {
          invalid = true;
        } else if(key === 'roleId') {
          invalid = (userRole.length === 0) ? true : false;
        } else if(key !== 'roleId'){
          invalid = true;
        } else {
          invalid = false;
        }
      }
    }
    return invalid;
  }
  const saveForm = async () => {
    const URL = user ? `${Get_User}/${user.id}` : Create_User;
    let data = {
    ...formData
    }
    data.roleId = [...userRole];
    data.mobileNumber = origin + data.mobileNumber;
    if(user) {
      data = {
        firstName: data.firstName,
        lastName: data.lastName,
        mobileNumber : data.mobileNumber,
        email: data.email,
        roleId: data.roleId,
      }
    }
    try {
      await postInstantData(URL, data);
      const msg = user ? 'User updated successfully' : 'User added successfully';
      toast.success(msg, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      setFormData({ ...defaultFormValue });
      setUserRole([]);
      setTimeout(() => {
        navigate('/user-management');
      }, 3000);
    } catch (error) {
      const msg = user ? 'User update failed' : 'User creation failed';
      toast.error(msg, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    }
  }
  const resetForm = () => {
    if(user) {
      setEditObjectData();
      return;
    }
    setFormData({ ...defaultFormValue });
    setUserRole([]);
  }
  return (
    
    <div className="content-body">
      <div className="dashboard-content">
        <div className="top-content">
          <div className="left-side">
            <h3>{user ? 'User Update' : 'User Add'}</h3>
          </div>
          <div className="right-side">
          </div>
        </div>
        
        <div className="channel-creation-form">
          <form>
            <div className={`form-container overflow-hidden p-b-80`}>
              <div className='form-row'>
                <div className='form-col'>
                  <div className={errors.firstName ? 'form-field hasError' : 'form-field'}>
                    <label>First Name <i>*</i></label>
                    <input name='firstName' type='text' placeholder='Enter first name' required value={formData.firstName} onChange={(event) => handleFormChange(event, 'firstName', 4)} onBlur={(event) => handleFormChange(event, 'firstName', 4)}  />
                    <div className='field-info-row'>
                      {errors.firstName ? <span className='field-error'>{errors.firstName}</span> : null}
                    </div>
                  </div>
                </div>
                <div className='form-col'>
                  <div className={errors.lastName ? 'form-field hasError' : 'form-field'}>
                    <label>Last Name <i>*</i></label>
                    <input name='lastName' type='text' placeholder='Enter last name' required value={formData.lastName} onChange={(event) => handleFormChange(event, 'lastName', 4)} onBlur={(event) => handleFormChange(event, 'lastName', 4)} />
                    <div className='field-info-row'>
                      {errors.lastName ? <span className='field-error'>{errors.lastName}</span> : null}
                    </div>
                  </div>
                </div>
              </div>

              <div className='form-row'>
                <div className='form-col'>
                  <div className={errors.email ? 'form-field hasError' : 'form-field'}>
                    <label>Email <i>*</i></label>
                    <input name='email' type='email' placeholder='Enter email' required value={formData.email} onChange={(event) => handleFormChange(event, 'email')} onBlur={(event) => handleFormChange(event, 'email')} />
                    <div className='field-info-row'>
                      {errors.email ? <span className='field-error'>{errors.email}</span> : null}
                    </div>
                  </div>
                </div>
                <div className='form-col'>
                  <div className={errors.channelId ? 'form-field hasError' : 'form-field'}>
                    <label>Contact Details <i>*</i></label>
                    {/* <input disabled={isEdit ? "disabled" : ""} name='channelId' type='text' placeholder='Enter channel name' required value={channelId} onChange={handleChennelIdChange} onBlur={handleChennelIdChange} />
                    {errors.channelId ? <span className='field-error'>{errors.channelId}</span> : null} */}
                    <div className='form-row split-box'>
                      <div className='form-col width-20'>
                        <select
                          className="detail-inputs"
                          name="phoneCountryCode"
                          value={origin}
                          onChange={(e) => setOrigin(e.target.value)}
                          required
                        >
                          {territoryOption &&
                              territoryOption?.map((item) => {
                                  return (
                                      <option key={item.territoryId} value={item.phoneCode}>
                                          {item.phoneCode} - {item.territoryName}
                                      </option>
                                  );
                              })}
                        </select>
                      </div>
                      <div className='form-col width-80'>
                        <input name='phone' type='number' placeholder='Enter phone number' required value={formData.mobileNumber} onChange={(event) => handleFormChange(event, 'mobileNumber', 10,10)} onBlur={(event) => handleFormChange(event, 'mobileNumber', 10,10)} />
                      </div>
                    </div>
                    <div className='field-info-row'>
                      {errors.mobileNumber ? <span className='field-error'>{errors.mobileNumber}</span> : null}
                    </div>
                  </div>
                </div>
              </div>

              <div className='form-row'>
                <div className='form-col'>
                  <div className='form-field'>
                    <label>User permissions <i>*</i></label>
                    {UserRoles &&
                      <Multiselect
                        disable={false}
                        className="multiselect-field"
                        options={UserRoles}
                        displayValue="name"
                        selectedValues={UserRoles?.filter(option => userRole?.includes(option?.id))}
                        onSelect={handleSelect}
                        onRemove={handleRemove}
                        placeholder="Select User Premission"
                        customCloseIcon={<img src={closeChip} />}
                        showArrow={false}
                        customArrow={<img src={dropdownArrow} />}
                      />
                    }
                  </div>
                </div>
                {/* <div className='form-col'>
                  <div className='form-field'>
                    <label>Admin permissions</label>
                    {UserRoles?.[0]?.permissions &&
                      <Multiselect
                        disable={false}
                        className="multiselect-field"
                        options={UserRoles[0].permissions}
                        displayValue="description"
                        selectedValues={UserRoles[0]?.permissions?.filter(option => adminRole?.includes(option?.id))}
                        onSelect={handleSelectAdmin}
                        onRemove={handleRemoveAdmin}
                        placeholder="Select Admin Premission"
                        customCloseIcon={<img src={closeChip} />}
                        showArrow={false}
                        customArrow={<img src={dropdownArrow} />}
                      />
                    }
                  </div>
                </div> */}
              </div>
            </div>
              
            
            <div className='form-container'>
              <div className='button-row'>
                <button type='button' className='btn btn-danger' onClick={resetForm}>Reset</button>
                <button type='button'
                disabled={validateForm()}
                onClick={() => { saveForm() }} 
                className='btn btn-primary'>
                  {user ? 'Update' : 'Create'}
                </button>
              </div>
            </div>

          </form>
          <ToastContainer />
          </div>

      </div>
    </div>
  );
}

export default UserAdd;