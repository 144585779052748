import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css'; // Use this CSS module
import styles from './AdsPopup.module.scss';
import { Partner_create, Partner_update } from '../../api/api';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'react-time-picker/dist/TimePicker.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css'; // Use this CSS module

import deleteicon from "../../assets/images/deleteicon.png";
import editIcon from '../../assets/images/edit-outline.svg';
import TimePicker from 'react-time-picker';

const AdsPopup = ({ show, handleClose, data, parentData, updateData }) => {
    const [partnerName, setPartnerName] = useState(null);
    const [editData, setEditData] = useState(null);
    const [durationData, setDurationData] = useState(null);
    const [editIndex, setEditIndex] = useState(null);
    const [activeData, setActiveData] = useState(null);
    const [validForm, setValidForm] = useState('invalid');
    const [allowedEdit, setAllowedEdit] = useState(true);

    const updateAds = async () => {
        if(validForm === 'invalid') {
            return;
        }
        updateData(data);
        setEditData(null);
        handleClose();
        setValidForm('invalid');
    };

    const closeAds = async() => {
        setEditData(null);
        handleClose();
        setValidForm('invalid');
    }

    const isValidTime = (time) => {
        if(!time) return false;
        const msTimeString = parentData.extendedProps.endAt.toString().split(":");
        const msTime = ((parseInt(msTimeString[0])*60*60+parseInt(msTimeString[1])*60+parseInt(msTimeString[2]))*1000);
        const allowedTimeString = time.toString().split(":");
        const allowedTime = (parseInt(allowedTimeString[0])*60*60+parseInt(allowedTimeString[1])*60+parseInt(allowedTimeString[2]))*1000;
        return (allowedTime <= msTime) ? true : false;
    }

    const millisecondsToHuman = (duration) => {
        let milliseconds = parseInt((duration % 1000) / 100),
           seconds = parseInt((duration / 1000) % 60),
           minutes = parseInt((duration / (1000 * 60)) % 60),
           hours = parseInt((duration / (1000 * 60 * 60)) % 24);
   
         hours = (hours < 10) ? "0" + hours : hours;
         minutes = (minutes < 10) ? "0" + minutes : minutes;
         seconds = (seconds < 10) ? "0" + seconds : seconds;
   
         return hours + ":" + minutes + ":" + seconds ;
   }
   
    const setEndTimeAd = (time, duration, returnStart) => {
        if(!duration || !time) return;
        const msTimeString = time.toString().split(":");
        const msDurationArray = duration.toString().split(":");
        const msDuration = (msDurationArray.length > 1) ? (parseInt(msDurationArray[0])*60*60+parseInt(msDurationArray[1])*60+parseInt(msDurationArray[2]))*1000 : duration;
        const msTime = ((parseInt(msTimeString[0])*60*60+parseInt(msTimeString[1])*60+parseInt(msTimeString[2]))*1000) + msDuration;
        return (returnStart) ? millisecondsToHuman(msTime) : [millisecondsToHuman(msTime), millisecondsToHuman(msDuration)];
    }

    const setFormData = (data,index) => {
        setEditData(data.startAt);
        setActiveData(data)
        setDurationData(millisecondsToHuman(data.duration))
        setEditIndex(index+1);
    }

    const deleteEvent = (index) => {
        data.splice(index,1);
        setEditData(null);
        updateData(data);
        setValidForm('valid');
    }

    const getTitle = (data) => {
        if(data.type === 'ads') {
            return data.type;
        } else {
            return data.title;
        }
    }

    const overlapData = () => {
      //  console.log(data)
        isAdOverlap();
    }

    /**
     * Checks if there is any overlap between the scheduled dates and times of the ads in the data array.
     * If there is an overlap, it updates the start and end times of the ad to ensure non-overlap.
     *
     * @return {void} This function does not return a value.
     */
    const isAdOverlap = () => {
        data.forEach((d, index) => {
            if(index === 0) {
                return;
            }
            const start = d.scheduledDate + 'T' + d.startAt;
            const end = d.scheduledDate + 'T' + d.endAt;
            data.forEach((indata, insideIndex) => {
                if(index === insideIndex) {
                    return;
                }
                const prevStart = indata.scheduledDate + 'T' + indata.startAt;
                const prevEnd = indata.scheduledDate + 'T' + indata.endAt;
    
                if (start >= prevStart && end <= prevEnd) {
                    data[index].startAt = indata.endAt;
                    data[index].endAt = setEndTimeAd(data[index].startAt , data[index].duration, true);
                }
                //end-time in between any of the events
                else if (end >= prevStart && end <= prevEnd) {
                    data[index].startAt = indata.endAt;
                    data[index].endAt = setEndTimeAd(data[index].startAt , data[index].duration, true);
                }
                //any of the events in between/on the start-time and end-time
                else if (start <= prevStart && end >= prevEnd) {
                    data[index].startAt = indata.endAt;
                    data[index].endAt = setEndTimeAd(data[index].startAt , data[index].duration, true);
                }
            })
            

            
        })
        // Events
        // let events = ads;
        // const ds = new Date(eventStartDay);
        // eventStartDay= new Date(ds.toGMTString()).toISOString().slice(0, 19);
        // const de = new Date(eventEndDay);
        // eventEndDay= new Date(de.toGMTString()).toISOString().slice(0, 19);
        // let iteratedData = [];
        // for (let i = 0; i < events.length; i++) {
        //   const eventA = events[i];
        //   let newData = false;
        //   if(typeof eventA.start !== 'string') {
        //     if(!eventA.start) {
        //       eventA.start = eventA.scheduledDate + 'T' + eventA.startAt;
        //       eventA.end = eventA.scheduledDate + 'T' + eventA.endAt;
        //     } else {
        //       eventA.start = new Date(eventA.start.toGMTString()).toISOString().slice(0, 19);
        //       eventA.end = new Date(eventA.end.toGMTString()).toISOString().slice(0, 19);
        //     }
        //   }
      
        //   // start-time in between any of the events
        //   if (eventStartDay >= eventA.start && eventStartDay <= eventA.end) {
        //     iteratedData = [eventA, i];
        //     newData = true;
        //   }
        //   //end-time in between any of the events
        //   if (eventEndDay >= eventA.start && eventEndDay <= eventA.end) {
        //     iteratedData = [eventA,i];
        //     newData = true;
        //   }
        //   //any of the events in between/on the start-time and end-time
        //   if (eventStartDay <= eventA.start && eventEndDay >= eventA.end) {
        //     iteratedData = [eventA,i];
        //     newData = true;
        //   }
        //   if(newData) {
        //     eventStartDay = eventA.start;
        //     eventEndDay = eventA.end;
        //   }
        // }
        // return iteratedData;
      }

    useEffect(() => {
        
        let yesterDay = new Date();
        yesterDay = new Date(yesterDay.setDate(yesterDay.getDate() - 1)).toISOString().substring(0,10);
        if(new Date(parentData?.extendedProps.scheduledDate).getTime() < new Date(yesterDay).getTime())  { 
            setAllowedEdit(false)
        }
    }, [parentData])

    return (
        <Modal show={show} className={styles.partnermodal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{parentData?.title} | Schedule: {parentData?.extendedProps.scheduledDate} at {parentData?.extendedProps.startAt} | Duration: {millisecondsToHuman(parentData?.extendedProps.totalDuration)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {editData && <div>
                        {/* <div className='edit-title-ad'>
                            Change start time of Ad {editIndex}
                        </div> */}
                        <div className={styles.adsTimeEditor}>
                            <div className={styles.adsTimeFirstChild}>
                                <label  className={styles.adsLabel}>Start Time of {getTitle(activeData)}</label>
                                <TimePicker 
                                    value={editData}
                                    hourPlaceholder="HH"
                                    minutePlaceholder="mm"
                                    secondPlaceholder="ss"
                                    maxDetail="second"
                                    maxTime={parentData?.extendedProps.endAt}
                                    minTime={parentData?.extendedProps.startAt}
                                    format="HH:mm:ss"
                                    onChange={(e) => {
                                        if(!isValidTime(e)) {
                                            setValidForm('invalid');
                                            return;
                                        }
                                        const newData = [...data];
                                        newData[editIndex-1].startAt = e;
                                        newData[editIndex-1].endAt = setEndTimeAd(e , newData[editIndex-1].duration, true);
                                        data = newData;
                                        setEditData(e);
                                        setValidForm('valid');
                                        overlapData()
                                    }}
                                    disableClock={true}
                                />
                            </div>
                            {(activeData.type === 'ad' || activeData.type === 'Ad') && 
                                <div>
                                    <label className={styles.adsLabel}>Duration</label>
                                    <TimePicker 
                                        value={durationData}
                                        hourPlaceholder="HH"
                                        minutePlaceholder="mm"
                                        secondPlaceholder="ss"
                                        maxDetail="second"
                                        // maxTime={parentData?.extendedProps.endAt}
                                        minTime="00:00:01"
                                        format="HH:mm:ss"
                                        onChange={(e) => {
                                            const newData = [...data];
                                            const ar = setEndTimeAd(newData[editIndex-1].startAt, e, false);
                                            newData[editIndex-1].endAt = ar[0]
                                            newData[editIndex-1].duration = ar[1];
                                            data = newData;
                                            setDurationData(e);
                                            setValidForm('valid');
                                            overlapData()
                                        }}
                                        disableClock={true}
                                    />
                                </div>
                            }
                        </div>
                    </div>}
                    {data?.length > 0 &&
                        <div className="table">
                            <table className="striped-table">
                                <thead>
                                    <th>Subject</th>
                                    <th>Start At</th>
                                    <th>End At</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </thead>
                                <tbody>
                                    {data.map((data, index) => (
                                            <tr key={index}>
                                                <td>{getTitle(data)}</td>
                                                <td>{data.startAt}</td>
                                                <td>{data.endAt}</td>
                                                <td onClick={()=> {
                                                    setFormData(data,index)
                                                }} className={styles.editButton}>
                                                    {allowedEdit && <img src={editIcon}/>}
                                                </td>
                                                <td onClick={()=> {
                                                    deleteEvent(index)
                                                }}
                                                className={styles.editButton}>
                                                    <img src={deleteicon} />
                                                </td>
                                            </tr>
                                    ))
                                }    
                                </tbody>
                            </table>
                        </div>     
                    }           
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className='Cancel btn btn-primary' onClick={closeAds}>
                    Cancel
                </button>
                {allowedEdit && <button className={`Add btn btn-primary ${validForm}`} onClick={updateAds}>
                    Update
                </button>}
            </Modal.Footer>
        </Modal>
    );
};

export default AdsPopup;
