import React, { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/css/style.scss";
import "./index.scss";
import Loader from "../../component/Loader/Loader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Devicetype, getDeviceType } from "../../api/api";
import ChannelSequencing from "./ChannelSequencing";
import ContentSequencing from "./ContentSequencing";
import FeatureFiltering from "./FeatureFiltering";
import Banners from "./Banners";
import ChannelsFilterSequencing from "./ChannelsFilterSequencing";
import Spotlight from "./Spotlight";
import { permissionDetails } from '../../utility/localStoageData';
import viewIcon from '../../assets/images/carbon_view.svg';

function StoreFront() {
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [ThemeData, setThemeData] = useState([]);
  const [CategoriesData, setCategoriesData] = useState([]);
  const [activeTab, setActiveTab] = useState("Default");
  const [platforms, setPlatforms] = useState([]);
  const [permission, setpermission] = useState(false);
 
  const [activeSequenceTab, setActiveSequenceTab] =
    useState("Channel Sequencing");

  const getdevice = async () => {
    const device = await Devicetype();
  };

  const getAllDevicesType = async() => {
    const response = await getDeviceType();
    setPlatforms(response?.data);
  }

  const getPermission = () => {
    let findEdit = permissionDetails();
    if (findEdit.includes("STOREFRONT_EDIT")) setpermission("edit");
    else if (findEdit.includes("STOREFRONT_READ")) setpermission("read");
    else setpermission(null);
  }

  useEffect(() => {
    getAllDevicesType();
    getPermission();
    deviceTabChange("Default");
    getdevice();
  }, []);

  const deviceTabChange = async (tabName) => {
    setActiveTab(tabName);
  };

  const sequenceTabChange = async (tabName) => {
    setActiveSequenceTab(tabName);
  };

  const getActiveTabPage = () => {
    switch (activeSequenceTab) {
      case "Channel Sequencing":
        return (
          <ChannelSequencing deviceType={activeTab} platforms={platforms} permission={permission} />
        );
      case "Content Sequencing TV":
        return (
          <ContentSequencing deviceType={activeTab} platforms={platforms} permission={permission} />
        );
      case "Feature Filtering":
        return (
          <FeatureFiltering deviceType={activeTab} platforms={platforms} permission={permission} />
        );
      case "Banners":
        return <Banners deviceType={activeTab} platforms={platforms} permission={permission} />;
      case "Channels Filter Sequencing":
        return (
          <ChannelsFilterSequencing
            deviceType={activeTab}
            platforms={platforms}
            permission={permission}
          />
        );
      case "Spotlight":
        return <Spotlight deviceType={activeTab} platforms={platforms} permission={permission} />;

      default:
        return "";
    }
  };
  //pagination
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;
  const currentThemeData = ThemeData.slice(startIndex, endIndex);
  const currentCategoryData = CategoriesData.slice(startIndex, endIndex);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // console.log("ASsas", totalnosetting?.numberOfElements);
  return (
    permission !== null?
    <div className="content-body">
      <div className="dashboard-content">
        <div className="top-content">
          <div className="left-side">
            <h3>Store Front</h3>
          </div>
        </div>

        {showLoader ? (
          <div className="tableLoader">
            <Loader></Loader>
          </div>
        ) : (
          <Fragment>
            <div className="setting-genre">
              {platforms.map((tab) => (
                <span
                  key={tab}
                  className={activeTab == tab ? "active" : ""}
                  onClick={() => deviceTabChange(tab)}
                >
                  {tab}
                </span>
              ))}
            </div>
            <div className="store-front-data">
              <div className="feature-tab-wrapper">
                <div className="tab-wrapper-shadow">
                  {[
                    "Channel Sequencing",
                    "Content Sequencing TV",
                    "Feature Filtering",
                    "Banners",
                    "Channels Filter Sequencing",
                    "Spotlight",
                  ].map((tab) => (
                    <div
                      className={`tab-item-wrapper ${
                        activeSequenceTab == tab ? "active" : ""
                      }`}
                      key={tab}
                      onClick={() => sequenceTabChange(tab)}
                    >
                      <div className="tab-item">{tab}</div>
                    </div>
                  ))}
                </div>
              </div>
              {getActiveTabPage()}
            </div>
          </Fragment>
        )}

        <ToastContainer />
      </div>
    </div>
    :
    ''
  );
}

export default StoreFront;
