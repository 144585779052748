import React from 'react';
import './popup.scss';
import error from "../../assets/images/error-icon.svg"
const ErrorPopup = ({ message, closebox, overrides, btntext }) => {
 
  return (
    <div className="error-popup">
      <div className="error-content">
        <div className='errorimg'> <img src={error} /></div>
    
<div className="error-message"><span>{message}</span></div>
        <div className='container-button'>
        <button className="default-even mx-3" onClick={()=>{overrides();closebox()}}>
      {btntext}
        </button>

        <button className="default-odd " onClick={()=>closebox()} >
     Cancel
        </button>
        </div>
    
      </div>
    </div>
  );
};

export default ErrorPopup;
