import React, { useState } from "react";
import  ReactTooltip  from 'react-tooltip'
import MapChart from './MapChart';
import MapDialog from './MapDialog';
import { useDispatch, useSelector } from "react-redux";



const AreaSelector = ({zooms}) => {
    const [content, setContent] = useState("");
    const [STName, setSTName] = useState("")
    const [show, setShow] = useState(false);
    const {
        appThemeReducer: { appTheme },
        adminModeReducer: { adminMode },
      } = useSelector((state) => state);

    const handleClose = () => setShow(false);
// console.log("zoom",zooms);
    return (
       <>
            
            <div
  style={{
    backgroundColor: appTheme === "dark" ? '#929DAC' : '#FFFFFF',
    borderRadius: '8px',
    // height: '450px', 
    overflow: 'hidden' ,
    padding:10
  }}
>  
  <MapChart setTooltipContent={setContent} setStateName={setSTName} setShowDistrict={setShow} setzoom={zooms}  />
  <ReactTooltip>{content}</ReactTooltip>
</div>
            </>
    );
}

export default AreaSelector;