import React, { useState } from 'react';
import "../../assets/css/style.scss"
import "./searchBar.scss";
import searchIcon from "../../assets/images/search.svg";

function SearchBar(props) {
  const [searchValue, setSearchValue] = useState(props.searchValue);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    if (props.keyPress) {
      props.searchOutput(event.target.value);
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && props.keyEnter) {
      props.searchOutput(searchValue);
    }
  }

  return (
    <div className='search-bar'>
        <input type='text' placeholder={props.placeholder} value={searchValue} onKeyDown={handleKeyDown} onChange={(event) => handleSearchChange(event)} />
        <button className='search-btn'><img src={searchIcon} /></button>
    </div>
  )
}

SearchBar.defaultProps = {
  searchValue: "",
  searchOutput: () => {},
  keyEnter : true,
  keyPress : true
};

export default SearchBar;
