import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import rightarrow from "../../assets/images/rightvector.png";
import leftarrow from "../../assets/images/Vector.png";
import icroundsearch from "../../assets/images/ic_round-search.svg";
import sortingUp from '../../assets/images/sortarrow.png';
import sortingDown from '../../assets/images/desc.png';
import sortingDefault from '../../assets/images/sorting-default.svg';
import { getAuditLogs } from "../../api/api";
import Loader from "../../component/Loader/Loader";
import Filter from "./filter";
import { duration } from "moment";

const AuditLogs = () => {
    // <----------------- React hooks and variables ------------------>

    const [auditLogList, setAuditLogList] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [sortColumn, setSortColumn] = useState('timestamp');
    const [sortDirection, setSortDirection] = useState('desc');
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [pageJump, setPageJump] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    const [filterVal, setFilterVal] = useState("DAY");
    const {
        appThemeReducer: { appTheme },
      } = useSelector((state) => state);
    const startIndex = (currentPage) * pageSize;

    // <----------------- End of react hooks and variables ------------------>


    // <----------------- Functions performed on this page ------------------>

    // Function to get Audit logs all data
    const getAllAuditLogs = async () => {
        setShowLoader(true);
        const params = {
            pageNumber: currentPage,
            pageSize: pageSize,
            sortBy: sortColumn,
            sortOrder: sortDirection,
            duration: filterVal,
        }
        const res = await getAuditLogs(params);
        setAuditLogList(res?.data);
        setTotalPages(res?.data?.totalPages);
        setCurrentPage(res?.data?.number);
        setShowLoader(false);
    }
    const getAllAuditLogsByPage = async (pageNo) => {
        const params = {
            pageNumber: pageNo,
            pageSize: pageSize,
            sortBy: sortColumn,
            sortOrder: sortDirection,
            duration: filterVal,
        }
        const res = await getAuditLogs(params);
        setAuditLogList(res?.data);
        setTotalPages(res?.data?.totalPages);
        setCurrentPage(res?.data?.number);
    } 
    // Function to filter and sort data
    const handleSorting = (column) => {
        let sorting = {
          sortColumn: column,
          sortDirection: null
        }
        setSortColumn(column);
        if (sortDirection == 'asc') {
          setSortDirection('desc');
          sorting.sortDirection = 'desc';
        } else {
          setSortDirection('asc');
          sorting.sortDirection = 'asc';
        }
    
        let queryParams = {
          sortBy: sorting.sortColumn,
          sortOrder: sorting.sortDirection,
          pageNumber: currentPage,
          pageSize: pageSize,
          duration: filterVal,
        };
        getAuditLogs(queryParams);
    }
    // Function to filter data on the bases of duration
    const handleDuration = async (duration) => {
        setShowLoader(true);
        const params = {
            pageNumber: 0,
            pageSize: 10,
            sortBy: 'timestamp',
            sortOrder: 'desc',
            duration: duration,
        }
        const res = await getAuditLogs(params);
        setAuditLogList(res?.data);
        setTotalPages(res?.data?.totalPages);
        setCurrentPage(res?.data?.number);
        setShowLoader(false);
    }
    // Function to Filter datatable by day and month
    const filterChangeData = async (view) => {
        setFilterVal(view.toUpperCase());
        handleDuration(view.toUpperCase())
    }

    useEffect(() => {
        getAllAuditLogs();
    }, [currentPage]);

    // Functions to set the pagination of datatable
    const handlePrev = () => {
        if (currentPage > 0) {
          setCurrentPage(currentPage - 1);
        }
    }
    const handleNext = () => {
        if (currentPage < (totalPages - 1)) {
          setCurrentPage(currentPage + 1);
        }
    }
    const setPage = (e) => {
        if (e.target.value > 0 && e.target.value <= totalPages) {
          setPageJump(e.target.value - 1);
        }
    }
    const handleKeyPress = (event) => {
        event.preventDefault();
        getAllAuditLogsByPage(pageJump);
    }

    // Function to set the Date Format
    const getDateFormat = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleString('en-US', {
            weekday: 'short', // E.g., "Tue"
            year: 'numeric',  // E.g., "2024"
            month: 'short',    // E.g., "Sep"
            day: 'numeric',    // E.g., "3"
            hour: 'numeric',   // E.g., "3 PM"
            minute: 'numeric', // E.g., "45"
            hour12: true       // Use 12-hour time format
        });
    }

    // <----------------- End of functions performed on this page ------------------>


    // <----------------- JSX ------------------>
    return (
        <div className="content-body">
            <div className="dashboard-content">

                {/* Top content (heading and search bar) */}
                <div className="top-content">
                    <div className="left-side">
                        <h3 className="mb-0">Audit Logs</h3>
                    </div>
                    <div className="right-side">
                        <div className="icon-list">
                            {/* <div className={`search-box bg-white p-1 mr-16 ${appTheme}`}>
                                <img src={icroundsearch} alt="Search" />
                                <input
                                    className="border-0"
                                    type="text"
                                    placeholder="Search"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    // onKeyPress={handleKeyPress}
                                />
                            </div> */}
                            <Filter handleChange={filterChangeData} />
                        </div>
                    </div>
                </div>
                {/* End of top content (heading and search bar) */}

                {/* Page Content */}
                <div className='channel-setup-table'>
                    <div className="cms-table-list tenant-table">
                        <table className='w-100'>
                            <thead>
                                <tr>
                                    <th onClick={() => { handleSorting('timestamp') }} >Time
                                        <span>
                                            {sortColumn == 'timestamp' && sortDirection == 'asc' ? <img src={sortingUp} /> : null}
                                            {sortColumn == 'timestamp' && sortDirection == 'desc' ? <img src={sortingDown} /> : null}
                                            {sortColumn != 'timestamp' ? <img src={sortingDefault} /> : null}
                                        </span>
                                    </th>
                                    <th onClick={() => { handleSorting('contentType') }}>Content Type
                                        <span>
                                            {sortColumn == 'contentType' && sortDirection == 'asc' ? <img src={sortingUp} /> : null}
                                            {sortColumn == 'contentType' && sortDirection == 'desc' ? <img src={sortingDown} /> : null}
                                            {sortColumn != 'contentType' ? <img src={sortingDefault} /> : null}
                                        </span>
                                    </th>
                                    <th onClick={() => { handleSorting('userEmail') }}>Email
                                        <span>
                                            {sortColumn == 'userEmail' && sortDirection == 'asc' ? <img src={sortingUp} /> : null}
                                            {sortColumn == 'userEmail' && sortDirection == 'desc' ? <img src={sortingDown} /> : null}
                                            {sortColumn != 'userEmail' ? <img src={sortingDefault} /> : null}
                                        </span>
                                    </th>
                                    <th onClick={() => { handleSorting('metadata') }}>Meta Data
                                        <span>
                                            {sortColumn == 'metadata' && sortDirection == 'asc' ? <img src={sortingUp} /> : null}
                                            {sortColumn == 'metadata' && sortDirection == 'desc' ? <img src={sortingDown} /> : null}
                                            {sortColumn != 'metadata' ? <img src={sortingDefault} /> : null}
                                        </span>
                                    </th>
                                    <th onClick={() => { handleSorting('action') }}>Action
                                        <span>
                                            {sortColumn == 'action' && sortDirection == 'asc' ? <img src={sortingUp} /> : null}
                                            {sortColumn == 'action' && sortDirection == 'desc' ? <img src={sortingDown} /> : null}
                                            {sortColumn != 'action' ? <img src={sortingDefault} /> : null}
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    auditLogList?.content?.map((data)=> {
                                        return(
                                            
                                            showLoader?
                                            <Loader></Loader>
                                            :
                                            <tr>
                                                <td>
                                                    {getDateFormat(data?.timestamp)}
                                                </td>
                                                <td>{data?.contentType}</td>
                                                <td>{data?.userEmail}</td>
                                                <td>{data?.metadata}</td>
                                                <td>{data?.action}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        <div className="table-pagination">
                            <div className="pagination-count">
                                <div className="count">
                                    Page: {(currentPage + 1)} of {totalPages}
                                </div>
                                <div className="count">
                                    {`${startIndex + 1}-${Math.min(startIndex + auditLogList?.numberOfElements)} of ${auditLogList?.totalElements}`}
                                </div>
                                <div className="pagination-arrow">
                                    <a
                                        className={`prev ${currentPage == 0 ? 'disable' : ''}`}
                                        onClick={handlePrev}
                                    >
                                        <img src={leftarrow} alt="Previous" />
                                    </a>
                                    <div className="text-center">
                                        <form onSubmit={handleKeyPress}>
                                            <input 
                                            type='text' 
                                            defaultValue={currentPage + 1} 
                                            onChange={setPage} 
                                            />
                                        </form>
                                    </div>
                                    <a
                                        className={`next ${currentPage == (totalPages - 1) ? 'disable' : ''}`}
                                        onClick={handleNext}
                                    >
                                        <img src={rightarrow} alt="Next" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End of Page Content */}

                {/* Toast Alert */}
                <ToastContainer />
                {/* End of Toast Alert */}

            </div>
        </div>
    )
    // <----------------- End of JSX ------------------>
}

export default AuditLogs;