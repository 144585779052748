import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';
import { getInstantData, deleteInstantData } from "./api/apiMethod";
import { METADATA_SCHEMA, VALIDATION_SCHEMA, Configdata_URL, GetChannelSchemes_URL } from './service/API_URL';

import { isLogin, isLoginstatus } from "./service/commonFunc";

import AppRoutes from './routes/appRouting';
import { GenreAction, ThemesAction, RoleDataAction, CategoriesAction, AudioLanguagesAction,CountryAction, MaturityListAction, TimeZoneListAction, MetaDataAction, ValidationDataAction, ConfigDataAction, ChannelSchemesAction } from "./Redux/slices";

import {
  Theme_list,
  Categories_list,
  Genres_list,
  Language_list,
  Country_list,
  MatuarityRatings_ListData_list,
  TerritoriesList,
  Timezone_list,
  Roles_list
} from "./api/api";
import { useDispatch } from "react-redux";

import { saveTenantInfo } from './service/multiTenancyUtil';
import { image_url } from './service/Constant';
import favicon from '../src/assets/images/logo.png'


// Save tenant info when the application loads
saveTenantInfo();

// Update tenant info every minute
setInterval(saveTenantInfo, 60000);

function App() {
  const dispatch = useDispatch();

  const accessStore = () => {
    const tenantLocalData = localStorage.getItem('tenantInfo');
    const tenantLocalDataParse = JSON.parse(tenantLocalData);
    let link = document.querySelector('link[rel="shortcut icon"]') ||
    document.querySelector('link[rel="icon"]');
    document.title = tenantLocalDataParse?.superTenant?.appTitle;
    if (!link) {
        link = document.createElement('link');
        link.id = 'favicon';
        link.rel = 'shortcut icon';
        document.head.appendChild(link);
    }
    link.href = `${tenantLocalDataParse?.superTenant?.logoImagePath? image_url + tenantLocalDataParse?.superTenant?.logoImagePath : favicon}`;

  };

  useEffect(() => {
    accessStore();
    const fetchQuery = {searchText: "", sortBy:"name",sortOrder:"asc" }
    const fetchData = isLoginstatus();

    (async() => {
      if(fetchData) {
        const GenreData = await Genres_list(fetchQuery);
        if (GenreData?.content) {
          dispatch(GenreAction(GenreData?.content));
        }
        const Timezone = await Timezone_list();
        if (Timezone?.data) {
          dispatch(TimeZoneListAction(Timezone?.data));
        }
        const CategoriesData = await Categories_list(fetchQuery);
        if (CategoriesData?.content) {
          dispatch( CategoriesAction(CategoriesData?.content));
        }
        const ThemesData = await  Theme_list(fetchQuery);
        if (ThemesData?.content) {
          dispatch(ThemesAction(ThemesData?.content));
        }
        const LanguageData = await  Language_list(fetchQuery);
        if (LanguageData?.content) {
          dispatch(AudioLanguagesAction(LanguageData?.content));
        }
        const AudioData = await Language_list(fetchQuery);
        if (AudioData?.content) {
          dispatch(AudioLanguagesAction(AudioData?.content));
        }
        const MaturityData = await MatuarityRatings_ListData_list(fetchQuery);
        if (MaturityData?.content) {
          dispatch(MaturityListAction(MaturityData?.content));
        }
        const TerritoriesLists = await TerritoriesList(fetchQuery);
        if (TerritoriesLists.status == true) {
          dispatch(CountryAction(TerritoriesLists));
        }
        const RolesList = await Roles_list();
        if (RolesList?.data) {
          dispatch(RoleDataAction(RolesList?.data));
        }
        getMetaData();
        getValidationData();
        getConfigData();
        getChanneelSchemes();
      }
    })()
    
  }, [])

  const getMetaData = async () => {
    const metaData = await getInstantData(METADATA_SCHEMA, {});
    if (metaData?.data) {
      dispatch(MetaDataAction(metaData?.data));
    }
  }
  const getValidationData = async () => {
    const validationData = await getInstantData(VALIDATION_SCHEMA, {});
    if (validationData?.data) {
      dispatch(ValidationDataAction(validationData?.data));
    }
  }
  const getConfigData = async () => {
    const configData = await getInstantData(Configdata_URL, {});
    if (configData?.data) {
      dispatch(ConfigDataAction(configData?.data));
    }
  }
  const getChanneelSchemes = async () => {  
    const channelSchemes = await getInstantData(GetChannelSchemes_URL, {});
    if (channelSchemes?.data) {
      dispatch(ChannelSchemesAction(channelSchemes?.data));
    }
  };

  return (
  <AppRoutes />
  );
}

export default App;
