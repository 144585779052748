import axios from "axios";
import { buildTenantIdV2 } from "./multiTenancyUtil";

const publicInstance = axios.create({
  baseURL: process.env.REACT_APP_REFRESHTOKEN_URL,
  withCredentials: false,
});

publicInstance.interceptors.request.use((config) => {
  const tenantId = buildTenantIdV2();
  console.log("tenantId:", tenantId);
  if (tenantId) {
    config.headers["X-Tenant-Id"] = tenantId;
  }
  return config;
});

export default publicInstance;
