import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { CategoryDurationAction } from "../../Redux/slices";
import { GetMonitorScheduleList, Monitor_Datalist } from "../../api/api";
import "./GridMonitor";
import Loader from "../../component/Loader/Loader";
import SlideBar from "../../component/sidebar/sidebar";
import MonitorGridView from "../../component/girdMonitorView/monitoringGridView";


const GridMonitor = () => {
    const [allChannelData, setAllChannelData] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    const dispatch = useDispatch();
    const { MonitorDate } = useSelector(state => ({ MonitorDate: state.MonitorDate?.data }));

    const {
        appThemeReducer: { appTheme },
        adminModeReducer: { adminMode },
    } = useSelector((state) => state);

    useEffect(() => {
        if (MonitorDate) {
            fetchSchedule(MonitorDate);
        }

        return () => clearInterval();
    }, [MonitorDate]);

    const fetchSchedule = async (MonitorDate) => {
        setShowLoader(true);
        try {
            const scheduleData = await GetMonitorScheduleList(MonitorDate);
            console.log('Fetched Schedule Data:', scheduleData);
            if (!scheduleData?.data?.length) {
                setAllChannelData([]);
                dispatch(CategoryDurationAction([]));
                return;
            }

            const monitordate = new Date(MonitorDate).toISOString().substring(0, 10);
            const allDataPromises = scheduleData.data.map(channel => Monitor_Datalist(channel.id, monitordate));
            const allDataResponses = await Promise.all(allDataPromises);
            console.log('All Data Responses:', allDataResponses);

            const aggregatedData = scheduleData.data.map((channel, index) => {
                const gridData = allDataResponses[index];
                console.log(`Processing Channel ID: ${channel.id}`, gridData);
                if (!gridData?.data) return null;

                let data = gridData.data;


                data = getDuration(data, MonitorDate);
                data = filterLiveVideos(data, MonitorDate);

                return {
                    channelId: channel.id,
                    channelName: channel.name,
                    channelLogo: channel.channelLogo[0]?.thumbnailSourcePath || '',
                    scheduleItems: data?.scheduleItems || [],
                    playbackUrl: channel.playbackUrl || ''
                };
            }).filter(item => item !== null && (item.scheduleItems.length > 0 || item.playbackUrl));

            console.log('Aggregated Data:', aggregatedData);

            setAllChannelData(aggregatedData);
            dispatch(CategoryDurationAction(aggregatedData));
        } catch (error) {
            console.error("Error fetching schedule data:", error);
            setAllChannelData([]);
            dispatch(CategoryDurationAction([]));
        } finally {
            setShowLoader(false);
        }
    };

    const getDuration = (data, MonitorDate) => {
        if (data?.scheduleItems.length === 0) return data;

        data.scheduleItems = data.scheduleItems.map(item => {
            const elapsedTime = getElapsedTime(item.startTime);
            item.elapsedTime = elapsedTime;
            return item;
        });

        return data;
    };

    const filterLiveVideos = (data, MonitorDate) => {
        if (!data?.scheduleItems) return data;
        const currentTime = new Date();

        // Filter live videos based on current time
        const liveItems = data.scheduleItems.filter(item => {
            const startDateTime = new Date(item.startTime);
            const endDateTime = new Date(item.endTime);
            return currentTime >= startDateTime && currentTime <= endDateTime;
        });

        // If no live items, find upcoming items
        if (liveItems.length === 0) {
            const upcomingItems = data.scheduleItems.filter(item => {
                const startDateTime = new Date(item.startTime);
                return startDateTime > currentTime;
            });

            // Sort upcoming items by start time
            upcomingItems.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));

            if (upcomingItems.length > 0) {
                liveItems.push(upcomingItems[0]);
            }
        }

        data.scheduleItems = liveItems;
        return data;
    };

    const getElapsedTime = (startTime) => {
        const currentDate = new Date();
        const startDate = new Date(startTime);
        const elapsedMilliseconds = currentDate - startDate;

        return millisecondsToHuman(elapsedMilliseconds);
    };

    const millisecondsToHuman = (duration) => {
        let seconds = parseInt((duration / 1000) % 60),
            minutes = parseInt((duration / (1000 * 60)) % 60),
            hours = parseInt((duration / (1000 * 60 * 60)) % 24);

        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;

        return `${hours}:${minutes}:${seconds}`;
    };

    const backMenu = () => {
        setIsSidebarCollapsed(prev => !prev);
    };


    return (

<>
         {/* <div className={`content-body ${(adminMode || appTheme == "dark")}`}> */}
            {/* <div className={`dashboard-content ${(adminMode || appTheme == "dark")}`}> */}
                {/* <div className="top-content"> */}



                <div style={{padding:10}}>


                    {showLoader ? <Loader /> : <MonitorGridView allChannelData={allChannelData} />}
                </div>
            {/* </div> */}


        {/* </div> */}
        {/* </div> */}

</>
    );
}

export default GridMonitor;
