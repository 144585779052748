const Filter = ({handleChange}) => {

    const filterChange = (e) => {
        const val = e.target.value;
        handleChange(val);
    }

    return (
        <div className="view-duration">
            <div className="duration-block">
                {/* <h5>
                    Duration <small className="smalltext">({Durations})</small>{" "}
                </h5> */}
                <div className="radio-list">
                    <div className="radio-box">
                        <input
                        id="day"
                        value="day"
                        name="Duration"
                        type="radio"
                        onChange={(e) => filterChange(e)}
                        defaultChecked
                        />
                        <label htmlFor="day">Today</label>
                    </div>
                    <div className="radio-box">
                        <input
                        id="Week"
                        value="Week"
                        name="Duration"
                        type="radio"
                        onChange={(e) => filterChange(e)}
                        />
                        <label htmlFor="Week">This Week</label>
                    </div>
                    <div className="radio-box">
                        <input
                        id="Month"
                        value="Month"
                        name="Duration"
                        type="radio"
                        onChange={(e) => filterChange(e)}
                        />
                        <label htmlFor="Month">This Month</label>
                    </div>
                    <div className="radio-box">
                        <input
                        id="Year"
                        value="Year"
                        name="Duration"
                        type="radio"
                        onChange={(e) => filterChange(e)}
                        />
                        <label htmlFor="Year">This Year</label>
                    </div>
                    <div className="radio-box">
                        <input
                        id="All"
                        value="All"
                        name="Duration"
                        type="radio"
                        onChange={(e) => filterChange(e)}
                        />
                        <label htmlFor="All">All</label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Filter;