
import Multiselect from 'multiselect-react-dropdown';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import closeChip from '../../assets/images/close-chip.svg';
import dropdownArrow from '../../assets/images/dropdown-arrow.svg';

import styles from './editable-grid.module.scss';

const genres = [
  {name: 'GN-DRA'},
  {name: 'GN-COM'},
  {name: 'GN-SCF'},
  {name: 'GN-CRI'},
  {name: 'GN-ROM'}
]
const themes = [
  {name: 'TH-SMV'},
  {name: 'TH-EMO'}
]

export function MultiDropdown({
  row,
  column,
  onRowChange,
  onClose }) {
 // console.log(column)
  let selectedColumn;
  let labels;
  
  const [TerritoryDetails, setTerritoryDetails] = useState([]);
  const { CountryList, GenreList, ThemesList, CategoriesList, AudioLanguageList } = useSelector(state =>
     ({ CountryList: state.CountryList, GenreList: state.GenreList, ThemesList: state.ThemesList, CategoriesList: state.CategoriesList,
       AudioLanguageList: state.AudioLanguageList }))
  let selectedValue = row[column.key];
  //useEffect(() => {
    switch(column.key) {
      case 'genres':
        //selectedColumn = genres;
        selectedColumn = GenreList?.data;
        labels = 'Select Genre';
        break;
      case 'themes':
        selectedColumn = ThemesList?.data;
        labels = 'Select Theme';
        break;
      case 'categories':
          selectedColumn = CategoriesList?.data;
          labels = 'Select Categories';
          break;
      case 'audioLanguages':
          selectedColumn = AudioLanguageList?.data;
          labels = 'Select Audio';
          break;
      case 'originalLanguage':
          selectedColumn = AudioLanguageList?.data;
          labels = 'Select Orignal Language';
          break;
      case 'territories':
          selectedColumn = CountryList?.data?.data;
         // console.log(selectedColumn)
          labels = 'Select Territory';
          break;
      default :
      //  selectedColumn = state?.CategoriesList?.data;
       // labels = 'Select Categories';
        break;
    }
    
 // }, [state])
  
      // Hanlde Field Change
  const handleFieldChangeKeyValue = (field, event, required = false) => {
    onRowChange({ ...row, [column.key]: event });
  }

  return (
    <>
    <Multiselect
       placeholder={labels}
       options={selectedColumn}
       selectedValues={selectedValue}
       displayValue="name"
       onSelect={(event) => { handleFieldChangeKeyValue('territoryDetails', event, true) }}
       onRemove={(event) => { handleFieldChangeKeyValue('territoryDetails', event, true) }}
     //  customCloseIcon={<img src={closeChip}/>}
     //  showArrow={true}
     //  customArrow={<img src={dropdownArrow} 
      />
    </>
  );
}