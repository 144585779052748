import React from "react";
import "./loader.scss";
import loading from "../../assets/images/loading.gif";
function UILoader(props) {
  return (
    <div className="ui-loader">
      <img src={loading} />
    </div>
  );
}

export default UILoader;
