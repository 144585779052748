import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import SuperAdminHeader from "../component/dashboard-header/superAdminHeader";
import SuperAdminSidebar from "../component/sidebar/superAdminSidebar";
function SuperAdminLayout() {
  return (
    <>
      {" "}
      <SuperAdminHeader />
      <div className="main-content">
        <SuperAdminSidebar />
        <Outlet />
      </div>
    </>
  );
}

export default SuperAdminLayout;
