import React, {useState, useEffect} from 'react';
import './FileUploaderContainer.scss';
import laptopIcon from '../../assets/images/laptop.png';
import mobileIcon from '../../assets/images/mobile.png';
import tabletIcon from '../../assets/images/tablet.png';
import tvIcon from '../../assets/images/tv.png';
import FilesDragAndDrop from '../FileUploader/file-uploader';

function FileUploaderContainer(props) {

    const defaultFiles = {
        Mobile: {
            thumbnail: { file: null, multiPart: null, type: 'Mobile', subType: 'thumbnail' },
            poster: { file: null, multiPart: null, type: 'Mobile', subType: 'poster' },
            coverart: { file: null, multiPart: null, type: 'Mobile', subType: 'coverart' },
        },
        Tablet: {
            thumbnail: { file: null, multiPart: null, type: 'Tablet', subType: 'thumbnail' },
            poster: { file: null, multiPart: null, type: 'Tablet', subType: 'poster' },
            coverart: { file: null, multiPart: null, type: 'Tablet', subType: 'coverart' },
        },
        TV: {
            thumbnail: { file: null, multiPart: null, type: 'TV', subType: 'thumbnail' },
            poster: { file: null, multiPart: null, type: 'TV', subType: 'poster' },
            coverart: { file: null, multiPart: null, type: 'TV', subType: 'coverart' },
        },
        Web: {
            thumbnail: { file: null, multiPart: null, type: 'Web', subType: 'thumbnail' },
            poster: { file: null, immultiPartg: null, type: 'Web', subType: 'poster' },
            coverart: { file: null, immultiPartg: null, type: 'Web', subType: 'coverart' },
        } ,        
        Partner: {
            thumbnail: { file: null, multiPart: null, type: 'Partner', subType: 'thumbnail' },
            poster: { file: null, immultiPartg: null, type: 'Partner', subType: 'poster' },
            coverart: { file: null, immultiPartg: null, type: 'Partner', subType: 'coverart' },
        }         
    }
    const [uploadedFiles, setUploadedFiles] = useState(props.files? props.files : defaultFiles);
    const [deviceList, setDeviceList] = useState([
        {
            deviceName: "Mobile",
            iconPath: mobileIcon,
            isActive: true,
            deviceId: 3
        },
        {
            deviceName: "Tablet",
            iconPath: tabletIcon,
            isActive: false,
            deviceId: 4
        },
        {
            deviceName: "TV",
            iconPath: tvIcon,
            isActive: false,
            deviceId: 1
        },
        {
            deviceName: "Web",
            iconPath: laptopIcon,
            isActive: false,
            deviceId: 2
        },
        {
            deviceName: "Partner",
            iconPath: laptopIcon,
            isActive: false,
            deviceId: 5
        }
    ]);

    const handleTabClick = (deviceId) => {
        const tabData = deviceList.map((item) => {
            if (item.deviceId === deviceId) {
                item.isActive = true;
            } else {
                item.isActive = false;
            }
            return item;
        });
        setDeviceList(tabData);
    }

    const onChangeHandler = (event) => {
        const currentFiles = {...uploadedFiles};
        currentFiles[event.type][event.subType] = {
            file: event.file,
            multiPart: event.multiPart,
            type: event.type,
            subType: event.subType
        }
        setUploadedFiles(currentFiles);
        props.onChange(currentFiles);
    }

    return (
        <div className='file-uploader-container'>
            <div className='file-uploader-device-list'>
                <ul>
                    {
                        deviceList.map((device) => {
                            return (
                                <li key={device.deviceId} className={device.isActive? 'active': ''} onClick={() => { handleTabClick(device.deviceId)}}>
                                    <img src={device.iconPath} />
                                    <span>{device.deviceName}</span>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <div className='file-uploader-body'>
                { deviceList[2].isActive?
                    <div className='tv'>
                        <FilesDragAndDrop 
                            id="tv-thumb"
                            label={'TV Logo'}
                            size={'16x14'}
                            type={'TV'}
                            subType={'thumbnail'}
                            onChange={(event) => onChangeHandler(event)}
                            readOnly={false}
                            sizeLable={'Size 16 x 14px'}
                            file={uploadedFiles['TV']['thumbnail']}
                        />
                        <FilesDragAndDrop 
                            id="tv-poster"
                            label={'TV Hero Art'}
                            size={'16x14'}
                            type={'TV'}
                            subType={'poster'}
                            onChange={(event) => onChangeHandler(event)}
                            readOnly={false}
                            sizeLable={'Size 16 x 14px'}
                            file={uploadedFiles['TV']['poster']}
                        />
                        <FilesDragAndDrop 
                            id="tv-cover"
                            label={'TV Cover Art'}
                            size={'16x14'}
                            type={'TV'}
                            subType={'coverart'}
                            onChange={(event) => onChangeHandler(event)}
                            readOnly={false}
                            sizeLable={'Size 16 x 14px'}
                            file={uploadedFiles['TV']['coverart']}
                        />
                    </div>:null
                }
                
                { deviceList[3].isActive?
                <div className='web'>
                    <FilesDragAndDrop 
                        id="web-thumb"
                        label={'Web Logo'}
                        size={'16x14'}
                        type={'Web'}
                        subType={'thumbnail'}
                        onChange={(event) => onChangeHandler(event)}
                        readOnly={false}
                        sizeLable={'Size 16 x 14px'}
                        file={uploadedFiles['Web']['thumbnail']}
                    />
                    <FilesDragAndDrop 
                        id="web-poster"
                        label={'Web Hero Art'}
                        size={'16x14'}
                        type={'Web'}
                        subType={'poster'}
                        onChange={(event) => onChangeHandler(event)}
                        readOnly={false}
                        sizeLable={'Size 16 x 14px'}
                        file={uploadedFiles['Web']['poster']}
                    />
                    <FilesDragAndDrop 
                        id="web-cover"
                        label={'Web Cover Art'}
                        size={'16x14'}
                        type={'Web'}
                        subType={'coverart'}
                        onChange={(event) => onChangeHandler(event)}
                        readOnly={false}
                        sizeLable={'Size 16 x 14px'}
                        file={uploadedFiles['Web']['coverart']}
                    />
                </div>:null
                }

                { deviceList[0].isActive?
                    <div className='mobile'>
                        <FilesDragAndDrop 
                            id="mobile-thumb"
                            label={'Mobile Logo'}
                            size={'16x14'}
                            type={'Mobile'}
                            subType={'thumbnail'}
                            onChange={(event) => onChangeHandler(event)}
                            readOnly={false}
                            sizeLable={'Size 16 x 14px'}
                            file={uploadedFiles['Mobile']['thumbnail']}
                        />
                        <FilesDragAndDrop 
                            id="mobile-poster"
                            label={'Mobile Hero Art'}
                            size={'16x14'}
                            type={'Mobile'}
                            subType={'poster'}
                            onChange={(event) => onChangeHandler(event)}
                            readOnly={false}
                            sizeLable={'Size 16 x 14px'}
                            file={uploadedFiles['Mobile']['poster']}
                        />
                        <FilesDragAndDrop 
                            id="mobile-cover"
                            label={'Mobile Cover Art'}
                            size={'16x14'}
                            type={'Mobile'}
                            subType={'coverart'}
                            onChange={(event) => onChangeHandler(event)}
                            readOnly={false}
                            sizeLable={'Size 16 x 14px'}
                            file={uploadedFiles['Mobile']['coverart']}
                        />
                    </div>:null
                }

                { deviceList[1].isActive?
                    <div className='tablet'>
                        <FilesDragAndDrop 
                            id="tablet-thumb"
                            label={'Tablet Logo'}
                            size={'16x14'}
                            type={'Tablet'}
                            subType={'thumbnail'}
                            onChange={(event) => onChangeHandler(event)}
                            readOnly={false}
                            sizeLable={'Size 16 x 14px'}
                            file={uploadedFiles['Tablet']['thumbnail']}
                        />
                        <FilesDragAndDrop 
                            id="tablet-poster"
                            label={'Tablet Hero Art'}
                            size={'16x14'}
                            type={'Tablet'}
                            subType={'poster'}
                            onChange={(event) => onChangeHandler(event)}
                            readOnly={false}
                            sizeLable={'Size 16 x 14px'}
                            file={uploadedFiles['Tablet']['poster']}
                        />
                        <FilesDragAndDrop 
                            id="tablet-cover"
                            label={'Tablet Cover Art'}
                            size={'16x14'}
                            type={'Tablet'}
                            subType={'coverart'}
                            onChange={(event) => onChangeHandler(event)}
                            readOnly={false}
                            sizeLable={'Size 16 x 14px'}
                            file={uploadedFiles['Tablet']['coverart']}
                        />
                    </div>:null
                }
                { deviceList[4].isActive?
                    <div className='tablet'>
                        <FilesDragAndDrop 
                            id="Partner-thumb"
                            label={'Tablet Logo'}
                            size={'16x14'}
                            type={'Partner'}
                            subType={'thumbnail'}
                            onChange={(event) => onChangeHandler(event)}
                            readOnly={false}
                            sizeLable={'Size 16 x 14px'}
                            file={uploadedFiles['Partner']['thumbnail']}
                        />
                        <FilesDragAndDrop 
                            id="Partner-poster"
                            label={'Tablet Hero Art'}
                            size={'16x14'}
                            type={'Partner'}
                            subType={'poster'}
                            onChange={(event) => onChangeHandler(event)}
                            readOnly={false}
                            sizeLable={'Size 16 x 14px'}
                            file={uploadedFiles['Partner']['poster']}
                        />
                        <FilesDragAndDrop 
                            id="Partner-cover"
                            label={'Tablet Cover Art'}
                            size={'16x14'}
                            type={'Partner'}
                            subType={'coverart'}
                            onChange={(event) => onChangeHandler(event)}
                            readOnly={false}
                            sizeLable={'Size 16 x 14px'}
                            file={uploadedFiles['Partner']['coverart']}
                        />
                    </div>:null
                }
            </div>
        </div>
    )
}

FileUploaderContainer.defaultProps = {
    onUpload: () => {},
    onChange : () => {}
};

export default FileUploaderContainer;