import { css } from '@linaria/core';

import styles from './editable-grid.module.scss';

import { useSelector } from 'react-redux';

const textEditorInternalClassname = `
 rdg.TextEditor {
    appearance: none;

    box-sizing: border-box;
    inline-size: 100%;
    block-size: 100%;
    padding-block: 0;
    padding-inline: 6px;
    border: 2px solid #ccc;
    vertical-align: top;
    color: var(--rdg-color);
    background-color: var(--rdg-background-color);

    font-family: inherit;
    font-size: var(--rdg-font-size);

    &:focus {
      border-color: var(--rdg-selection-color);
      outline: none;
    }

    &::placeholder {
      color: #999;
      opacity: 1;
    }
  }
`;

export const textEditorClassname = `rdg-text-editor ${textEditorInternalClassname} ${styles.customGridInput}`;

function autoFocusAndSelect(input) {
  input?.focus();
  input?.select();
}

export function TextEditorCustom({
  row,
  column,
  onRowChange,
  onClose
}) {
 const { MetaGridList } = useSelector(state => ({ MetaGridList: state.MetaGridList?.data }));
  return (column.key === 'programId' && !row['id']) ?
    <input
      className={textEditorClassname}
      ref={autoFocusAndSelect}
      value={row[column.key]}
      onChange={(event) => onRowChange({ ...row, [column.key]: event.target.value })}
      onBlur={() => onClose(true, false)}
    /> : <span className={styles.customReadonlyLabel}
    >{row[column.key]}</span>;
  
}