import React,{useState, useEffect} from 'react'
import SlideBar from '../../component/sidebar/sidebar'
import DashboardHeader from '../../component/dashboard-header'
import editoutline from "../../assets/images/edit-outline.svg"
import listView from "../../assets/images/list-view.svg"
import currentDatas from "../../dummyjson/channelList.json"
import "./scheduling.scss"
import rightarrow  from "../../assets/images/rightvector.png"
import leftarrow  from "../../assets/images/Vector.png"
import scheduleActive  from "../../assets/images/schedule-active.png"
import scheduleHalf  from "../../assets/images/schedule-half.png"
import icroundsearchGray from "../../assets/images/ic_round-search-gray.svg";
import { useNavigate } from "react-router-dom";
import { cloudFront_URL } from '../../service/API_URL';
import uploadicon from "../../assets/images/uploadicon.png";
import refresh from "../../assets/images/refresh.png";
import { toast, ToastContainer } from "react-toastify";
import { permissionDetails } from '../../utility/localStoageData';
import image_thum from '../../assets/images/image-thum.png';
import Loader from '../../component/Loader/Loader'

import {ScheduleUpload_URL} from "../../service/API_URL"

import {
  postFormData
} from "../../api/apiMethod";

import {
  GetScheduleList,
  ListApiSync
} from "../../api/api";

import CalendarComponent from './Schedule'
function Scheduling() {
    // const pageSize = 5; // Number of items per page
    const [currentPage, setCurrentPage] = useState(0);
    const [scheduleList, setScheduleList] = useState([]);
    const [daysList, setDaysList] = useState(7);
    const [startDay, setStartDay] = useState('');
    const [endDay, setEndDay] = useState('');
    const [uploadID, setUploadID] = useState('');
    const navigate = useNavigate();
    const [permission, setpermission] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [sortColumn, setSortColumn] = useState('name');
    const [sortDirection, setSortDirection] = useState('asc');
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [pageJump, setPageJump] = useState(0);
    const [showLoader, setShowLoader] = useState(true);
  
    const startIndex = (currentPage) * pageSize;
    const endIndex = startIndex + pageSize;
    const currentData = currentDatas.slice(startIndex, endIndex);

    const handlePrevPage = () => {
      if (currentPage > 0) {
        // setCurrentPage(currentPage - 1);
        fetchSchedule(currentPage - 1);
      }
    };
  
    const handleNextPage = () => {
      if (currentPage < (totalPages - 1)) {
        // setCurrentPage(currentPage + 1);
        fetchSchedule(currentPage + 1);
      }
    };

    const uploadFile = async (event) => {
      const selectedFile = event.target.files[0];
  
      if (selectedFile) {
        const formData = new FormData();
        formData.append("file", selectedFile);
        try {
          const URL = `${ScheduleUpload_URL}/${uploadID}/schedule/external/csv`;
          const response = await postFormData(URL, formData);
          if(response) {
            toast.success("File uploaded successfully", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 5000
            });
          } else {
            toast.error("File not uploaded", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 5000
            });
          }    
        } catch (error) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000
          }); 
        }

      }
    };

    useEffect(() => {
      getPermission();
      fetchSchedule(currentPage);
    },[daysList])
  

    const fetchSchedule = async (currentPage) => {
      setShowLoader(true);
      let queryParams = {
        searchText: searchText,
        sortBy: sortColumn,
        sortOrder: sortDirection,
        pageNumber: currentPage,
        pageSize: pageSize,
        daysList: daysList,
      };
      let scheduleData = await GetScheduleList(queryParams);
      setScheduleList(scheduleData?.data);
      setShowLoader(false);
      setCurrentPage(scheduleData?.data?.number);
      setTotalPages(scheduleData?.data?.totalPages);
      scheduleData = scheduleData?.data?.content;
      const startDate = scheduleData[0].commonScheduleChannelDetails[0].scheduleDate.split("-")
      setStartDay(startDate[2]+"-"+startDate[1]+"-"+startDate[0]);
      const len = scheduleData[0].commonScheduleChannelDetails.length;
      const endDate = scheduleData[0].commonScheduleChannelDetails[len -1].scheduleDate.split("-")
      setEndDay(endDate[2]+"-"+endDate[1]+"-"+endDate[0])
      // totalPages = Math.ceil(scheduleData.length / pageSize);
      
    }

    const redirectScheduleDetail = (channelId, channelName, type, scheduleType) => {
      const redirectPage = (scheduleType === 'LOOP') ? '/schedulingLoop' : '/schedulingDetail';
      navigate(redirectPage, {
        state: {
          channelId,
          channelName,
          type,
          permission: permission
        }
      });
    }

    const listUpdate = async (id, name, type, scheduleType) => {
      
      if(type === 'XLSInput') {
        setUploadID(id);
        document.getElementById('fileid').click();
      } else if(type === 'APIDriven') {
        const response = await ListApiSync(id);

        if (response) {
          toast.success("Channel Sync successfully", {
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 3000
          });
        } else {
          toast.error("Channel Sync failed", {
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 3000
          });
        }

      } else {
        redirectScheduleDetail(id, name, type, scheduleType);
      }
    }

    const getDate = (date) => {
      const current = new Date(date);
      const today = current.toLocaleDateString('en-US',{weekday: 'long'});
      return today.substring(0,3);
    }

    const getStatus = (status, returnImage) => {
      if(status === 'Complete') {
        return (returnImage) ? scheduleActive : 'scheduleActive';
      } else if(status === 'In Progress') {
        return (returnImage) ? scheduleHalf : 'scheduleHalfActive';
      } else {
        return (returnImage) ? '' : 'scheduleInActive';
      }
    }

    const getPermission = () => {
      let findEdit = permissionDetails();
      if (findEdit.includes("SCHEDULING_EDIT")) setpermission("edit");
      else if (findEdit.includes("SCHEDULING_READ")) setpermission("read");
      else setpermission(null);
    }

    const setPage = (e) => {
      if (e.target.value > 0 && e.target.value <= totalPages) {
        setPageJump(e.target.value - 1);
      }
    }

    const setSearch = (e) => {
      setSearchText(e.target.value);
    }

    const handleKeyPress = (event) => {
      event.preventDefault();
      fetchSchedule(pageJump);
    }

    const handleSearch = (event) => {
      event.preventDefault();
      fetchSchedule();
    }

  return (
      permission !== null?
      <div className="content-body Scheduling">
         <div className="dashboard-content">
          <div className="top-content">
            <div className="left-side">
              <h3>Scheduling</h3>
            </div>
          </div>
          <div className={`channel-form ${daysList == 15 ? 'w-1200' : ''}`}>
            <form
              onSubmit={handleSearch}
              >
              <div className="form-group">
                <input 
                  type="text" 
                  placeholder="Search by channel name" 
                  value={searchText}
                  onChange={setSearch}
                  />
                <button type="submit">
                  <img src={icroundsearchGray} alt="" />
                </button>
              </div>
            </form>
          </div>
          
            <div className={`table table-striped ${daysList == 15 ? 'w-1200' : 'w-800'}`}>
              {
                showLoader?
                <div className='tableLoader'>
                  <Loader></Loader>
                </div>
                :
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th width="30%">Channel Name</th>
                      <th width="55%">
                        Date : <br/><b>{startDay} to {endDay}</b>
                      </th>
                      {
                        permission === "edit"?
                        <th className='text-right'>Status for 
                          <select className='select-grid-header' onChange={(e) => setDaysList(e.target.value)}>
                            <option value='7'>Next 7 days</option>
                            <option value='15'>Next 15 days</option>
                          </select>
                        </th>
                        :
                        ''
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {
                      scheduleList?.content.length !== 0?
                      scheduleList?.content?.map((item, index) => (
                        <tr key={index} onClick={() => redirectScheduleDetail(item.channelId, item.channelName, item.epgType, item?.scheduleType)}>
                          <td><img src={item?.channelLogo[0]?.thumbnailSourcePath? cloudFront_URL + item?.channelLogo[0]?.thumbnailSourcePath : image_thum} className='img-40' alt="" /></td>
                          <td>{item.channelName}</td>
                          {item.scheduleType === 'LOOP' ? 
                          <td className='text-center'>
                            <div className='d-flex justify-content-between align-items-center'>
                              <a className="edit-icon list-view-icon">
                                <img src={listView} alt="" />
                              </a> 
                              <span class="list-view-btn fs-6 w-75 border py-1 rounded">List View</span>
                            </div>
                            {/* <button className='btn btn-primary btn-list' type="submit">
                              List View
                            </button> */}
                          </td>
                            : 
                          <td>
                            {item.commonScheduleChannelDetails.map((schedule, index) => (
                              <span key={index} className={`${getStatus(schedule.scheduleStatus, false)}`}>
                                {getDate(schedule.scheduleDate)} <img src={getStatus(schedule.scheduleStatus, true)} />
                              </span>
                            ))}
                          </td>
                          }
                          
                          {
                            permission === "edit"?
                              <td>
                                <a className="edit-icon" onClick={(event) => {event.stopPropagation(); listUpdate(item.channelId, item.channelName, item.epgType, item.scheduleType);}}>
                                  {
                                    (item.epgType === 'XLSInput') ?
                                    <img src={uploadicon} alt="" /> :
                                    (item.epgType === 'APIDriven') ?
                                    <img src={refresh} alt="" /> :
                                    <img src={editoutline} alt="" />
                                  }
                                  
                                </a>
                              </td>
                            :
                            ''
                          }
  
                        </tr>
                      ))
                      :
                      <tr>
                        <td className='text-center' colspan="5">
                          No data Found.
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              }
            </div>
          
          <div className={`table-pagination ${daysList == 15 ? 'w-1200' : ''}`}>
            <div className="pagination-count">
              <div className="count">
                Page: {currentPage + 1} of {totalPages}
              </div>
              <div className="count">
                {startIndex + 1}-{Math.min(endIndex, scheduleList.totalElements)} of {scheduleList.totalElements}
              </div>
              <div className="pagination-arrow">
                <a onClick={handlePrevPage} className="prev" >  <img src={leftarrow} alt="leftarrow" /></a>
                <div className="text-center">
                  <form 
                    onSubmit={handleKeyPress}
                  >
                    <input 
                      type='text' 
                      defaultValue={currentPage + 1} 
                      onChange={setPage} 
                    />
                  </form>
                </div>
                <a onClick={handleNextPage} className="next" >  <img src={rightarrow} alt="rightarrow" /></a>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        <input id='fileid' type='file' hidden
        onChange={(event) => uploadFile(event)} />
{/* <CalendarComponent /> */}
      </div>
      :
      ''
  )
}

export default Scheduling