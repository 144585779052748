import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
const theme = localStorage.getItem("theme");
const ChannelData = createSlice({
  name: "ChannelData",
  initialState: {
    data: [],
  },
  reducers: {
    ChannelDataAction: (data, action) => {
      // console.log("config", action.payload);

      data.data = action.payload;
    },
  },
});

export const { ChannelDataAction } = ChannelData.actions;
export const ChannelDataReducer = ChannelData.reducer;

const ChannelList = createSlice({
  name: "ChannelList",
  initialState: {
    data: [],
  },
  reducers: {
    ChannelListAction: (data, action) => {
      data.data = action.payload;
    },
  },
});

export const { ChannelListAction } = ChannelList.actions;
export const ChannelListReducer = ChannelList.reducer;

const ChannelSchemes = createSlice({
  name: "ChannelSchemes",
  initialState: {
    data: [],
  },
  reducers: {
    ChannelSchemesAction: (data, action) => {
      data.data = action.payload;
    },
  },
});

export const { ChannelSchemesAction } = ChannelSchemes.actions;
export const ChannelSchemesReducer = ChannelSchemes.reducer;

const MapData = createSlice({
  name: "MapData",
  initialState: {
    data: [],
  },
  reducers: {
    MapDataAction: (data, action) => {
      // console.log("config", action.payload);

      data.data = action.payload;
    },
  },
});

export const { MapDataAction } = MapData.actions;
export const MapDataReducer = MapData.reducer;

const AssetDetail = createSlice({
  name: " AssetDetail",
  initialState: {
    data: [],
  },
  reducers: {
    AssetDetailAction: (data, action) => {
      // console.log("config", action.payload);

      data.data = action.payload;
    },
  },
});

export const { AssetDetailAction } = AssetDetail.actions;
export const AssetDetailReducer = AssetDetail.reducer;

const Genre = createSlice({
  name: "genre",
  initialState: {
    data: [],
  },
  reducers: {
    GenreAction: (data, action) => {
      data.data = action.payload;
    },
  },
});
export const { GenreAction } = Genre.actions;
export const GenreReducer = Genre.reducer;

const Themes = createSlice({
  name: "themes",
  initialState: {
    data: [],
  },
  reducers: {
    ThemesAction: (data, action) => {
      data.data = action.payload;
    },
  },
});
export const { ThemesAction } = Themes.actions;
export const ThemesReducer = Themes.reducer;

const Categories = createSlice({
  name: "categories",
  initialState: {
    data: [],
  },
  reducers: {
    CategoriesAction: (data, action) => {
      data.data = action.payload;
    },
  },
});
export const { CategoriesAction } = Categories.actions;
export const CategoriesReducer = Categories.reducer;

const AudioLanguages = createSlice({
  name: "audioLanguages",
  initialState: {
    data: [],
  },
  reducers: {
    AudioLanguagesAction: (data, action) => {
      data.data = action.payload;
    },
  },
});
export const { AudioLanguagesAction } = AudioLanguages.actions;
export const AudioLanguagesReducer = AudioLanguages.reducer;

const Country = createSlice({
  name: "country",
  initialState: {
    data: [],
  },
  reducers: {
    CountryAction: (data, action) => {
      data.data = action.payload;
    },
  },
});
export const { CountryAction } = Country.actions;
export const CountryReducer = Country.reducer;

const MetaGrid = createSlice({
  name: "metagrid",
  initialState: {
    data: [],
  },
  reducers: {
    MetaGridAction: (data, action) => {
      data.data = action.payload;
    },
  },
});
export const { MetaGridAction } = MetaGrid.actions;
export const MetaGridReducer = MetaGrid.reducer;

const MaturityList = createSlice({
  name: "maturitylist",
  initialState: {
    data: [],
  },
  reducers: {
    MaturityListAction: (data, action) => {
      data.data = action.payload;
    },
  },
});
export const { MaturityListAction } = MaturityList.actions;
export const MaturityListReducer = MaturityList.reducer;

const TimeZoneList = createSlice({
  name: "timezonelist",
  initialState: {
    data: [],
  },
  reducers: {
    TimeZoneListAction: (data, action) => {
      data.data = action.payload;
    },
  },
});
export const { TimeZoneListAction } = TimeZoneList.actions;
export const TimeZoneListReducer = TimeZoneList.reducer;

const CategoryDuration = createSlice({
  name: "categoryduration",
  initialState: {
    data: [],
  },
  reducers: {
    CategoryDurationAction: (data, action) => {
      data.data = action.payload;
    },
  },
});
export const { CategoryDurationAction } = CategoryDuration.actions;
export const CategoryDurationReducer = CategoryDuration.reducer;

const SearchChannel = createSlice({
  name: "searchchannel",
  initialState: {
    data: [],
  },
  reducers: {
    SearchChannelAction: (data, action) => {
      data.data = action.payload;
    },
  },
});
export const { SearchChannelAction } = SearchChannel.actions;
export const SearchChannelReducer = SearchChannel.reducer;

const MonitorDate = createSlice({
  name: "monitordate",
  initialState: {
    data: [],
  },
  reducers: {
    MonitorDateAction: (data, action) => {
      data.data = action.payload;
    },
  },
});
export const { MonitorDateAction } = MonitorDate.actions;
export const MonitorDateReducer = MonitorDate.reducer;

const MetaData = createSlice({
  name: "metadata",
  initialState: {
    data: [],
  },
  reducers: {
    MetaDataAction: (data, action) => {
      data.data = action.payload;
    },
  },
});
export const { MetaDataAction } = MetaData.actions;
export const MetaDataReducer = MetaData.reducer;

const ValidationData = createSlice({
  name: "validationdata",
  initialState: {
    data: [],
  },
  reducers: {
    ValidationDataAction: (data, action) => {
      data.data = action.payload;
    },
  },
});
export const { ValidationDataAction } = ValidationData.actions;
export const ValidationDataReducer = ValidationData.reducer;

const ConfigData = createSlice({
  name: "configdata",
  initialState: {
    data: [],
  },
  reducers: {
    ConfigDataAction: (data, action) => {
      data.data = action.payload;
    },
  },
});
export const { ConfigDataAction } = ConfigData.actions;
export const ConfigDataReducer = ConfigData.reducer;

const RoleData = createSlice({
  name: "roledata",
  initialState: {
    data: [],
  },
  reducers: {
    RoleDataAction: (data, action) => {
      data.data = action.payload;
    },
  },
});
export const { RoleDataAction } = RoleData.actions;
export const RoleDataReducer = RoleData.reducer;

const StoreFront = createSlice({
  name: "StoreFrontContentSequence",
  initialState: {
    themeList: [],
    genreList: [],
    categoryList: [],
    languageList: [],
    channelList: [],
    featuredChannelList: [],
    bannerList: [],
    channelFilterList: [],
    spotlightContentData: [],
    TimeZoneList: [],
    countryList: [],
    MetaDataList: [],
    ConfigData: [],
    ChannelSchemes: [],
    RolesList: [],
  },
  reducers: {
    StoreFrontContentSequenceThemeListAction: (state, action) => {
      state.themeList = action.payload;
    },
    StoreFrontContentSequenceGenreListAction: (state, action) => {
      state.genreList = action.payload;
    },
    StoreFrontContentSequenceTimeZoneListAction: (state, action) => {
      state.timezoneList = action.payload;
    },
    StoreFrontContentSequenceCategoryListAction: (state, action) => {
      state.categoryList = action.payload;
    },
    StoreFrontContentSequenceLanguageListAction: (state, action) => {
      state.languageList = action.payload;
    },
    StoreFrontChannelListAction: (state, action) => {
      state.channelList = action.payload;
    },
    StoreFrontFeaturedChannelListAction: (state, action) => {
      state.featuredChannelList = action.payload;
    },
    StoreFrontBannerListAction: (state, action) => {
      state.bannerList = action.payload;
    },
    StoreFrontChannelFilterListAction: (state, action) => {
      state.channelFilterList = action.payload;
    },
    StoreFrontSpotlightContentListAction: (state, action) => {
      state.spotlightContentData = action.payload;
    },
    StoreFrontContentSequenceCountryListAction: (state, action) => {
      state.countryList = action.payload;
    },
    StoreFrontContentSequenceMetaDataAction: (state, action) => {
      state.metadataList = action.payload;
    },
    StoreFrontContentSequenceConfigDataAction: (state, action) => {
      state.configdataList = action.payload;
    },
    StoreFrontChannelSchemesAction: (state, action) => {
      state.ChannelSchemes = action.payload;
    },
    StoreFrontRolesListAction: (state, action) => {
      state.RolesList = action.payload;
    },
  },
});
export const {
  StoreFrontContentSequenceThemeListAction,
  StoreFrontContentSequenceGenreListAction,
  StoreFrontContentSequenceLanguageListAction,
  StoreFrontContentSequenceCategoryListAction,
  StoreFrontChannelListAction,
  StoreFrontFeaturedChannelListAction,
  StoreFrontBannerListAction,
  StoreFrontChannelFilterListAction,
  StoreFrontSpotlightContentListAction,
  StoreFrontContentSequenceTimeZoneListAction,
  StoreFrontContentSequenceCountryListAction,
  StoreFrontContentSequenceMetaDataAction,
  StoreFrontContentSequenceConfigDataAction,
  StoreFrontChannelSchemesAction,
  StoreFrontRolesListAction
} = StoreFront.actions;
export const StoreFrontReducer = StoreFront.reducer;

const Theme = createSlice({
  name: "Theme",
  initialState: {
    appTheme: theme || "light",
  },
  reducers: {
    appThemeAction: (state, action) => {
      state.appTheme = action.payload;
      localStorage.setItem("theme", action.payload);
    },
  },
});
export const { appThemeAction } = Theme.actions;
export const appThemeReducer = Theme.reducer;


const AdminMode = createSlice({
  name: "AdminMode",
  initialState: {
    adminMode: false,
  },
  reducers: {
    adminModeAction: (state, action) => {
      state.adminMode = action.payload;
      localStorage.setItem("adminMode", action.payload);
    },
  },
});
export const { adminModeAction } = AdminMode.actions;
export const adminModeReducer = AdminMode.reducer;